import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingService, CommonService, LoaderService, TokenService } from '../../../../__services';

declare var CKEDITOR: any;
@Component({
	selector: 'app-content-management',
	templateUrl: './content-management.component.html',
	styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {

	ckeConfig: any;
	content: string = '';
	isEditorLoaded: boolean = false;
	isDisabled: boolean = false;
	page: String = '';
	isPermission: boolean = false;
	authUserPermission: any;
	constructor(
		public settingService: SettingService,
		public commonService: CommonService,
		public loader: LoaderService,
		public activatedRoute: ActivatedRoute,
		public tokenService: TokenService,
		public router: Router
	) {
		this.loader.show();
		this.authUserPermission = this.tokenService.getPermission();
		this.isPermission = this.commonService.isPermission(this.authUserPermission, this.activatedRoute.data['value'].permission_code);
		this.page = this.activatedRoute.data['value'].page;
	}

	ngOnInit(): void {
		this.ckeConfig = {
			allowedContent: false,
			forcePasteAsPlainText: true
		};
		this.fetchContent();
	}

	// Save terms & conditions content
	saveContent() {
		this.isDisabled = true;
		let editorContent: any = { content: this.content };
		this.settingService.updateContent(editorContent, this.page).subscribe(res => {
			this.commonService.growlSuccess(res.message);
			this.isDisabled = false;
		}, error => {
			this.commonService.growlError(error.message);
			this.isDisabled = false;
		});
	}

	onReady(event) {
		this.loader.hide();
	}

	fetchContent() {
		this.settingService.fetchContent(this.page, false).subscribe(res => {
			this.content = res && res.success && res.response ? res.response.content : '';
		}, error => {
		});

		let _this = this;
		setTimeout(() => {
			_this.isEditorLoaded = true;
		}, 1000);
	}

}
