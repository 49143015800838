import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import Swal from 'sweetalert2';
import { MESSAGE } from '../../__utilities/messages';
declare var $: any;
@Component({
	selector: 'app-export-orders',
	templateUrl: './export-orders.component.html',
	styleUrls: ['./export-orders.component.css']
})
export class ExportOrdersComponent implements OnInit {

	noOfRecord:number = 0;
	@Output() downloadReport = new EventEmitter();
	@Output() emailReport = new EventEmitter<number>();
	@Input() isDownloadInProgress:boolean=false;
	@Input() isEmailInProgress:boolean=false;
	@Input() isEmail:boolean = true;
	@Input() isDownload:boolean = true;
	constructor() { }

	ngOnInit(): void {
	}

	openFilterBox() {
		this.noOfRecord = 0;
		Swal.fire({
			title: MESSAGE.SELECT_RECORD,
			html:
				'<br>' +
				'<span class = "swal_number" style = "border-radius:50%; padding:10px; border:1px solid #F58634; cursor:pointer;">10</span>&nbsp;' +
				'<span class = "swal_number" style = "border-radius:50%; padding:10px; border:1px solid #F58634; cursor:pointer;">50</span>&nbsp;' +
				'<span class = "swal_number" style = "border-radius:50%; padding:10px; border:1px solid #F58634; cursor:pointer;">100</span>&nbsp;' +
				'<span class = "swal_number" style = "border-radius:50%; padding:10px; border:1px solid #F58634; cursor:pointer;">250</span>&nbsp;' +
				'<span class = "swal_number" style = "border-radius:50%; padding:10px; border:1px solid #F58634; cursor:pointer;">500</span>&nbsp;',
			focusConfirm: false,
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			confirmButtonText: "Save",
			preConfirm: () => {
				return new Promise(function (resolve, reject) {
					if (!_this.noOfRecord) {
						reject(MESSAGE.SELECT_RECORD);
					} else {
						_this.emailOrdersReport();
						resolve(true);
					}
				}).catch(err => {
					return false
				});
			}
		});
		var _this = this;
		$(document).on('click', "span.swal_number", function () {
			$("span.swal_number").css('background-color','');
			$(this).css('background-color','rgb(245, 134, 52)');
			_this.noOfRecord = $(this).text();
		});

	}
	
	// Email report
	emailOrdersReport(){
		this.emailReport.emit(this.noOfRecord);
	}

	// Download report
	downloadOrdersReport(){
		this.downloadReport.emit();
	}
}