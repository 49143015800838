import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService, CommonService, ValidationService, TokenService } from '../../../../__services';
import { TITLE, BTN_TEXT } from '../../../../__utilities/text';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { PHONE_CONFIG, DEFAULT_COUNTRY } from '../../../../__utilities/phone';
import { MESSAGE } from '../../../../__utilities/messages';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {
	isLoading: boolean = false;
	searchStr: string = '';
	corporateUsers: any = [];
	heading: string = TITLE.CORPORATE_USERS;
	btnText: string = BTN_TEXT.ADD_USERS;
	isAdd: boolean = false;
	usersForm: UntypedFormGroup;
	isDisabled: boolean = false;
	isSubmitted: boolean = false;
	selectedIndex: number = 0;
	phoneConfig = PHONE_CONFIG;
	roleslist: any;
	permissions: any = PERMISSIONS;
	authUserPermission: number;
	selectedUser: any = "";
	isDeletePermission: boolean = false;
	isEditPermission: boolean = false;
	routeReuseSubscription:Subscription;

	constructor(public usersService: UsersService,
		public commonService: CommonService,
		public validationService: ValidationService,
		public tokenService: TokenService,
		private fb: UntypedFormBuilder) { }

	ngOnInit(): void {
		this.initializeControls();
		this.fetchUsers();
		this.routeSubscription();
		this.authUserPermission = this.tokenService.getPermission();
		this.isDeletePermission = this.commonService.isPermission(this.authUserPermission, PERMISSIONS.DELETE_USERS);
		this.isEditPermission = this.commonService.isPermission(this.authUserPermission, PERMISSIONS.UPDATE_CORPORATE_USER_COMPANY_NAME);
	}

	fetchUsers() {
		this.isLoading = true;
		this.usersService.fetchCorporateUsers().subscribe(res => {
			if (res && res.success) {
				this.corporateUsers = res.response.data;
				this.roleslist = res.response.roleslist;
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}

	initializeControls() {
		this.usersForm = this.fb.group({
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			companyName: ['', Validators.compose([Validators.required])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			role: ['', Validators.compose([Validators.required])],
			contact: ['', Validators.compose([Validators.required])],
		});
	}

	addMore(isAdd) {
		this.isAdd = isAdd;
		this.usersForm.controls.role.setValue('');
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.corporateUsers = [];
	}

	// Submit plant form
	onSubmit(userData) {
		this.isSubmitted = true;
		if (this.usersForm.valid) {
			userData = this.createPayload(userData);
			this.isDisabled = true;
			this.usersService.manageCorporateUser(userData, this.selectedUser && this.selectedUser._id).subscribe(res => {
				if (res && res.success)
					this.selectedUser ? this.corporateUsers[this.selectedIndex] = res.response.data : this.corporateUsers.unshift(res.user);
				this.resetForm();
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.isSubmitted = false;
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.usersForm);
		}
	}

	resetForm() {
		this.isSubmitted = false;
		this.usersForm.reset();
		this.selectedUser = "";
		this.selectedIndex = 0;
		this.isAdd = false;
		this.phoneConfig.CountryISO = DEFAULT_COUNTRY;
	}

	createPayload(userData) {
		userData.roleId = userData.role;
		if (typeof userData.contact == "object") {
			userData.countryCode = userData.contact.countryCode;
			userData.dialCode = userData.contact.dialCode;
			userData.contact = this.commonService.formatContactNumber(userData.contact.number);
		}
		const { role, ...data } = userData;
		return data;
	}

	// Delete User
	deleteUser(user, index) {
		Swal.fire({
			title: MESSAGE.DELETE_USER_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_USER_CONFIRMATION_TEXT + user.firstName + ' ' + user.lastName + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedUser(user._id, index);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Delete user
	deleteSelectedUser(userId, index) {
		this.usersService.deleteCorporateUsers(userId).subscribe(res => {
			if (res && res.success) {
				this.corporateUsers.splice(index, 1);
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// Edit User
	editUser(user, index) {
		this.isAdd = true;
		this.selectedIndex = index;
		this.selectedUser = user;
		this.usersForm.controls['firstName'].setValue(user.firstName);
		this.usersForm.controls['lastName'].setValue(user.lastName);
		this.usersForm.controls['email'].setValue(user.email);
		this.usersForm.controls['role'].setValue(user.roleId._id);
		this.usersForm.controls['companyName'].setValue(user.companyName);
		this.phoneConfig.CountryISO = user.countryCode;
		this.usersForm.controls['contact'].setValue(user.contact);
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.CORPORATE_USERS ? this.resetForm() : false;
		});
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}
