import { Component, OnInit } from '@angular/core';
import {
	CommonService, PlantService, UsersService,
	WarehouseService, StorageService, LoaderService, CylinderService
} from '../../../../__services';
import { ROLES } from '../../../../__utilities/roles';
import { TITLE, BTN_TEXT } from '../../../../__utilities/text';
import { MESSAGE } from '../../../../__utilities/messages';
declare var $;

@Component({
	selector: 'app-cylinder-stock',
	templateUrl: './cylinder-stock.component.html',
	styleUrls: ['./cylinder-stock.component.css']
})
export class CylinderStockComponent implements OnInit {
	authUser: any;
	btnText: object = BTN_TEXT;
	isLoading: boolean = false;
	cylinderCategories: any = [];
	cylinder: any;
	title: any = TITLE;
	inputId: string = "cylinder";
	isManageStock: boolean = false;
	roles: any = ROLES;
	isIndependentRetailer: boolean = false;
	messages: any = MESSAGE;
	constructor(
		public commonService: CommonService,
		public storageService: StorageService,
		public loader: LoaderService,
		public plantService: PlantService,
		public usersService: UsersService,
		public warehouseService: WarehouseService,
		public cylinderService: CylinderService
	) {
		this.authUser = this.storageService.getItem('userObj');
		this.isIndependentRetailer = this.authUser.roleId.name == ROLES.INDEPENDENT_RETAILER;
	}

	ngOnInit(): void {

		if (this.authUser && this.authUser.hasOwnProperty('roleId')) {

			if (this.authUser.roleId.name == ROLES.PLANT_MANAGER && this.authUser.hasOwnProperty('plant'))
				this.fetchPlantCylinderStock(this.authUser.plant._id);

			if (this.authUser.roleId.name == ROLES.DISTRIBUTOR && this.authUser.hasOwnProperty('distributor'))
				this.fetchDistributorCylinderStock(this.authUser.distributor._id);

			if (this.authUser.roleId.name == ROLES.WAREHOUSE_MANAGER && this.authUser.hasOwnProperty('warehouse'))
				this.fetchWarehouseCylinderStock(this.authUser.warehouse._id);

			if (this.isIndependentRetailer)
				this.fetchGasRetailerCylinderStock(this.authUser.independent._id);
		}
	}

	// Fetch Gas Retailer Cylinder Stock
	fetchGasRetailerCylinderStock(retailerId) {
		this.startLoader();
		this.usersService.fetchGasRetailersById(retailerId).subscribe(res => {
			this.response(res);
			this.stopLoader();
		}, error => {
			this.stopLoader();
		});
	}

	// Fetch Plant Cylinder Stock
	fetchPlantCylinderStock(plantId) {
		this.startLoader();
		this.plantService.fetchPlantById(plantId).subscribe(res => {
			this.response(res);
			this.stopLoader();
		}, error => {
			this.stopLoader();
		});
	}

	// Fetch Distributor Cylinder Stock
	fetchDistributorCylinderStock(distributorId) {
		this.startLoader();
		this.usersService.fetchDistributorById(distributorId).subscribe(res => {
			this.response(res);
			this.stopLoader();
		}, error => {
			this.stopLoader();
		});
	}

	// Fetch Warehouse Cylinder Stock
	fetchWarehouseCylinderStock(warehouseId) {
		this.startLoader();
		this.warehouseService.fetchWarehouseById(warehouseId).subscribe(res => {
			this.response(res);
			this.stopLoader();
		}, error => {
			this.stopLoader();
		});
	}

	startLoader() {
		this.isLoading = true;
		this.loader.show();
	}

	stopLoader() {
		this.isLoading = false;
		this.loader.hide();
	}

	response(res) {
		if (res.success && res.response && res.response) {
			this.cylinder = res.response.data ? res.response.data : [];
			if (res.response.data.type == "independent") {
				this.cylinderCategories = res.response.igr_categories ? res.response.igr_categories.sort(this.commonService.sortByProperty('size')) : [];
			} else {
				this.cylinderCategories = res.response.categories ? res.response.categories.sort(this.commonService.sortByProperty('size')) : [];
			}
		}
	}

	// Add/Update Stock
	manageStock() {
		if (this.isLoading) return;
		let request = [];
		let noOfCylinder = 0;
		this.cylinderCategories.forEach((element, index) => {
			noOfCylinder = parseInt($('#' + this.inputId + index).val());
			if (noOfCylinder)
				request.push({ 'numberOfCylinder': noOfCylinder, 'size': element.size });
		});

		if (!request.length)
			return this.commonService.growlError(MESSAGE.ENTER_QUANTITY);
		this.isLoading = true;
		this.cylinderService.updateStock(this.authUser.independent._id, { updateCylinders: request }).subscribe(response => {
			if (response && response.success && response.response) {
				this.cylinder = response.response.data;
			}
			this.commonService.growlSuccess(response.message);
			this.isLoading = false;
			this.isManageStock = false;
		}, error => {
			this.isLoading = false;
			this.isManageStock = false;
			this.commonService.growlSuccess(error.message);
		});
	}

	isAddStock(isTrue) {
		this.isManageStock = isTrue;
	}

}