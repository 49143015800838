import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../__guard/auth.guard';
import { ROLES } from '../__utilities/roles';
import { TITLE } from '../__utilities/text';

// Import Containers
import { DefaultLayoutComponent } from '../containers/default-layout';
import {
	ProfileComponent, ChangePasswordComponent, P404Component, P500Component, ResetPasswordComponent,
	LoginComponent, NotificationsComponent, TermsConditionsComponent,
	ChatLayoutComponent, ReferralComponent, QuestionsComponent, CurrentPriceComponent,
	CylinderStockComponent, FaqComponent, RetailerRegistrationComponent,
	ClientOrderPaymentComponent
} from './index';
import { PERMISSIONS } from '../__utilities/permissions';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ImageCropperModule } from 'ngx-img-cropper';
import { SetLpgPriceComponent } from './authenticated/cylinders/set-lpg-price/set-lpg-price.component';
import { SetDeliveryChargesComponent } from './authenticated/cylinders/set-delivery-charges/set-delivery-charges.component';

const routes: Routes = [
	{ path: '', component: LoginComponent, data: { title: TITLE.LOGIN } },
	{ path: '404', component: P404Component, data: { title: TITLE.PAGE_404 } },
	{ path: '500', component: P500Component, data: { title: TITLE.PAGE_500 } },
	{ path: 'login', component: LoginComponent, data: { title: TITLE.LOGIN } },
	{ path: 'verification/:token', component: ResetPasswordComponent, data: { title: TITLE.RESET_PASSWORD, isVerification: true } },
	{ path: 'reset-password/:token', component: ResetPasswordComponent, data: { title: TITLE.RESET_PASSWORD, isVerification: false } },
	{ path: 'terms-conditions', component: TermsConditionsComponent, data: { title: TITLE.TERMS_CONDITIONS, page: 'TC' } },
	{ path: 'privacy-policy', component: TermsConditionsComponent, data: { title: TITLE.PRIVACY_POLICY, page: 'PP' } },
	{ path: 'consent-clause', component: TermsConditionsComponent, data: { title: TITLE.CONSENT_CLAUSE, page: 'CC' } },
	{ path: 'faq', component: FaqComponent, data: {} },
	{ path: 'independent-gas-retailer/signup', component: RetailerRegistrationComponent, data: {} },
	// { path: 'registration', component: RetailerRegistrationComponent, data: {} },

	{
		path: '', component: DefaultLayoutComponent, data: { title: TITLE.HOME },
		children: [
			{ path: 'dashboard', loadChildren: () => import('../views/authenticated/__modules/dashboard.module').then(m => m.DashboardModule) },
			{ path: 'orders', loadChildren: () => import('../views/authenticated/__modules/order.module').then(m => m.OrderModule) },
			{ path: 'setting', loadChildren: () => import('../views/authenticated/__modules/setting.module').then(m => m.SettingModule) },
			{ path: 'request', loadChildren: () => import('../views/authenticated/__modules/cylinder.module').then(m => m.CylinderModule) },
			{ path: 'users', loadChildren: () => import('../views/authenticated/__modules/user.module').then(m => m.UserModule) },
			{ path: 'unapprovedusers', loadChildren: () => import('../views/authenticated/__modules/unapproved-user.module').then(m => m.UnapprovedUserModule) },
			{ path: 'hubs', loadChildren: () => import('../views/authenticated/__modules/plants.module').then(m => m.PlantsModule) },
			// { path: 'warehouses', loadChildren: () => import('../views/authenticated/__modules/warehouse.module').then(m => m.WarehouseModule), data: { breadcrumb: TITLE.WAREHOUSE, } },
			// { path: 'distributors', loadChildren: () => import('../views/authenticated/__modules/distributor.module').then(m => m.DistributorModule), data: { title: TITLE.DISTRIBUTORS, breadcrumb: TITLE.DISTRIBUTORS } },
			{ path: 'independent-gas-retailers', loadChildren: () => import('../views/authenticated/__modules/gas-retailer.module').then(m => m.GasRetailerModule) },
			{
				path: 'change-password',
				component: ChangePasswordComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.CHANGE_PASSWORD,
					breadcrumb: [
						{
							label: TITLE.CHANGE_PASSWORD,
							url: ''
						},
					],
				},
			},
			{
				path: 'profile',
				component: ProfileComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.PROFILE,
					breadcrumb: [
						{
							label: TITLE.PROFILE,
							url: ''
						},
					],
				}
			},
			// {
			// 	path: 'cylinders',
			// 	component: CylindersComponent,
			// 	canActivate: [AuthGuard],
			// 	data: {
			// 		title: TITLE.CYLINDER_REGISTRATION,
			// 		breadcrumb: [
			// 			{
			// 				label: TITLE.CYLINDER_REGISTRATION,
			// 				url: ''
			// 			},
			// 		],
			// 		permission: ['', ROLES.WAREHOUSE_MANAGER]
			// 	}
			// },
			{
				path: 'cylinder-stock',
				component: CylinderStockComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.CYLINDER_STOCK,
					breadcrumb: [
						{
							label: TITLE.CYLINDER_STOCK,
							url: ''
						},
					],
					permission: ['', ROLES.INDEPENDENT_RETAILER]
				}
			},
			{
				path: 'set-lpg-price',
				component: SetLpgPriceComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.SET_LPG_PRICE,
					breadcrumb: [
						{
							label: TITLE.SET_LPG_PRICE,
							url: ''
						},
					],
					permission: ['', ROLES.INDEPENDENT_RETAILER]
				}
			},
			{
				path: 'set-delivery-charges',
				component: SetDeliveryChargesComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.SET_DEL_CHRG,
					breadcrumb: [
						{
							label: TITLE.SET_DEL_CHRG,
							url: ''
						},
					],
					permission: ['', ROLES.INDEPENDENT_RETAILER]
				}
			},
			{
				path: 'current-selling-price',
				component: CurrentPriceComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.CURRENT_SELLING_PRICE,
					breadcrumb: [
						{
							label: TITLE.CURRENT_SELLING_PRICE,
							url: ''
						},
					],
					permission: ['', ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER]
				}
			},
			{
				path: 'chat',
				component: ChatLayoutComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.CHAT,
					breadcrumb: [
						{
							label: TITLE.CHAT,
							url: ''
						},
					],
				}
			},
			{
				path: 'notifications',
				component: NotificationsComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.NOTIFICATIONS,
					breadcrumb: [
						{
							label: TITLE.NOTIFICATIONS,
							url: ''
						},
					],
					permission: ['', ROLES.CORPORATE, ROLES.WAREHOUSE_MANAGER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
				}
			},
			{
				path: 'help-desk',
				component: QuestionsComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.HELP_DESK,
					breadcrumb: [
						{
							label: TITLE.HELP_DESK,
							url: ''
						},
					],
					permission: [PERMISSIONS.HELP_DESK, ROLES.CORPORATE]
				}
			},
			{
				path: 'clients-order-payment',
				component: ClientOrderPaymentComponent,
				canActivate: [AuthGuard],
				data: {
					title: TITLE.CLIENT_ORDER_PAYMENT,
					breadcrumb: [
						{
							label: TITLE.CLIENT_ORDER_PAYMENT,
							url: ''
						},
					],
					permission: [PERMISSIONS.VIEW_CLIENTS_ORDER_PAYMENT, ROLES.CORPORATE]
				},
			},
		]
	},
	{ path: '**', component: P404Component }
];

@NgModule({
	imports: [RouterModule.forChild(routes), RecaptchaModule, RecaptchaFormsModule, ImageCropperModule],
	exports: [RouterModule, RecaptchaModule, RecaptchaFormsModule, ImageCropperModule]
})
export class BaseRoutingModule { }

export const ROUTING_COMPONENTS = [
	P404Component, P500Component, LoginComponent, NotificationsComponent, ResetPasswordComponent,
	TermsConditionsComponent, ChatLayoutComponent, ReferralComponent, QuestionsComponent, FaqComponent, CurrentPriceComponent, ClientOrderPaymentComponent
];
