import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService,UsersService } from '../../__services';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  clientDetail:any;

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public usersService: UsersService) { 
    this.setBreadCrumb();
  }

  ngOnInit(): void {
    this.fetchClientDetail();
  }

  setBreadCrumb() {
		var breadcrumb = [...this.activatedRoute.data['value'].breadcrumb, ...[{
			label: "Details",
			url: '',
		}]];
		let _this = this;
		setTimeout(() => {
			_this.commonService.sendRouteChanges(breadcrumb);
		}, 0);
	}

  //fetch Client detail
  fetchClientDetail(){
    const clientId = this.activatedRoute.url["_value"][2].path;
    this.usersService.fetchSingleClient(clientId).subscribe(res => {
			if (res && res.success && res.response) {
				this.clientDetail = res.response.data;
        
			}
		}, error => {
		});
  }

}
