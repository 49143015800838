import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
declare var $: any;
@Injectable()
export class ValidationService {
    isValidPassword: boolean = false;
    isValidAllPassword: boolean = false;
    constructor() { }

    checkLetters(key) {
        let pattern = /^[A-z]+$/;
        return pattern.test(key);
    }

    checkNums(key) {
        let pattern = /^[0-9]+$/;
        return pattern.test(key);
    }

    isAllowedKeys(keycode) {
        let allowed = [8, 9, 13, 35, 36, 37, 39, 46];
        if (allowed.indexOf(keycode) > -1)
            return true;
        else
            return false;
    }

    isFieldValid(formGroup: UntypedFormGroup, field: string) {

        if (!formGroup.get(field).valid && formGroup.get(field).touched) {
            if (formGroup.get(field).errors) {
                return this.returnMessage(formGroup.get(field), field);
            }
            else {
                return '';
            }

        }
        else {
            var res = null;
            if (field == 'confirmPassword') {
                const checkControl = 'newPassword'
                const errorMsg = 'Confirm password must be equal to New password';
                res = this.equalPasswordMatchValidator(formGroup, field, checkControl, errorMsg);
            }
            if (field == 'newPassword') {
                const checkControl = 'oldPassword'
                const errorMsg = 'New password should be different from old password';
                res = this.unEqualPasswordMatchValidator(formGroup, field, checkControl, errorMsg);
            }
            if (field == 'repeat_remail')
                res = this.emailMatchValidator(formGroup, field);
            if (field == 'dob') {
                if (formGroup.get('dob').value != '') {
                    return true;
                }
            }
            return res;
        }
    }


    isGroupFieldValid(formGroup: UntypedFormGroup, groupName: string, field: string) {
        const formField = formGroup.get(groupName).get(field);
        if (!formField.valid && formField.touched) {
            return this.returnMessage(formField, field);
        }
    }

    returnMessage(formField, field) {
        if (formField.errors) {
            var str = this.checkField(field).replace(/\_/g, ' ');

            // str = str.replace('id', '')
            var ctrl = (str.charAt(0).toUpperCase() + str.slice(1));
            if (formField.errors.minlength) {
                return ctrl + ' must be minimum ' + formField.errors.minlength.requiredLength + ' character ';
            }
            if (formField.errors.maxlength) {
                return ctrl + ' must not be greater than ' + formField.errors.maxlength.requiredLength + ' character.';
            }
            if (formField.errors.email) {
                return 'Invalid email address';
            }
            if (formField.errors.required) {
                return ctrl + ' is required';
            }
            if (formField.errors.min) {
                return ctrl + ' should not be less than ' + formField.errors.min.min;
            }
            if (formField.errors.max) {
                return ctrl + ' should not be greater than ' + formField.errors.max.max;
            }
            if (formField.errors.pattern) {
                if (field == 'personal_number' || field == 'co_user_number' || field == 'zipcode' || field == 'phone_Number')
                    return ctrl + ' accept only numbers';
                if (field == 'first_name' || field == 'last_name')
                    return ctrl + 'accept only letters';
                return true;
            }
            if (formField.errors.character) {
                if (field == 'first_name') {
                    return ctrl + 'field accept only character';
                    return true;
                }
            }
        }
        else {
            return '';
        }

    }

    checkField(field) {
        var result = field.replace(/([A-Z]+)/g, " $1").replace(/^ /, "");
        return result.charAt(0).toUpperCase() + result.substring(1).toLowerCase();
    }

    unEqualPasswordMatchValidator(formGroup: UntypedFormGroup, field: string, checkField: string, errorMsg) {
        if (formGroup.get(field) && formGroup.get(field).value && formGroup.get(checkField)) {
            let message = (formGroup.get(checkField).value == ""
                || formGroup.get(field).value == null || (formGroup.get(checkField).value !==
                    formGroup.get(field).value)) ? null : errorMsg;
            this.isValidAllPassword = message ? false : (this.isValidPassword && true);
            return message;
        } else {
            this.isValidAllPassword = true;
            this.isValidPassword = true;
            return null;
        }

    }

    equalPasswordMatchValidator(formGroup: UntypedFormGroup, field: string, checkField: string, errorMsg) {
        if (formGroup.get(field) && formGroup.get(field).value && formGroup.get(checkField)) {
            let message = (formGroup.get(checkField).value == ""
                || formGroup.get(field).value == null || (formGroup.get(checkField).value ===
                    formGroup.get(field).value)) ? null : errorMsg;
            this.isValidPassword = message ? false : true;
            return message;
        }
    }

    emailMatchValidator(formGroup: UntypedFormGroup, field: string) {
        if (formGroup.get('repeat_remail')) {
            return (formGroup.get('email').value == "" || formGroup.get(field).value == ""
                || formGroup.get(field).value == null || (formGroup.get('email').value ===
                    formGroup.get(field).value)) ? null : 'Repeat email must be equal to email';
        }
    }

    displayFieldCss(formGroup: UntypedFormGroup, field: string) {
        return {
            'has-error': this.isFieldValid(formGroup, field),
            'has-feedback': this.isFieldValid(formGroup, field)
        };
    }

    validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    // Keypress Event
    checkNumber(event: any) {
        event = (event) ? event : window.event;
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && ((charCode < 48 || charCode > 57) || (charCode > 95 || charCode < 105))) {
            return false;
        }
        return true;
    }

    isloginValid(formGroup: UntypedFormGroup, field: string) {
        if (!formGroup.get(field).valid && formGroup.get(field).touched) {
            if (formGroup.get(field).errors) {
                var str = this.checkField(field).replace(/\_/g, ' ');
                str = str.replace('id', '')
                var ctrl = (str.charAt(0).toUpperCase() + str.slice(1));

                if (formGroup.get(field).errors.user_name) {
                    return 'Invalid email address';
                }
                if (formGroup.get(field).errors.required) {
                    return ctrl + ' is required';
                }
            }
            else
                return true;
        }
    }


}
