import { Component, OnInit } from '@angular/core';
import { SettingService, LoaderService } from '../../__services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-terms-conditions',
	templateUrl: './terms-conditions.component.html',
	styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
	content: any;
	page: string = '';
	title: string = '';
	constructor(
		public settingService: SettingService,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService,
		public router: Router
	) {
		this.page = this.activatedRoute.data['value'].page;
		this.title = this.activatedRoute.data['value'].title;
	}

	ngOnInit(): void {
		this.loader.show();
		this.fetchContent();
	}

	// If isPrivacyPolicy is true that fetch privacy policy else terms & conditions.
	fetchContent() {
		this.settingService.fetchContent(this.page, this.title).subscribe(res => {
			this.content = res && res.success ? res.response.content : '';
			this.loader.hide();
		}, error => {
			this.loader.hide();
			this.content = '';
		});
	}
}
