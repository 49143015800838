import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DistributorComponent } from '../../index';
import { OrdersComponent } from '../../../containers';
import { AuthGuard } from '../../../__guard/auth.guard';
import { ROLES } from '../../../__utilities/roles';
import { TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';

const routes: Routes = [
	{
		path: '',
		component: DistributorComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.DISTRIBUTORS,
			viewURL: '/distributors/distributor-details/',
			viewOrdersURL: '/distributors/orders/',
			breadcrumb: [
				{
					label: TITLE.DISTRIBUTORS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_DISTRIBUTORS, ROLES.CORPORATE]
		}
	},
	{
		path: 'orders/:id',
		component: OrdersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.DISTRIBUTORS,
			isOrders: true,
			viewURL: '/distributors/orders/order-details',
			breadcrumb: [
				{
					label: TITLE.DISTRIBUTORS,
					url: '/distributors'
				},
				{
					label: TITLE.ORDERS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_DISTRIBUTORS, ROLES.CORPORATE]
		}
	},
	{
		path: 'orders/order-details/:id',
		component: OrdersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.DISTRIBUTORS,
			isOrders: false,
			breadcrumb: [
				{
					label: TITLE.DISTRIBUTORS,
					url: '/distributors'
				},
				{
					label: TITLE.ORDERS,
					url: '/distributors/orders/'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_DISTRIBUTORS, ROLES.CORPORATE]
		}
	},
	{
		path: 'distributor-details/:id',
		component: DistributorComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.DISTRIBUTOR_DETAILS,
			breadcrumb: [
				{
					label: TITLE.DISTRIBUTORS,
					url: '/distributors'
				},
				{
					label: TITLE.DISTRIBUTOR_DETAILS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_DISTRIBUTORS, ROLES.CORPORATE]
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DistributorRoutingModule { }

export const ROUTING_COMPONENTS = [
	DistributorComponent
];
