import { Component, OnInit,OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService, OrderService, StorageService, CylinderService, LoaderService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { MESSAGE } from '../../../../__utilities/messages';
import { COMPONENT_KEYS, ORDER_STATUS } from '../../../../__utilities/orders';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $;

@Component({
	selector: 'app-plants-refill-order',
	templateUrl: './plants-refill-order.component.html',
	styleUrls: ['./plants-refill-order.component.css']
})
export class PlantsRefillOrderComponent implements OnInit, AfterViewInit, OnDestroy {

	paginate: any = { ...PAGINATION };
	orderStatus: object = { ...ORDER_STATUS };
	plantsOrder: any = [];
	isLoading: boolean = false;
	searchStr: string = '';
	warehouseId: string;
	heading: string = TITLE.PLANTS_REFILL_ORDER;
	selectedRequest: any;
	cylinderCategories: any = [];
	requestId: string = "";
	noRecord: string = "";
	activatedRouteValues: any = "";
	searchOrderId: string = "";
	statusFilter: string = "";
	subscription:Subscription;
	constructor(public commonService: CommonService,
		public storageService: StorageService,
		public cylinderService: CylinderService,
		public loader: LoaderService,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public orderService: OrderService) {
		let userObj: any = this.storageService.getItem('userObj');
		this.warehouseId = userObj && userObj.warehouse && userObj.warehouse._id;
	}

	ngOnInit(): void {
		this.getRoutingParams();
		delete this.orderStatus['CANCELLED'];
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ requestId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.requestId = results.requestId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.searchOrderId = results.query.orderId ? results.query.orderId : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.plantsOrder = [];
				this.requestId ? this.fetchPlantRefillRequestById() : this.fetchPlantsOrder();
			});
	}

	// Fetch refill request by id
	fetchPlantRefillRequestById() {
		this.isLoading = true;
		this.loader.show();
		this.cylinderService.fetchPlantRefillRequestById(this.requestId).subscribe(res => {
			if (res && res.success && res.response.data)
				this.selectedRequest = res.response.data;
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
		}, error => {
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
			this.selectedRequest = "";
		});
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	// Plants Order
	fetchPlantsOrder() {
		this.isLoading = true;
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.statusFilter) searchQuery += "&filter=" + this.statusFilter;
		this.orderService.fetchPlantsOrder(this.warehouseId, searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response.categories ? res.response.categories.sort(this.commonService.sortByProperty('size')) : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.plantsOrder = res.response.requests ? res.response.requests : [];
			}
			else {
				this.plantsOrder = [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetAndSearch();
	}

	resetAndSearch() {
		this.resetPagination();
		this.plantsOrder = [];
		this.fetchPlantsOrder();
	}

	onOrderStatusChange(status) {
		this.statusFilter = status;
		this.resetAndSearch();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.plantsOrder = [];
		this.fetchPlantsOrder();
	}

	approveRequest(isApprove, request, index) {
		if (request.isApproved || request.isRejected) return;
		this.orderService.approvePlantRefillOrder(request._id, { approve: isApprove, reject: !isApprove }).subscribe(res => {
			if (res && res.success) {
				this.plantsOrder[index] = res.response;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	dispatchRequest(event, request, index) {
		if (request.isDispatched)
			return;
		this.orderService.dispatchPlantRefillOrder(request._id, { dispatch: event }).subscribe(res => {
			if (res && res.success) {
				this.plantsOrder[index] = res.response;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	viewRequest(request) {
		this.router.navigate([this.activatedRouteValues.viewURL, request._id], { queryParams: this.queryParamsObj() });
	}

	goBack() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		return queryParams;
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		if (this.requestId) return;
		this.commonService.setCurrentOrderKey(COMPONENT_KEYS.CYLINDER_REFILL_REQUEST);
		this.subscription = this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { })
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.searchStr = "";
		this.resetAndSearch();
	}

	ngOnDestroy(){
		if(this.subscription) this.subscription.unsubscribe();
	}
}