import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Get Associates
	getAssociates(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.ASSOCIATES + searchQuery, this.headerService.httpHeaders());
	}

	// Get Associates By Id
	fetchAssociateById(associateId): Observable<any> {
		return this.http.get(BASE_URL.FETCH_USER_DETAIL + "/" + associateId, this.headerService.httpHeaders());
	}

	// Get Unapproved Associates By Id
	fetchUnApprovedAssociateById(filter): Observable<any> {
		return this.http.get(BASE_URL.FETCH_UNAPPROVED_USER_DETAIL + filter, this.headerService.httpHeaders());
	}

	// Get Retailer By Id
	fetchGasRetailersById(retailerId): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_GAS_RETAILER + "/" + retailerId, this.headerService.httpHeaders());
	}

	// Get Walkins
	getWalkin(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.WALKIN + searchQuery, this.headerService.httpHeaders());
	}

	// Get walkin By Id
	fetchWalkinById(fetchWalkinById): Observable<any> {
		return this.http.get(BASE_URL.WALKIN + "/" + fetchWalkinById, this.headerService.httpHeaders());
	}

	// Add walkin User
	addWalkinUser(userData): Observable<any> {
		return this.http.post(BASE_URL.WALKIN, userData, this.headerService.httpHeaders());
	}


	// Delete walkin User
	deleteWalkinUsers(walkinId): Observable<any> {
		return this.http.delete(BASE_URL.WALKIN + "/" + walkinId, this.headerService.httpHeaders());
	}

	// update walkin User
	updateWalkinUser(userData, walkinId): Observable<any> {
		return this.http.put(BASE_URL.WALKIN + "/" + walkinId, userData, this.headerService.httpHeaders());
	}

	// Fetch Clients Account Delete Request
	fetchAssociateAccountDeleteReq(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.ASSOCIATE_ACCOUNT_DELETE_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Get Clients
	getClients(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CLIENTS + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Single Client Detail
	fetchSingleClient(id): Observable<any> {
		return this.http.get(BASE_URL.CLIENT_BY_ID + "/" + id, this.headerService.httpHeaders());
	}

	// Fetch Clients/Associate Account Delete Request
	fetchAccountDeleteReq(searchQuery, isClientRequest): Observable<any> {
		if (isClientRequest)
			return this.http.get(BASE_URL.CLIENT_ACCOUNT_DELETE_REQUEST + searchQuery, this.headerService.httpHeaders());
		return this.http.get(BASE_URL.ASSOCIATE_ACCOUNT_DELETE_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Manage (Accept, Reject) Client/Associate Account Delete Request
	manageAccountDeleteRequest(userId, status): Observable<any> {
		return this.http.patch(BASE_URL.MANAGE_ACCOUNT_DELETE_REQUEST + userId, status, this.headerService.httpHeaders());
	}

	// Approve / Disapprove / Delete associate
	manageAssociate(id, status): Observable<any> {
		return this.http.put(BASE_URL.ASSOCIATES + "/" + id + "/approve", status, this.headerService.httpHeaders());
	}

	// Approve / Disapprove Client
	manageClients(id, status): Observable<any> {
		return this.http.put(BASE_URL.CLIENTS + "/" + id + "/approve", status, this.headerService.httpHeaders());
	}

	// Get Fleet Owner By Id
	getFleetAssociateDetails(fleetOwnerId): Observable<any> {
		return this.http.get(BASE_URL.FLEET_ASSOCIATE + "/" + fleetOwnerId, this.headerService.httpHeaders());
	}

	// Get Fleet Owner
	getFleetowner(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.FLEET_OWNER + searchQuery, this.headerService.httpHeaders());
	}

	// Approve / Disapprove Fleet Owner
	manageFleetOwners(id, status): Observable<any> {
		return this.http.put(BASE_URL.FLEET_OWNER + "/" + id + "/approve", status, this.headerService.httpHeaders());
	}

	// Fetch Question
	fetchQuestion(): Observable<any> {
		return this.http.get(BASE_URL.QUESTIONS, this.headerService.httpHeaders());
	}

	// Add/Update Corporate User
	manageCorporateUser(userData, id): Observable<any> {
		if (id)
			return this.http.put(BASE_URL.CORPORATE_USER_FETCH + "/" + id, userData, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.CORPORATE_USER_REGISTRATION, userData, this.headerService.httpHeaders());
	}

	// Fetch Corporate Users
	fetchCorporateUsers(): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_USER_FETCH, this.headerService.httpHeaders());
	}

	// Delete Corporate User
	deleteCorporateUsers(userId): Observable<any> {
		return this.http.delete(BASE_URL.CORPORATE_USER_FETCH + "/" + userId, this.headerService.httpHeaders());
	}

	// Manage Distributor
	manageDistributor(distributorData): Observable<any> {
		if (distributorData.id)
			return this.http.put(BASE_URL.DISTRIBUTOR + "/" + distributorData.id, distributorData, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.DISTRIBUTOR, distributorData, this.headerService.httpHeaders());
	}

	// Approve / Disapprove Gas Supplier
	approveRejectGasRetailer(id, payload): Observable<any> {
		return this.http.patch(BASE_URL.INDEPENDENT_GAS_RETAILER + "/" + id + "/approve", payload, this.headerService.httpHeaders());
	}

	// Fetch Distributor
	fetchDistributors(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.DISTRIBUTOR + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Gas Retailers
	fetchGasRetailers(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_GAS_RETAILER + searchQuery, this.headerService.httpHeaders());
	}

	// Set Gas Retailer Order Sale Permission
	setRetailerOrderSalePermission(retailerId, payload): Observable<any> {
		return this.http.patch(BASE_URL.INDEPENDENT_GAS_RETAILER + "/" + retailerId + "/permission", payload, this.headerService.httpHeaders());
	}

	// Fetch Distributor By Id
	fetchDistributorById(distributorId): Observable<any> {
		return this.http.get(BASE_URL.DISTRIBUTOR + "/" + distributorId, this.headerService.httpHeaders());
	}

	// Delete Distributor
	deleteDistributor(distributorId): Observable<any> {
		return this.http.delete(BASE_URL.DISTRIBUTOR + "/" + distributorId, this.headerService.httpHeaders());
	}
}