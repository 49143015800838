import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService, ValidationService, TokenService } from '../../../__services';
import { ROLES_APP_USER } from '../../../__utilities/roles';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

	loading: boolean = false;
	resetPasswordForm: UntypedFormGroup;
	verificationToken: string = '';
	isValidLink: boolean = true;
	isLoading: boolean = false;
	error: any = { isError: false, message: '' };
	isVerification: boolean = false;
	isResetSuccess: boolean = false;
	timer: any = 0;
	isDisabled: boolean = false;
	isClientOrAssociate: boolean = false;
	constructor(private fb: UntypedFormBuilder,
		private authenticationService: AuthenticationService,
		private route: ActivatedRoute,
		public validationService: ValidationService,
		public tokenService: TokenService,
		private router: Router) {
		this.getRouteParams();
	}

	ngOnInit() {
		this.initializeFormControls();
		this.isVerification = this.route.data['value'].isVerification;
	}

	// Get route params
	getRouteParams() {
		this.route.params.subscribe(res => {
			this.isLoading = true;
			this.verificationToken = res.token;
			let userDetail = this.tokenService.validateToken(res.token);
			const roleName = userDetail && userDetail.roleId.name;
			if (roleName == ROLES_APP_USER.CLIENT || roleName == ROLES_APP_USER.ASSOCIATE || roleName == ROLES_APP_USER.FLEET) {
				this.isClientOrAssociate = true;
			}
			this.checkTokenValidity(res.token);
		}, error => {
			this.isValidLink = false;
		})
	}

	// Check token validity
	checkTokenValidity(token) {
		this.authenticationService.verifyToken({ token: token }).subscribe(res => {
			if (res && res.success) {
				this.isValidLink = true;
			}
			this.isLoading = false;
		}, error => {
			this.isValidLink = false;
			this.isLoading = false;
		});
	}

	// Initialize form controls
	initializeFormControls() {
		this.resetPasswordForm = this.fb.group({
			newPassword: ['', [Validators.required, Validators.minLength(6)]],
			confirmPassword: ['', Validators.required]
		});
	}

	// Submit form
	onSubmit(password) {
		if (this.validationService.isValidPassword && this.resetPasswordForm.valid) {
			this.isDisabled = true;
			let payload = { token: this.verificationToken, password: password.newPassword };
			this.authenticationService.resetPassword(payload).subscribe(res => {
				if (res.success) {
					if (!this.isClientOrAssociate)
						this.setTimer();
					this.displayError(false, res.message);
				}
				this.isDisabled = false;
			}, error => {
				this.displayError(true, error.message);
				this.isResetSuccess = false;
				this.isDisabled = false;
				this.error.isError = true;
				this.error.message = error.message;
			});
		}
		else {
			this.validationService.validateAllFormFields(this.resetPasswordForm);
		}
	}

	displayError(isError, errorMsg) {
		this.isResetSuccess = !isError;
		this.error.isError = isError;
		this.error.message = errorMsg;
	}

	// Set timer for automatically redirection
	setTimer() {
		let counter = 16;
		let _this = this;
		var interval = setInterval(() => {
			counter -= 1;
			_this.timer = '00:' + (counter < 10 ? '0' + counter : counter);
			if (counter == 0) {
				clearInterval(interval);
				_this.router.navigate(['/']);
			}
		}, 1000);
	}
}