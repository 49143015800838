import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService, ProfileService, CommonService, TokenService } from '../../../../__services';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { ROLES } from '../../../../__utilities/roles';

@Component({
	selector: 'app-basic-info',
	templateUrl: './basic-info.component.html',
	styleUrls: ['./basic-info.component.css'],
})
export class BasicInfoComponent implements OnInit, OnChanges {

	profileForm: UntypedFormGroup;
	isSubmitted: boolean = false;
	// Image cropper properties
	name: string;
	imageCropData: any;
	isFileUpload: boolean = false;
	profileImage: string = '';
	fileObj: any;
	extArr = ['image/jpeg', 'image/png'];
	isDisabled: boolean = false;
	isUploading: boolean = false;
	isNotCorporateUser: boolean = true;
	isIndependentGasRetailer: boolean = false;
	isUnknownLocation: boolean = false;
	address: string;
	canvasRotation: number = 0;
	location: any = {
		type: 'Point',
		coordinates: []
	};
	@Input() cropperSettings;
	@Input() phoneConfig;
	@Input() message;
	@Input() myProfile: any;
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;
	constructor(public validationService: ValidationService,
		public profileService: ProfileService,
		public commonService: CommonService,
		public tokenService: TokenService,
		private fb: UntypedFormBuilder,
		private cd: ChangeDetectorRef
	) {
		this.imageCropData = {};
		let userObj = this.tokenService.getLoggedInUser();
		let ids = Object.values(ROLES);
		this.isNotCorporateUser = ids.indexOf(userObj.roleId.name) == -1;
		this.isIndependentGasRetailer = userObj.roleId.name == ROLES.INDEPENDENT_RETAILER;
	}

	ngOnInit(): void {
		this.initializeControls();
	}

	options = {
		types: ['address'],
		componentRestrictions: { country: 'ng' }
	};

	initializeControls() {
		if (!this.isIndependentGasRetailer)
			this.profileForm = this.fb.group({
				firstName: ['', Validators.compose([Validators.required])],
				lastName: ['', Validators.compose([Validators.required])],
				companyName: ['', Validators.compose([])],
				date_of_birth: ['', Validators.compose([Validators.required])],
				address: ['', Validators.compose([Validators.required])],
				email: ['', Validators.compose([Validators.required])],
			});
		else
			this.profileForm = this.fb.group({
				companyName: ['', Validators.compose([Validators.required])],
				email: ['', Validators.compose([Validators.required, Validators.email])],
				firstName: ['', Validators.compose([Validators.required])],
				lastName: ['', Validators.compose([Validators.required])],
				contact: ['', Validators.compose([Validators.required])],
				address: ['', Validators.compose([Validators.required])],
				date_of_birth: ['', Validators.compose([Validators.required])],
				landMarkAddress: ['', Validators.compose([])],
			});
	}

	onSubmit(profileData) {
		this.isSubmitted = true;
		if (this.profileForm.valid) {
			this.isDisabled = true;
			profileData.dob = profileData.date_of_birth;
			profileData.address = { houseNo: "", address: profileData.address };
			this.profileService.updateProfile(profileData).subscribe(res => {
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.commonService.growlError(this.message.ERROR);
				this.isDisabled = false;
			});
		}
		else {
			this.validationService.validateAllFormFields(this.profileForm);
		}
	}

	onSubmitIndependnetProfile(profileData) {
		this.isSubmitted = true;
		if (this.profileForm.valid) {
			this.isDisabled = true;
			profileData = this.createPayload(profileData);
			this.profileService.updateIndependentProfile(profileData).subscribe(res => {
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.commonService.growlError(error.message);
				this.isDisabled = false;
			});
		}
		else {
			this.validationService.validateAllFormFields(this.profileForm);
		}
	}

	createPayload(profileData) {
		profileData.dob = profileData.date_of_birth;
		profileData.name = profileData.companyName;
		profileData.landMarkAddress = profileData.landMarkAddress;
		profileData.location = Object.keys(this.location.coordinates).length ? this.location : this.myProfile.location;
		profileData.address = this.address ? this.address : this.myProfile.address;
		if (typeof profileData.contact == "object") {
			profileData.countryCode = profileData.contact.countryCode;
			profileData.dialCode = profileData.contact.dialCode;
			profileData.contact = this.commonService.formatContactNumber(profileData.contact.number);
		}
		return profileData;
	}


	fileChangeListener(event) {
		var image: any = new Image();
		var file: File = event.target.files[0];
		if (this.extArr.indexOf(file.type) == -1) {
			this.isFileUpload = false;
			this.commonService.growlError('Invalid file format (Accept only jpeg, png format)');
			return;
		}
		this.isFileUpload = true;
		this.fileObj = file;
		var myReader: FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent: any) {
			image.src = loadEvent.target.result;
			that.cropper.setImage(image);
		};
		myReader.readAsDataURL(file);
	}

	getImageData() {
		var _this = this;
		this.isUploading = true;
		this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
			let formData = new FormData();
			formData.append('image', file);
			_this.profileService.uploadAvatar(formData).subscribe(res => {
				_this.commonService.growlSuccess(res.message);
				_this.commonService.profileSubject.next({ image: res.response.image });
				_this.isFileUpload = false;
				_this.isUploading = false;
			}, error => {
				_this.displayError();
			});
		}, error => {
			_this.displayError();
		});
	}

	displayError() {
		this.commonService.growlError(this.message.ERROR);
		this.isFileUpload = false;
		this.imageCropData.image = null;
		this.isUploading = false;
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.profileForm.controls.address.setValue(this.address);
		}
		else {
			this.profileForm.controls.address.setValue('');
			this.isUnknownLocation = true;
		}
	}

	ngOnChanges(change: SimpleChanges) {
		if (change.myProfile && change.myProfile.currentValue) {
			const userDetails = this.isIndependentGasRetailer ? this.myProfile.user : this.myProfile;
			this.profileImage = userDetails.image;
			let controls = this.profileForm.controls;
			controls.companyName.setValue(this.isIndependentGasRetailer ? this.myProfile.name : userDetails.companyName);
			controls.firstName.setValue(userDetails.firstName);
			controls.lastName.setValue(userDetails.lastName);
			controls.email.setValue(userDetails.email);
			if (this.isIndependentGasRetailer) {
				controls.email.setValue(userDetails.email);
				this.phoneConfig.CountryISO = userDetails.countryCode;
				controls.contact.setValue(userDetails.contact);
				this.cd.detectChanges();
				controls.address.setValue(this.myProfile.address);
				controls.landMarkAddress.setValue(this.myProfile.landMarkAddress);
			}
			controls.date_of_birth.setValue(this.commonService.convertDate(userDetails.dob));
			if (!this.isIndependentGasRetailer && userDetails.address && userDetails.address.address)
				controls.address.setValue(userDetails.address.address);
		}
	}

}