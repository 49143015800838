import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
const preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
export const DEFAULT_COUNTRY = CountryISO.Nigeria;

export const PHONE_CONFIG = {
    separateDialCode: true,
    SearchCountryField: SearchCountryField,
    TooltipLabel: TooltipLabel,
    CountryISO: DEFAULT_COUNTRY,
    preferredCountries: preferredCountries
}

