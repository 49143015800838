import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxGalleryModule } from 'ngx-gallery-9';

import {
	TableLayoutComponent, MapLayoutComponent, FilterOptionComponent, CylinderQuantityComponent,
	VehicleDetailsComponent, DriverDetailsComponent, DriverLicenceDetailsComponent, GuarantorDetailsComponent,
	RatingComponent, DocumentsComponent, EmergencyDetailsComponent, OrderPaymentComponent,
	MultiOrderViewComponent, CartOrderComponent, IndependentPaymentInfoComponent, BankDetailsComponent, OrdersComponent, ClientDetailsComponent,
	ExportOrdersComponent,
} from '../containers';
import { AssociateRequestComponent } from '../views/authenticated/cylinders/associate-request/associate-request.component';
import { NumberOnlyDirective, IntegerOnlyDirective } from '../views/authenticated/__directive';
import { CustomDateTimePipe } from '../views/authenticated/__pipes/datetime.pipe';
import { CustomDatePipe } from '../views/authenticated/__pipes/date.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UiScrollModule } from 'ngx-ui-scroll';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { environment } from '../../environments/environment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SetLpgPriceComponent } from '../views/authenticated/cylinders/set-lpg-price/set-lpg-price.component';
import { CylinderStockComponent } from '../views';
import { SetDeliveryChargesComponent } from '../views/authenticated/cylinders/set-delivery-charges/set-delivery-charges.component';

const COMPONENTS = [
	TableLayoutComponent, FieldErrorDisplayComponent, MapLayoutComponent, FilterOptionComponent,
	AssociateRequestComponent, CylinderQuantityComponent, VehicleDetailsComponent, DriverDetailsComponent, ClientDetailsComponent,
	DocumentsComponent, EmergencyDetailsComponent, OrderPaymentComponent, BankDetailsComponent,
	DriverLicenceDetailsComponent, GuarantorDetailsComponent, RatingComponent, MultiOrderViewComponent, SetLpgPriceComponent, SetDeliveryChargesComponent, CylinderStockComponent,
	CartOrderComponent, IndependentPaymentInfoComponent, OrdersComponent, ExportOrdersComponent];

const MODULES = [
	ModalModule, NgxPaginationModule, NgxIntlTelInputModule, TabsModule,
	CommonModule, FormsModule, ReactiveFormsModule, InfiniteScrollModule,
	Ng2SearchPipeModule, UiScrollModule, AgmCoreModule, AgmDirectionModule,
	GooglePlaceModule, TooltipModule, NgxGalleryModule, NgMultiSelectDropDownModule
];

const DIRECTIVES = [
	NumberOnlyDirective, IntegerOnlyDirective
];

const CUSTOM_PIPES = [
	CustomDateTimePipe, CustomDatePipe
];

@NgModule({
	declarations: [COMPONENTS, DIRECTIVES, CUSTOM_PIPES],
	imports: [
		CommonModule,
		NgxPaginationModule,
		NgxIntlTelInputModule,
		ModalModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		FormsModule,
		GooglePlaceModule,
		ReactiveFormsModule,
		ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
		NgxGalleryModule,
		AgmCoreModule.forRoot({
			apiKey: environment.AGM_KEY,
			libraries: ['places']
		}),
		AgmDirectionModule,
		NgxDaterangepickerMd.forRoot(),
		NgMultiSelectDropDownModule.forRoot()
	],
	exports: [COMPONENTS, MODULES, DIRECTIVES, CUSTOM_PIPES],
	providers: [BsModalRef, CustomDatePipe]
})

export class SharedModule { }