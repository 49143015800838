import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Component({
	selector: 'app-filter-option',
	templateUrl: './filter-option.component.html',
	styleUrls: ['./filter-option.component.css']
})
export class FilterOptionComponent implements OnInit {

	month: string;
	@Output() onFilter = new EventEmitter<any>();
	@Output() onOrderModeChange = new EventEmitter<any>();
	@Output() onOrderStatusChange = new EventEmitter<any>();
	@Input() isOrderFilter: boolean = false;
	@Input() isDateSelection: boolean = true;
	@Input() optionValue: string = "";
	@Input() statusOptionValue: string = "";
	@Input() orderStatus: any;
	@Input() isOrderStatusFilter: boolean = false;
	constructor() {
		this.setMonth();
	}
	selected: { startDate: Moment, endDate: Moment };
	locale = {
		format: 'YYYY-MM-DD', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
		displayFormat: 'YYYY-MM-DD', // default is format value
		direction: 'ltr', // could be rtl
		weekLabel: 'W',
		separator: ' To ', // default is ' - '
		cancelLabel: 'Cancel', // detault is 'Cancel'
		// applyLabel: 'Okay', // detault is 'Apply'
		clearLabel: 'Clear', // detault is 'Clear'
		customRangeLabel: 'Custom range',
		//daysOfWeek: moment.weekdaysMin(),
		//monthNames: moment.monthsShort(),
		firstDay: 1 // first day is monday
	};
	ngOnInit(): void {
		this.orderStatus = this.isOrderStatusFilter && Object.values(this.orderStatus);
	}

	setMonth() {
		const date = new Date();
		const currentMonth = date.getMonth() + 1;
		this.month = date.getFullYear() + "-" + (currentMonth > 10 ? currentMonth : ('0' + currentMonth));
	}

	onChange(event) {
		if (event.startDate && event.endDate) {
			let obj = { startDate: '', endDate: '' };
			obj.startDate = moment(event.startDate).format('YYYY-MM-DD');
			obj.endDate = moment(event.endDate).format('YYYY-MM-DD');
			this.onFilter.next(obj);
		} else {
			let obj = { startDate: '', endDate: '' };
			this.onFilter.next(obj);
		}
	}

	onOrderChange(event) {
		this.onOrderModeChange.next(event.target.value);
	}

	onStatusChange(event) {
		this.onOrderStatusChange.next(event.target.value);
	}
}

