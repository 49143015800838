import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class ChatService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Fetch Chat Users
	fetchChatUsers(): Observable<any> {
		return this.http.get(BASE_URL.CHAT_USERS, this.headerService.httpHeaders());
	}

	// Post Chat
	postChat(payload): Observable<any> {
		return this.http.post(BASE_URL.CHAT_URL, payload, this.headerService.httpHeaders());
	}

	// Fetch Chat History
	fetchChatHistory(id, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CHAT_URL + "/" + id + searchQuery, this.headerService.httpHeaders());
	}
}