import { Component, OnInit } from '@angular/core';
import {
  ValidationService, CommonService, LoaderService, IndependentGasRetailerService, TokenService, StorageService
} from '../../../../__services';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-set-delivery-charges',
  templateUrl: './set-delivery-charges.component.html',
  styleUrls: ['./set-delivery-charges.component.scss']
})
export class SetDeliveryChargesComponent implements OnInit {

  authUser: any;
  deliveryChargeForm: UntypedFormGroup;
  isCreateBtn: boolean = false;
  isEnabled: boolean = true;
  isUpdateLPG: boolean = false;
  deliveryCharges: any;
  isEnableDelivery: boolean = false;
  isDeliveryChargeSet: boolean = false;
  isDeliveryChargeEnable: boolean = false;

  constructor(
    public validationService: ValidationService,
    public storageService: StorageService,
    public IndependentGasRetailerService: IndependentGasRetailerService,
    private fb: UntypedFormBuilder,
    public commonService: CommonService,
  ) {
    this.authUser = this.storageService.getItem('userObj');
    this.initializeControls();
  }

  ngOnInit(): void {
    this.fetchIGR();
  }

  initializeControls() {

    this.deliveryChargeForm = this.fb.group({
      deliveryCharge: [{ disabled: true, value: "" }, Validators.compose([Validators.required])]
    });
  }

  //Enable-Disable Edit Btn
  editBtn() {
    this.isEnabled = false
    this.deliveryChargeForm.controls.deliveryCharge.enable()
  }


  //Fetch IGR Delivery Charges
  fetchIGR() {
    this.IndependentGasRetailerService.fetchIGR(this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
      if (res && res.success && res.response && res.response.data) {
        console.log("es.response.data", res.response.data);
        this.isDeliveryChargeSet = res.response.data.isDeliveryChargeSet;
        this.isDeliveryChargeEnable = res.response.data.isDeliveryChargeSet;
        this.deliveryCharges = res.response.data.deliveryCharge;
        this.deliveryChargeForm.controls.deliveryCharge.setValue(this.deliveryCharges ? this.deliveryCharges : "");

      }
      else {

      }
    }, error => {

    });
  }

  //enable delivery charges
  enableDeliveryCharge(val) {
    console.log(val, "val");

    let payload = {
      "deliveryCharge": 0,
      "isEnable": false
    }
    this.IndependentGasRetailerService.setIGRDeliveryCharge(payload, this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
      if (res && res.success && res.response) {
        this.isDeliveryChargeSet = res.response.isDeliveryChargeSet;
        this.isDeliveryChargeEnable = val;
        console.log("this.isDeliveryChargeEnable", this.isDeliveryChargeEnable, "val", val);

        if (!val) {
          this.commonService.growlSuccess(res.message);
        }
      }
    }, error => {
      this.commonService.growlError(error.message);
    });
  }

  setIGRDeliveryCharge() {
    this.isUpdateLPG = true
    let payload = {
      "deliveryCharge": this.deliveryChargeForm.controls.deliveryCharge.value,
      "isEnable": true
    }
    this.IndependentGasRetailerService.setIGRDeliveryCharge(payload, this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
      if (res && res.response) {
        console.log("res.response.data", res.response);
        this.deliveryCharges = res.response.deliveryCharge
        this.isEnabled = true
        this.isUpdateLPG = false
        this.deliveryChargeForm.controls.deliveryCharge.disable()
        this.commonService.growlSuccess(res.message);
      }
    }, error => {
      this.commonService.growlSuccess(error.message);

    });
  }

}
