import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
	ValidationService, CommonService, SettingService, LoaderService,
	ImageCropperService, IndependentGasRetailerService, TokenService, StorageService
} from '../../../../__services';
import { MESSAGE, FILE_PLACEHOLDER } from '../../../../__utilities/messages';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_TYPE, ORDER_VALUES } from '../../../../__utilities/orders';
import Swal from 'sweetalert2';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-independent-product-setting',
	templateUrl: './independent-product-setting.component.html',
	styleUrls: ['./independent-product-setting.component.css']
})
export class IndependentProductSettingComponent implements OnInit {
	cylinderCategoryForm: UntypedFormGroup;
	cylinderCategories: any = [];
	titles: object = TITLE;
	selectedCategory: any;
	isDisabled: boolean = false;
	cylStock: boolean = false;
	isAdd: boolean = false;
	isStock: boolean = true;
	filePlaceholder = FILE_PLACEHOLDER;
	isFileUpload: boolean = false;
	extArr = ['image/jpeg', 'image/png'];
	fileObj: any;
	imageCropData: any = {};
	// categoryImage: any;
	cropperSettings: any;
	isSubmitted: boolean = false;
	permissions: any = {};
	orderTypes: object = ORDER_TYPE;
	orderValues: object = ORDER_VALUES;
	lpgPrice: any = "";
	isEnabled: boolean = false;
	isUpdateLPG: boolean = false;
	routeReuseSubscription: Subscription;
	authUser: any;
	isCreateBtn: boolean = false;
	dropdownList = [];
	selectedItems = [];
	dropdownSettings = {};
	brandNames: any;
	selectedItemsNames = [];
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;
	constructor(
		public validationService: ValidationService,
		public commonService: CommonService,
		public settingService: SettingService,
		public loader: LoaderService,
		public imageCropperService: ImageCropperService,
		public IndependentGasRetailerService: IndependentGasRetailerService,
		public tokenService: TokenService,
		private fb: UntypedFormBuilder,
		public storageService: StorageService
	) {
		this.authUser = this.storageService.getItem('userObj');
		this.initializeControls();
		this.fetchIGRCylinderCategory();
	}

	ngOnInit(): void {
		this.fetchBrands();
		this.dropdownSettings = {
			singleSelection: false,
			idField: '_id',
			textField: 'brandName',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 5,
			allowSearchFilter: false
		};

		this.loader.show();
		this.cropperSettings = this.imageCropperService.cropperSetting();
		this.cropperSettings.rounded = false;
		this.cropperSettings.keepAspect = false;
		this.setPermissions();
		this.routeSubscription();
	}

	// Set Permissions
	setPermissions() {
		let authUserPermission = this.tokenService.getPermission();
		this.permissions = {
			isAddUpdateCategory: this.commonService.isPermission(authUserPermission, PERMISSIONS.ADD_UPDATE_PRODUCT_PRICE),
			isDeleteCategory: this.commonService.isPermission(authUserPermission, PERMISSIONS.DELETE_PRODUCT_PRICE)
		}
	}
	//fulfilRefillExchangeBrands
	onItemSelect(event) {
		if (!this.selectedItems.find(({ _id }) => _id === event._id)) {
			this.selectedItems.push(event);
		}
		console.log(this.selectedItems);

	}

	onItemUnSelect(event) {
		this.selectedItems.splice(this.selectedItems.findIndex(v => v._id === event._id), 0);
		console.log(this.selectedItems);
	}

	onItemUnSelectAll(event) {
		this.selectedItems = [];
	}
	onSelectAll(event) {
		this.selectedItems = this.dropdownList;
	}

	initializeControls() {
		this.cylinderCategoryForm = this.fb.group({
			size: ['', Validators.compose([Validators.required])],
			discount: ['', Validators.compose([])],
			cylinderStock: ['', Validators.compose([Validators.required])],
			// categoryImage: ['', Validators.compose([Validators.required])],
			emptyCylinderCharge: ['', Validators.compose([Validators.required])],
			brand: ['', Validators.compose([Validators.required])],
		});
	}

	// Submit form
	onSubmit(tankAttribute) {
		this.isSubmitted = true;
		if (this.cylinderCategoryForm.valid) {
			this.isFileUpload = false;
			this.isDisabled = true;
			tankAttribute = this.createPayload(tankAttribute);
			this.IndependentGasRetailerService.manageIGRCylinderCategory(tankAttribute, this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
				if (res && res.success) {
					this.fetchIGRCylinderCategory();
					if (tankAttribute.get('id')) {
						this.cylinderCategories.forEach((element, index) => {
							if (element._id == tankAttribute.get('id')) {
								var categoryRes = res.response;
								categoryRes.cylinderStock = this.cylinderCategories[index].cylinderStock ? this.cylinderCategories[index].cylinderStock : 0;
								this.cylinderCategories[index] = categoryRes;
							}
						});
					}
					else {

						var categoryRes = res.response;
						categoryRes.cylinderStock = tankAttribute.get('cylinderStock') ? tankAttribute.get('cylinderStock') : 0;
						this.cylinderCategories.push(categoryRes);
					}
					this.cylinderCategories?.map((cat, index) => {
						this.selectedItemsNames[index] = cat.fulfilRefillExchangeBrands?.map((elem) => {
							return elem.brandName;
						}).join(",");
					});
				}
				this.resetForm();
				this.commonService.growlSuccess(res.message);
			}, error => {
				this.isDisabled = false;
				this.isSubmitted = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.cylinderCategoryForm);
		}
	}

	// Create Payload 
	createPayload(tankAttribute) {
		let formData = new FormData();
		formData.append('size', tankAttribute.size);
		if (tankAttribute.discount) formData.append('discount', tankAttribute.discount);
		formData.append('emptyCharge', tankAttribute.emptyCylinderCharge);
		formData.append('cylinderStock', tankAttribute.cylinderStock);
		formData.append('retailerId', this.authUser[this.authUser.roleId.name]._id);
		console.log("final result", this.selectedItems)
		const usersSelectedItems = this.selectedItems.map(item => {
			return item._id;
		})
		formData.append('fulfilRefillExchangeBrands', JSON.stringify(usersSelectedItems));
		// if (this.categoryImage) formData.append('image', this.categoryImage);
		if (this.selectedCategory) formData.append('id', this.selectedCategory._id);
		return formData;
	}

	//Fetch Brand Names
	fetchBrands() {
		this.settingService.fetchBrandsIgr(this.authUser.independent._id).subscribe(res => {
			if (res && res.success && res.response) {
				this.brandNames = res.response.data;
				//   this.dropdownList = res.response.data && Array.isArray(res.response.data) ? res.response.data : [];
				if (res.response.data && Array.isArray(res.response.data)) {
					for (let index = 0; index < res.response.data.length; index++) {
						const element = res.response.data[index];
						if (element.isEnableRetailer) {
							this.dropdownList.push({
								_id: element._id,
								brandName: element.brandName
							})
						}
					}
				}

			}
		}, error => {

		});
	}

	// Fetch Tank Categories
	fetchIGRCylinderCategory() {
		this.IndependentGasRetailerService.fetchIGRCylinderCategory(this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response;
				console.log("cylinderCategories", this.cylinderCategories);

				this.cylinderCategories?.map((cat, index) => {
					this.selectedItemsNames[index] = cat.fulfilRefillExchangeBrands?.map((elem) => {
						return elem.brandName;
					}).join(",");
				});

			} else {
				this.cylinderCategories = [];
			}
			this.loader.hide();
		}, error => {
			this.loader.hide();
			this.cylinderCategories = [];
		});
	}

	// On Edit
	onEdit(category) {
		this.selectedItems = category && category.fulfilRefillExchangeBrands && Array.isArray(category.fulfilRefillExchangeBrands) ? category.fulfilRefillExchangeBrands : [];
		this.isAdd = true;
		this.isStock = false;
		this.selectedCategory = category;
		console.log(category.fulfilRefillExchangeBrands, "category.fulfilRefillExchangeBrands")
		let controls = this.cylinderCategoryForm.controls;
		controls.size.setValue(category.size);
		controls.cylinderStock.setValue(category.cylinderStock);
		controls.emptyCylinderCharge.setValue(category.emptyCharge);
		controls.discount.setValue(category.discount);
		this.commonService.scrollTop();
		// this.cylinderCategoryForm.controls.categoryImage.clearValidators();
		// this.cylinderCategoryForm.controls.categoryImage.updateValueAndValidity();

	}

	// Reset form
	resetForm() {
		this.cylinderCategoryForm.reset();
		this.selectedCategory = '';
		//this.selectedItems = [];
		this.isDisabled = false;
		this.isFileUpload = false;
		this.isAdd = false;
		this.cylStock = false;
		this.isSubmitted = false;
		// this.cylinderCategoryForm.controls.categoryImage.setValidators([Validators.required]);
		// this.cylinderCategoryForm.controls.categoryImage.updateValueAndValidity();
	}

	// On Delete
	onDelete(attribute) {
		this.resetForm();
		Swal.fire({
			title: MESSAGE.DELETE_CATEGORY_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_CATEGORY_CONFIRMATION_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedAttribute(attribute._id);
			}
		});
	}

	// Delete selected attribute
	deleteSelectedAttribute(id) {
		this.IndependentGasRetailerService.deleteIGRCylinderCategory(id, this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
			if (res && res.success) {
				const tempArr = [];
				this.cylinderCategories.forEach(element => {
					if (element._id != id) {
						tempArr.push(element);
					}
				});
				this.cylinderCategories = tempArr;
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	addNew(x) {
		this.isStock = true;
		this.resetForm();
		if (x == 'size') {
			this.isAdd = true;
		} else {
			this.cylStock = true;
		}
	}
	goBack() {
		this.fetchIGRCylinderCategory();
		this.cylStock = false;
	}

	// fileChangeListener(event, key, placeholder) {
	// 	var image: any = new Image();
	// 	var file: File = event.target.files[0];
	// 	var that = this;
	// 	if (file) {
	// 		if (this.extArr.indexOf(file.type) == -1) {
	// 			this.isFileUpload = false;
	// 			setTimeout(() => {
	// 				that.commonService.setFilePlaceholder('fileCategory', placeholder);
	// 			}, 0);
	// 			this.commonService.growlError(MESSAGE.INVALID_IMG_FORMAT);
	// 			return;
	// 		}
	// 		this.isFileUpload = true;
	// 		this.fileObj = file;
	// 		var myReader: FileReader = new FileReader();
	// 		myReader.onloadend = function (loadEvent: any) {
	// 			image.src = loadEvent.target.result;
	// 			setTimeout(() => { that.cropper.setImage(image); }, 0);
	// 		};
	// 		myReader.readAsDataURL(file);
	// 	}
	// }

	// getImageData(key) {
	// 	var _this = this;
	// 	this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
	// 		_this.isFileUpload = false;
	// _this.categoryImage = file;
	// 		_this.commonService.setFilePlaceholder('fileCategory', file.name);
	// _this.cylinderCategoryForm.controls.categoryImage.setValue(_this.fileObj.name);
	// 	}, error => {
	// 		_this.commonService.growlError(MESSAGE.ERROR);
	// 		_this.isFileUpload = false;
	// 		_this.imageCropData.image = null;
	// 	});
	// }

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.CYLINDER_CATEGORIES ? this.resetForm() : false;
		});
	}

	ngOnDestroy() {
		this.routeReuseSubscription.unsubscribe();
	}
}
