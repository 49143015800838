import { Injectable } from '@angular/core';
// import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { TokenService } from '../__services/token.service';
import { BASE_URL } from '../__utilities/api';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from '../__services/header.service';
import { Observable } from 'rxjs';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Socket } from 'ngx-socket-io';

@Injectable({
	providedIn: 'root'
})

export class WebSocketService {
	// socket;
	userId: string;
	constructor(public tokenService: TokenService, private socket: Socket,
		private http: HttpClient, private headerService: HeaderService) {
		this.userId = this.tokenService.getLoggedInUser()._id;
	}

	setupSocketConnection() {
		this.getBrowserUniqueId().subscribe(res => {
			if (res && res.success && res.response) {
				this.socketConnection(res.response.hash);
				console.log("res.response.hash", res.response.hash);

			}
		}, error => {
			this.socketConnection('');
		});
	}

	// Get Browser Unique Id
	getBrowserUniqueId(): Observable<any> {
		return this.http.get(BASE_URL.PUSH_NOTIFICATION_URL, this.headerService.httpHeaders());
	}

	// Create Connection
	socketConnection(deviceId) {
		// const config: SocketIoConfig = {
		// 	url: "https://stage.smartgasng.com", // socket server url;
		// 	options: {
		// 		query: {
		// 			"userId": this.userId,
		// 			"deviceId": deviceId
		// 		},
		// 		transports: ['websocket']
		// 	}
		// };
		this.socket.disconnect()
		this.socket.ioSocket.io.opts.query = {
			"userId": this.userId,
			"deviceId": deviceId
		};
		if (this.userId && deviceId) {
			this.socket.ioSocket.connect();
		}
		const query = {
			"userId": this.userId,
			"deviceId": deviceId
		}
		// SocketIoModule.forRoot(config)
		// this.socket = io(environment.API_HOST + "?userId=" + this.userId + "&deviceId=" + deviceId);
	}

	public onMessage(): Observable<any> {
		// return new Observable<any>(observer => {
		return this.socket.fromEvent('message');
		// this.socket.on('message', (res: any) => observer.next(res));
		// });
	}

	public onAssociateLocationChange(): Observable<any> {
		// return new Observable<any>(observer => {
		return this.socket.fromEvent('associate-location-changed');
		// this.socket.on('associate-location-changed', (res: any) => observer.next(res));
		// });
	}

	public onOrderStatusChange(): Observable<any> {
		// return new Observable<any>(observer => {
		return this.socket.fromEvent('orderStatusChange');
		// this.socket.on('orderStatusChange', (res: any) => observer.next(res));
		// });
	}

	public fetchUpdatedSetting(): Observable<any> {
		// return new Observable<any>(observer => {
		return this.socket.fromEvent('SocketEmitToIGR');
		// this.socket.on('SocketEmitToIGR', (res: any) => observer.next(res));
		// });
	}
}