import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Fetch Cart Order Count
	fetchCartOrdersCount(queryParams): Observable<any> {
		return this.http.get(BASE_URL.CART_ORDER_DASHBOARD_COUNT + queryParams, this.headerService.httpHeaders());
	}

	// Fetch Associate Request Count
	fetchAssociateReqCount(): Observable<any> {
		return this.http.get(BASE_URL.DASHBOARD_ASSOCIATE_REQUEST_COUNT, this.headerService.httpHeaders());
	}

	// // Fetch Plant's Refill Request Count
	// fetchPlantsRefillReqCount(queryParams): Observable<any> {
	// 	return this.http.get(BASE_URL.DASHBOARD_PLANTS_REFILL_REQUEST_COUNT + queryParams, this.headerService.httpHeaders());
	// }

	// Fetch Plant's Cylinder Request Count
	fetchPlantsCylinderReqCount(queryParams): Observable<any> {
		return this.http.get(BASE_URL.DASHBOARD_PLANTS_CYLINDER_REQUEST_COUNT + queryParams, this.headerService.httpHeaders());
	}

	// Fetch Independent Cylinder Request Count
	fetchIndependentCylinderRequestCount(queryParams): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_CYLINDER_REQUEST_COUNT + queryParams, this.headerService.httpHeaders());
	}
}
