import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { GasRetailerRoutingModule, ROUTING_COMPONENTS } from './gas-retailer-routing.module';

const MODULES = [
  SharedModule, CommonModule, GasRetailerRoutingModule
];

@NgModule({
  declarations: [ROUTING_COMPONENTS],
  imports: [MODULES]
})
export class GasRetailerModule { }
