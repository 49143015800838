import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService, CommonService, TokenService, OrderService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_VALUES } from '../../../../__utilities/orders';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { MESSAGE } from '../../../../__utilities/messages';
import { ROLES } from '../../../../__utilities/roles';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $;

@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

	paginate: any = { ...PAGINATION };
	isLoading: boolean = false;
	searchStr: string = '';
	clients = [];
	selectedAssociate = [];
	isView: boolean = false;
	heading: string = TITLE.CLIENTS;
	isPermission: boolean = false;
	noRecord: string = "";
	clientId: string = "";
	orderDetails: any = "";
	orderTypes: any = ORDER_VALUES;
	activatedRouteValue: any = "";
	isPrintOrderPermission: boolean = false;
	searchClientId: string = "";
	constructor(public usersService: UsersService,
		public commonService: CommonService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public orderService: OrderService,
		public tokenService: TokenService) {
		const authUserPermission = this.tokenService.getPermission();
		this.isPermission = this.commonService.isPermission(authUserPermission, PERMISSIONS.CLIENT_APPROVE_REJECT_ACCOUNT);
		this.isPermission = [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER].indexOf(authUserPermission.name) == -1;
		this.isPrintOrderPermission = authUserPermission.name == ROLES.CORPORATE;
		this.activatedRouteValue = this.activatedRoute.data['value'];
	}

	ngOnInit(): void {
		this.getRoutingParams();
	}

	// Get Routing Params
	getRoutingParams() {
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ clientId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.searchClientId = results.query.userId ? results.query.userId : "";
				this.clients = [];
				this.fetchClients();
			});
	}

	// Reset Pagination
	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	fetchClients() {
		var searchQuery = this.getSearchString();
		// searchQuery += `&status=approve`
		this.isLoading = true;
		this.usersService.getClients(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.clients = res.response.associates ? res.response.associates : [];
			}
			else
				this.clients = [];
			this.isLoading = false;
		}, error => {
			this.clients = [];
			this.isLoading = false;
		});
	}

	getSearchString() {
		return "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.resetParam();
	}

	resetParam() {
		this.clients = [];
		this.fetchClients();
	}

	onClick(event, associate, index, isApprove) {
		const payload = {
			approve: isApprove,
			reject: !isApprove
		};
		this.usersService.manageClients(associate._id, payload).subscribe(res => {
			if (res && res.success) {
				this.clients[index] = res.user;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			$("#checkbox" + index).prop("checked", false);
			this.commonService.growlError(error.message);
		});
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.resetParam();
	}

	viewClient(client) {
		this.router.navigate([this.activatedRouteValue.viewURL, client._id], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	viewOrderDetails(order) {
		this.orderDetails = order;
	}

	viewClientDetails(client, url) {
		this.router.navigate([url, client._id], { queryParams: this.queryParamsObj() });
	}

	goBack(isMainList) {
		isMainList ? this.router.navigate([this.activatedRouteValue.breadcrumb[0].url]) : this.orderDetails = "";
	}

	downloadCSVOrderDetails(client) {
		this.orderService.getClientOrdersCSVFile(client._id).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});

			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = `orders-${client.uniqueIdentity}.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}
}