import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-map-layout',
	templateUrl: './map-layout.component.html',
	styleUrls: ['./map-layout.component.css']
})
export class MapLayoutComponent implements OnInit {

	lat: number = 9.0820;
	lng: number = 8.6753;

	@Input() origin;
	@Input() destination;


	// public lat: Number = 22.7196;
	// public lng: Number = 75.8577;

	// @Input() origin = { lat: 22.7196, lng: 75.8577 };
	// @Input() destination = { lat: 28.7041, lng: 77.1025 };
	// public waypoints = [
	// 	{
	// 		location: { lat: 22.7196, lng: 75.8577 },
	// 		stopover: false,
	// 	},
	// 	{
	// 		location: { lat: 23.2599, lng: 77.4126 },
	// 		stopover: false,
	// 	}, {
	// 		location: { lat: 28.7041, lng: 77.1025 },
	// 		stopover: false,
	// 	}
	// ];

	// public markerOptions = {
	// 	origin: {
	// 		icon: 'https://www.shareicon.net/data/32x32/2016/04/28/756617_face_512x512.png',
	// 		draggable: true,
	// 	},
	// 	destination: {
	// 		icon: 'https://www.shareicon.net/data/32x32/2016/04/28/756626_face_512x512.png',
	// 		label: 'MARKER LABEL',
	// 		opacity: 0.8,
	// 	},
	// 	waypoints:{
	// 		icon: 'https://www.shareicon.net/data/32x32/2016/04/28/756617_face_512x512.png',
	// 		draggable: true,
	// 	}
	// }

	constructor() { }

	ngOnInit(): void {
	}

	// public renderOptions = {
	// 	suppressMarkers: true,
	// };

}
