import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TITLE } from '../../__utilities/text';

@Component({
	selector: 'app-warehouse-manager-dashboard',
	templateUrl: './warehouse-manager-dashboard.component.html',
	styleUrls: ['./warehouse-manager-dashboard.component.css']
})
export class WarehouseManagerDashboardComponent implements OnInit {

	@Input() dashboardService;
	@Input() loggedInUser: any;
	@Input() cylinderRequests: any;
	@Input() refillRequests: any;
	@Input() orderStatus: any;
	@Input() independentCylinderRequests: any;
	@Output() cylinderReqEvent = new EventEmitter<string>();
	@Output() plantRefillReqEvent = new EventEmitter<string>();
	@Output() independentCylinderReqEvent = new EventEmitter<string>();
	queryParams: string;
	title: any = TITLE;
	constructor() { }

	ngOnInit(): void {
		this.queryParams = "?warehouseId=" + this.loggedInUser.warehouse._id;
		this.fetchPlantsCylinderReqCount();
		// this.fetchPlantsRefillReqCount();
		this.fetchIndependentRequestCount();
	}

	// Fetch Plant'c Cylinder Request Count
	fetchPlantsCylinderReqCount() {
		this.cylinderReqEvent.next(this.queryParams);
	}

	// // Fetch Plants Refill Request Count
	// fetchPlantsRefillReqCount() {
	// 	this.plantRefillReqEvent.next(this.queryParams);
	// }

	// Fetch Independent New Request Count
	fetchIndependentRequestCount() {
		const queryParams = "?warehouseId=" + this.loggedInUser[this.loggedInUser.roleId.name]._id;
		this.independentCylinderReqEvent.next(queryParams);
	}
}
