import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
	ValidationService, CommonService, SettingService, LoaderService,
	ImageCropperService, CylinderService, TokenService
} from '../../../../__services';
import { MESSAGE, FILE_PLACEHOLDER } from '../../../../__utilities/messages';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_TYPE, ORDER_VALUES } from '../../../../__utilities/orders';
import Swal from 'sweetalert2';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-cylinder-category-setting',
	templateUrl: './cylinder-category-setting.component.html',
	styleUrls: ['./cylinder-category-setting.component.css']
})
export class CylinderCategorySettingComponent implements OnInit, OnDestroy {

	cylinderCategoryForm: UntypedFormGroup;
	cylinderPricingForm: UntypedFormGroup;
	cylinderCategories: any = [];
	titles: object = TITLE;
	selectedCategory: any;
	isDisabled: boolean = false;
	isAdd: boolean = false;
	filePlaceholder = FILE_PLACEHOLDER;
	isFileUpload: boolean = false;
	extArr = ['image/jpeg', 'image/png'];
	fileObj: any;
	imageCropData: any = {};
	// categoryImage: any;
	cropperSettings: any;
	isSubmitted: boolean = false;
	permissions: any = {};
	orderTypes: object = ORDER_TYPE;
	orderValues: object = ORDER_VALUES;
	lpgPrice: any = "";
	isEnabled: boolean = false;
	isUpdateLPG: boolean = false;
	routeReuseSubscription: Subscription;
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;
	constructor(
		public validationService: ValidationService,
		public commonService: CommonService,
		public settingService: SettingService,
		public loader: LoaderService,
		public imageCropperService: ImageCropperService,
		public cylinderService: CylinderService,
		public tokenService: TokenService,
		private fb: UntypedFormBuilder,
	) {
		this.initializeControls();
		this.fetchLPGPrice();
		this.fetchCylinderCategory();
	}

	ngOnInit(): void {
		this.loader.show();
		this.cropperSettings = this.imageCropperService.cropperSetting();
		this.cropperSettings.rounded = false;
		this.cropperSettings.keepAspect = false;
		this.setPermissions();
		this.routeSubscription();
	}

	// Set Permissions
	setPermissions() {
		let authUserPermission = this.tokenService.getPermission();
		this.permissions = {
			isAddUpdateCategory: this.commonService.isPermission(authUserPermission, PERMISSIONS.ADD_UPDATE_PRODUCT_PRICE),
			isDeleteCategory: this.commonService.isPermission(authUserPermission, PERMISSIONS.DELETE_PRODUCT_PRICE)
		}
	}

	initializeControls() {
		this.cylinderCategoryForm = this.fb.group({
			size: ['', Validators.compose([Validators.required])],
			discount: ['', Validators.compose([])],
			// categoryImage: ['', Validators.compose([Validators.required])],
			emptyCylinderCharge: ['', Validators.compose([Validators.required])],
		});

		this.cylinderPricingForm = this.fb.group({
			lpg_price_per_Kg: [{ disabled: true, value: "" }, Validators.compose([Validators.required])]
		});
	}

	// Submit form
	onSubmit(tankAttribute) {
		this.isSubmitted = true;
		if (this.cylinderCategoryForm.valid) {
			this.isFileUpload = false;
			this.isDisabled = true;
			tankAttribute = this.createPayload(tankAttribute);
			this.cylinderService.manageCylinderCategory(tankAttribute).subscribe(res => {
				if (res && res.success) {
					if (tankAttribute.get('id')) {
						this.cylinderCategories.forEach((element, index) => {
							if (element._id == tankAttribute.get('id')) {
								this.cylinderCategories[index] = res.response;
							}
						});
					}
					else {
						this.cylinderCategories.push(res.response);
					}
				}
				this.resetForm();
				this.commonService.growlSuccess(res.message);
			}, error => {
				this.isDisabled = false;
				this.isSubmitted = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.cylinderCategoryForm);
		}
	}

	// Create Payload 
	createPayload(tankAttribute) {
		let formData = new FormData();
		formData.append('size', tankAttribute.size);
		if (tankAttribute.discount) formData.append('discount', tankAttribute.discount);
		formData.append('emptyCharge', tankAttribute.emptyCylinderCharge);
		// if (this.categoryImage) formData.append('image', this.categoryImage);
		if (this.selectedCategory) formData.append('id', this.selectedCategory._id);
		return formData;
	}

	// Fetch Tank Categories
	fetchCylinderCategory() {
		this.cylinderService.fetchCylinderCategory().subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response;
			} else {
				this.cylinderCategories = [];
			}
			this.loader.hide();
		}, error => {
			this.loader.hide();
			this.cylinderCategories = [];
		});
	}

	// Fetch LPG Price
	fetchLPGPrice() {
		this.cylinderService.fetchLPGPrice().subscribe(res => {
			if (res && res.success && res.response) {
				this.lpgPrice = res.response.data ? res.response.data : "";
				this.cylinderPricingForm.controls.lpg_price_per_Kg.setValue(this.lpgPrice ? this.lpgPrice.LPGPricePerKg : "");
			}
		}, error => {
			this.lpgPrice = "";
		});
	}

	// On Edit
	onEdit(category) {
		this.isAdd = true;
		this.selectedCategory = category;
		let controls = this.cylinderCategoryForm.controls;
		controls.size.setValue(category.size);
		controls.emptyCylinderCharge.setValue(category.emptyCharge);
		controls.discount.setValue(category.discount);
		this.commonService.scrollTop();
		// this.cylinderCategoryForm.controls.categoryImage.clearValidators();
		// this.cylinderCategoryForm.controls.categoryImage.updateValueAndValidity();
	}

	// Reset form
	resetForm() {
		this.cylinderCategoryForm.reset();
		this.selectedCategory = '';
		this.isDisabled = false;
		this.isFileUpload = false;
		this.isAdd = false;
		this.isSubmitted = false;
		// this.cylinderCategoryForm.controls.categoryImage.setValidators([Validators.required]);
		// this.cylinderCategoryForm.controls.categoryImage.updateValueAndValidity();
	}

	// On Delete
	onDelete(attribute) {
		this.resetForm();
		Swal.fire({
			title: MESSAGE.DELETE_CATEGORY_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_CATEGORY_CONFIRMATION_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedAttribute(attribute._id);
			}
		});
	}

	// Delete selected attribute
	deleteSelectedAttribute(id) {
		this.cylinderService.deleteCylinderCategory(id).subscribe(res => {
			if (res && res.success) {
				const tempArr = [];
				this.cylinderCategories.forEach(element => {
					if (element._id != id) {
						tempArr.push(element);
					}
				});
				this.cylinderCategories = tempArr;
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	addNew() {
		this.resetForm();
		this.isAdd = true;
	}

	fileChangeListener(event, key, placeholder) {
		var image: any = new Image();
		var file: File = event.target.files[0];
		var that = this;
		if (file) {
			if (this.extArr.indexOf(file.type) == -1) {
				this.isFileUpload = false;
				setTimeout(() => {
					that.commonService.setFilePlaceholder('fileCategory', placeholder);
				}, 0);
				this.commonService.growlError(MESSAGE.INVALID_IMG_FORMAT);
				return;
			}
			this.isFileUpload = true;
			this.fileObj = file;
			var myReader: FileReader = new FileReader();
			myReader.onloadend = function (loadEvent: any) {
				image.src = loadEvent.target.result;
				setTimeout(() => { that.cropper.setImage(image); }, 0);
			};
			myReader.readAsDataURL(file);
		}
	}

	// getImageData(key) {
	// 	var _this = this;
	// 	this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
	// 		_this.isFileUpload = false;
	// 		_this.categoryImage = file;
	// 		_this.commonService.setFilePlaceholder('fileCategory', file.name);
	// 		_this.cylinderCategoryForm.controls.categoryImage.setValue(_this.fileObj.name);
	// 	}, error => {
	// 		_this.commonService.growlError(MESSAGE.ERROR);
	// 		_this.isFileUpload = false;
	// 		_this.imageCropData.image = null;
	// 	});
	// }

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.CYLINDER_CATEGORIES ? this.resetForm() : false;
		});
	}

	editPrice() {
		let controls = this.cylinderPricingForm.controls;
		if (this.isEnabled && this.cylinderPricingForm.valid) {
			this.isUpdateLPG = true;
			this.cylinderService.updateLPGPrice(this.lpgPrice._id, { LPGPricePerKg: controls.lpg_price_per_Kg.value }).subscribe(response => {
				if (response && response.success) {
					this.fetchCylinderCategory();
					controls.lpg_price_per_Kg.disable();
					this.isEnabled = false;
					this.commonService.growlSuccess(response.message);
				}
				this.isUpdateLPG = false;
			}, error => {
				this.isUpdateLPG = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.isEnabled = true;
			controls.lpg_price_per_Kg.enable();
			this.validationService.validateAllFormFields(this.cylinderPricingForm);
		}
	}

	ngOnDestroy() {
		this.routeReuseSubscription.unsubscribe();
	}
}
