import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {
	WarehouseService, IndependentGasRetailerService, LoaderService,
	CommonService, StorageService, TokenService, ValidationService,
	CylinderService, PaymentService
} from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { MESSAGE } from '../../../../__utilities/messages';
import { TITLE, BTN_TEXT } from '../../../../__utilities/text';
import { COMPONENT_KEYS, ORDER_STATUS } from '../../../../__utilities/orders';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $: any;

@Component({
	selector: 'app-independent-new-cylinder-request',
	templateUrl: './independent-new-cylinder-request.component.html',
	styleUrls: ['./independent-new-cylinder-request.component.css']
})
export class IndependentNewCylinderRequestComponent implements OnInit, AfterViewInit, OnDestroy {
	warehouses: any = [];
	cylinderCategories: any = [];
	inputId = "cylinder";
	isDisabled: boolean = false;
	paginate: any = { ...PAGINATION };
	orderStatus: object = { ...ORDER_STATUS };
	searchStr: string = '';
	myRequestList: any = [];
	isLoading: boolean = false;
	isAdd: boolean = false; 
	btnText: string = BTN_TEXT.ADD_REQUEST;
	selectedRequest: any = '';
	independentId: string = "";
	selectedIndex: number = 0;
	heading: string = TITLE.EMPTY_CYLINDER_REQUEST;
	searchOrderId: string = "";

	//Credit card properties
	cardForm: UntypedFormGroup;
	ccnum: any = "5377283645077450";
	cvv: any = "789";
	mm: any = "09";
	yy: any = "31";
	cylinderPayload: any = "";
	deliveryCharges: number = 0;
	isDeliveryCall: boolean = false;
	isNext: boolean = false;
	cylinderArr = [];
	currency: string = "NGN";
	country: string = "NG";
	totalCharges: number = 0;
	discount: number = 0;
	transactionCharges: number = 0;
	isTransactionCall: boolean = false;
	activatedRouteValues: any = "";
	requestId: string = "";
	noRecord: string = "";
	isDeliveryChargeLoading: boolean = false;
	isReadyToProceed: boolean = false;
	cards = [];
	isValidWarehouse: boolean = true;
	statusFilter: string = "";
	subscription: Subscription;
	paymentModes = {
		ONLINE: 'online',
		OFFLINE: 'offline'
	};
	selectedPaymentMode: string = this.paymentModes.ONLINE;
	constructor(
		private fb: UntypedFormBuilder,
		public warehouseService: WarehouseService,
		public independentGasRetailerService: IndependentGasRetailerService,
		public commonService: CommonService,
		public cylinderService: CylinderService,
		public storageService: StorageService,
		public tokenService: TokenService,
		public loader: LoaderService,
		public validationService: ValidationService,
		public activatedRoute: ActivatedRoute,
		public paymentService: PaymentService,
		public router: Router) {
		this.fetchRouteParameters();
		let userObj = this.storageService.getItem('userObj');
		this.independentId = userObj[userObj.roleId.name]._id;
		this.subscription = new Subscription();
	}

	ngOnInit(): void {
		this.fetchWarehouses();
		this.initializeControls();
		this.getRoutingParams();
		this.fetchCardDetails();
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ requestId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.requestId = results.requestId;
				this.routeSubscription();
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.myRequestList = [];
				this.requestId ? this.fetchCylinderRequestById() : (this.isLoading = true, this.fetchIndependentCylinderRequest());
			});
	}

	// Fetch Cylinder Request By Id.
	fetchCylinderRequestById() {
		this.isLoading = true;
		this.loader.show();
		this.cylinderService.fetchIndependentCylinderRequestById(this.requestId).subscribe(res => {
			if (res && res.success && res.response)
				this.selectedRequest = res.response;
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
		}, error => {
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
			this.selectedRequest = "";
		});
	}

	initializeControls() {
		this.cardForm = this.fb.group({
			card: ['', Validators.compose([Validators.required])],
			comment: ['', Validators.compose([])]
		});
	}

	// Fetch route params
	fetchRouteParameters() {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params && params.orderId)
				this.searchOrderId = params.orderId;
		});
	}

	addMore() {
		this.resetForm(true);
	}

	resetPagination() {
		this.paginate.currentPage = 1;
		this.paginate.totalItems = 0;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetAndSearch();
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.resetForm();
		this.searchStr = "";
		this.resetAndSearch();
	}

	resetAndSearch() {
		this.resetPagination();
		this.myRequestList = [];
		this.fetchIndependentCylinderRequest();
	}

	onOrderStatusChange(status) {
		this.statusFilter = status;
		this.resetAndSearch();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.myRequestList = [];
		this.fetchIndependentCylinderRequest();
	}

	fetchWarehouses() {
		let searchQuery = '';
		this.warehouseService.fetchWarehouse(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.warehouses = res.response.response ? res.response.response : [];
			}
			else
				this.warehouses = [];
		}, error => {
			this.warehouses = [];
		});
	}

	onContinue() {
		let warehouseId = $("#selectWarehouse").val();
		if (!warehouseId && !this.selectedRequest) {
			this.isValidWarehouse = false;
			return;
		}
		//if (!this.isDeliveryCall) return;
		let request = [];
		let noOfCylinder = 0;
		this.cylinderArr = [];
		let obj: any = '';
		this.totalCharges = 0;
		this.discount = 0;
		this.cylinderCategories.forEach((element, index) => {
			noOfCylinder = parseInt($('#' + this.inputId + index).val());
			if (noOfCylinder) {
				obj = { 'numberOfCylinder': noOfCylinder, 'size': element.size };
				request.push(obj);
				obj.charges = noOfCylinder * element.emptyCharge;
				this.discount += (obj.charges * element.discount) / 100;
				this.totalCharges += obj.charges;
				this.cylinderArr.push(obj);
			}
		});

		if (!request.length)
			return this.commonService.growlError(MESSAGE.ENTER_QUANTITY);

		let payload: any = {
			warehouseId: warehouseId,
			request: request,
			independentId: this.independentId,
			comment: this.cylinderPayload.comment
		};
		if (this.selectedRequest) {
			payload.id = this.selectedRequest._id;
			delete payload.warehouseId;
		}
		this.cylinderPayload = payload;
		this.isNext = true;
	}

	submitRequest() {
		this.isLoading = true;
		this.independentGasRetailerService.makeCylinderRequest(this.cylinderPayload).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems += 1;
				this.myRequestList.unshift(res.response.data);
				this.resetForm(false);
			}
			this.commonService.growlSuccess(res.message);
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.commonService.growlError(error.message);
		});
	}

	createPayload(cardValues) {
		this.cylinderPayload.cardToken = cardValues.card;
		this.cylinderPayload.comment = cardValues.comment;
		this.cylinderPayload.paymentMode = this.selectedPaymentMode;
		return this.cylinderPayload;
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		return queryParams;
	}

	// Fetch Plant Cylinder Request
	fetchIndependentCylinderRequest() {
		this.isLoading = true;
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.statusFilter) searchQuery += "&filter=" + this.statusFilter;
		this.independentGasRetailerService.fetchIndependentCylinderRequest(this.independentId, searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response.categories ? (res.response.categories).sort(this.commonService.sortByProperty('size')) : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.myRequestList = res.response.requests ? res.response.requests : [];
			}
			else {
				this.myRequestList = [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.myRequestList = [];
		});
	}

	resetForm(isAdd = false) {
		this.totalCharges = 0;
		this.discount = 0;
		this.isValidWarehouse = true;
		this.isReadyToProceed = false;
		this.isNext = false;
		this.cylinderArr = [];
		//this.isDeliveryCall = false;
		this.isTransactionCall = false;
		//this.deliveryCharges = 0;
		this.transactionCharges = 0;
		this.cylinderPayload = "";
		this.isDisabled = false;
		this.selectedRequest = '';
		this.cardForm.reset();
		this.cardForm.controls.card.setValue("");
		this.isAdd = isAdd;

	}

	cancelRequest(request, index) {
		if (request.isCancelled) return;
		this.independentGasRetailerService.cancelRequest(request._id).subscribe(res => {
			if (res && res.success && res.response) {
				this.myRequestList[index] = res.response.data ? res.response.data : [];
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}


	completeRequest(request, index) {
		if (request.isCompleted) return;
		this.independentGasRetailerService.completeRequest(request._id, { complete: true }).subscribe(res => {
			if (res && res.success) {
				this.myRequestList[index] = res.response
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	routeSubscription() {
		this.subscription.add(this.commonService.routeReuse.subscribe(res => {
			res == TITLE.EMPTY_CYLINDER_REQUEST ? this.resetForm() : false;
		}));
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		this.commonService.setCurrentOrderKey(COMPONENT_KEYS.INDEPENDENT_NEW_CYLINDER_REQUEST);
		this.subscription.add(this.commonService.orderStatusChange.subscribe(res => {
			if (res) this.fetchUpdatedList();
		}, error => { }));
	}

	toNext(isNext) {
		isNext ? this.onContinue() : this.selectedPaymentMode == this.paymentModes.OFFLINE ? this.offlinePay() : this.fetchTransactionCharges();
	}

	offlinePay() {
		const payload = this.createPayload(this.cardForm.value);
		this.isReadyToProceed = true;
	}

	backTo() {
		this.isReadyToProceed = false;
		this.isNext = false;
	}

	goBack() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	onComment(comment) {
		this.cylinderPayload.comment = comment;
	}

	fetchTransactionCharges() {
		if (this.cardForm.valid) {
			this.isDisabled = true;
			this.cylinderPayload = this.createPayload(this.cardForm.value);
			var payload: any = { amount: (this.totalCharges) - this.discount, cardToken: this.cylinderPayload.cardToken };
			this.independentGasRetailerService.fetchTransactionCharges(payload).subscribe(res => {
				this.transactionCharges = res && res.success && res.response && res.response.data ? res.response.data.fee : 0;
				this.isTransactionCall = true;
				this.isReadyToProceed = true;
				this.isDisabled = false;
			}, error => {
				this.transactionCharges = 0;
				this.isTransactionCall = false;
				this.isReadyToProceed = false;
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.cardForm);
		}
	}

	viewRequest(request) {
		this.router.navigate([this.activatedRouteValues.viewURL, request._id], { queryParams: this.queryParamsObj() });
	}

	fetchCardDetails() {
		this.paymentService.fetchCardDetails().subscribe(res => {
			this.cards = res && res.success && res.response.data ? res.response.data : [];
		}, error => {
			this.cards = [];
		});
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}

	// Check payment mode
	onPaymentChange(event, paymentMode) {
		paymentMode == this.paymentModes.ONLINE
			? this.cardForm.controls.card.setValidators(Validators.required)
			: this.cardForm.controls.card.clearValidators();
		this.cardForm.controls.card.updateValueAndValidity();
		this.selectedPaymentMode = paymentMode;
	}

}
