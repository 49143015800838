import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService, CommonService, TokenService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { ActivatedRoute } from '@angular/router';
import { MESSAGE } from '../../../../__utilities/messages';
import Swal from 'sweetalert2';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { Subscription } from 'rxjs';
declare var $;

@Component({
	selector: 'app-client-delete-request',
	templateUrl: './client-delete-request.component.html',
	styleUrls: ['./client-delete-request.component.css']
})
export class ClientDeleteRequestComponent implements OnInit, OnDestroy {

	paginate: any = { ...PAGINATION };
	isLoading: boolean = false;
	searchStr: string = '';
	deleteRequests = [];
	isView: boolean = false;
	isClientRequest: boolean = false;
	selectedRequest: any;
	heading: string = "";
	isPermission: boolean = false;
	routeReuseSubscription:Subscription;
	constructor(public usersService: UsersService,
		public commonService: CommonService,
		public tokenService: TokenService,
		public activatedRoute: ActivatedRoute) {
		this.isClientRequest = this.activatedRoute.data['value'].isClientRequest;
		this.heading = this.isClientRequest ? TITLE.CLIENT_ACCOUNT_DELETE_REQUEST : TITLE.ASSOCIATE_ACCOUNT_DELETE_REQUEST;
		const authUserPermission = this.tokenService.getPermission();
		this.isPermission = this.isClientRequest ? this.commonService.isPermission(authUserPermission, PERMISSIONS.APPROVE_REJECT_CLIENT_ACCOUNT_DELETE_REQUEST) :
			this.commonService.isPermission(authUserPermission, PERMISSIONS.APPROVE_REJECT_DRIVER_ACCOUNT_DELETE_REQUEST);
	}

	ngOnInit(): void {
		this.fetchAccountDeleteReq();
		this.routeSubscription();
	}

	// Reset Pagination
	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	fetchAccountDeleteReq() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.isLoading = true;
		this.usersService.fetchAccountDeleteReq(searchQuery, this.isClientRequest).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.deleteRequests = res.response.data ? res.response.data : [];
			}
			else
				this.deleteRequests = [];
			this.isLoading = false;
		}, error => {
			this.deleteRequests = [];
			this.isLoading = false;
		});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.deleteRequests = [];
		this.fetchAccountDeleteReq();
	}

	// Accept/Reject Account Delete Request
	manageRequest(request, index, isAccept) {

		Swal.fire({
			title: isAccept ? MESSAGE.ACCEPT_DELETE_ACCOUNT_REQUEST_TITLE : MESSAGE.REJECT_DELETE_ACCOUNT_REQUEST_TITLE,
			text: isAccept ? MESSAGE.ACCEPT_DELETE_ACCOUNT_REQUEST_TEXT : MESSAGE.REJECT_DELETE_ACCOUNT_REQUEST_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value)
				this.manageSelectedRequest(request, index, isAccept);
			else {
				if (isAccept)
					$("#checkboxAccept" + index).prop("checked", false);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
				$("#checkboxAccept" + index).prop("checked", false);
			}
		});
	}

	manageSelectedRequest(request, index, isAccept) {
		let payload = {
			isAccept: isAccept,
			isReject: !isAccept
		}
		this.usersService.manageAccountDeleteRequest(request._id, payload).subscribe(res => {
			if (res && res.success) {
				if (!isAccept) {
					this.deleteRequests.splice(index, 1);
					this.paginate.totalItems -= 1;
				}
				else
					this.deleteRequests[index].isDeleteRequestAccept = true;
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.deleteRequests = [];
		this.fetchAccountDeleteReq();
	}

	viewRequest(request) {
		this.selectedRequest = request;
		this.isView = true;
	}

	goBack() {
		this.isView = false;
		this.selectedRequest = '';
	}

	isPendingOrders(order) {
		let isPending = !order.pendingCartOrder;
		if (!this.isClientRequest)
			isPending = isPending && !order.pendingAssociateRequest;
		return !isPending;
	}

	routeSubscription() {
		this.routeReuseSubscription =this.commonService.routeReuse.subscribe(res => {
			res == (TITLE.CLIENT_ACCOUNT_DELETE_REQUEST || TITLE.ASSOCIATE_ACCOUNT_DELETE_REQUEST) ? this.goBack() : false;
		});
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}
