import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AuthGuard } from '../../../__guard/auth.guard';
import { TITLE } from '../../../__utilities/text';
import {
	PlantManagerDashboardComponent, WarehouseManagerDashboardComponent,
	CorporateDashboardComponent, RetailerDashboardComponent
} from '../../../containers';

const routes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.DASHBOARD,
			breadcrumb: [
				{
					label: TITLE.DASHBOARD,
					url: ''
				}
			],
			permission: []
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DashboardRoutingModule { }

export const ROUTING_COMPONENTS = [
	DashboardComponent, PlantManagerDashboardComponent, RetailerDashboardComponent,
	CorporateDashboardComponent, WarehouseManagerDashboardComponent
];
