import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from '../__interceptor/auth.interceptor';
import { BaseRoutingModule, ROUTING_COMPONENTS } from './base-routing.module';
import { ProfileModule } from './authenticated/__modules';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../__services';
import { SharedModule } from '././../shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {
	AppAsideModule, AppBreadcrumbModule,
	AppHeaderModule, AppFooterModule, AppSidebarModule
} from '@coreui/angular';
import { RetailerRegistrationComponent } from './authentication/retailer-registration/retailer-registration.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


// Import Modules
const IMPORTS_MODULES = [
	CommonModule, BaseRoutingModule,
	ProfileModule, ReactiveFormsModule, FormsModule,
	AppAsideModule, AppBreadcrumbModule.forRoot(), AppHeaderModule, AppFooterModule,
	AppSidebarModule, SharedModule, NgxDaterangepickerMd.forRoot(),
	NgMultiSelectDropDownModule.forRoot(),
	JwtModule.forRoot({
		config: {
			tokenGetter: jwtTokenGetter,
			whitelistedDomains: [''],
			blacklistedRoutes: ['']
		}
	})
];


// Export Modules
const EXPORT_MODULES = [
	AppAsideModule, AppBreadcrumbModule, AppHeaderModule,
	AppFooterModule, AppSidebarModule];

@NgModule({
	declarations: [ROUTING_COMPONENTS, RetailerRegistrationComponent],
	imports: [IMPORTS_MODULES],
	exports: [EXPORT_MODULES],
	providers: [{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		useFactory: function (router: Router, storageService: StorageService) {
			return new AuthInterceptor(router, storageService);
		},
		multi: true,
		deps: [Router, StorageService]
	}]
})


export class BaseModule { }

export function jwtTokenGetter() {
	return this.StorageService.getItem('token');;
}
