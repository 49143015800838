import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../__services';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.css']
})
export class DriverDetailsComponent implements OnInit {

  @Input() associateDetail: any
  constructor(public commonService: CommonService) {
    
   }

  ngOnInit(): void {
    console.log('associateDetail',this.associateDetail.image);
    
  }

}
