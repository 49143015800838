import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../__guard/auth.guard';
import { ROLES } from '../../../__utilities/roles';
import { TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';
import {
	CylindersComponent, MakeCylinderRequestComponent, ApproveCylinderRequestComponent,
	AssociateRequestComponent, PlantCylinderRequestComponent, PlantRefillRequestComponent,
	IndependentCylinderRequestComponent
} from '../../index';

const routes: Routes = [
	{
		path: 'cylinder',
		component: MakeCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CYLINDER_REQUEST,
			breadcrumb: [
				{
					label: TITLE.CYLINDER_REQUEST,
					url: ''
				},
			],
			permission: ['', ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'cylinder-request',
		component: ApproveCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CYLINDER_REQUEST,
			viewURL: '/request/cylinder-request/request-details/',
			breadcrumb: [
				{
					label: TITLE.CYLINDER_REQUEST,
					url: ''
				},
			],
			permission: ['', ROLES.WAREHOUSE_MANAGER]
		}
	},
	{
		path: 'cylinder-request/request-details/:id',
		component: ApproveCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CYLINDER_REQUEST,
			breadcrumb: [
				{
					label: TITLE.CYLINDER_REQUEST,
					url: '/request/cylinder-request'
				},
				{
					label: TITLE.REQUEST_DETAILS,
					url: ''
				}
			],
			permission: ['', ROLES.WAREHOUSE_MANAGER]
		}
	},
	{
		path: 'driver',
		component: AssociateRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE_REQUEST,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE_REQUEST,
					url: ''
				},
			],
			permission: ['', ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'driver/request-details/:id',
		component: AssociateRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE_REQUEST,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE_REQUEST,
					url: '/request/driver'
				},
				{
					label: TITLE.REQUEST_DETAILS,
					url: ''
				}
			],
			permission: ['', ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'driver-cylinder-request',
		component: AssociateRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE_REQUEST,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE_REQUEST,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_DRIVER_CYLINDER_REQUEST,
			ROLES.CORPORATE]
		}
	},
	{
		path: 'driver-cylinder-request/request-details/:id',
		component: AssociateRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE_REQUEST,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE_REQUEST,
					url: '/request/driver-cylinder-request'
				},
				{
					label: TITLE.REQUEST_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_DRIVER_CYLINDER_REQUEST,
			ROLES.CORPORATE]
		}
	},
	// {
	// 	path: 'hub-cylinder-request',
	// 	component: PlantCylinderRequestComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.PLANT_CYLINDER_REQUEST,
	// 		viewURL: '/request/hub-cylinder-request/request-details/',
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.PLANT_CYLINDER_REQUEST,
	// 				url: ''
	// 			},
	// 		],
	// 		permission: [PERMISSIONS.VIEW_HUB_NEW_CYLINDER_REQUEST, ROLES.CORPORATE]
	// 	}
	// },
	// {
	// 	path: 'hub-cylinder-request/request-details/:id',
	// 	component: PlantCylinderRequestComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.PLANT_CYLINDER_REQUEST,
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.PLANT_CYLINDER_REQUEST,
	// 				url: '/request/hub-cylinder-request'
	// 			},
	// 			{
	// 				label: TITLE.REQUEST_DETAILS,
	// 				url: ''
	// 			}
	// 		],
	// 		permission: [PERMISSIONS.VIEW_HUB_NEW_CYLINDER_REQUEST, ROLES.CORPORATE]
	// 	}
	// },
	// {
	// 	path: 'hubs-inventory-request',
	// 	component: PlantRefillRequestComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.PLANT_REFILL_REQUEST,
	// 		viewURL: '/request/hubs-inventory-request/request-details/',
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.PLANT_REFILL_REQUEST,
	// 				url: ''
	// 			},
	// 		],
	// 		permission: [PERMISSIONS.VIEW_HUB_REFILL_CYLINDER_REQUEST, ROLES.CORPORATE]
	// 	}
	// },
	// {
	// 	path: 'hubs-inventory-request/request-details/:id',
	// 	component: PlantRefillRequestComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.PLANT_REFILL_REQUEST,
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.PLANT_REFILL_REQUEST,
	// 				url: '/request/hubs-inventory-request'
	// 			},
	// 			{
	// 				label: TITLE.REQUEST_DETAILS,
	// 				url: ''
	// 			},
	// 		],
	// 		permission: [PERMISSIONS.VIEW_HUB_REFILL_CYLINDER_REQUEST, ROLES.CORPORATE]
	// 	}
	// },
	// {
	// 	path: 'independent-cylinder-request',
	// 	component: IndependentCylinderRequestComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.INDEPENDENT_CYLINDER_REQUEST,
	// 		viewURL: '/request/independent-cylinder-request/request-details/',
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.INDEPENDENT_CYLINDER_REQUEST,
	// 				url: ''
	// 			},
	// 		],
	// 		permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER_NEW_CYLINDER_REQUEST, ROLES.CORPORATE]
	// 	}
	// },
	// {
	// 	path: 'independent-cylinder-request/request-details/:id',
	// 	component: IndependentCylinderRequestComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.INDEPENDENT_CYLINDER_REQUEST,
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.INDEPENDENT_CYLINDER_REQUEST,
	// 				url: '/request/independent-cylinder-request'
	// 			},
	// 			{
	// 				label: TITLE.REQUEST_DETAILS,
	// 				url: ''
	// 			}
	// 		],
	// 		permission: ['', ROLES.CORPORATE]
	// 	}
	// },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CylinderRoutingModule { }

export const ROUTING_COMPONENTS = [
	CylindersComponent, MakeCylinderRequestComponent, ApproveCylinderRequestComponent,
	PlantCylinderRequestComponent, PlantRefillRequestComponent, IndependentCylinderRequestComponent
];