import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { TITLE } from '../../__utilities/text';

@Component({
	selector: 'app-retailer-dashboard',
	templateUrl: './retailer-dashboard.component.html',
	styleUrls: ['./retailer-dashboard.component.css']
})
export class RetailerDashboardComponent implements OnInit {

	@Input() dashboardService;
	@Input() loggedInUser: any;
	@Input() cartOrders: any;
	@Input() userRoles: any;
	@Input() orderStatus: any;
	@Input() independentCylinderRequests: any;
	@Output() cartOrderEvent = new EventEmitter<string>();
	@Output() independentCylinderReqEvent = new EventEmitter<string>();
	title: any = TITLE;
	constructor() { }

	ngOnInit(): void {
		this.fetchCartOrderCount();
		this.fetchIndependentRequestCount();
	}

	// Fetch Cart Order
	fetchCartOrderCount() {
		const queryParams = "?plantId=" + this.loggedInUser[this.loggedInUser.roleId.name]._id;
		this.cartOrderEvent.next(queryParams);
	}

	// Fetch Independent New Request Count
	fetchIndependentRequestCount() {
		const queryParams = "?independentId=" + this.loggedInUser[this.loggedInUser.roleId.name]._id;
		this.independentCylinderReqEvent.next(queryParams);
	}
}
