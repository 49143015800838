import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CommonService, TokenService } from '../../__services';
import { PERMISSIONS } from '../.././__utilities/permissions';

@Component({
	selector: 'app-rating',
	templateUrl: './rating.component.html',
	styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit, OnChanges {

	@Input() review: any;
	@Input() reviewTo: string;
	@Input() reviewFrom: string;
	@Input() isChanges: boolean = false;
	@Input() isCorporate: boolean = false;
	@Output() onReply = new EventEmitter<any>();
	isPermission: boolean = false;
	rating: any = { givenBy: '', email: '', phone: '', rating: '', review: '', reviewId: '', date: '', isResponded: false, corporateRespond: '' };
	constructor(
		public commonService: CommonService,
		public tokenService: TokenService,
	) {
		const permission = this.tokenService.getPermission();
		this.isPermission = this.commonService.isPermission(permission, PERMISSIONS.RESPONDS_TO_REVIEW);
		console.log("review", this.review);
		console.log("reviewjj", this.reviewTo);
	}

	ngOnInit(): void {
		console.log("review", this.review);
		console.log("reviewjj", this.reviewTo);
	}

	// On reply
	onComment(comment) {
		if (comment.trim())
			this.onReply.emit({ comment: comment, id: this.rating.reviewId });
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.reviewTo == "client" && this.review.deliveredBy && this.review.deliveryMode && this.review.clientReviewTip) {
			this.rating.givenBy = this.review.deliveredBy.firstName + " " + this.review.deliveredBy.lastName;
			this.rating.email = this.review.deliveredBy.email;
			this.rating.phone = this.review.deliveredBy.dialCode + " " + this.review.deliveredBy.contact;
			this.rating.review = this.review.clientReviewTip.review;
			this.rating.rating = this.review.clientReviewTip.rating;
			this.rating.date = this.review.clientReviewTip.date;
			this.rating.reviewId = this.review.clientReviewTip._id;
			this.rating.isResponded = this.review.clientReviewTip.corporateRespond ? true : false;
			this.rating.corporateRespond = this.review.clientReviewTip.corporateRespond ? this.review.clientReviewTip.corporateRespond : "";
		} else if (this.reviewTo == "hub" && this.reviewFrom == "driver" && this.review.associateReviewTipPlant && this.review.plantId && this.review.plantId.user) {
			this.rating.givenBy = this.review.deliveredBy.firstName + " " + this.review.deliveredBy.lastName;
			this.rating.email = this.review.deliveredBy.email;
			this.rating.phone = this.review.deliveredBy.dialCode + " " + this.review.deliveredBy.contact;
			this.rating.review = this.review.associateReviewTipPlant.review;
			this.rating.rating = this.review.associateReviewTipPlant.rating;
			this.rating.date = this.review.associateReviewTipPlant.date;
			this.rating.reviewId = this.review.associateReviewTipPlant._id;
			this.rating.isResponded = this.review.associateReviewTipPlant.corporateRespond ? true : false;
			this.rating.corporateRespond = this.review.associateReviewTipPlant.corporateRespond ? this.review.associateReviewTipPlant.corporateRespond : "";
		} else if (this.reviewTo == "hub" && this.reviewFrom == "client" && this.review.clientReviewTipPlant && this.review.plantId && this.review.plantId.user) {
			this.rating.givenBy = this.review.orderBy.firstName + " " + this.review.orderBy.lastName;
			this.rating.email = this.review.orderBy.email;
			this.rating.phone = this.review.orderBy.dialCode + " " + this.review.orderBy.contact;
			this.rating.review = this.review.clientReviewTipPlant.review;
			this.rating.rating = this.review.clientReviewTipPlant.rating;
			this.rating.date = this.review.clientReviewTipPlant.date;
			this.rating.reviewId = this.review.clientReviewTipPlant._id;
			this.rating.isResponded = this.review.clientReviewTipPlant.corporateRespond ? true : false;
			this.rating.corporateRespond = this.review.clientReviewTipPlant.corporateRespond ? this.review.clientReviewTipPlant.corporateRespond : "";
		}
		else {
			this.rating.givenBy = this.review.orderBy.firstName + " " + this.review.orderBy.lastName
			this.rating.email = this.review.orderBy.email;
			this.rating.phone = this.review.orderBy.dialCode + " " + this.review.orderBy.contact;
			this.rating.review = this.review.associateReviewTip && this.review.associateReviewTip.review;
			this.rating.rating = this.review.associateReviewTip && this.review.associateReviewTip.rating;
			this.rating.date = this.review.associateReviewTip && this.review.associateReviewTip.date;
			this.rating.reviewId = this.review.associateReviewTip && this.review.associateReviewTip._id;
			this.rating.isResponded = this.review.associateReviewTip && this.review.associateReviewTip.corporateRespond ? true : false;
			this.rating.corporateRespond = this.review.associateReviewTip.corporateRespond ? this.review.associateReviewTip.corporateRespond : "";
		}
	}
}