import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class CylinderService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Fetch Cylinders
	fetchCylinders(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CYLINDER + searchQuery, this.headerService.httpHeaders());
	}

	// Manage Cylinder
	manageCylinder(cylinder): Observable<any> {
		if (cylinder.id)
			return this.http.put(BASE_URL.CYLINDER, cylinder, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.CYLINDER, cylinder, this.headerService.httpHeaders());
	}

	// Delete Cylinder
	deleteCylinder(cylinderId): Observable<any> {
		return this.http.delete(BASE_URL.CYLINDER + "/" + cylinderId, this.headerService.httpHeaders());
	}

	// Fetch Tank Attribute (For custom size)
	fetchCylinderCategory(): Observable<any> {
		return this.http.get(BASE_URL.CYLINDER_CATEGORY, this.headerService.httpHeaders());
	}

	// Fetch Tank Attribute By Id
	fetchCylinderCategoryById(categoryId): Observable<any> {
		return this.http.get(BASE_URL.CYLINDER_CATEGORY + "/" + categoryId, this.headerService.httpHeaders());
	}

	// Manage Tank Attribute (POST/UPDATE)
	manageCylinderCategory(cylinderCategory): Observable<any> {
		if (cylinderCategory.get('id'))
			return this.http.put(BASE_URL.CYLINDER_CATEGORY, cylinderCategory, this.headerService.imageHttpHeaders());
		return this.http.post(BASE_URL.CYLINDER_CATEGORY, cylinderCategory, this.headerService.imageHttpHeaders());
	}

	// Delete Tank Attribute
	deleteCylinderCategory(attributeId): Observable<any> {
		return this.http.delete(BASE_URL.CYLINDER_CATEGORY + "/" + attributeId, this.headerService.httpHeaders());
	}

	// Plants Cylinder Request To Warehouse
	makeCylinderRequest(request): Observable<any> {
		if (request.id)
			return this.http.put(BASE_URL.MAKE_CYLINDER_REQUEST + "/" + request.id, request, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.MAKE_CYLINDER_REQUEST, request, this.headerService.httpHeaders());
	}

	// Plants Cylinder Request To Warehouse
	fetchPlantCylinderRequest(plantId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.PLANT_CYLINDER_REQUEST_LIST + plantId + "/request" + searchQuery, this.headerService.httpHeaders());
	}

	// Delete Request (By Plant)
	deleteCylinderRequest(requestID): Observable<any> {
		return this.http.delete(BASE_URL.MAKE_CYLINDER_REQUEST + "/" + requestID, this.headerService.httpHeaders());
	}

	// Plants Cylinder Request To Warehouse
	warehouseRequestList(warehouseId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.WAREHOUSE_REQUEST_LIST + "/" + warehouseId + "/request" + searchQuery, this.headerService.httpHeaders());
	}

	// Approve Cylinder Request By Warehouse
	approvePlantRequest(requestId, status): Observable<any> {
		return this.http.patch(BASE_URL.PLANT_REQUEST_APPROVE + "/" + requestId + "/approve", status, this.headerService.httpHeaders());
	}

	// Approve Cylinder Request By Warehouse
	dispatchPlantRequest(requestId, status): Observable<any> {
		return this.http.patch(BASE_URL.PLANT_REQUEST_APPROVE + "/" + requestId + "/dispatch", status, this.headerService.httpHeaders());
	}

	// Complete Cylinder Request (By Plant) 
	completeRequest(requestId, status): Observable<any> {
		return this.http.patch(BASE_URL.MAKE_CYLINDER_REQUEST + "/" + requestId + "/complete", status, this.headerService.httpHeaders());
	}

	// Plants Cylinder Request To Warehouse
	makeCylinderRefillRequest(request): Observable<any> {
		if (request.id)
			return this.http.put(BASE_URL.PLANT_REFILL_REQUEST + "/" + request.id, request, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.PLANT_REFILL_REQUEST, request, this.headerService.httpHeaders());
	}

	// Fetch Cylinder Refill Request
	fetchCylinderRefillRequest(plantId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.PLANT_CYLINDER_REQUEST_LIST + plantId + "/refill" + searchQuery, this.headerService.httpHeaders());
	}

	// Complete Plant Refill Order
	completePlantRefillRequest(requestId, status): Observable<any> {
		return this.http.patch(BASE_URL.PLANT_REFILL_REQUEST + "/" + requestId + "/complete", status, this.headerService.httpHeaders());
	}

	// Delete Refill Request
	deleteRefillRequest(requestId): Observable<any> {
		return this.http.delete(BASE_URL.PLANT_REFILL_REQUEST + "/" + requestId, this.headerService.httpHeaders());
	}

	// Fetch Warehouse Recycle Request
	fetchWarehouseRecycleRequest(warehouseId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.WAREHOUSE + "/" + warehouseId + "/recycle/cylinder" + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Plant's Cylinder Request For Corporate
	fetchPlantsCylinderRequest(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_PLANT_CYLINDER_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Plant's Cylinder Request For Corporate
	fetchPlantCylinderRequestById(requestId): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_PLANT_CYLINDER_REQUEST + "/" + requestId, this.headerService.httpHeaders());
	}

	// Fetch Independent Cylinder Request For Corporate
	fetchIndependentCylinderRequest(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_INDEPENDENT_CYLINDER_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Independent Cylinder Request For Corporate
	fetchIndependentCylinderRequestById(requestId): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_INDEPENDENT_CYLINDER_REQUEST + "/" + requestId, this.headerService.httpHeaders());
	}

	// Fetch Plant's Refill Request For Corporate
	fetchPlantsRefillRequest(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_PLANT_REFILL_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Plant's Refill Request By Id For Corporate
	fetchPlantRefillRequestById(requestId): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_PLANT_REFILL_REQUEST + "/" + requestId, this.headerService.httpHeaders());
	}

	// Fetch Associates's Cylinder Request For Corporate
	fetchAssociateCylinderRequest(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_ASSOCIATE_CYLINDER_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Regular Information
	fetchRegulator(): Observable<any> {
		return this.http.get(BASE_URL.REGULATOR_URL, this.headerService.httpHeaders());
	}

	// Fetch Regular Information BY regulator id.
	fetchRegulatorDetailsById(regulatorId): Observable<any> {
		return this.http.get(BASE_URL.REGULATOR_URL + "/" + regulatorId, this.headerService.httpHeaders());
	}

	// Manage Regulator
	manageRegulator(regulator): Observable<any> {
		if (regulator.get('id'))
			return this.http.put(BASE_URL.REGULATOR_URL, regulator, this.headerService.imageHttpHeaders());
		return this.http.post(BASE_URL.REGULATOR_URL, regulator, this.headerService.imageHttpHeaders());
	}

	// Delete Regulator
	deleteRegulator(regulatorId): Observable<any> {
		return this.http.delete(BASE_URL.REGULATOR_URL + "/" + regulatorId, this.headerService.httpHeaders());
	}

	// Fetch Associate Order Review
	fetchAssociateReview(orderId): Observable<any> {
		return this.http.get(BASE_URL.REVIEW_URL + orderId, this.headerService.httpHeaders());
	}

	// Update Gas Retailers Stock
	updateStock(id, payload): Observable<any> {
		return this.http.put(BASE_URL.MANAGE_INDEPENDENT_GAS_RETAILER_STOCK + "/" + id, payload, this.headerService.httpHeaders());
	}

	// Fetch LPG Price
	fetchLPGPrice(): Observable<any> {
		return this.http.get(BASE_URL.LPG_PRICE, this.headerService.httpHeaders());
	}

	// update LPG Price
	updateLPGPrice(id, payload): Observable<any> {
		return this.http.put(BASE_URL.LPG_PRICE + "/" + id, payload, this.headerService.httpHeaders());
	}

	// Fetch current price for igr
	fetchCurrentPrice(): Observable<any>{
		return this.http.get(BASE_URL.FETCH_COMMON_DETAILS, this.headerService.httpHeaders());
	}
}