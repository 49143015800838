import { Component, OnInit } from '@angular/core';
import { UsersService, LoaderService } from '../../../__services/index';
import { MESSAGE } from '../../../__utilities/messages';

@Component({
	selector: 'app-questions',
	templateUrl: './questions.component.html',
	styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

	questions: any = [];
	noComment: string = "";
	constructor(public usersService: UsersService, public loader: LoaderService) { }

	ngOnInit(): void {
		this.fetchQuestion();
	}

	// Fetch Question
	fetchQuestion() {
		this.loader.show();
		this.usersService.fetchQuestion().subscribe(res => {
			if (res && res.success && res.response && res.response.length) {
				this.questions = res.response;
				this.noComment = "";
			}
			this.loader.hide();
		}, error => {
			this.noComment = MESSAGE.NO_COMMENT;
			this.loader.hide();
			this.questions = [];
		});
	}
}

