import { PERMISSIONS } from './__utilities/permissions';
import { ROLES } from './__utilities/roles';
import { TITLE } from './__utilities/text';

const SETTINGS_PERMISSION = [PERMISSIONS.VIEW_TERMS_CONDITION, PERMISSIONS.VIEW_PRIVACY_POLICY, PERMISSIONS.VIEW_ROLE_PERMISSION, PERMISSIONS.VIEW_PRODUCT_PRICE, PERMISSIONS.VIEW_COMMON_SETTING];
const REQUEST_PERMISSION = [PERMISSIONS.VIEW_DRIVER_CYLINDER_REQUEST, PERMISSIONS.VIEW_HUB_NEW_CYLINDER_REQUEST, PERMISSIONS.VIEW_HUB_REFILL_CYLINDER_REQUEST, PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER_NEW_CYLINDER_REQUEST];
const USERS_PERMISSION = [PERMISSIONS.VIEW_USERS, PERMISSIONS.VIEW_CLIENT, PERMISSIONS.VIEW_DRIVER, PERMISSIONS.VIEW_WALKIN];
const ALL_ROLES = ["", ROLES.CORPORATE, ROLES.WAREHOUSE_MANAGER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER];
export const navItems = [
	{
		name: TITLE.DASHBOARD,
		url: '/dashboard',
		icon: 'icon-speedometer',
		permission: ALL_ROLES,
		isAccessForAll: true,
		class: 'menu'
	},
	{
		name: TITLE.PLANT,
		url: '/hubs',
		icon: 'icon-user',
		permission: [PERMISSIONS.VIEW_HUB, ROLES.CORPORATE],
		class: 'menu'
	},
	// {
	// 	name: TITLE.DISTRIBUTORS,
	// 	url: '/distributors',
	// 	icon: 'fa fa-truck',
	// 	permission: [PERMISSIONS.VIEW_DISTRIBUTORS, ROLES.CORPORATE],
	// 	class: 'menu'
	// },
	// {
	// 	name: TITLE.WAREHOUSE,
	// 	url: '/warehouses',
	// 	icon: 'icon-home',
	// 	permission: [PERMISSIONS.VIEW_WAREHOUSE, ROLES.CORPORATE],
	// 	class: 'menu'
	// },
	{
		name: TITLE.INDEPENDENT_GAS_RETAILER,
		url: '/independent-gas-retailers',
		icon: 'fa fa-truck',
		permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER, ROLES.CORPORATE],
		class: 'menu',
	},
	// {
	// 	name: TITLE.CYLINDERS,
	// 	url: '/cylinders',
	// 	icon: '',
	// 	permission: ["", ROLES.WAREHOUSE_MANAGER],
	// 	class: 'menu menu_icon icon_cylinder'
	// },
	{
		name: TITLE.SET_LPG_PRICE,
		url: '/set-lpg-price',
		icon: '',
		permission: ["", ROLES.INDEPENDENT_RETAILER],
		class: 'menu menu_icon icon_cylinder'
	},
	{
		name: TITLE.SET_DEL_CHRG,
		url: '/set-delivery-charges',
		icon: '',
		permission: ["", ROLES.INDEPENDENT_RETAILER],
		class: 'menu menu_icon icon_cylinder'
	},
	// {
	// 	name: TITLE.CYLINDER_STOCK,
	// 	url: '/cylinder-stock',
	// 	icon: '',
	// 	permission: ["", ROLES.WAREHOUSE_MANAGER, ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER],
	// 	class: 'menu menu_icon icon_cylinder'
	// },
	// {
	// 	name: TITLE.EMPTY_CYLINDER_REQUEST,
	// 	url: '/independent-gas-retailers/cylinder-request',
	// 	icon: '',
	// 	permission: ["", ROLES.INDEPENDENT_RETAILER],
	// 	class: 'menu menu_icon icon_cylinder'
	// },
	{
		name: TITLE.INDEPENDENT_CYLINDER_REQUEST,
		url: '/independent-gas-retailers/cylinder-requests',
		icon: '',
		permission: ["", ROLES.WAREHOUSE_MANAGER],
		class: 'menu menu_icon icon_cylinder'
	},
	{
		name: TITLE.CURRENT_SELLING_PRICE,
		url: '/current-selling-price',
		icon: '',
		permission: ["", ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER],
		class: 'menu menu_icon icon_cylinder'
	},
	{
		name: TITLE.CYLINDER_REQUEST,
		url: '/request/cylinder-request',
		icon: '',
		permission: ["", ROLES.WAREHOUSE_MANAGER],
		class: 'menu menu_icon icon_cylinder',
	},
	{
		name: TITLE.REFILL_ORDERS,
		url: '/orders/hubs-refill-order',
		icon: 'icon-refresh',
		permission: ["", ROLES.WAREHOUSE_MANAGER],
		class: 'menu menu_icon',
	},
	// {
	// 	name: TITLE.CYLINDER_REQUEST,
	// 	url: '/request/cylinder',
	// 	icon: '',
	// 	permission: ["", ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR],
	// 	class: 'menu menu_icon icon_cylinder',
	// },
	// {
	// 	name: TITLE.REFILL_ORDERS,
	// 	url: '/orders/refill-order',
	// 	icon: 'icon-refresh',
	// 	permission: ["", ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR],
	// 	class: 'menu menu_icon',
	// },
	{
		name: TITLE.LIVE_ORDER,
		url: '/orders/live-orders',
		icon: '',
		permission: [PERMISSIONS.DRIVER_ORDER_DELIVERY_TRACK, ROLES.CORPORATE],
		class: 'menu menu_icon icon_cylinder order_icon',
	},
	// {
	// 	name: TITLE.ASSOCIATE_REQUEST,
	// 	url: '/request/driver',
	// 	icon: 'icon-question',
	// 	permission: ["", ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER],
	// 	class: 'menu menu_icon',
	// },
	{
		name: TITLE.USERS,
		url: '/clients',
		icon: 'icon-user',
		permission: [USERS_PERMISSION, ROLES.CORPORATE],
		class: 'menu sub-menu',
		children: [
			{ name: TITLE.CORPORATE_USERS, url: '/users/corporate-users', icon: '', permission: [PERMISSIONS.VIEW_USERS, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.CLIENTS, url: '/users/clients', icon: '', permission: [PERMISSIONS.VIEW_CLIENT, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
			{ name: TITLE.WALKIN, url: '/users/walk-ins', icon: '', permission: [PERMISSIONS.VIEW_WALKIN, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
			{ name: TITLE.ASSOCIATE, url: '/users/drivers', icon: '', permission: [PERMISSIONS.VIEW_DRIVER, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.CLIENT_ACCOUNT_DELETE_REQUEST, url: '/users/client-account-delete-request', icon: '', permission: [PERMISSIONS.VIEW_CLIENT_ACCOUNT_DELETE_REQUEST, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.ASSOCIATE_ACCOUNT_DELETE_REQUEST, url: '/users/driver-account-delete-request', icon: '', permission: [PERMISSIONS.VIEW_DRIVER_ACCOUNT_DELETE_REQUEST, ROLES.CORPORATE], class: 'sub-menu' },
		]
	},
	{
		name: TITLE.UNAPPROVED_USERS,
		url: '/drivers',
		icon: 'icon-user',
		permission: [USERS_PERMISSION, ROLES.CORPORATE],
		class: 'menu sub-menu',
		children: [
			{ name: TITLE.ASSOCIATE, url: '/unapprovedusers/drivers', icon: '', permission: [PERMISSIONS.VIEW_DRIVER, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.INDEPENDENT_GAS_RETAILER, url: '/unapprovedusers/igr', icon: '', permission: [PERMISSIONS.VIEW_CLIENT, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
			{ name: TITLE.FLEET_OWNER, url: '/unapprovedusers/fleet', icon: '', permission: [PERMISSIONS.VIEW_WALKIN, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
		]
	},
	{
		name: TITLE.CLIENTS_MENU,
		url: '/clients',
		icon: 'icon-user',
		permission: [USERS_PERMISSION, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR],
		class: 'menu sub-menu',
		children: [
			{ name: TITLE.CLIENTS, url: '/users/clients', icon: '', permission: [PERMISSIONS.VIEW_CLIENT, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
			{ name: TITLE.WALKIN, url: '/users/walk-ins', icon: '', permission: [PERMISSIONS.VIEW_WALKIN, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
		]
	},
	{
		name: TITLE.FLEET_OWNER,
		url: '/users/fleet-owner',
		icon: 'icon-user',
		permission: [PERMISSIONS.VIEW_FLEET_OWNER, ROLES.CORPORATE],
		class: 'menu sub-menu',
		// children: [
		// 	{ name: TITLE.FLEET_OWNER, url: '/users/fleet-owner', icon: '', permission: [PERMISSIONS.VIEW_FLEET_OWNER, ROLES.CORPORATE], class: 'sub-menu' },
		// ]
	},
	// {
	// 	name: TITLE.ASSOCIATE_REQUEST,
	// 	url: '/request/driver-cylinder-request',
	// 	icon: 'icon-user',
	// 	permission: [PERMISSIONS.VIEW_DRIVER_CYLINDER_REQUEST, ROLES.CORPORATE],
	// 	class: 'menu sub-menu'
	// },
	{
		name: TITLE.ORDERS_HISTORY,
		url: '/orders/pickup',
		icon: 'fa fa-history',
		permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR],
		class: 'menu sub-menu',
		children: [
			{ name: TITLE.PICKUP_ORDERS, url: '/orders/pickup', icon: '', permission: [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },

			{ name: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS, url: '/orders/delivery', icon: '', permission: [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR], class: 'sub-menu' },
		]
	},
	{
		name: TITLE.PICKUP_ORDERS,
		url: '/orders/pickup',
		icon: 'fa fa-truck',
		permission: [ROLES.INDEPENDENT_RETAILER],
		class: 'menu sub-menu'
	},
	{
		name: TITLE.HOME_DELIVERY_ORDERS,
		url: '/orders/delivery',
		icon: 'fa fa-history',
		permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.INDEPENDENT_RETAILER],
		class: 'menu sub-menu',
		children: [

			{ name: TITLE.FULFILLED_BY_SMARTGAS, url: '/orders/delivery', icon: '', permission: [ROLES.INDEPENDENT_RETAILER], class: 'sub-menu' },

			{ name: TITLE.FULFILLED_BY_ME, url: '/orders/gas-supplier/delivery', icon: '', permission: [ROLES.INDEPENDENT_RETAILER], class: 'sub-menu' },
		]
	},
	{
		name: TITLE.ORDERS_HISTORY,
		url: '/orders/cart-orders',
		icon: 'fa fa-history',
		permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.CORPORATE],
		class: 'menu sub-menu',
	},
	{
		name: TITLE.PAYMENT_HISTORY,
		url: '/clients-order-payment',
		icon: 'fa fa-history',
		permission: [PERMISSIONS.VIEW_CLIENTS_ORDER_PAYMENT, ROLES.CORPORATE],
		class: 'menu sub-menu',
	},
	// {
	// 	name: TITLE.REQUESTS,
	// 	url: '/request/driver-cylinder-request',
	// 	icon: 'icon-question',
	// 	permission: [REQUEST_PERMISSION, ROLES.CORPORATE],
	// 	class: 'menu sub-menu',
	// 	children: [
	// 		{ name: TITLE.PLANT_REFILL_REQUEST, url: '/request/hubs-inventory-request', icon: '', permission: [PERMISSIONS.VIEW_HUB_REFILL_CYLINDER_REQUEST, ROLES.CORPORATE], class: 'sub-menu' },
	// 		{ name: TITLE.PLANT_CYLINDER_REQUEST, url: '/request/hub-cylinder-request', icon: '', permission: [PERMISSIONS.VIEW_HUB_NEW_CYLINDER_REQUEST, ROLES.CORPORATE], class: 'sub-menu' },
	// 		{ name: TITLE.INDEPENDENT_CYLINDER_REQUEST, url: '/request/independent-cylinder-request', icon: '', permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER_NEW_CYLINDER_REQUEST, ROLES.CORPORATE], class: 'sub-menu' },
	// 	]
	// },
	{
		name: TITLE.SETTINGS,
		url: '/setting/terms-conditions',
		icon: 'icon-settings',
		permission: [SETTINGS_PERMISSION, ROLES.CORPORATE],
		class: 'menu sub-menu',
		children: [
			{ name: TITLE.TERMS_CONDITIONS, url: '/setting/terms-conditions', icon: '', permission: [PERMISSIONS.VIEW_TERMS_CONDITION, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.PRIVACY_POLICY, url: '/setting/privacy-policy', icon: '', permission: [PERMISSIONS.VIEW_PRIVACY_POLICY, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.CONSENT_CLAUSE_TITLE, url: '/setting/consent-clause', icon: '', permission: [PERMISSIONS.VIEW_CONSENT_CLAUSE, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.ROLES_PERMISSIONS, url: '/setting/roles-permissions', icon: '', permission: [PERMISSIONS.VIEW_ROLE_PERMISSION, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.CYLINDER_CATEGORIES, url: '/setting/cylinder-category-pricing', icon: '', permission: [PERMISSIONS.VIEW_PRODUCT_PRICE, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.CYLINDER_BRAND_CATEGORIES, url: '/setting/cylinder-brand-category', icon: '', permission: [ROLES.CORPORATE, ROLES.INDEPENDENT_RETAILER], class: 'sub-menu' },
			//{ name: TITLE.REGULATOR_INFO, url: '/setting/regulator-info', icon: '', permission: [PERMISSIONS.VIEW_REGULATOR_INFORMATION, ROLES.CORPORATE], class: 'sub-menu' },
			{ name: TITLE.COMMON_SETTINGS, url: '/setting/common-setting', icon: '', permission: [PERMISSIONS.VIEW_COMMON_SETTING, ROLES.CORPORATE], class: 'sub-menu' }
		]
	},
	{
		name: TITLE.PURCHASE_ORDERS,
		url: '/setting/category-pricing',
		icon: 'fa fa-history',
		permission: [[PERMISSIONS.USER_NEW_PURCHASE], ROLES.INDEPENDENT_RETAILER],
		class: 'menu sub-menu',
		children: [
			{ name: TITLE.ADD_YOUR_CYLINDER_STOCK, url: '/setting/category-pricing', icon: '', permission: [PERMISSIONS.USER_NEW_PURCHASE, ROLES.INDEPENDENT_RETAILER], class: 'menu sub-menu' },
			{ name: TITLE.CYLINDER_BRAND_CATEGORIES, url: '/setting/cylinder-brand-category', icon: '', permission: [PERMISSIONS.USER_NEW_PURCHASE, ROLES.INDEPENDENT_RETAILER], class: 'sub-menu' },
		]
	},
	{
		name: TITLE.HELP_DESK,
		url: '/help-desk',
		icon: 'fa fa-question-circle-o',
		permission: [PERMISSIONS.HELP_DESK, ROLES.CORPORATE],
		class: 'menu menu_icon',
	},
	{
		name: TITLE.NOTIFICATIONS,
		url: '/notifications',
		icon: 'fa fa-bell-o',
		permission: ALL_ROLES,
		class: 'menu menu_icon',
	}
];