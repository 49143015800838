import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService, WarehouseService, CommonService, SettingService, CylinderService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { PHONE_CONFIG, DEFAULT_COUNTRY } from '../../../../__utilities/phone';
import { MESSAGE } from '../../../../__utilities/messages';
import { BTN_TEXT, TITLE } from '../../../../__utilities/text';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-cylinders',
	templateUrl: './cylinders.component.html',
	styleUrls: ['./cylinders.component.css']
})
export class CylindersComponent implements OnInit, OnDestroy {
	isAdd: boolean = false;
	paginate: any = { ...PAGINATION };
	cylinderForm: UntypedFormGroup;
	cylinders: any = [];
	phoneConfig = PHONE_CONFIG;
	location: any = {};
	address: String = '';
	isDisabled: boolean = false;
	isLoading: boolean = false;
	selectedCylinder: any;
	searchStr: string = '';
	index: number;
	cylinderCategories = [];
	addBtnText: string = BTN_TEXT.ADD_CYLINDER;
	heading: string = TITLE.CYLINDER_REGISTRATION;
	routeReuseSubscription:Subscription;
	constructor(
		private fb: UntypedFormBuilder,
		public validationService: ValidationService,
		public warehouseService: WarehouseService,
		public commonService: CommonService,
		public settingService: SettingService,
		public cylinderService: CylinderService
	) {
		this.fetchCylinderCategory();
	}

	ngOnInit(): void {
		this.initializeControls();
		this.fetchCylinders();
		this.routeSubscription();
	}

	fetchCylinderCategory() {
		this.cylinderService.fetchCylinderCategory().subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response;
			} else {
				this.cylinderCategories = [];
			}
		}, error => {
			this.cylinderCategories = [];
		});
	}

	initializeControls() {
		this.cylinderForm = this.fb.group({
			size: ['', Validators.compose([Validators.required])],
			barcode: ['', Validators.compose([Validators.required])],
		});
	}

	editCylinder(cylinder, index) {
		this.isAdd = true;
		this.index = index;
		this.selectedCylinder = cylinder;
		this.cylinderForm.controls.size.setValue(cylinder.size);
		this.cylinderForm.controls.barcode.setValue(cylinder.barcode);
	}

	deleteCylinder(cylinder) {
		Swal.fire({
			title: MESSAGE.DELETE_CYLINDER_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_CYLINDER_CONFIRMATION_TEXT + cylinder.size + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedCylinder(cylinder._id);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	deleteSelectedCylinder(cylinderId) {
		this.cylinderService.deleteCylinder(cylinderId).subscribe(res => {
			if (res && res.success) {
				const tempArr = [];
				this.cylinders.forEach(element => {
					if (element._id != cylinderId) {
						tempArr.push(element);
					}
				});
				this.cylinders = tempArr;
				this.paginate.totalItems -= 1;
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(MESSAGE.ERROR);
		});
	}

	addMore() {
		this.isAdd = true;
		this.resetForm();
	}

	resetForm() {
		this.cylinderForm.reset();
		this.isDisabled = false;
		this.selectedCylinder = '';
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	onSubmit(cylinder) {
		if (this.cylinderForm.valid) {
			this.isDisabled = true;
			if (this.selectedCylinder) cylinder.id = this.selectedCylinder._id;
			this.cylinderService.manageCylinder(cylinder).subscribe(res => {
				if (res && res.success) {
					if (this.selectedCylinder) {
						this.cylinders[this.index] = res.response;
					}
					else {
						this.paginate.totalItems += 1;
						this.cylinders.push(res.response);
					}
					this.isAdd = false;
					this.resetForm();
				}
				this.commonService.growlSuccess(res.message);
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.cylinderForm);
		}
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.cylinders = [];
		this.fetchCylinders();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.cylinders = [];
		this.fetchCylinders();
	}

	fetchCylinders() {
		this.isLoading = true;
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.cylinderService.fetchCylinders(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.cylinders = res.response.pagination.totalDocs ? res.response.cylinder : [];
			}
			else {
				this.cylinders = [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.cylinders = [];
		});
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.CYLINDERS ? (this.isAdd = false) : false;
		});
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}