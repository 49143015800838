import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, ValidationService, CommonService, ImageCropperService, PaymentService } from '../../../__services';
import { PHONE_CONFIG } from '../../../__utilities/phone';
import { MESSAGE, FILE_PLACEHOLDER } from '../../../__utilities/messages';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';

import Swal from 'sweetalert2';
import * as moment from 'moment';
@Component({
	selector: 'app-retailer-registration',
	templateUrl: './retailer-registration.component.html',
	styleUrls: ['./retailer-registration.component.css']
})
export class RetailerRegistrationComponent implements OnInit {
	registrationForm: UntypedFormGroup;
	phoneConfig = PHONE_CONFIG;
	isUnknownLocation: boolean = false;
	isUnknownEmergencyAddress: boolean = false;
	location: any = {
		type: 'Point',
		coordinates: []
	};
	emergencyInfolocation: any = {
		type: 'Point',
		coordinates: []
	};
	options = {
		types: ['address'],
		componentRestrictions: { country: 'ng' }
	};
	emergencyContactoptions = {
		types: ['address'],
		//componentRestrictions: { country: 'ng' }
	};
	error: any = { isError: false, message: null };
	address: string;
	isSubmitted: boolean = false;
	filePlaceholder: any = FILE_PLACEHOLDER;
	isFileUpload: boolean = false;
	fileObj: any;
	cropperSettings: CropperSettings;
	imgExt = ['image/jpeg', 'image/png'];
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;
	modalImageCropping: any;
	imageCropData: any = {}
	selectedImage: any = "";
	isDisabled: boolean = false;
	loading: boolean = false;
	message: any = MESSAGE;
	imageKey: string = "";
	frontImage: any = '';
	backImage: any = '';
	successMsg: string = "";
	isDeviceExist: boolean = true;
	step: number = 1;
	bankList = [];
	formGroupValidateArr = {
		1: 'personalInfoFormGroup',
		2: 'bankInfoFormGroup',
		3: 'emergencyInfoFromGroup',
		4: 'otherInfoFromGroup'
	};
	registrationData: any = "";
	selectedBank: any = "";
	isValidAccountDetails: boolean = false;
	isValidatingAccountDetails: boolean = false;
	docExt = ['application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'image/jpeg', 'image/png', 'application/pdf'];
	utilityBill: any = '';
	isInvalidEmergencyContact: boolean = false;
	emergencyContactAddress: string = "";
	public lat;
	public lng;
	public locationData: any;
	minDate = moment();

	constructor(
		private fb: UntypedFormBuilder,
		private imageCropperService: ImageCropperService,
		public validationService: ValidationService,
		public commonService: CommonService,
		public authenticationService: AuthenticationService,
		public paymentService: PaymentService,
		public activatedRoute: ActivatedRoute,
		public cd: ChangeDetectorRef
	) {
		// this.isDeviceExist = (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
		// if (!this.isDeviceExist) this.checkDeviceExist();
	}

	ngOnInit(): void {
		if (this.isDeviceExist) {
			this.initializeFormControls();
			this.setCropperConfig();
			this.fetchBankList();
		}
	}

	fetchBankList() {
		this.paymentService.fetchBankList().subscribe(res => {
			this.bankList = res && res.success && res.response && res.response.data ? res.response.data : [];
		}, error => {
			this.bankList = [];
		});
	}

	checkDeviceExist() {
		this.activatedRoute.queryParams.subscribe(res => {
			this.isDeviceExist = res && res.q;
		}, error => {
			this.isDeviceExist = false;
		});
	}

	// Set Cropper Config
	setCropperConfig() {
		this.cropperSettings = this.imageCropperService.cropperSetting();
		this.cropperSettings.rounded = false;
		this.cropperSettings.keepAspect = false;
	}

	closeModal() {
		this.modalImageCropping.hide();
	}

	openModal() {
		this.modalImageCropping.show();
	}

	initializeFormControls() {
		this.registrationForm = this.fb.group({
			personalInfoFormGroup: new UntypedFormGroup({
				companyName: new UntypedFormControl('', Validators.compose([Validators.required])),
				email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email])),
				contact: new UntypedFormControl('', Validators.compose([Validators.required])),
				firstName: new UntypedFormControl('', Validators.compose([Validators.required])),
				lastName: new UntypedFormControl('', Validators.compose([Validators.required])),
			}),
			bankInfoFormGroup: new UntypedFormGroup({
				bankName: new UntypedFormControl('', Validators.compose([])),
				accountNumber: new UntypedFormControl('', Validators.compose([])),
				accountName: new UntypedFormControl('', Validators.compose([]))
			}),
			emergencyInfoFromGroup: new UntypedFormGroup({
				address: new UntypedFormControl('', Validators.compose([])),
				emergencyContact: new UntypedFormControl('', Validators.compose([])),
				firstName: new UntypedFormControl('', Validators.compose([])),
				lastName: new UntypedFormControl('', Validators.compose([])),
			}),
			otherInfoFromGroup: new UntypedFormGroup({
				frontImage: new UntypedFormControl('', Validators.compose([])),
				backImage: new UntypedFormControl('', Validators.compose([])),
				utilityBill: new UntypedFormControl('', Validators.compose([])),
				address: new UntypedFormControl('', Validators.compose([])),
				landMarkAddress: new UntypedFormControl('', Validators.compose([])),
				documentExpiryDate: new UntypedFormControl('', Validators.compose([])),
				password: new UntypedFormControl('', Validators.compose([])),
				referralCode: new UntypedFormControl('', Validators.compose([]))
			}),
		});
	}

	onSubmit() {
		this.getLocation().then(data => {
			this.isDisabled = true;
			let formData = this.createPayload();
			this.authenticationService.signUpGasRetailer(formData).subscribe(res => {
				this.isDisabled = false;
				this.successMsg = MESSAGE.GAS_RETAILER_SIGNUP_SUCCESS;
				this.resetForm();
			}, error => {
				this.isDisabled = false;
				Swal.fire({
					icon: 'error',
					text: error.message,
				});
			})
		}).catch(err => {
             alert("Please Turn On Device Location")
		});
	}

	resetForm() {
		this.registrationForm.reset();
		this.resetPlaceHolder();
		this.step = 0;
	}

	resetPlaceHolder() {
		this.commonService.setFilePlaceholder('frontImage', this.filePlaceholder.UPLOAD_GOVT_ID);
		this.commonService.setFilePlaceholder('backImage', this.filePlaceholder.UPLOAD_GOVT_ID);
		this.commonService.setFilePlaceholder('utilityBill', this.filePlaceholder.UPLOAD_UTILITY_BILL);
	}

	createPayload() {
		let formData = new FormData();

		// Personal Info Group Controls
		const personalInfoGroupControls = this.registrationForm.get(this.formGroupValidateArr[1])['controls'];
		formData.append('firstName', personalInfoGroupControls.firstName.value);
		formData.append('lastName', personalInfoGroupControls.lastName.value);
		formData.append('email', personalInfoGroupControls.email.value);
		formData.append('name', personalInfoGroupControls.companyName.value);
		if (typeof personalInfoGroupControls.contact.value == "object") {
			formData.append('countryCode', personalInfoGroupControls.contact.value.countryCode);
			formData.append('dialCode', personalInfoGroupControls.contact.value.dialCode);
			formData.append('contact', this.commonService.formatContactNumber(personalInfoGroupControls.contact.value.number));
		}

		// Bank Info Group Controls
		const bankInfoGroupControls = this.registrationForm.get(this.formGroupValidateArr[2])['controls'];
		formData.append('bankName', this.selectedBank.bankName);
		formData.append('bankCode', bankInfoGroupControls.bankName.value);
		formData.append('accountNumber', bankInfoGroupControls.accountNumber.value);
		formData.append('accountName', bankInfoGroupControls.accountName.value);

		// Emergency Info Details
		const emergencyInfoGroupControls = this.registrationForm.get(this.formGroupValidateArr[3])['controls'];
		formData.append('emergencyFirstName', emergencyInfoGroupControls.firstName.value);
		formData.append('emergencyLastName', emergencyInfoGroupControls.lastName.value);
		formData.append('emergencyAddress', emergencyInfoGroupControls.address.value);
		if (typeof emergencyInfoGroupControls.emergencyContact.value == "object") {
			formData.append('emergencyCountryCode', emergencyInfoGroupControls.emergencyContact.value.countryCode);
			formData.append('emergencyDialCode', emergencyInfoGroupControls.emergencyContact.value.dialCode);
			formData.append('emergencyContact', this.commonService.formatContactNumber(emergencyInfoGroupControls.emergencyContact.value.number));
		}

		// Other Info Group Controls
		const otherInfoGroupControls = this.registrationForm.get(this.formGroupValidateArr[4])['controls'];
		formData.append('address', this.address);
		formData.append('latitude', this.location.coordinates[1]);
		formData.append('longitude', this.location.coordinates[0]);
		formData.append('password', otherInfoGroupControls.password.value);
		formData.append('referralCode', otherInfoGroupControls.referralCode.value);
		formData.append('landMarkAddress', otherInfoGroupControls.landMarkAddress.value);
		formData.append('govtIdFrontImage', this.frontImage);
		formData.append('govtIdBackImage', this.backImage);
		formData.append('resume', this.utilityBill);
		formData.append('govtIdExpireDate', otherInfoGroupControls.documentExpiryDate.value.startDate._d);
		formData.append('locationType', 'Point');
		formData.append('signupAddress', this.locationData);
		formData.append('signupLocationType', 'Point');
		formData.append('signupLocationLat', this.lat);
		formData.append('signupLocationLng', this.lng);

		return formData;
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.registrationForm.get(this.formGroupValidateArr[this.step]).get('address').setValue(this.address);
		}
		else {
			this.registrationForm.get(this.formGroupValidateArr[this.step]).get('address').setValue('');
			this.isUnknownLocation = true;
		}
	}

	public handleEmergencyAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownEmergencyAddress = false;
			let location = address.geometry.location;
			this.emergencyInfolocation.coordinates[0] = location.lng();
			this.emergencyInfolocation.coordinates[1] = location.lat();
			this.emergencyContactAddress = address.formatted_address;
			this.registrationForm.get(this.formGroupValidateArr[this.step]).get('address').setValue(this.emergencyContactAddress);
		}
		else {
			this.registrationForm.get(this.formGroupValidateArr[this.step]).get('address').setValue('');
			this.isUnknownEmergencyAddress = true
		}
	}

	setImageAttr(fileInput, modalImageCropping) {
		fileInput.value = null;
		this.modalImageCropping = modalImageCropping;
	}

	// Doctype = resume, photoId
	fileChangeListener(event, docType, placeHolder) {
		var image: any = new Image();
		var file: File = event.target.files[0];

		if (this.docExt.indexOf(file.type) == -1 && (docType == 'utilityBill')) {
			this.displayError(event, placeHolder, this.message.INVALID_DOC_FORMAT);
			return;
		}

		if (this.imgExt.indexOf(file.type) == -1) {
			this.displayError(event, placeHolder, this.message.INVALID_IMG_FORMAT);
			return;
		}
		this.isFileUpload = true;
		this.fileObj = file;
		var myReader: FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent: any) {
			image.src = loadEvent.target.result;
			if (docType != 'utilityBill') {
				that.imageKey = docType;
				that.openModal();
				setTimeout(() => {
					that.cropper.setImage(image);
				}, 0);

			} else {
				that.commonService.setFilePlaceholder(event.target.id, file.name);
				that.utilityBill = that.fileObj;
				const otherInfoGroupControls = that.registrationForm.get(that.formGroupValidateArr[4])['controls'];
				otherInfoGroupControls.utilityBill.setValue(file.name);
			}
		};
		myReader.readAsDataURL(file);
	}


	displayError(event, placeHolder, message) {
		this.isFileUpload = false;
		let _this = this;
		setTimeout(() => {
			_this.commonService.setFilePlaceholder(event.target.id, placeHolder);
		}, 0);
		this.commonService.growlError(message);
	}

	// Get Image data
	getImageData() {
		var _this = this;
		this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
			_this.isFileUpload = false;
			_this.closeModal();
			_this.commonService.setFilePlaceholder(_this.imageKey, file.name);
			_this.registrationForm.get(_this.formGroupValidateArr[_this.step]).get(_this.imageKey).setValue(file.name);
			_this.imageCropData.image = null;
			_this.imageKey == 'frontImage' ? (_this.frontImage = file) : (_this.backImage = file);
		}, error => {
			_this.commonService.growlError(MESSAGE.ERROR);
			_this.closeModal();
			_this.resetImageOption();
		});
	}

	resetImageOption() {
		this.isFileUpload = false;
		this.imageCropData.image = null;
		this.registrationForm.controls.frontImage.setValue('');
		this.registrationForm.controls.backImage.setValue('');
		this.registrationForm.controls.resume.setValue('');
		this.frontImage = '';
		this.backImage = '';
		this.isSubmitted = false;
		this.resetPlaceHolder();
	}

	previous() {
		this.removeValidator(this.registrationForm.get(this.formGroupValidateArr[this.step]));
		this.step = this.step - 1;
		if (this.step == 1) {
			const personalInfoGroupControls = this.registrationForm.get(this.formGroupValidateArr[1])['controls'];
			if (typeof personalInfoGroupControls.contact.value == "object") {
				this.phoneConfig.CountryISO = personalInfoGroupControls.contact.value.countryCode;
			}
		}

		if (this.step == 3) {
			const emergencyInfoGroupControl = this.registrationForm.get(this.formGroupValidateArr[3])['controls'];
			if (typeof emergencyInfoGroupControl.emergencyContact.value == "object") {
				this.phoneConfig.CountryISO = emergencyInfoGroupControl.emergencyContact.value.countryCode;
			}
		}
		this.cd.detectChanges();
	}

	setRequireValidator(form: any) {
		for (const field in form.controls) {
			let con = form.get(field);
			this.step == 4 && field == "password"
				? con.setValidators([Validators.required, Validators.minLength(6)])
				: (field !== "referralCode" && field !== "landMarkAddress") ? con.setValidators([Validators.required]) : con.clearValidators();
			con.updateValueAndValidity();
		}
	}

	removeValidator(form: any) {
		for (const field in form.controls) {
			let con = form.get(field);
			con.clearValidators();
			con.updateValueAndValidity();
		}
	}

	isInvalidNumber(isInvalidNumber) {
		this.isInvalidEmergencyContact = isInvalidNumber;
	}

	next() {
		this.isSubmitted = true;

		if (this.step == 3 && this.isInvalidEmergencyContact) return;


		this.step > 1 && this.setRequireValidator(this.registrationForm.get(this.formGroupValidateArr[this.step]));


		if (this.step == 3) {
			this.isUnknownEmergencyAddress = Object.keys(this.emergencyInfolocation.coordinates).length ? false : true;
			if (!this.isUnknownEmergencyAddress) {
				const controls = this.registrationForm.get(this.formGroupValidateArr[3])['controls'];
				let inputAddress = (controls.address.value).trim();
				this.isUnknownEmergencyAddress = inputAddress != (this.emergencyContactAddress).trim();
			}
			if (this.isUnknownEmergencyAddress) return;
		}


		if (this.step == 4) {
			this.isUnknownLocation = Object.keys(this.location.coordinates).length ? false : true;
			if (!this.isUnknownLocation) {
				const controls = this.registrationForm.get(this.formGroupValidateArr[4])['controls'];
				let inputAddress = (controls.address.value).trim();
				this.isUnknownLocation = inputAddress != (this.address).trim();
			}
			if (this.isUnknownLocation) return;
		}

		if (this.registrationForm.valid) {
			if (this.step == 2 && !this.isValidAccountDetails) {
				this.openSwal(MESSAGE.INVALID_ACCOUNT_DETAILS);
				return;
			}

			this.step == 4 && this.onSubmit();
			this.step += this.step == 4 ? 0 : 1;
			let _this = this;;
			setTimeout(() => {
				_this.step == 4 && _this.setFilePlaceholder();
			}, 0);
			this.isSubmitted = false;
		}
		else {
			this.validationService.validateAllFormFields(this.registrationForm);
		}
	}

	setFilePlaceholder() {
		this.commonService.setFilePlaceholder("frontImage", this.frontImage ? this.frontImage.name : this.filePlaceholder.UPLOAD_GOVT_ID);
		this.commonService.setFilePlaceholder("backImage", this.backImage ? this.backImage.name : this.filePlaceholder.UPLOAD_GOVT_ID);
		this.commonService.setFilePlaceholder("utilityBill", this.utilityBill ? this.utilityBill.name : this.filePlaceholder.UPLOAD_UTILITY_BILL);
		return true;
	}

	onBankChange(event, accountNumber) {
		this.selectedBank = {
			bankName: event.target.options[event.target.selectedIndex].innerHTML,
			code: event.target.value
		};
		this.validateBankDetails(accountNumber);
	}

	validateBankDetails(accountNumber) {
		if (this.selectedBank.code && accountNumber) {
			this.isValidatingAccountDetails = true;
			const payload = {
				accountNumber: accountNumber,
				bankCode: this.selectedBank.code
			};
			this.paymentService.validateBankDetails(payload).subscribe(res => {
				this.isValidAccountDetails = res && res.success;
				this.isValidatingAccountDetails = false;
			}, error => {
				this.isValidAccountDetails = false;
				this.isValidatingAccountDetails = false;
				this.openSwal(error.message);
			});
		}
	}

	openSwal(message) {
		Swal.fire({
			icon: 'error',
			text: message,
		});
	}

	getLocation() {
		return new Promise((resolve,reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.watchPosition((position) => {
					if (position) {
						this.lat = position.coords.latitude;
						this.lng = position.coords.longitude;
						let geocoder = new google.maps.Geocoder();
						let latlng = { lat: this.lat, lng: this.lng };
						geocoder.geocode({ location: latlng }, (results) => {
							if (results && results[0] && results[0].formatted_address) {
								this.locationData = results[0].formatted_address;
								resolve(this.locationData);
							} else {
								reject('Not found');
							}
						});
					} else {
						reject('Not found');
					}
				},
					(error) => {
						console.log(error)
						reject(error);
					});
			} else {
				reject("Geolocation is not supported by this browser.");
				// alert("Geolocation is not supported by this browser.");
			}
		});
	}
}