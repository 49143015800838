import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class PushNotificationService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	public sendSubscriptionToTheServer(subscription: PushSubscription): Observable<any> {
		return this.http.post(BASE_URL.PUSH_NOTIFICATION_URL, subscription, this.headerService.httpHeaders());
	}

	// Remove Push Notification Subscription Id
	removeSubscriptionId() {
		return this.http.delete(BASE_URL.PUSH_NOTIFICATION_URL, this.headerService.httpHeaders());
	}

	// Fetch Notifications
	fetchNotifications(queryParams): Observable<any> {
		return this.http.get(BASE_URL.FETCH_NOTIFICATIONS + queryParams, this.headerService.httpHeaders());
	}

	// Read Notification
	readNotification(notificationId): Observable<any> {
		return this.http.put(BASE_URL.READ_NOTIFICATION, notificationId, this.headerService.httpHeaders());
	}
}
