import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

const ENCRYPTION_KEY = environment.CARD_ENCRYPTION_KEY;

export function aesEncrypt(textToConvert: string) {
    return CryptoJS.AES.encrypt(textToConvert.trim(), ENCRYPTION_KEY).toString();
}

export function aesDecrypt(textToConvert: string) {
    return CryptoJS.AES.decrypt(textToConvert.trim(), ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
}