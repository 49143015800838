import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class WarehouseService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Manage Warehouse (POST/UPDATE)
	manageWarehouse(warehouse): Observable<any> {
		if (warehouse.id)
			return this.http.put(BASE_URL.WAREHOUSE + "/" + warehouse.id, warehouse, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.WAREHOUSE, warehouse, this.headerService.httpHeaders());
	}

	// Fetch Warehouse
	fetchWarehouse(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.WAREHOUSE + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Warehouse By Id
	fetchWarehouseById(warehouseId): Observable<any> {
		return this.http.get(BASE_URL.WAREHOUSE + "/" + warehouseId, this.headerService.httpHeaders());
	}

	// Delete Warehouse
	deleteWarehouse(id): Observable<any> {
		return this.http.delete(BASE_URL.WAREHOUSE + "/" + id, this.headerService.httpHeaders());
	}

	// Add Filled cylinder
	addFilledCylinder(id, payload): Observable<any> {
		return this.http.put(BASE_URL.WAREHOUSE + "/" + id + "/cylinder/add", payload, this.headerService.httpHeaders());
	}

	downloadCSV(value, filter): Observable<any> {
		const url = value == 1 ? BASE_URL.CORPORATE_PLANT_CYLINDER_REQUEST_CSV :
			value == 2 ? BASE_URL.CORPORATE_PLANT_REFILL_CYLINDER_REQUEST_CSV :
				BASE_URL.CORPORATE_INDEPENDENT_CYLINDER_ORDER_REPORT;
		return this.http.get(url + filter, { responseType: 'arraybuffer' });
	}
}