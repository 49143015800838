import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
  ValidationService, CommonService, LoaderService, IndependentGasRetailerService, TokenService, StorageService
} from '../../../../__services';
import { TITLE } from '../../../../__utilities/text';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-set-lpg-price',
  templateUrl: './set-lpg-price.component.html',
  styleUrls: ['./set-lpg-price.component.scss']
})
export class SetLpgPriceComponent implements OnInit {
  cylinderPricingForm: UntypedFormGroup;
  lpgPrice: any = "";
  isEnabled: boolean = false;
  isUpdateLPG: boolean = false;
  routeReuseSubscription: Subscription;
  authUser: any;
  isCreateBtn: boolean = false;
  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  constructor(
    public validationService: ValidationService,
    public commonService: CommonService,
    public loader: LoaderService,
    public IndependentGasRetailerService: IndependentGasRetailerService,
    public tokenService: TokenService,
    private fb: UntypedFormBuilder,
    public storageService: StorageService
  ) {
    this.authUser = this.storageService.getItem('userObj');
    this.initializeControls();
    this.fetchIGRLPGPrice();
  }

  ngOnInit(): void {
    this.loader.show();
    this.routeSubscription();
  }


  initializeControls() {

    this.cylinderPricingForm = this.fb.group({
      lpg_price_per_Kg: [{ disabled: true, value: "" }, Validators.compose([Validators.required])]
    });
  }

  // Fetch LPG Price
  fetchIGRLPGPrice() {

    this.IndependentGasRetailerService.fetchIGRLPGPrice(this.authUser[this.authUser.roleId.name]._id).subscribe(res => {
      if (res && res.success && res.response && res.response.data) {
        this.lpgPrice = res.response.data ? res.response.data : "";
        this.cylinderPricingForm.controls.lpg_price_per_Kg.setValue(this.lpgPrice ? this.lpgPrice.LPGPricePerKg : "");
      }
      else {
        this.isCreateBtn = true;
      }
    }, error => {
      this.isCreateBtn = true;
      this.lpgPrice = "";
    });
  }

  // Reset form
  resetForm() {

  }

  routeSubscription() {
    this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
      res == TITLE.CYLINDER_CATEGORIES ? this.resetForm() : false;
    });
  }

  editPrice() {
    let controls = this.cylinderPricingForm.controls;
    if (this.isEnabled && this.cylinderPricingForm.valid) {
      this.isUpdateLPG = true;
      this.IndependentGasRetailerService.updateIGRLPGPrice(this.lpgPrice._id, { LPGPricePerKg: controls.lpg_price_per_Kg.value, retailerId: this.authUser[this.authUser.roleId.name]._id }).subscribe(response => {
        if (response && response.success) {
          controls.lpg_price_per_Kg.disable();
          this.isEnabled = false;
          this.commonService.growlSuccess(response.message);
        }
        this.isUpdateLPG = false;
      }, error => {
        this.isUpdateLPG = false;
        this.commonService.growlError(error.message);
      });
    }
    else {
      this.isEnabled = true;
      controls.lpg_price_per_Kg.enable();
      this.validationService.validateAllFormFields(this.cylinderPricingForm);
    }
  }

  //Set IGR LPG Price
  setPrice() {
    let controls = this.cylinderPricingForm.controls;
    if (this.isEnabled && this.cylinderPricingForm.valid) {
      this.isUpdateLPG = true;
      this.IndependentGasRetailerService.setIGRLPGPrice({ LPGPricePerKg: controls.lpg_price_per_Kg.value, retailerId: this.authUser[this.authUser.roleId.name]._id }).subscribe(response => {
        if (response && response.success) {
          this.isCreateBtn = false;
          controls.lpg_price_per_Kg.disable();
          this.isEnabled = false;
          this.commonService.growlSuccess(response.message);
        }
        this.isUpdateLPG = false;
      }, error => {
        this.isUpdateLPG = false;
        this.commonService.growlError(error.message);
      });
    }
    else {
      this.isEnabled = true;
      controls.lpg_price_per_Kg.enable();
      this.validationService.validateAllFormFields(this.cylinderPricingForm);
    }
  }

  ngOnDestroy() {
    this.routeReuseSubscription.unsubscribe();
  }
}
