import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

// Import routing module
import { AppRoutingModule } from './app.routing';
import { BaseModule } from './views/base.module';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';

//import services
import {
  StorageService, HeaderService,
  AuthenticationService, ValidationService,
  ImageCropperService, SettingService, LoaderService,
  PlantService, WarehouseService, PushNotificationService,
  CylinderService, WebSocketService, UsersService, ChatService, PaymentService
} from './__services';
import { environment } from '../environments/environment';

const SERVICES = [
  StorageService, HeaderService,
  AuthenticationService, ValidationService, WebSocketService, ChatService,
  ImageCropperService, SettingService, LoaderService, PlantService, PaymentService,
  WarehouseService, PushNotificationService, CylinderService, UsersService
];

const config: SocketIoConfig = {
  url: environment.API_HOST, // socket server url;
  options: {
    transports: ['websocket']
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    BaseModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    SocketIoModule.forRoot(config), 
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent,
    APP_CONTAINERS  
  ],
  providers: [SERVICES],
  bootstrap: [AppComponent]
})

export class AppModule { }