import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlantService, CommonService, StorageService, CylinderService, OrderService, LoaderService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import Swal from 'sweetalert2';
import { MESSAGE } from '../../../../__utilities/messages';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_TYPE, ORDER_VALUES } from '../../../../__utilities/orders';
import { ROLES } from '../../../../__utilities/roles';
import { ORDER_MODE, COMPONENT_KEYS, ORDER_STATUS } from '../../../../__utilities/orders';
import { NgxGalleryOptions, NgxGalleryAnimation } from 'ngx-gallery-9';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import 'hammerjs';
declare var $: any;

@Component({
	selector: 'app-associate-request',
	templateUrl: './associate-request.component.html',
	styleUrls: ['./associate-request.component.css']
})
export class AssociateRequestComponent implements OnInit, AfterViewInit, OnDestroy {

	cylinderCategories: any = [];
	isDisabled: boolean = false;
	paginate: any = { ...PAGINATION };
	orderStatus: object = { ...ORDER_STATUS };
	searchStr: string = '';
	orderTypes: any = ORDER_TYPE;
	associateRequestList: any = [];
	isLoading: boolean = false;
	isAdd: boolean = false;
	selectedRequest: any = '';
	plantId: string = "";
	selectedIndex: number = 0;
	heading: string = TITLE.ASSOCIATE_REQUEST;
	roles: any = ROLES;
	isCorporate: boolean = false;
	filterOptions: string = "";
	currentRating: string = "";
	isPlantOrDistributor: boolean = false;
	requestId: string = "";
	noRecord: string = "";
	orderValues: any = ORDER_VALUES;
	orderModes: any = ORDER_MODE;
	selectedOrder: any;
	actionText: string;
	prevId: string = "";
	galleryOptions: NgxGalleryOptions[];
	galleryImages: any = [];
	searchOrderId: string = "";
	isDropdownRequired: boolean = true;
	statusFilter: string = "";
	subscription: Subscription;
	paymentInfo: any = "";
	constructor(
		public plantService: PlantService,
		public commonService: CommonService,
		public storageService: StorageService,
		public cylinderService: CylinderService,
		public orderService: OrderService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService
	) {
		this.checkAuthUser();
	}

	// Check Auth User
	checkAuthUser() {
		let userObj = this.storageService.getItem('userObj');
		this.isPlantOrDistributor = [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER].indexOf(userObj.roleId.name) > -1;
		this.isCorporate = userObj.roleId.name == ROLES.CORPORATE;
		this.plantId = this.isPlantOrDistributor ? userObj[userObj.roleId.name]._id : "";
	}

	ngOnInit(): void {
		this.getRoutingParams();
		this.galleryOptions = [
			{
				thumbnailsColumns: 4,
				imageAnimation: NgxGalleryAnimation.Slide,
				preview: false
			}
		];
		delete this.orderStatus["DECLINED"];
	}

	// Fetch plant refill request by id
	fetchAssociateRequestById() {
		this.loader.show();
		this.isLoading = true;
		this.plantService.fetchAssociateRequestById(this.requestId).subscribe(res => {
			if (res.success && res.response && res.response.data) {
				this.selectedRequest = res.response.data;
			}
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.isLoading = false;
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
		});
	}

	filterImages(key, requestId, images) {
		this.galleryImages[key] = [];
		this.galleryImages[key][requestId] = [];
		images.forEach((element, index) => {
			element = this.commonService.hostUrl + element;
			this.galleryImages[key][requestId][index] = { small: element, medium: element, big: element };
		});
	}

	// Get Routing Params
	getRoutingParams() {
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ requestId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.requestId = results.requestId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.searchOrderId = results.query.orderId ? results.query.orderId : "";
				this.associateRequestList = [];
				this.requestId ? this.fetchAssociateRequestById() : this.fetchAssociateRequest();
			});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fetchAssociateRequest();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.associateRequestList = [];
		this.fetchAssociateRequest();
	}

	resetPagination() {
		this.associateRequestList = [];
		this.paginate.currentPage = 1;
		this.paginate.totalItems = 0;
	}

	onOrderStatusChange(status) {
		this.statusFilter = status;
		this.resetPagination();
		this.fetchAssociateRequest();
	}

	goBack() {
		this.router.navigate([this.plantId ? '/request/driver' : '/request/driver-cylinder-request'], { queryParams: this.queryParams() });
	}

	// Fetch Associate's Request
	fetchAssociateRequest() {
		this.isLoading = true;
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit="
			+ PAGINATION.itemsPerPage;
		if (this.plantId) searchQuery += "&plantId=" + this.plantId;
		if (this.filterOptions) searchQuery += this.filterOptions;
		if (this.statusFilter) searchQuery += "&filter=" + this.statusFilter;
		this.plantService.fetchAssociateRequest(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.associateRequestList = res.response.data && res.response.data.length ? res.response.data : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}

	// Approve Request 
	approveRequest(request, index) {
		this.plantService.approveAssociateRequest(request._id).subscribe(res => {
			if (res && res.success) {
				this.commonService.growlSuccess(res.message);
				this.associateRequestList[index].isApproved = res.response.data.isApproved;
			}
		}, error => {
			$("#checkboxApprove" + index).prop("checked", false);
			this.commonService.growlError(error.message);
		});
	}

	// Reject Request
	rejectRequest(request) {
		Swal.fire({
			title: MESSAGE.REJECT_ASSOCIATE_REQUEST_CONFIRMATION_TITILE,
			text: MESSAGE.REJECT_ASSOCIATE_REQUEST_CONFIRMATION_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.rejectSelectedRequest(request._id);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Reject Seleted Request
	rejectSelectedRequest(requestId) {
		this.plantService.rejectAssociateRequest(requestId).subscribe(res => {
			if (res && res.success) {
				let tempArr = [];
				this.associateRequestList.forEach((element, index) => {
					if (element._id != requestId) {
						tempArr.push(element);
					}
				});
				this.associateRequestList = tempArr;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// Complete Request
	completeRequest(request, index) {
		if (request.isCompleted) return;
		this.plantService.completeAssociateRequest(request._id).subscribe(res => {
			if (res && res.success) {
				this.commonService.growlSuccess(res.message);
				this.associateRequestList[index] = res.response.data;
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// Cancel Request
	cancelRequest(request, index) {
		if (request.isCancelled) return;
		this.plantService.cancelAssociateRequest(request._id).subscribe(res => {
			if (res && res.success) {
				this.commonService.growlSuccess(res.message);
				this.associateRequestList[index] = res.response.data;
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	//Picked Up Request
	pickedUpRequest(request, index) {
		this.plantService.pickedUpRequest(request._id).subscribe(res => {
			if (res && res.success) {
				this.commonService.growlSuccess(res.message);
				this.associateRequestList[index] = res.response.data;
				var tempArr = [];
				var isOtherRefillExchange = true;
				if (!res.response.data.isCancelRequired) {
					res.response.data.requests.forEach(element => {
						element.items.forEach(ele => {
							if (ele.orderType == ORDER_TYPE.REFILL_EXCHANGE)
								element.isCancelledByMe ? tempArr.push(ele.orderType) : isOtherRefillExchange = false;
						});
					});
					if ((tempArr.length && isOtherRefillExchange) || isOtherRefillExchange) {
						this.actionText = "";
						this.isDropdownRequired = false;
					}
				}
				else {
					this.actionText = "";
				}
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// View Request
	viewRequest(request) {
		this.router.navigate([this.plantId ? '/request/driver/request-details' : '/request/driver-cylinder-request/request-details', request._id], { queryParams: this.queryParams() });
	}

	queryParams() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		return queryParams;
	}

	// Search By day , Month, Week And Year
	onFilter(obj) {
		this.filterOptions = "&startDate=" + obj.startDate + "&endDate=" + obj.endDate;
		this.associateRequestList = [];
		this.resetPagination();
		this.fetchAssociateRequest();
	}

	// Rating
	onTabChange(currentRating) {
		this.currentRating = currentRating;
	}

	// Post review
	postReview(message, id, reviewTo, index) {
		if (message.trim()) {
			this.orderService.respondToReview(id, message).subscribe(res => {
				if (res && res.success) {
					if (reviewTo == "plantReviewTip")
						this.selectedRequest.requests[index].plantReviewTip = res.response.data;

					if (reviewTo == "clientReviewTip")
						this.selectedRequest.requests[index].clientReviewTip = res.response.data;

					if (reviewTo == "associateReviewTip")
						this.selectedRequest.requests[index].associateReviewTip = res.response.data;

					this.commonService.growlSuccess(res.message);
				}
			}, error => {
				this.commonService.growlError(error.message);
			});
		}
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		this.commonService.setCurrentOrderKey(COMPONENT_KEYS.ASSOCIATE_REQUEST);
		this.subscription = this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { })
	}

	// fetch Updated list
	fetchUpdatedList() {
		if (this.requestId) {
			this.fetchAssociateRequestById();
			return;
		}
		this.resetPagination();
		this.searchStr = "";
		this.associateRequestList = [];
		this.fetchAssociateRequest();
	}

	viewOrder(request) {
		this.selectedOrder = request;
	}

	checkCancelledCondition(request) {
		return request.isCancelRequired;
	}

	isRefillExcahngOrCancellationeExist(requests) {
		return requests.isCancelRequired ? true : this.checkRefillExchangeExist(requests.requests);
	}

	checkRefillExchangeExist(requests) {
		let tempArr = [];
		requests.forEach(ele => {
			ele.items.forEach(element => {
				if ((!ele.isCancelledByMe && element.orderType == ORDER_TYPE.REFILL_EXCHANGE) || (ele.isCancelledByMe && ele.pickedUp)) {
					tempArr.push(element.orderType);
				}
			});
		});
		return tempArr.length ? true : false;
	}

	responseText(request) {
		if (this.prevId == request._id) return;
		this.prevId = request._id;
		let tempArr = [];
		request.requests.forEach(ele => {
			ele.items.forEach(element => {
				if ((!ele.isCancelled && !ele.isCancelledByMe) || (ele.isCancelledByMe && ele.pickedUp)) {
					tempArr.push(element.orderType);
				}
			});
		});
		const refillExchangeIndex = tempArr.indexOf(ORDER_TYPE.REFILL_EXCHANGE) > -1;
		this.actionText = refillExchangeIndex ? 'Cylinder Returned' : '';
		this.isDropdownRequired = request.isApproved ? (!request.pickedUp ? true : this.actionText ? true : (request.isApproved && !refillExchangeIndex ? request.isCancelRequired : false)) : true;
		return this.isDropdownRequired;
	}

	countInArray(array, what) {
		return array.filter(item => item == what).length;
	}

	selectedOption(request) {
		return (request.isPlantReject ||
			request.isCompleted ||
			request.isCancelled ||
			(request.isApproved
				?
				(
					request.pickedUp
						?
						!this.isRefillExcahngOrCancellationeExist(request)
						: false) :
				false)
		)
			? '' : 'show-arrow'
	}

	selectedValue(request) {
		return request.isCancelled ? 'Cancelled' :
			request.isPlantReject ? 'Rejected' :
				(
					request.isCompleted ? 'Completed' :
						request.pickedUp ? 'Picked Up' :
							(
								request.isApproved ?
									(
										request.orderStatus ?
											request.orderStatus : 'Accepted'
									) : 'Select'
							)
				);
	}

	selectedClass(request) {
		return (request.isCancelled ||
			request.isPlantReject)
			? 'optionbg2' : request.isCompleted
				? 'optionbg4' : request.pickedUp
					? 'optionbg1' : request.isApproved
						? 'optionbg3' : 'shadow-sm'
	}

	selectedLabel(request) {
		return request.isCancelled
			? 'Cancelled' :
			request.isPlantReject
				? 'Rejected' : request.isCompleted
					? 'Completed' :
					(request.pickedUp
						? 'Picked Up' :
						(
							request.isApproved
								?
								(
									request.orderStatus
										? request.orderStatus
										: 'Accepted'
								)
								:
								'Pending'
						)
					)
	}

	showActionText(request) {
		return !request.isCompleted &&
			this.actionText &&
			request.isApproved &&
			request.pickedUp &&
			!request.isCancelRequired;
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}