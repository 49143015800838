export const ORDER_MODE = {
    online: "online",
    offline: "offline"
};

export const ORDER_TYPE = {
    REFILL_EXCHANGE: "refill_exchange",
    PURCHASE: "purchase",
    REFILL_MY_OWN: "refill_my_own"
};

interface orderType {
    [key: string]: string;
}

export const ORDER_VALUES: orderType = {
    [ORDER_TYPE.REFILL_EXCHANGE]: "Refill: Cylinder Exchange",
    [ORDER_TYPE.PURCHASE]: "New Cylinder + Gas",
    [ORDER_TYPE.REFILL_MY_OWN]: "Refill: My Own Cylinder"
};

export const CART_KEY = "sg_cart_orders";

export const NAVIGATION_KEYS = {
    warehouse_recycle_cylinder_request: "warehouse_recycle_cylinder_request",
    hub_old_cylinder_request: "hub_old_cylinder_request",
    hub_new_cylinder_request: "hub_new_cylinder_request",
    hub_refill_cylinder_request: "hub_refill_cylinder_request",
    help_desk: "help_desk",
    client_account_delete_request: "client_account_delete_request",
    associate_account_delete_request: "associate_account_delete_request",
    fleet_account_delete_request: "fleet_account_delete_request",
    associate_request: "associate_request",
    new_client_refill_purchase_order: "new_client_refill_purchase_order",
    user_chat: "user_chat",
    user_profile: "user_profile",
    independent_new_cylinder_request: "independent_new_cylinder_request",
    new_client_registartion: "new_client_registartion",
    new_associate_registartion: "new_associate_registartion",// New
    new_fleet_registartion: "new_fleet_registartion",// New
    new_igr_registartion: "new_igr_registartion",// New

    new_client_refill_purchase_pickup_order: "new_client_refill_purchase_pickup_order",
    new_client_refill_purchase_delivery_order: "new_client_refill_purchase_delivery_order",
    new_client_refill_purchase_delivery_order_gas_supplier: "new_client_refill_purchase_delivery_order_gas_supplier",
};

export const NAVIGATION_URL = {
    [NAVIGATION_KEYS.warehouse_recycle_cylinder_request]: ["/request/recycle", "request/recycle-request"],
    [NAVIGATION_KEYS.hub_old_cylinder_request]: "/request/old-cylinder",
    [NAVIGATION_KEYS.hub_new_cylinder_request]: ["/request/cylinder", "/request/cylinder-request"],
    [NAVIGATION_KEYS.hub_refill_cylinder_request]: ["/orders/refill-order", "/orders/hubs-refill-order"],
    [NAVIGATION_KEYS.help_desk]: "/help-desk",
    [NAVIGATION_KEYS.client_account_delete_request]: "/users/client-account-delete-request",
    [NAVIGATION_KEYS.associate_account_delete_request]: "/users/driver-account-delete-request",
    [NAVIGATION_KEYS.fleet_account_delete_request]: "/users/fleet-owner",
    [NAVIGATION_KEYS.associate_request]: ["/request/driver-cylinder-request", "/request/driver"],
    [NAVIGATION_KEYS.new_client_refill_purchase_order]: ["/orders/cart-orders", "/orders/pickup"],
    [NAVIGATION_KEYS.user_chat]: "/chat",
    [NAVIGATION_KEYS.user_profile]: "/profile",
    [NAVIGATION_KEYS.independent_new_cylinder_request]: ["/independent-gas-retailers/cylinder-request", "/independent-gas-retailers/cylinder-requests", "/request/independent-cylinder-request"],
    [NAVIGATION_KEYS.new_client_registartion]: "/users/clients",
    [NAVIGATION_KEYS.new_associate_registartion]: "/users/drivers",
    [NAVIGATION_KEYS.new_fleet_registartion]: "/users/fleet-owner",
    [NAVIGATION_KEYS.new_igr_registartion]: "/independent-gas-retailers",
    [NAVIGATION_KEYS.new_client_refill_purchase_pickup_order]: "/orders/pickup/order-details",
    [NAVIGATION_KEYS.new_client_refill_purchase_delivery_order]: "orders/delivery/order-details",
    [NAVIGATION_KEYS.new_client_refill_purchase_delivery_order_gas_supplier]: "orders/gas-supplier/delivery/order-details",
};

export const COMPONENT_KEYS = {
    CLIENT_CART_ORDER: "CLIENT_CART_ORDER",
    CLIENT_CART_PICKUP_ORDER: "CLIENT_CART_PICKUP_ORDER",
    ASSOCIATE_REQUEST: "ASSOCIATE_CART_REQUEST",
    NEW_CYLINDER_REQUEST: "NEW_CYLINDER_REQUEST",
    CYLINDER_REFILL_REQUEST: "CYLINDER_REFILL_REQUEST",
    INDEPENDENT_NEW_CYLINDER_REQUEST: "INDEPENDENT_NEW_CYLINDER_REQUEST",
    REATILER_ONLINE_OFFLINE: "REATILER_ONLINE_OFFLINE",
    SETTING_PRICE_UPDATE: "SETTING_PRICE_UPDATE",
    LPG_PRICE_UPDATE: "LPG_PRICE_UPDATE"
};

export const PAYMENT_MODE = {
    cash: "Cash",
    cardSwipe: "Card Swipe",
    bankTransfer: "Bank Transfer",
    upi: "UPI",
    walletTransfer: "Wallet Transfer",
};

export const CLIENT_PAYMENT_MODE = {
    creditCard: "Credit Card"
};

export const ORDER_STATUS = {
    TOTAL: "total",
    PENDING: "pending",
    PROCESSING: "processing",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
    DECLINED: "declined"
};