import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService {

	constructor(private http: HttpClient, private headerService: HeaderService) {
	}

	// Approve Sale Order
	approveSaleOrder(orderId, payload): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER + "/" + orderId + "/admin/approve", payload, this.headerService.httpHeaders());
	}

	// Fetch Cart Order Refill/Purchase/Sale.
	fetchCartOrders(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.ADMIN_CART_ORDER + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Order Details By Id Refill/Purchase/Sale.
	fetchCartOrderById(orderId): Observable<any> {
		return this.http.get(BASE_URL.ADMIN_CART_ORDER + "/" + orderId, this.headerService.httpHeaders());
	}

	// Cancel Cart Order Refill/Purchase/Sale.
	cancelCartOrder(orderId): Observable<any> {
		return this.http.put(BASE_URL.ORDER_REFILL + "/" + orderId + "/cancel", null, this.headerService.httpHeaders());
	}

	// Approve Purchase Order
	approveRefillOrder(orderId, payload): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_PICKUP + "/" + orderId + "/plant/approve", payload, this.headerService.httpHeaders());
	}

	// Fetch Plants Order
	fetchPlantsOrder(warehouseId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.WAREHOUSE + "/" + warehouseId + "/refill" + searchQuery, this.headerService.httpHeaders());
	}

	// Approve Plant Refill Request
	approvePlantRefillOrder(requestId, status): Observable<any> {
		return this.http.patch(BASE_URL.MANAGE_PLANT_REFILL_REQUEST + "/" + requestId + "/approve", status, this.headerService.httpHeaders());
	}

	// Dispatch Plant Refill Request
	dispatchPlantRefillOrder(requestId, status): Observable<any> {
		return this.http.patch(BASE_URL.MANAGE_PLANT_REFILL_REQUEST + "/" + requestId + "/dispatch", status, this.headerService.httpHeaders());
	}

	// Fetch Live Orders (Corporate Section)
	fetchLiveOrders(): Observable<any> {
		return this.http.get(BASE_URL.LIVE_ORDERS, this.headerService.httpHeaders());
	}

	// Complete Refill Order
	completeRefillOrder(orderId, status): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_PICKUP_DROPOFF + "/" + orderId + "/plant/complete", status, this.headerService.httpHeaders());
	}

	// Complete Delivery Orders
	completeDeliveryOrder(orderId): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_DELIVERY + "/" + orderId + "/plant/complete", this.headerService.httpHeaders());
	}

	// PickedUp Orders
	pickedUpOrder(orderId): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_PICKUP + "/" + orderId + "/plant/picked", this.headerService.httpHeaders());
	}

	// igrOutForDelivery Orders
	igrOutForDelivery(orderId): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_PICKUP + "/" + orderId + "/igr/delivery", this.headerService.httpHeaders());
	}

	// igrCompleteDelivery Orders
	igrCompleteDelivery(orderId): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_PICKUP + "/" + orderId + "/igr/complete", this.headerService.httpHeaders());
	}

	// cancel Orders
	cancelOrder(orderId): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER_PICKUP + "/" + orderId + "/plant/cancel", this.headerService.httpHeaders());
	}

	// Respond To Review
	respondToReview(reviewId, comment): Observable<any> {
		return this.http.patch(BASE_URL.RESPOND_TO_REVIEW + "/" + reviewId, { comment: comment }, this.headerService.httpHeaders());
	}

	// Fetch Clients/Associate Order
	fetchOrdersById(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.ADMIN_CART_ORDER + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Clients/Associate Order
	fetchCartOrdersById(orderId): Observable<any> {
		return this.http.get(BASE_URL.ADMIN_CART_ORDER + "/" + orderId, this.headerService.httpHeaders());
	}

	// Create Orders for walkin users
	createWalkinOrders(order, type): Observable<any> {
		return this.http.post(BASE_URL.ADMIN_CART_ORDER, order, this.headerService.httpHeaders());
	}

	fetchCreditPoint(userId): Observable<any> {
		return this.http.get(BASE_URL.FETCH_CREDIT_POINT + "?id=" + userId, this.headerService.httpHeaders());
	}

	sendOTP(payload): Observable<any> {
		return this.http.post(BASE_URL.SEND_OTP, payload, this.headerService.httpHeaders());
	}

	emailInvoice(id): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER + "/" + id + "/invoice/send", {}, this.headerService.httpHeaders());
	}

	manageOrders(payload) {
		return this.approveRefillOrder(payload._id, payload);

	}

	fetchInvoice(orderId): Observable<any> {
		return this.http.patch(BASE_URL.ADMIN_CART_ORDER + "/" + orderId + "/invoice/pdf", {}, { responseType: 'blob' });
	}

	getAssociateOrdersCSVFile(associateId, queryParams = ""): Observable<any> {
		return this.http.get(BASE_URL.ASSOCIATES + "/report/" + associateId + queryParams, {
			responseType: 'arraybuffer'
		});
	}

	getClientOrdersCSVFile(clientId): Observable<any> {
		return this.http.get(BASE_URL.CLIENTS + "/report/" + clientId, {
			responseType: 'arraybuffer'
		});
	}

	// Download Sales Report
	downloadSalesReport(reportType, filter): Observable<any> {
		const url = reportType == 1 ? BASE_URL.INDEPENDENT_GAS_RETAILER_SALES_REPORT :
			reportType == 2 ? BASE_URL.CORPORATE_PLANT_SALES_REPORT :
				reportType == 3 ? BASE_URL.ASSOCIATE_SALES_REPORT : BASE_URL.FLEET_SALES_REPORT;
		return this.http.get(url + filter, { responseType: 'arraybuffer' });
	}

	// Fetch Clients Order Payment
	fetchClientsOrderPayment(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.CLIENTS_ORDER_PAYMENT + searchQuery, this.headerService.httpHeaders());
	}

	// Email report
	emailReport(searchQuery): Observable<any> {
		return this.http.patch(BASE_URL.EMAIL_ORDER_REPORT + searchQuery, this.headerService.httpHeaders());
	}

	// Email report
	downloadReport(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.DOWNLOAD_ORDER_REPORT + searchQuery, { responseType: 'arraybuffer' });
	}

	//download user list report
	downloadUserListReport(filter): Observable<any> {
		return this.http.get(BASE_URL.DOWNLOAD_USER_LIST_REPORT + filter, { responseType: 'arraybuffer' });
	}

	//download unapproved user list report
	downloadUnapprovedUserListReport(filter): Observable<any> {
		return this.http.get(BASE_URL.DOWNLOAD_UNAPPROVED_USER_LIST_REPORT + filter, { responseType: 'arraybuffer' });
	}

	// Fetch Payment Status
	fetchPaymentStatus(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.FETCH_PAYMENT_DETAILS + searchQuery, this.headerService.httpHeaders());
	}
}	