import { Component, OnInit, Input } from '@angular/core';
import { MESSAGE } from '../../__utilities/messages';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit {
  @Input() bankDetails: any;
  messages: any = MESSAGE;
  @Input() isBillingVisible:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
