import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../../../shared/shared.module';
import { DashboardRoutingModule, ROUTING_COMPONENTS } from './dashboard-routing.module';

@NgModule({
  imports: [
    DashboardRoutingModule,
    BsDropdownModule, SharedModule
  ],
  declarations: [ROUTING_COMPONENTS]
})
export class DashboardModule { }
