import { NgModule } from '@angular/core';
import { UserRoutingModule, ROUTING_COMPONENTS } from './user-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ImageCropperModule } from 'ngx-img-cropper';

@NgModule({
  declarations: [ROUTING_COMPONENTS],
  imports: [
    SharedModule,
    UserRoutingModule,
    GooglePlaceModule,
    ImageCropperModule
  ]
})
export class UserModule { }
