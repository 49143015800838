import { Component, OnInit, OnDestroy } from '@angular/core';
import { TITLE, TABLE_TEXT } from '../../../__utilities/text';
import { ROLES } from '../../../__utilities/roles';
import { Router } from '@angular/router'
import { CommonService, PushNotificationService, TokenService } from '../../../__services';
import { PAGINATION } from '../../../__utilities/pagination';
import { NAVIGATION_URL, NAVIGATION_KEYS } from '../../../__utilities/orders';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {
	heading: string = TITLE.NOTIFICATIONS;
	notifications = [];
	isLoading: boolean = false;
	paginate: any = PAGINATION;
	throttle = 300;
	scrollDistance = 1;
	scrollUpDistance = 2;
	tableText: string = TABLE_TEXT.NO_DATA;
	timer: number = 0;
	userRole: string = "";
	notificationSubscription: Subscription;
	constructor(
		public route: Router,
		public commonService: CommonService,
		public tokenService: TokenService,
		public notificationService: PushNotificationService) {
		this.fetchNotifications();
	}

	ngOnInit(): void {
		this.subscribeNotification();
		const userDetails = this.tokenService.getLoggedInUser();
		this.userRole = userDetails && userDetails.roleId && userDetails.roleId.name
	}

	// Subscribe Notification
	subscribeNotification() {
		this.notificationSubscription = this.commonService.notificationEmitter.subscribe(res => {
			if (res) {
				this.paginate.currentPage = 1;
				this.fetchNotifications();
			}
		});
	}

	// Fetch Notifications
	fetchNotifications() {
		this.isLoading = true;
		let queryParam = "?page=" + this.paginate.currentPage + "&limit=" + (this.paginate.itemsPerPage + 40);
		this.notificationService.fetchNotifications(queryParam).subscribe(res => {
			if (res && res.success) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				if (res.response.data)
					res.response.data.forEach(element => {
						this.notifications.push(element);
					});
			}
			this.isLoading = false;

		}, error => {
			this.isLoading = false;
			this.notifications = [];
		});
	}

	// On Scroll Down
	onScrollDown() {
		if (this.paginate.totalItems == this.notifications.length)
			return;
		this.paginate.currentPage += 1;
		this.fetchNotifications();
		this.timer += 10000;
		let _this = this;
		setTimeout(() => {
			_this.readNotification();
		}, this.timer);
	}

	readNotification(notificationId: any = 0) {
		if (!notificationId) {
			notificationId = this.notifications[this.notifications.length - 1]._id;
			this.notificationService.readNotification({ id: notificationId }).subscribe(res => {
			}, error => {
			});
		}
	}

	navigateTo(notification) {
		var url = NAVIGATION_URL[notification.eventName];
		var queryParams: any = {};
		if (notification.orderId)
			queryParams.orderId = notification.orderId;

		const arr1 = [NAVIGATION_KEYS.new_client_refill_purchase_order, NAVIGATION_KEYS.new_client_refill_purchase_order];
		const arr2 = [NAVIGATION_KEYS.hub_refill_cylinder_request, NAVIGATION_KEYS.hub_new_cylinder_request, NAVIGATION_KEYS.warehouse_recycle_cylinder_request];
		if (arr1.indexOf(notification.eventName) > -1)
			url = this.userRole == ROLES.CORPORATE ? NAVIGATION_URL[notification.eventName][0] : NAVIGATION_URL[notification.eventName][1];

		if (arr2.indexOf(notification.eventName) > -1)
			url = this.userRole == ROLES.PLANT_MANAGER ? NAVIGATION_URL[notification.eventName][0] : NAVIGATION_URL[notification.eventName][1];

		if (notification.eventName == NAVIGATION_KEYS.user_chat) {
			queryParams.senderId = notification.senderId._id;
			queryParams.key = notification.senderId.roleId.name;
		}
		if (notification.eventName == NAVIGATION_KEYS.independent_new_cylinder_request) {
			url = this.userRole == ROLES.INDEPENDENT_RETAILER ? NAVIGATION_URL[notification.eventName][0] :
				this.userRole == ROLES.WAREHOUSE_MANAGER ? NAVIGATION_URL[notification.eventName][1] : NAVIGATION_URL[notification.eventName][2];
		}

		if (notification.eventName == NAVIGATION_KEYS.new_client_refill_purchase_pickup_order || notification.eventName == NAVIGATION_KEYS.new_client_refill_purchase_delivery_order) {
			url = notification.eventName == NAVIGATION_KEYS.new_client_refill_purchase_delivery_order ? (notification.isIgrDeliveryOrder ? NAVIGATION_URL[NAVIGATION_KEYS.new_client_refill_purchase_delivery_order_gas_supplier] + "/" + notification.orderId : NAVIGATION_URL[notification.eventName] + "/" + notification.orderId) : NAVIGATION_URL[notification.eventName] + "/" + notification.orderId;
		}

		if (notification.eventName == NAVIGATION_KEYS.new_igr_registartion)
			queryParams.id = notification.plantId;

		if ([NAVIGATION_KEYS.new_igr_registartion, NAVIGATION_KEYS.new_fleet_registartion, NAVIGATION_KEYS.new_client_registartion, NAVIGATION_KEYS.new_associate_registartion].indexOf(notification.eventName) > -1)
			queryParams.userId = notification.userId;

		this.route.navigate([url], { queryParams: queryParams });
	}

	ngOnDestroy() {
		this.notificationSubscription.unsubscribe();
	}
}