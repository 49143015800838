import { Component, OnInit } from '@angular/core';
import { DashboardService, StorageService } from '../../../__services';
import { ROLES } from '../../../__utilities/roles';
import { ORDER_STATUS } from '../../../__utilities/orders';

@Component({
	templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {
	public userRoles = ROLES;
	loggedInUser: any;
	cylinderRequests: any;
	refillRequests: any;
	cartOrders: any = "";
	independentCylinderRequests: any = "";
	orderStatus = ORDER_STATUS;
	constructor(public dashboardService: DashboardService,
		public storageService: StorageService) {
		this.loggedInUser = this.storageService.getItem('userObj');
	}

	ngOnInit(): void { }

	// Fetch Plant'c Cylinder Request Count
	fetchPlantsCylinderReqCount(queryParams) {
		this.dashboardService.fetchPlantsCylinderReqCount(queryParams).subscribe(res => {
			if (res && res.success)
				this.cylinderRequests = res.response;
		}, error => {
			this.cylinderRequests = {};
		});
	}

	// // Fetch Plants Refill Request Count
	// fetchPlantsRefillReqCount(queryParams) {
	// 	this.dashboardService.fetchPlantsRefillReqCount(queryParams).subscribe(res => {
	// 		if (res && res.success)
	// 			this.refillRequests = res.response;
	// 	}, error => {
	// 		this.refillRequests = {};
	// 	});
	// }

	// Fetch Cart Order Count
	fetchCartOrderCount(queryParams) {
		this.dashboardService.fetchCartOrdersCount(queryParams).subscribe(res => {
			if (res && res.success)
				this.cartOrders = res.response;
		}, error => {
			this.cartOrders = {};
		});
	}

	// Fetch Independent Cylinder Request Count
	fetchIndependentCylinderRequestCount(queryParams) {
		this.dashboardService.fetchIndependentCylinderRequestCount(queryParams).subscribe(res => {
			if (res && res.success)
				this.independentCylinderRequests = res.response;
		}, error => {
			this.independentCylinderRequests = {};
		});
	}
}