import { Component, OnInit } from '@angular/core';
import { PAGINATION } from '../../../__utilities/pagination';
import { UsersService, CommonService, TokenService, LoaderService, IndependentGasRetailerService } from '../../../__services';
import { TITLE } from '../../../__utilities/text';
import { ORDER_TYPE, ORDER_VALUES } from '../../../__utilities/orders';
import { Router, ActivatedRoute } from '@angular/router';
import { MESSAGE } from '../../../__utilities/messages';
import Swal from 'sweetalert2';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PERMISSIONS } from '../../../__utilities/permissions';

@Component({
	selector: 'app-independent-gas-retailer',
	templateUrl: './independent-gas-retailer.component.html',
	styleUrls: ['./independent-gas-retailer.component.css']
})
export class IndependentGasRetailerComponent implements OnInit {
	messages: any = MESSAGE;
	paginate: any = { ...PAGINATION };
	isLoading: boolean = false;
	searchStr: string = '';
	heading: string = TITLE.INDEPENDENT_GAS_RETAILER;
	isPermission: boolean = false;
	activatedRouteValues: any = "";
	gasRetailers = [];
	retailerId: string = "";
	selectedRetailer: any = "";
	documents: any;
	permissions = {};
	cylinderCategories = [];
	toolTipMessage: string = "";
	searchRetailerId: string = "";
	constructor(
		public usersService: UsersService,
		public commonService: CommonService,
		public tokenService: TokenService,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public loader: LoaderService,
		public gasRetailerService: IndependentGasRetailerService
	) { }

	ngOnInit(): void {
		this.getRoutingParams();
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ plantId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.retailerId = results.plantId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.searchRetailerId = results.query.id ? results.query.id : "";
				this.gasRetailers = [];
				this.retailerId ? this.fetchGasRetailersById(this.retailerId) : this.fetchGasRetailers();
				this.setPermission();
			});
	}

	// Set Permission
	setPermission() {
		let authUserPermission = this.tokenService.getPermission();
		this.permissions = {
			isApproveRejectRetailer: this.commonService.isPermission(authUserPermission, PERMISSIONS.APPROVE_REJECT_INDEPENDENT_RETAILER),
			isSaleOrderPermission: this.commonService.isPermission(authUserPermission, PERMISSIONS.INDEPENDENT_ORDER_SALE_PERMISSION),
			isViewStockPermission: this.commonService.isPermission(authUserPermission, PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER_CYLINDER_STOCK)
		}
	}

	// Reset Pagination
	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.gasRetailers = [];
		this.resetPagination();
		this.fetchGasRetailers();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.gasRetailers = [];
		this.fetchGasRetailers();
	}

	// Fetch Gas Retailers
	fetchGasRetailers() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.isLoading = true;
		this.usersService.fetchGasRetailers(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.gasRetailers = res.response.independent ? res.response.independent : [];
			}
			else
				this.gasRetailers = [];
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.gasRetailers = [];
		});
	}

	// Fetch Gas Retailers By Id
	fetchGasRetailersById(retailerId) {
		this.loader.show();
		this.isLoading = true;
		this.usersService.fetchGasRetailersById(retailerId).subscribe(res => {
			if (res.success && res.response && res.response.data) {
				this.selectedRetailer = res.response.data;
				this.documents = this.selectedRetailer.user.hasOwnProperty("govtId") && Object.keys(this.selectedRetailer.user.govtId).length
					? this.selectedRetailer.user.govtId : "";
				if (this.documents) {
					this.documents.resume = this.selectedRetailer.user.resume;
					this.documents.signedContract = this.selectedRetailer.user.signedContract;
				}
				this.cylinderCategories = res.response.data.type == "independent" ? (res.response.igr_categories ? res.response.igr_categories.sort(this.commonService.sortByProperty('size')) : []) : (res.response.categories ? res.response.categories.sort(this.commonService.sortByProperty('size')) : []);
			}
			this.stopLoading();
		}, error => {
			this.stopLoading();
		});
	}

	stopLoading() {
		this.loader.hide();
		this.isLoading = false;
	}

	setRetailerOrderSalePermission(retailer, payload, index) {
		this.usersService.setRetailerOrderSalePermission(retailer._id, payload).subscribe(response => {
			this.commonService.growlSuccess(response.message);
			this.gasRetailers[index] = response.response.data;
		}, error => {
			this.commonService.growlSuccess(error.message);
		});
	}

	openOrderPermissionPopup(retailer, index) {
		const purchaseCheckedText = retailer.isNewPurchase ? 'checked' : '';
		const refillMyOwnCheckedText = retailer.isRefillMyOwn ? 'checked' : '';
		Swal.fire({
			title: 'Retailer : ' + retailer.name,
			html:
				'<p style = "text-align:left; padding-top:10px;"><label style="position:absolute; margin-left: -1.3rem;" class="checkbox"><input type="checkbox" name="checkAll" id="swalRefillOwn" ' + refillMyOwnCheckedText + ' disabled /><span class="default"></span></label>&nbsp;&nbsp;' + ORDER_VALUES[ORDER_TYPE.REFILL_MY_OWN] + '</p>' +
				'<p style = "text-align:left; padding-top:10px;"><label style="position:absolute; margin-left: -1.3rem;" class="checkbox"><input type="checkbox" name="checkAll" id="swalPurchase" ' + purchaseCheckedText + ' /><span class="default"></span></label>&nbsp;&nbsp;' + ORDER_VALUES[ORDER_TYPE.PURCHASE] + '</p>',
			focusConfirm: false,
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			confirmButtonText: "Save",
			preConfirm: () => {
				const payload = {
					isNewPurchase: (<HTMLInputElement>document.getElementById('swalPurchase')).checked,
					isRefillMyOwn: (<HTMLInputElement>document.getElementById('swalRefillOwn')).checked
				};
				this.setRetailerOrderSalePermission(retailer, payload, index);
			}
		});
	}

	onApprove(event, retailer, index, isApprove) {
		if (!isApprove)
			Swal.fire({
				title: MESSAGE.REJECT_INDIVIDUAL_GAS_RETAILER_CONFIRMATION_TITLE,
				text: MESSAGE.REJECT_INDIVIDUAL_GAS_RETAILER_CONFIRMATION_TEXT + retailer.name + '?',
				allowEscapeKey: false,
				allowOutsideClick: false,
				reverseButtons: true,
				allowEnterKey: false,
				customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
				showCancelButton: true,
				cancelButtonText: 'Cancel',
				showConfirmButton: true,
				confirmButtonText: "Confirm",
			}).then((confirm) => {
				if (confirm.value) {
					this.approveRejectGasRetailer(retailer, index, isApprove);
				}
			}).catch(err => {
				if (err) {
					this.commonService.growlError(MESSAGE.ERROR);
				}
			});
		else {
			this.approveRejectGasRetailer(retailer, index, isApprove);
		}
	}

	approveRejectGasRetailer(retailer, index, isApprove) {
		const payload = {
			approve: isApprove,
			reject: !isApprove
		};
		this.usersService.approveRejectGasRetailer(retailer._id, payload).subscribe(res => {
			if (res && res.success) {
				if (!retailer.isVerified && !retailer.isRejected && !isApprove) {
					this.gasRetailers.splice(index, 1);
					this.paginate.totalItems -= 1;
				}
				else {
					this.gasRetailers[index] = res.independent;
				}
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	viewRetailer(retailerId) {
		this.router.navigate([this.activatedRouteValues.viewURL, retailerId], { queryParams: this.queryParamsObj() });
	}

	goBack() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	viewOrder(retailer) {
		this.searchStr = "";
		this.router.navigate([this.activatedRouteValues.viewOrdersURL, retailer._id], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	openOrderPopup(retailer) {
		this.selectedRetailer = retailer;
		const inputOptions = {
			'emptyCylinderRequest': TITLE.EMPTY_CYLINDER_REQUEST,
		};
		Swal.fire({
			title: TITLE.ORDER_TYPE_SELECTION,
			input: 'select',
			inputOptions: inputOptions,
			inputPlaceholder: TITLE.ORDER_TYPE_SELECTION,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				this.newCylinderRequestCSV();
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	downloadCSV(res, fileName) {
		const blob = new Blob([res], {
			type: 'text/csv'
		});
		var a = window.document.createElement("a");
		a.href = window.URL.createObjectURL(blob);
		a.download = `${fileName}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	newCylinderRequestCSV() {
		const filter = `?independentId=${this.selectedRetailer._id}`
		this.gasRetailerService.newCylinderRequestCSV(filter).subscribe(res => {
			this.downloadCSV(res, `orders-emptyCylinderRequest-${this.selectedRetailer.name}`)
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}

	showHideTip(isShow) {
		this.toolTipMessage = isShow ? "Sale Permission" : "";
	}
}