import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class PlantService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Post Terms and conditions
	managePlant(plantData): Observable<any> {
		if (plantData.id)
			return this.http.put(BASE_URL.PLANTS + "/" + plantData.id, plantData, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.PLANTS, plantData, this.headerService.httpHeaders());
	}

	// Fetch Plant
	fetchPlants(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.PLANTS + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch plant by id
	fetchPlantById(plantId): Observable<any> {
		return this.http.get(BASE_URL.PLANTS + "/" + plantId, this.headerService.httpHeaders());
	}

	// Delete Plant
	deletePlant(plantId): Observable<any> {
		return this.http.delete(BASE_URL.PLANTS + "/" + plantId, this.headerService.httpHeaders());
	}

	// Associate Request
	fetchAssociateRequest(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.ASSOCIATE_REQUEST_CART + searchQuery, this.headerService.httpHeaders());
		// if (plantId)
		// 	return this.http.get(BASE_URL.ASSOCIATE_REQUEST + "/" + plantId + searchQuery, this.headerService.httpHeaders());
		// return this.http.get(BASE_URL.CORPORATE_ASSOCIATE_CYLINDER_REQUEST + searchQuery, this.headerService.httpHeaders());
	}

	// Associate Request By Id
	fetchAssociateRequestById(requestId): Observable<any> {
		return this.http.get(BASE_URL.ASSOCIATE_REQUEST_CART + "/" + requestId, this.headerService.httpHeaders());
	}

	// Accept/ Reject Associate Request
	manageAssociateRequest(requestId, key): Observable<any> {
		return this.http.patch(BASE_URL.ASSOCIATE_REQUEST + "/" + requestId + "/" + key, this.headerService.httpHeaders());
	}

	// Approve Associate Request
	approveAssociateRequest(requestId): Observable<any> {
		return this.http.patch(BASE_URL.ASSOCIATE_REQUEST_CART + "/" + requestId + "/accept", this.headerService.httpHeaders());
	}

	// Reject Associate Request
	rejectAssociateRequest(requestId): Observable<any> {
		return this.http.patch(BASE_URL.ASSOCIATE_REQUEST_CART + "/" + requestId + "/reject", this.headerService.httpHeaders());
	}

	// Complete Associate Request
	completeAssociateRequest(requestId): Observable<any> {
		return this.http.patch(BASE_URL.ASSOCIATE_REQUEST_CART + "/" + requestId + "/complete", this.headerService.httpHeaders());
	}

	// Cancel Associate Request
	cancelAssociateRequest(requestId): Observable<any> {
		return this.http.patch(BASE_URL.ASSOCIATE_REQUEST_CART + "/" + requestId + "/cancel", this.headerService.httpHeaders());
	}

	newCylinderRequestCSV(filter): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_PLANT_CYLINDER_REQUEST_CSV + filter, {
			responseType: 'arraybuffer'
		});
	}

	refillCylinderRequestCSV(filter): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_PLANT_REFILL_CYLINDER_REQUEST_CSV + filter, {
			responseType: 'arraybuffer'
		});
	}

	pickedUpRequest(requestId): Observable<any> {
		return this.http.patch(BASE_URL.ASSOCIATE_REQUEST_CART + "/" + requestId + "/picked", this.headerService.httpHeaders());
	}
}
