import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { SettingService, CommonService, TokenService } from "../../../../__services";
import { MESSAGE } from '../../../../__utilities/messages';
import { BTN_TEXT, TITLE, TABLE_TEXT } from '../../../../__utilities/text';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-roles-permission-setting',
	templateUrl: './roles-permission-setting.component.html',
	styleUrls: ['./roles-permission-setting.component.css']
})
export class RolesPermissionSettingComponent implements OnInit, OnDestroy {

	isPagination: boolean = false;
	btnText: string = BTN_TEXT.ADD_ROLE;
	isTable: boolean = false;
	isAdd: boolean = false;
	isEdit: boolean = false;
	roleName: string = '';
	permissionsList: any = [];
	rolesList: any = [];
	permission: any = [];
	userPermission: any = [];
	selectedRole: any = '';
	isDisabled: boolean = false;
	isLoading: boolean = false;
	searchText: string = null;
	isCheckedAll: boolean = false;
	noRecord: string = TABLE_TEXT.NO_DATA;
	heading: string = TITLE.ROLES_PERMISSIONS;
	isPermission: boolean = false;
	routeReuseSubscription:Subscription;
	constructor(
		public settingService: SettingService,
		public commonService: CommonService,
		public tokenService: TokenService
	) {
		this.isPermission = this.commonService.isPermission(this.tokenService.getPermission(), PERMISSIONS.ADD_UPDATE_ROLE_PERMISSION);
	}

	ngOnInit(): void {
		this.fetchPermissionsList();
		this.fetchRoles();
		this.routeSubscription();
	}

	addMore() {
		this.isAdd = true;
		this.roleName = '';
		this.userPermission = [];
		this.selectedRole = '';
		this.permission = [];
		this.permissionsList.map(element => { element.isChecked = false; });
	}

	checkAll(event) {
		const isCheckedAll = event.target.checked;
		this.permissionsList.forEach((element, index) => {
			if (element.value) {
				this.permissionsList[index].isChecked = isCheckedAll;
				this.permission.push(element.value);
			}
		});
		if (!isCheckedAll)
			this.permission = [];
	}

	editRole(role) {
		this.roleName = role.name;
		this.userPermission = role.permission;
		this.selectedRole = role;
		this.isAdd = true;
		this.permission = [];
		let isChecked: boolean = false;
		this.permissionsList.forEach((element, index) => {
			isChecked = this.userPermission.indexOf(element.value) > -1;
			this.permissionsList[index].isChecked = isChecked;
			if (isChecked) this.permission.push(element.value);
		});
		this.isCheckedAll = this.permission.length == Object.keys(PERMISSIONS).length;
	}

	deleteRole(role) {
		Swal.fire({
			title: MESSAGE.DELETE_ROLE_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_ROLE_CONFIRMATION_TEXT + role.name + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedRole(role._id);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	deleteSelectedRole(roleId) {
		this.settingService.deleteRole(roleId).subscribe(res => {
			if (res && res.success) {
				this.rolesList.forEach((element, index) => {
					if (element._id == roleId)
						this.rolesList.splice(index, 1);
				});
				this.commonService.growlSuccess(res.message);
			}
			else
				this.commonService.growlError(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}


	onSearch(search) {
		this.searchText = search;
	}

	savePermission() {
		if (!this.roleName.trim()) {
			document.getElementById('roleName').focus();
			return;
		}

		if (!this.permission.length)
			return this.commonService.growlError(MESSAGE.INVALID_PERMISSION);

		this.isDisabled = true;
		let payload: any = { name: this.roleName, permission: this.permission };
		if (this.selectedRole) payload.id = this.selectedRole._id;
		this.settingService.manageRole(payload, this.selectedRole ? true : false).subscribe(res => {
			if (res && res.success) {
				this.commonService.growlSuccess(res.message);
				this.selectedRole ? this.resetPermissions(this.permission, res.permission.name) : this.rolesList.unshift(res.permission);
				this.isAdd = false;
				this.commonService.scrollTop();
			}
			this.isDisabled = false;
			this.selectedRole = '';
		}, error => {
			this.isDisabled = false;
			this.commonService.growlError(error.message);
		});
	}

	resetPermissions(permissionValue, roleName) {
		this.rolesList.forEach((element, index) => {
			if (element._id == this.selectedRole._id) {
				this.rolesList[index].permission = permissionValue;
				this.rolesList[index].name = roleName;
			}
		});
	}

	fetchPermissionsList() {
		this.settingService.fetchPermissionsList().subscribe(res => {
			if (res.success && res.response)
				for (var key in res.response) {
					if (res.response.hasOwnProperty(key)) {
						if (Object.keys(key).length) {
							this.permissionsList.push({ key: key.replace(/_/g, ' '), value: null });
							for (var innerKey in res.response[key]) {
									this.permissionsList.push({ key: innerKey.replace(/_/g, ' '), value: res.response[key][innerKey] });
							}
						}
					}
				}
		}, error => {
			this.permissionsList = [];
		});
	}

	fetchRoles() {
		this.rolesList = [];
		this.isLoading = true;
		this.settingService.fetchRoles().subscribe(res => {
			this.rolesList = res && res.success && res.permission && res.permission.length ? res.permission : [];
			this.isLoading = false;
		}, error => {
			this.rolesList = [];
			this.isLoading = false;
		});
	}

	// Select Permission
	selectPermission(value) {
		const itemIndex = this.permission.indexOf(value);
		itemIndex > -1 ? this.permission.splice(itemIndex, 1) : this.permission.push(value);
	}

	onBack() {
		this.isAdd = false;
		this.selectedRole = '';
		this.userPermission = [];
		this.permission = [];
		this.commonService.scrollTop();
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.ROLES_PERMISSIONS ? this.onBack() : false;
		});
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}