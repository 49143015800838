import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class IndependentGasRetailerService {

	constructor(private http: HttpClient, private headerService: HeaderService) {
	}

	// Gas Suppliers Cylinder Request To Warehouse
	makeCylinderRequest(request): Observable<any> {
		return this.http.post(BASE_URL.INDEPENDENT_CYLINDER_REQUEST, request, this.headerService.httpHeaders());
	}

	// Fetch Gas Suppliers Cylinder Request
	fetchIndependentCylinderRequest(independentId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_FETCH_CYLINDER_REQUEST + "/" + independentId + "/request" + searchQuery, this.headerService.httpHeaders());
	}

	// Fetch Delivery Charges
	fetchDeliveryCharge(payload): Observable<any> {
		return this.http.post(BASE_URL.FETCH_DELIVERY_CHARGES, payload, this.headerService.httpHeaders());
	}

	// Fetch Transaction Charges
	fetchTransactionCharges(payload): Observable<any> {
		return this.http.post(BASE_URL.FETCH_TRANSACTION_CHARGES, payload, this.headerService.httpHeaders());
	}

	// Cancel Cylinder request (By Independent)
	cancelRequest(requestId): Observable<any> {
		return this.http.patch(BASE_URL.INDEPENDENT_CYLINDER_REQUEST + "/" + requestId + "/cancel", {}, this.headerService.httpHeaders());
	}

	// Complete Cylinder Request (By Independent)
	completeRequest(requestId, payload): Observable<any> {
		return this.http.patch(BASE_URL.INDEPENDENT_CYLINDER_REQUEST + "/" + requestId + "/complete", payload, this.headerService.httpHeaders());
	}

	// Independent Cylinder Request To Warehouse
	independentCylinerRequestList(warehouseId, searchQuery): Observable<any> {
		return this.http.get(BASE_URL.WAREHOUSE_REQUEST_LIST + "/" + warehouseId + "/cylinder/request" + searchQuery, this.headerService.httpHeaders());
	}

	// Approve/Reject Independent Cylinder Request
	approveIndependentCylinderRequest(requestId, payload): Observable<any> {
		return this.http.patch(BASE_URL.WAREHOUSE_APPROVE_REJECT_INDEPENDENT_CYLINDER_REQUEST + "/" + requestId + "/approve", payload, this.headerService.httpHeaders());
	}

	// Approve/Reject Independent Cylinder Request
	dispatchIndependentCylinderRequest(requestId, payload): Observable<any> {
		return this.http.patch(BASE_URL.WAREHOUSE_APPROVE_REJECT_INDEPENDENT_CYLINDER_REQUEST + "/" + requestId + "/dispatch", payload, this.headerService.httpHeaders());
	}

	// Download Independent New Cylinder Request CSV
	newCylinderRequestCSV(filter): Observable<any> {
		return this.http.get(BASE_URL.CORPORATE_INDEPENDENT_CYLINDER_ORDER_REPORT + filter, { responseType: 'arraybuffer' });
	}

	// Fetch Bank List
	fetchBankList(): Observable<any> {
		return this.http.get(BASE_URL.BANK_LIST);
	}

	// Validate Bank Details
	validateBankDetails(payload): Observable<any> {
		return this.http.post(BASE_URL.VALIDATE_ACCOUNT, payload);
	}

	// Fetch Retailer Payment
	fetchRetailerPayment(searchQuery): Observable<any> {
		return this.http.get(BASE_URL.GAS_RETAILER_PAYMENT + searchQuery, this.headerService.httpHeaders());
	}

	//Fetch Independent LPG Price
	fetchIGRLPGPrice(id): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_LPG_PRICE + "/" + id, this.headerService.httpHeaders());
	}

	//Set IGR Price
	setIGRLPGPrice(payload): Observable<any> {
		return this.http.post(BASE_URL.INDEPENDENT_LPG_PRICE, payload, this.headerService.httpHeaders());
	}

	//Update INDEPENDENT LPG Price
	updateIGRLPGPrice(id, payload): Observable<any> {
		return this.http.put(BASE_URL.INDEPENDENT_LPG_PRICE + "/" + id, payload, this.headerService.httpHeaders());
	}

	//Set IGR Delivery charge
	setIGRDeliveryCharge(payload, id): Observable<any> {
		return this.http.put(BASE_URL.SET_IGR_DELIVERY_CHARGE + "/" + id, payload, this.headerService.httpHeaders());
	}

	//Fetch Independent
	fetchIGR(id): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_GAS_RETAILER + "/" + id, this.headerService.httpHeaders());
	}

	// Fetch Tank Attribute
	fetchIGRCylinderCategory(id): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_CYLINDER_CATEGORY + "/" + id, this.headerService.httpHeaders());
	}

	// Delete Tank Attribute
	deleteIGRCylinderCategory(attributeId, retailerId): Observable<any> {
		return this.http.delete(BASE_URL.INDEPENDENT_CYLINDER_CATEGORY + "/" + attributeId + "/" + retailerId, this.headerService.httpHeaders());
	}

	// Manage Tank Attribute (POST/UPDATE)
	manageIGRCylinderCategory(cylinderCategory, retailerId): Observable<any> {
		if (cylinderCategory.get('id'))
			return this.http.put(BASE_URL.INDEPENDENT_CYLINDER_CATEGORY + "/" + retailerId, cylinderCategory, this.headerService.imageHttpHeaders());
		return this.http.post(BASE_URL.INDEPENDENT_CYLINDER_CATEGORY, cylinderCategory, this.headerService.imageHttpHeaders());
	}

}
