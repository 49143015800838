import { Component, OnInit, ViewChild, Input, OnDestroy, ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService, ProfileService, CommonService, LoaderService } from '../../../../__services';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { MESSAGE } from '../../../../__utilities/messages';
import Swal from 'sweetalert2';
declare var $;

@Component({
	selector: 'app-guarantors',
	templateUrl: './guarantors.component.html',
	styleUrls: ['./guarantors.component.css']
})
export class GuarantorsComponent implements OnInit, OnDestroy, AfterViewChecked {

	@Input() cropperSettings;
	@Input() phoneConfig;
	@Input() defaultCountry;
	@Input() message;
	@Input() filePlaceholder;
	guarantorForm: UntypedFormGroup;
	isSubmitted: boolean = false;
	isDisabled: boolean = false;
	imageCropData: any = '';
	imgExt = ['image/jpeg', 'image/png'];
	docExt = ['application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'image/jpeg', 'image/png', 'application/pdf'];
	isFileUpload: boolean = false;
	fileError: string;
	fileObj: any;
	imageKey: string;
	utilityBill: any;
	photoId: any;
	guarantorsList = [];
	selectedGuarantor: any;
	isAdd: boolean = false;
	isLoading: boolean = true;
	isUnknownLocation: boolean = false;
	address: string = "";
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;
	constructor(public validationService: ValidationService,
		public profileService: ProfileService,
		public commonService: CommonService,
		public loader: LoaderService,
		private cd: ChangeDetectorRef,
		private fb: UntypedFormBuilder) { }

	ngOnInit(): void {
		this.imageCropData = {};
		this.initializeControls();
		this.fetchGuarantors();
		this.loader.show();
		this.setCropper();
	}

	initializeControls() {
		this.guarantorForm = this.fb.group({
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			contact: ['', Validators.compose([Validators.required])],
			address: ['', Validators.compose([Validators.required])],
			utilityBill: ['', Validators.compose([Validators.required])],
			photoId: ['', Validators.compose([Validators.required])]
		});
	}

	setCropper() {
		this.cropperSettings.rounded = false;
		this.cropperSettings.keepAspect = false;
	}

	fileChangeListener(event, key, placeholder) {
		var image: any = new Image();
		var file: File = event.target.files[0];

		if (this.docExt.indexOf(file.type) == -1 && (key == 'utilityBill')) {
			this.displayError(event, placeholder, this.message.INVALID_DOC_FORMAT);
			return;
		}

		if (this.imgExt.indexOf(file.type) == -1 && key != 'utilityBill') {
			this.displayError(event, placeholder, this.message.INVALID_IMG_FORMAT);
			return;
		}

		if (key != 'utilityBill') this.isFileUpload = true;

		if (file) {
			this.fileObj = file;
			var myReader: FileReader = new FileReader();
			var that = this;
			myReader.onloadend = function (loadEvent: any) {
				image.src = loadEvent.target.result;
				if (key == 'utilityBill') {
					that.utilityBill = that.fileObj;
					that.commonService.setFilePlaceholder(event.target.id, file.name);
					that.guarantorForm.controls.utilityBill.setValue(file.name);

				} else {
					that.imageKey = key;
					that.photoId = that.fileObj;
					that.guarantorForm.controls.photoId.setValue(file.name);
					setTimeout(() => {
						that.cropper.setImage(image);
					}, 0);
				}
			};
			myReader.readAsDataURL(file);
		}
	}

	displayError(event, placeHolder, message) {
		this.isFileUpload = false;
		let _this = this;
		setTimeout(() => {
			_this.commonService.setFilePlaceholder(event.target.id, placeHolder);
		}, 0);
		this.commonService.growlError(message);
	}

	getImageData(key) {
		var _this = this;
		this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
			_this.isFileUpload = false;
			if (key == 'utilityBill') {
				_this.utilityBill = file;
				_this.commonService.setFilePlaceholder('fileProfile', file.name);
				_this.guarantorForm.controls.utilityBill.setValue(_this.fileObj.name);
			}
			else {
				_this.commonService.setFilePlaceholder('filePhotoId', file.name);
				_this.photoId = file;
				_this.guarantorForm.controls.photoId.setValue(_this.fileObj.name);
			}
		}, error => {
			_this.commonService.growlError(_this.message.ERROR);
			_this.isFileUpload = false;
			_this.imageCropData.image = null;
		});
	}

	onSubmit(gurantorDetail) {
		this.isSubmitted = true;
		if (this.guarantorForm.valid) {
			this.isDisabled = true;
			const guarantor = this.createPayload(gurantorDetail);
			this.profileService.manageGuarantor(guarantor).subscribe(res => {
				if (res.success && res.user) {
					this.commonService.growlSuccess(res.message);
					this.guarantorsList = res.user.guarantors;
					this.resetForm();
				}
			}, error => {
				this.isDisabled = false;
				this.isSubmitted = false;
				this.commonService.growlError(this.message.ERROR);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.guarantorForm);
		}
	}

	createPayload(gurantorDetail) {
		let formData = new FormData();
		formData.append('firstName', gurantorDetail.firstName);
		formData.append('lastName', gurantorDetail.lastName);
		formData.append('email', gurantorDetail.email);
		formData.append('contact', this.commonService.formatContactNumber(gurantorDetail.contact.number));
		formData.append('dialCode', gurantorDetail.contact.dialCode);
		formData.append('countryCode', gurantorDetail.contact.countryCode);
		if (this.utilityBill) formData.append('gurUtilityImage1', this.utilityBill);
		if (this.photoId) formData.append('gurProfId1', this.photoId);
		formData.append('address', this.address);
		if (this.selectedGuarantor)
			formData.append('id', this.selectedGuarantor._id);
		return formData;
	}

	fetchGuarantors() {
		this.profileService.fetchGuarantors().subscribe(res => {
			if (res && res.success && res.guarantors) {
				this.guarantorsList = res.guarantors;
			}
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.isLoading = false;
			this.guarantorsList = [];
		});
	}

	// On edit
	onEdit(guarantor) {
		this.isSubmitted = false;
		this.phoneConfig.CountryISO = guarantor.countryCode;
		this.guarantorForm.controls.contact.setValue(guarantor.contact);
		this.cd.detectChanges();
		this.isAdd = true;
		this.isDisabled = false;
		this.clearValidator();
		this.selectedGuarantor = guarantor;
		this.guarantorForm.controls.firstName.setValue(guarantor.firstName);
		this.guarantorForm.controls.lastName.setValue(guarantor.lastName);
		this.guarantorForm.controls.email.setValue(guarantor.email);
		this.guarantorForm.controls.address.setValue(guarantor.address);
		this.address = guarantor.address;
		this.commonService.scrollTop();
	}

	// On Delete 
	onDelete(guarantor) {

		Swal.fire({
			title: MESSAGE.DELETE_GUARANTOR_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_GUARANTOR_CONFIRMATION_TEXT + guarantor.firstName + " " + guarantor.lastName + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedGuarantor(guarantor._id);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	deleteSelectedGuarantor(guarantorId) {
		this.profileService.deleteGuarantor(guarantorId).subscribe(res => {
			if (res && res.success) {
				this.commonService.growlSuccess(res.message);
				const tempArr = [];
				this.guarantorsList.forEach((element, index) => {
					if (element._id != guarantorId)
						tempArr.push(element);
				});
				this.guarantorsList = tempArr;
			}
			else {
				this.commonService.growlError(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	clearValidator() {
		this.guarantorForm.controls.utilityBill.clearValidators();
		this.guarantorForm.controls.utilityBill.updateValueAndValidity();
		this.guarantorForm.controls.photoId.clearValidators();
		this.guarantorForm.controls.photoId.updateValueAndValidity();
	}

	resetForm() {
		this.isAdd = false;
		this.guarantorForm.reset();
		this.selectedGuarantor = '';
		this.utilityBill = null;
		this.photoId = null;
		this.isSubmitted = false;
		this.isFileUpload = false;
		this.phoneConfig.CountryISO = this.defaultCountry;
		this.guarantorForm.controls.utilityBill.setValidators([Validators.required]);
		this.guarantorForm.controls.utilityBill.updateValueAndValidity();
		this.guarantorForm.controls.photoId.setValidators([Validators.required]);
		this.guarantorForm.controls.photoId.updateValueAndValidity();
		this.commonService.setFilePlaceholder('fileProfile', this.filePlaceholder.UPLOAD_PROFILE);
		this.commonService.setFilePlaceholder('filePhotoId', this.filePlaceholder.UPLOAD_GOVT_ID);
	}

	ngOnDestroy() {
		this.cropperSettings.rounded = true;
		this.cropperSettings.keepAspect = true;
	}

	ngAfterViewChecked() {
		this.cd.detectChanges();
	}

	addNew() {
		this.resetForm();
		this.isAdd = true;
		this.isDisabled = false;
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			this.address = address.formatted_address;
			this.guarantorForm.controls.address.setValue(this.address);
		}
		else {
			this.guarantorForm.controls.address.setValue('');
			this.isUnknownLocation = true;
		}
	}
}
