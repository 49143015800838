import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
  ) { }


  // Show Loader
  show() {
    const loader = <HTMLElement>this.document.querySelector(".loader");
    if (loader)
      loader.style.display = 'block';
  }

  // Hide Loader
  hide() {
    const loader = <HTMLElement>this.document.querySelector(".loader");
    if (loader)
      loader.style.display = 'none';
  }
}
