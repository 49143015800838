import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
	UsersService, CommonService, ImageCropperService, TokenService, OrderService,
	ValidationService, CylinderService, StorageService, LoaderService
} from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE, BTN_TEXT } from '../../../../__utilities/text';
import { ROLES } from '../../../../__utilities/roles';
import { PHONE_CONFIG } from '../../../../__utilities/phone';
import { CART_KEY, PAYMENT_MODE, CLIENT_PAYMENT_MODE } from '../../../../__utilities/orders';
import { MESSAGE, FILE_PLACEHOLDER } from '../../../../__utilities/messages';
import { ORDER_TYPE, ORDER_VALUES, ORDER_STATUS } from '../../../../__utilities/orders';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare var $;

@Component({
	selector: 'app-walk-ins-order',
	templateUrl: './walk-ins-order.component.html',
	styleUrls: ['./walk-ins-order.component.css']
})
export class WalkInsOrderComponent implements OnInit {
	paginate: any = { ...PAGINATION };
	orderStatus: any = { ...ORDER_STATUS };
	phoneConfig = PHONE_CONFIG;
	ordersType: any = ORDER_TYPE;
	selectedOrderType: any = ORDER_TYPE.REFILL_EXCHANGE;
	ordersValues: any = ORDER_VALUES;
	message: any = MESSAGE;
	walkinOrderForm: UntypedFormGroup;
	walkinId: string = "";
	heading: string = "";
	searchStr: string = "";
	orders: any = [];
	isButton: boolean = true;
	btnText: string = BTN_TEXT.CREATE_ORDER;
	isAdd: boolean = false;
	cylinderCategories: any = [];
	isDisabled: boolean = false;
	options = {
		types: ['address'],
	};
	address: any = { houseNo: "", address: "" };
	location: any = {
		type: 'Point',
		coordinates: []
	};
	plantId: string = "";
	isUnknownLocation: boolean = false;
	isLoading: boolean = false;
	isWalkinUserLoading: boolean = false;
	fileError: string;
	filePlaceholder: any = FILE_PLACEHOLDER;
	isSubmitted: boolean = false;
	selectedRowIndex: any = "";
	selectedOrder: any = "";
	selectedWalkin: any = "";
	cylinderSize = ["3", "6"];
	//isRegulatorCategory: boolean = false;
	paymentMode: any = PAYMENT_MODE;
	childCompClientPaymentMode: any = CLIENT_PAYMENT_MODE;
	clientPaymentMode: any = "";
	creditPoint: number = 0;
	orderTypes: any = ORDER_VALUES;
	isClientWalkin: boolean = false;
	selectedCategoryCharge: number = 0;
	selectedUnitCategoryCharge: number = 0;
	discount: number = 0;
	discountUnit: number = 0;
	isChargeLoading: boolean = false;
	isCategorySelected: boolean = false;
	//regulatorAmount: number = 0;
	//isRegulatorChargeLoading: boolean = false;
	filteredRegulators: any = [];
	orderMode: string = "";
	cartItems = [];
	selectedSize: number = 0;
	cartTotal: number = 0;
	discountTotal: number = 0;
	cartKey: string = CART_KEY;
	selectedRegulator = [];
	activatedRouteValues: any = "";
	searchOrderId: string = "";
	queryParamObj: any = {};
	statusFilter: string = "";
	isRefillMyOwn: boolean = false;
	LPGPricePerKg: number = 0;
	totalQuantity :number = 0;
	constructor(
		private fb: UntypedFormBuilder,
		public usersService: UsersService,
		public commonService: CommonService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public orderService: OrderService,
		public tokenService: TokenService,
		public cylinderService: CylinderService,
		public validationService: ValidationService,
		public storageService: StorageService,
		public imageCropperService: ImageCropperService,
		public loader: LoaderService,
	) {
		this.setUserDetails();
		this.setPaymentMode();
		delete this.orderStatus['DECLINED'];
	}

	ngOnInit(): void {
		this.isButton = this.plantId ? true : false;
		this.getRoutingParams();
	}

	// Set Payment Mode
	setPaymentMode() {
		this.isClientWalkin = this.activatedRoute.data['value'].isClientWalkin;
		if (this.isClientWalkin) {
			this.clientPaymentMode = this.paymentMode;
			this.clientPaymentMode.creditCard = CLIENT_PAYMENT_MODE.creditCard;
		}
	}

	// Set User details
	setUserDetails() {
		let userObj = this.storageService.getItem('userObj');
		this.plantId = [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR].indexOf(userObj.roleId.name) > -1 ? userObj[userObj.roleId.name]._id : "";
	}

	// Reset Pagination
	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	onSearch(searchStr) {
		this.orders = [];
		this.searchStr = searchStr;
		this.resetPagination();
		this.getWalkinOrderDetails();
	}

	onPaginate(pageNo) {
		this.orders = [];
		this.paginate.currentPage = pageNo;
		this.getWalkinOrderDetails();
	}

	// Get Routing Params
	getRoutingParams() {
		this.isWalkinUserLoading = true;
		this.activatedRouteValues = this.activatedRoute.data["value"];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ walkinId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.walkinId = results.walkinId;
				this.queryParamObj = {
					page: results.query.page ? results.query.page : 1,
				}
				if (results.query.search)
					this.queryParamObj.search = results.query.search ? results.query.search : "";
				this.searchOrderId = results.query.orderId ? results.query.orderId : "";
				this.orders = [];
				if (this.walkinId) {
					this.getWalkinUserById();
					this.initializeControls();
					this.heading = TITLE.CLIENTS_ORDER;
					this.fetchCylinderCategory();
					if (this.plantId)
						this.cartItems = this.fetchCartItems();
				}
				else
					this.isWalkinUserLoading = false;
			});
	}

	getWalkinUserById() {
		this.loader.show();
		this.usersService.fetchWalkinById(this.walkinId).subscribe(res => {
			if (res && res.success && res.response) {
				this.selectedWalkin = res.response;
				this.setBreadCrumb();
				if (this.selectedWalkin.isDeleted)
					this.isButton = false;
				if (this.selectedWalkin.isRejected)
					this.isButton = false;
				this.getWalkinOrderDetails();
			}
			this.isWalkinUserLoading = false;
			this.loader.hide();
		}, error => {
			this.loader.hide();
			this.isWalkinUserLoading = false;
			this.selectedWalkin = "";
		});
	}

	setBreadCrumb() {
		var breadcrumb = [...this.activatedRoute.data['value'].breadcrumb, ...[{
			label: this.selectedWalkin.firstName + " " + this.selectedWalkin.lastName,
			url: '',
			icon: 'fa fa-user'
		}]];
		let _this = this;
		setTimeout(() => {
			_this.commonService.sendRouteChanges(breadcrumb);
		}, 0);
	}

	// Fetch Regulators
	// fetchRegulators() {
	// 	this.cylinderService.fetchRegulator().subscribe(res => {
	// 		if (res && res.success && res.response) {
	// 			res.response.forEach(element => {
	// 				if (this.selectedOrderType == this.ordersType.REFILL_EXCHANGE && element.isRefill && !element.isDeleted) {
	// 					this.filteredRegulators.push(element);
	// 				}
	// 				if (this.selectedOrderType == this.ordersType.PURCHASE && element.isPurchase && !element.isDeleted) {
	// 					this.filteredRegulators.push(element);
	// 				}
	// 				this.manageRegulatorTypeValidator(this.filteredRegulators.length && this.selectedOrderType == this.ordersType.REFILL_EXCHANGE);
	// 			});
	// 		} else {
	// 			this.filteredRegulators = [];
	// 		}
	// 	}, error => {
	// 		this.filteredRegulators = [];
	// 	});
	// }

	// onRegulatorChange(event) {
	// 	const value = event.target.value;
	// 	this.selectedRegulator = this.filteredRegulators.filter(regulator => regulator._id == value);
	// 	this.regulatorAmount = 0;
	// 	if (this.selectedOrderType == this.ordersType.REFILL_EXCHANGE) return;
	// 	if (event.target.value) {
	// 		this.isRegulatorChargeLoading = true;
	// 		this.cylinderService.fetchRegulatorDetailsById(value).subscribe(res => {
	// 			if (res && res.success && res.response && Object.keys(res.response).length) {
	// 				this.regulatorAmount = res.response.charge;
	// 			}
	// 			this.isRegulatorChargeLoading = false;
	// 		}, error => {
	// 			this.isRegulatorChargeLoading = false;
	// 		});
	// 	}
	// }

	// Fetch Tank Categories
	fetchCylinderCategory() {
		this.cylinderService.fetchCylinderCategory().subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response;
			} else {
				this.cylinderCategories = [];
			}
		}, error => {
			this.cylinderCategories = [];
		});
	}

	initializeControls() {
		this.walkinOrderForm = this.fb.group({
			orderType: ['', Validators.compose([])],
			size: ['', Validators.compose([Validators.required])],
			regulatorType: ['', Validators.compose([])],
			customSize: [''],
			quantity: [''],
		});
	}

	getSearchString() {
		let queryStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage + "&orderBy=" + this.walkinId + "&orderMode=" + this.orderMode;
		if (this.plantId)
			queryStr += `&plantId=${this.plantId}`;
		if (this.statusFilter)
			queryStr += `&filter=${this.statusFilter}`;
		return queryStr;
	}

	// Fetch Walk in Order Details By Client Id
	getWalkinOrderDetails() {
		this.isLoading = true;
		let searchQuery = this.getSearchString();
		this.orderService.fetchCartOrders(searchQuery).subscribe(res => {
			if (res.success && res.response && res.response.data) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.orders = res.response.data;
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}

	goBack(isMainList) {
		isMainList ? this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamObj }) : (this.selectedOrder = '', this.isAdd = false);
	}

	addMore() {
		this.isAdd = true;
		this.selectedOrderType = ORDER_TYPE.REFILL_EXCHANGE;
		this.selectedOrder = "";
		this.selectedRowIndex = "";
	}

	resetForm() {
		//this.regulatorAmount = 0;
		this.isRefillMyOwn = false;
		this.selectedSize = 0;
		this.selectedCategoryCharge = 0;
		this.selectedUnitCategoryCharge = 0;
		this.discount = 0;
		this.discountUnit = 0;
		this.walkinOrderForm.reset();
		//this.isRegulatorCategory = false;
		this.manageRegulatorTypeValidator(false);
		this.walkinOrderForm.controls.size.setValue('');
		this.walkinOrderForm.controls.regulatorType.setValue('');
		this.filteredRegulators = [];
		this.selectedRegulator = [];
	}

	// Submit plant form
	onSubmit(orderData) {
		
		if(this.checkQuantity(orderData.quantity) >5){
			Swal.fire({
				title: "Max 5 Quantity Allowed",
				allowEscapeKey: false,
				allowOutsideClick: false,
				reverseButtons: true,
				allowEnterKey: false,
				showConfirmButton: true,
			}).then((confirm) => {
				return
			}).catch(err => {
				if (err) {
					this.commonService.growlError(MESSAGE.ERROR);
				}
			});
		}
		else{
			if (this.walkinOrderForm.valid && !this.isChargeLoading) {
				orderData = this.createPayload(orderData);
				this.isDisabled = true;
				this.resetForm();
				this.selectedOrderType = this.selectedOrderType == this.ordersType.REFILL_EXCHANGE ? this.ordersType.REFILL_EXCHANGE : this.ordersType.PURCHASE;
				$("#" + this.selectedOrderType).prop("checked", true);
				this.isDisabled = false;
			}
			else {
				this.validationService.validateAllFormFields(this.walkinOrderForm);
			}
		}
	}

	manageRegulatorTypeValidator(validate) {
		if (validate)
			this.walkinOrderForm.controls.regulatorType.setValidators(Validators.required);
		else
			this.walkinOrderForm.controls.regulatorType.clearValidators();
		this.walkinOrderForm.controls.regulatorType.updateValueAndValidity();
	}

	createPayload(orderData) {
		let items =
		{
			categoryId: !this.isRefillMyOwn ? orderData.size : '',
			regulatorId: orderData.regulatorType,
			orderType: this.selectedOrderType,
			orderText: ORDER_VALUES[this.selectedOrderType],
			charge: this.selectedCategoryCharge,
			discount: this.discount,
			size: !this.isRefillMyOwn ? Number(this.selectedSize) : Number(this.walkinOrderForm.controls.customSize.value),
			isCustomSize: this.isRefillMyOwn ? true : false,
			//regulatorCharge: this.regulatorAmount,
			regulatorType: this.selectedRegulator && this.selectedRegulator.length && this.selectedRegulator[0].type,
			quantity: orderData.quantity && parseInt(orderData.quantity) ? parseInt(orderData.quantity) : 1
		};

		let cartItems = this.fetchCart();
		if (cartItems && Object.keys(cartItems).length) {
			if (cartItems[this.walkinId])
				cartItems[this.walkinId].push(items);
			else
				cartItems[this.walkinId] = [items];
			this.storageService.removeItem(CART_KEY);
			this.addToCart(cartItems);
		}
		else {
			cartItems = { [this.walkinId]: [items] };
			this.addToCart(cartItems);
		}
		this.cartItems = this.fetchCartItems();
	}

	// Add To Cart
	addToCart(items) {
		this.storageService.setItem(CART_KEY, items);
		this.cartItems = this.fetchCartItems();
	}

	fetchCartItems() {
		this.cartTotal = 0;
		this.discountTotal = 0;
		let items = this.fetchCart();
		if (items && Object.keys(items).length) {
			items = items[this.walkinId];
			if (items)
				items.forEach(element => {
					this.cartTotal += parseFloat(element.charge);
					this.discountTotal += element.discount;
				});
		}
		return items;
	}

	checkQuantity(inputQuantity){
		this.totalQuantity = 0;
		let items = this.fetchCart();
		if (items && Object.keys(items).length) {
			items = items[this.walkinId];
			if (items)
				items.forEach(element => {
					inputQuantity = parseInt(inputQuantity) + parseInt(element.quantity);
				});
		}
		this.totalQuantity = this.totalQuantity + inputQuantity
		return this.totalQuantity
	}

	// Remove Cart
	removeCart() {
		this.storageService.removeItem(CART_KEY);
	}

	// Remove My Cart
	removeMyCart() {
		let cart = this.fetchCart();
		delete cart[this.walkinId];
		this.removeCart();
		this.addToCart(cart);
	}

	// Fetch Cart
	fetchCart() {
		return this.storageService.getItem(CART_KEY);
	}

	// Remove Item From Cart
	removeItemFromCart(itemIndex) {
		let carItems = this.fetchCart();
		carItems[this.walkinId].splice(itemIndex, 1);
		this.removeCart();
		this.addToCart(carItems);
		this.cartTotal = 0;
		this.discountTotal = 0;
		this.cartItems = this.fetchCartItems();
	}

	viewDetail(order, index) {
		this.selectedRowIndex = index;
		this.selectedOrder = order;
	}

	onCategoryChange(event) {
		this.selectedCategoryCharge = 0;
		this.selectedUnitCategoryCharge = 0;
		this.LPGPricePerKg = 0;
		this.discount = 0;
		this.discountUnit = 0;
		this.filteredRegulators = [];
		//this.regulatorAmount = 0;
		let size = event.target.options[event.target["selectedIndex"]].getAttribute("size");

		this.manageCustomSizeValidator(false);
		this.fetchCustomSizePrice();
		if (!size) {
			this.manageRegulatorTypeValidator(false);
			//this.isRegulatorCategory = false;
			this.isCategorySelected = false;
			return;
		}
		this.selectedSize = size;
		this.isCategorySelected = true;
		this.fetchCylinderCategoryById(event.target.value);


		// if (size && this.cylinderSize.indexOf(size) == -1) {
		// 	this.isRegulatorCategory = true;
		// 	this.fetchRegulators();
		// }
		// else {
		// 	this.manageRegulatorTypeValidator(false);
		// 	this.isRegulatorCategory = false;
		// }
	}

	manageCustomSizeValidator(isValidate) {
		isValidate ? this.walkinOrderForm.controls.customSize.setValidators(Validators.required) : this.walkinOrderForm.controls.customSize.clearValidators()
		this.walkinOrderForm.controls.customSize.updateValueAndValidity();
	}

	fetchCustomSizePrice() {
		this.cylinderService.fetchCylinderCategory().subscribe(res => {
			if (res && res.success && res.response) {
				this.LPGPricePerKg = res.LPGPricePerKg;

			}
		}, error => {

		});
	}

	onBlur(size) {
		this.selectedCategoryCharge = this.LPGPricePerKg * size ;
		this.selectedUnitCategoryCharge = this.selectedCategoryCharge;
	}

	onQuantityChange(qty){
		console.log("qty",qty);
		this.selectedCategoryCharge = qty > 0 ? this.selectedUnitCategoryCharge * qty : this.selectedUnitCategoryCharge;
		this.discount = qty > 0 ? this.discountUnit * qty : this.discountUnit;
	}

	fetchCylinderCategoryById(categoryId) {
		this.isChargeLoading = true;
		this.cylinderService.fetchCylinderCategoryById(categoryId).subscribe(res => {
			if (res && res.success && res.response) {
				this.selectedCategoryCharge = this.selectedOrderType == this.ordersType.PURCHASE ? res.response.purchaseFee : res.response.refillingCharge;
				this.discount = (this.selectedCategoryCharge * res.response.discount) / 100;
				this.selectedUnitCategoryCharge = this.selectedCategoryCharge;
				this.discountUnit = this.discount;
			}
			this.isChargeLoading = false;
		}, error => {
			this.selectedCategoryCharge = 0;
			this.selectedUnitCategoryCharge = 0;
			this.discount = 0;
			this.discountUnit = 0;
			this.isChargeLoading = false;
			this.commonService.growlError(error.message);
		});
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.setAddress(this.address);
		}
		else {
			this.setAddress("");
			this.isUnknownLocation = true;
		}
	}

	setAddress(address) {
		this.walkinOrderForm.controls.address.setValue(address);
	}

	onOrderModeChange(orderMode) {
		this.searchStr = "";
		this.orderMode = orderMode;
		this.resetAndFetch();
	}

	resetAndFetch() {
		this.resetPagination();
		this.orders = [];
		this.getWalkinOrderDetails();
	}

	// Place Cart Order
	onContinue() {
		this.commonService.openCartModal(true);
	}

	// On order placed
	onOrderPlaced(order) {
		this.paginate.totalItems += 1;
		this.orders.unshift(order);
		this.isAdd = false;
		this.removeMyCart();
		this.cartItems = this.fetchCartItems();
	}

	// On Order Change
	onOrderChange(event, orderType) {
		this.selectedOrderType = orderType;
		this.resetForm();
		if (orderType == this.ordersType.REFILL_MY_OWN) {
			this.walkinOrderForm.controls.size.clearValidators();
			this.walkinOrderForm.controls.size.updateValueAndValidity();
			this.manageCustomSizeValidator(true);
			this.fetchCustomSizePrice();
			this.isRefillMyOwn = true;
		}
		else {
			this.walkinOrderForm.controls.size.setValidators(Validators.required);
			this.walkinOrderForm.controls.size.updateValueAndValidity();
			this.isRefillMyOwn = false;
		}
	}

	onOrderStatusChange(orderStatus) {
		this.statusFilter = orderStatus;
		this.resetAndFetch();
	}
}