import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
	name: 'customDateTime'
})
export class CustomDateTimePipe extends DatePipe implements PipeTransform {

	transform(value: any, ...args: any): any {
		return value ? super.transform(value, "yyyy-MM-dd \xa0 H:mm:ss a") : null;
	}
}