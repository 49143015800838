import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ProfileService, ValidationService, CommonService, StorageService } from '../../../__services';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

	changePasswordForm: UntypedFormGroup;
	isDisabled: boolean = false;
	constructor(public profileService: ProfileService,
		public validationService: ValidationService,
		private fb: UntypedFormBuilder,
		public commonService: CommonService,
		public storageService: StorageService) { }

	ngOnInit() {

		this.changePasswordForm = this.fb.group({
			oldPassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
			newPassword: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
			confirmPassword: ['', Validators.compose([Validators.required])]
		});
	}

	// Matching the password 
	public passwordMatchValidator(g: UntypedFormGroup) {
		return g.get('confirmPassword').value === g.get('newPassword').value
			? null : { 'mismatch': true };
	}
	get f() { return this.changePasswordForm.controls; }

	onSubmit(credentials) {
		if (this.validationService.isValidAllPassword && this.changePasswordForm.valid) {
			this.isDisabled = true;
			delete credentials.confirmPassword;
			this.profileService.changePassword(credentials).subscribe(res => {
				if (res && res.response) {
					this.storageService.setItem('token', res.response);
					this.commonService.growlSuccess(res.message);
				}
				this.resetForm();
			}, error => {
				this.resetForm();
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.changePasswordForm);
		}
	}

	resetForm() {
		this.changePasswordForm.reset();
		this.isDisabled = false;
	}
}
