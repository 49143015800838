import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BTN_TEXT, TABLE_TEXT } from '../../__utilities/text';

@Component({
	selector: 'app-table-layout',
	templateUrl: './table-layout.component.html',
	styleUrls: ['./table-layout.component.css']
})
export class TableLayoutComponent implements OnInit {
	@Input() isAdd: boolean = false;
	@Input() isPagination: boolean = true;
	@Input() isSearch: boolean = true;
	@Input() isButton: boolean = true;
	@Input() isTable: boolean = true;
	@Input() isHeading: boolean = true;
	@Input() isLoading: boolean = false;
	@Input() totalSize: number = 0;
	@Input() btnText: string = BTN_TEXT.ADD_MORE;
	@Input() tableText: string = TABLE_TEXT.NO_DATA;
	@Input() heading: string;
	@Input() searchQuery:string="";
	@Input() isFilter: boolean = false;
	@Input() paginationItems: any;
	@Output() addMore = new EventEmitter<boolean>();
	@Output() search = new EventEmitter<string>();
	@Output() onPageChange = new EventEmitter<number>();

	constructor() {
	}

	ngOnInit() {
	}

	addNew() {
		this.addMore.emit(true);
	}

	onSearch(searchStr) {
		this.search.emit(searchStr);
	}

	onPaginate(event) {
		this.onPageChange.emit(event);
	}

	displayCount() {
		if (!this.paginationItems) {
			return this.totalSize?this.totalSize+" of "+this.totalSize:"";
		}
		let item = this.paginationItems;
		let total = item.currentPage > 1 && item.totalItems <= ((item.currentPage - 1) * item.itemsPerPage)
			? (10 - (((item.currentPage - 1) * item.itemsPerPage) - item.totalItems)) + ((item.currentPage - 1) * item.itemsPerPage) : item.totalItems;
		return item ?
			(
				item.currentPage == 1 ?
					(
						'1 - ' + (item.itemsPerPage>total?total:item.currentPage * item.itemsPerPage) + ' of ' + total
					) :
					(item.currentPage - 1) * item.itemsPerPage + 1 + " - " +
					(
						(item.currentPage > 1 && item.totalItems <= ((item.currentPage) * item.itemsPerPage)) ? total : ((item.currentPage) * item.itemsPerPage)) + ' of ' + total
			) : ''
	}
}

