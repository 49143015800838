import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';
import { SECURE_LS } from '../__utilities/localstorage';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	ls = new SecureLS({ encodingType: SECURE_LS.ENCODING_TYPE, encryptionSecret: SECURE_LS.ENCRYPTION_SECRET });

	constructor(
		public router: Router
	) { }
	initialiseLS($keys) {
		this.ls.set(SECURE_LS.STORAGE_KEY, $keys);
	}

	setItem($key, $value) {
		let obj = this.getStorage();
		if (obj) {
			obj[$key] = $value;
			this.initialiseLS(obj);
		}
		else {
			this.initialiseLS({ [$key]: $value });
		}
	}

	getItem($key) {
		let lsItems = this.getStorage();
		return lsItems[$key];
	}

	removeItem($key) {
		let lsItems = this.getStorage();
		delete lsItems[$key];
		this.initialiseLS(lsItems);
	}

	clearAll() {
		let remToken = this.getItem('rem_token');
		this.ls.remove(SECURE_LS.STORAGE_KEY);
		if (remToken)
			this.setItem('rem_token', remToken);
		this.router.navigate(['/']);
	}

	getStorage() {
		try {
			return this.ls.get(SECURE_LS.STORAGE_KEY);
		}
		catch (error) {
			this.router.navigate(['/login']);
			this.ls.remove(SECURE_LS.STORAGE_KEY);
			return false;
		}
	}
}
