import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DistributorRoutingModule, ROUTING_COMPONENTS } from './distributor-routing.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
  declarations: [ROUTING_COMPONENTS],
  imports: [
    SharedModule,
    DistributorRoutingModule,GooglePlaceModule
  ]
})
export class DistributorModule { }
