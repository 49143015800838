import { Component, OnInit } from '@angular/core';
import { UsersService, CommonService, TokenService, LoaderService, StorageService, OrderService } from '../../../../__services';
import { Router, ActivatedRoute } from '@angular/router';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_STATUS } from '../../../../__utilities/orders';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { MESSAGE } from '../../../../__utilities/messages';
import { ROLES } from '../../../../__utilities/roles';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
	selector: 'app-fleet-owner',
	templateUrl: './fleet-owner.component.html',
	styleUrls: ['./fleet-owner.component.css']
})
export class FleetOwnerComponent implements OnInit {
	paginate: any = { ...PAGINATION };
	isLoading: boolean = false;
	searchStr: string = "";
	isShow: boolean = false;
	fleetOwners: any = [];
	messages:any = MESSAGE;
	data = [];
	selectedFleetOwner: any;
	selectedAssociateDetails: any;
	heading: string = TITLE.FLEET_OWNER;
	isPermission: boolean = false;
	selectedRowIndex: any = "";
	fleetOwnerId: string = "";
	activatedRouteValues: any = "";
	isPrintOrderPermission: boolean = false;
	tabId = {
		FLEET: "fleetTab",
		ASSOCIATE: "associateTab"
	};
	selectedTab: string = "";
	searchFleetId:string = "";
	inputOptions: any = { ...ORDER_STATUS };
	constructor(public usersService: UsersService,
		public commonService: CommonService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService,
		public storageService: StorageService,
		public orderService: OrderService,
		public tokenService: TokenService) {
		const authUserPermission = this.tokenService.getPermission();
		this.isPermission = this.commonService.isPermission(authUserPermission, PERMISSIONS.APPROVE_REJECT_FLEET_OWNER_ACCOUNT);
		this.isPrintOrderPermission = authUserPermission.name == ROLES.CORPORATE;
	}

	ngOnInit(): void {
		const selectedTab = this.storageService.getItem("currentTab");
		this.selectedTab = selectedTab ? selectedTab : this.tabId.FLEET;
		this.storageService.removeItem("currentTab");
		this.getRoutingParams();
		this.filterInputOptions();
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ fleetOwnerId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.fleetOwnerId = results.fleetOwnerId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.searchFleetId = results.query.userId ? results.query.userId : "";
				this.fleetOwners = [];
				this.fleetOwnerId ? this.getFleetAssociateDetails() : this.fetchFleetowner();
			});
	}

	getFleetAssociateDetails() {
		this.isLoading = true;
		this.loader.show();
		this.usersService.getFleetAssociateDetails(this.fleetOwnerId).subscribe(res => {
			if (res && res.success && res.response) {
				this.selectedFleetOwner = res.response;
			}
			this.isLoading = false;
			this.loader.hide();
		}, error => {
			this.isLoading = false;
			this.loader.hide();
		});
	}

	fetchFleetowner() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.isLoading = true;
		this.usersService.getFleetowner(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.fleetOwners = res.response.data ? res.response.data : [];
			}
			else
				this.fleetOwners = [];
			this.isLoading = false;
		}, error => {
			this.fleetOwners = [];
			this.isLoading = false;
		});
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	viewFleetDetails(fleetOwner) {
		this.router.navigate([this.activatedRouteValues.viewURL, fleetOwner._id], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	viewAssociateDetails(fleetDetails, index) {
		if (this.selectedAssociateDetails && this.selectedRowIndex == index) {
			this.selectedRowIndex = "";
			this.selectedAssociateDetails = "";
			return;
		}
		this.selectedAssociateDetails = fleetDetails;
		this.selectedRowIndex = index;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fleetOwners = [];
		this.fetchFleetowner();
	}

	onClick(flag, data, index) {
		const payload = {
			isVerified: flag,
			isRejected: !flag
		}
		this.usersService.manageFleetOwners(data._id, payload).subscribe(res => {
			if (res && res.success && res.response) {
				if (!data.isVerified && !data.isRejected && !flag) {
					this.fleetOwners.splice(index, 1);
					this.paginate.totalItems -= 1;
				}
				else
					this.fleetOwners[index] = res.response;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		}
		);
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.fleetOwners = [];
		this.fetchFleetowner();
	}

	goBack() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	viewOrder(associate, url) {
		this.storageService.setItem('fleetId', this.selectedFleetOwner.fleet._id);
		this.storageService.setItem("currentTab", this.tabId.ASSOCIATE);
		this.router.navigate([url, associate._id], { queryParams: this.queryParamsObj() });
	}

	filterInputOptions() {
		delete this.inputOptions['PENDING'];
		delete this.inputOptions['DECLINED'];
		for (var key in this.inputOptions) {
			this.inputOptions[key] = this.inputOptions[key].charAt(0).toUpperCase() + this.inputOptions[key].substring(1)
		}
	}

	openDownloadBox(associate) {
		Swal.fire({
			title: TITLE.ORDER_STATUS_SELECTION,
			input: 'select',
			inputOptions: this.inputOptions,
			inputPlaceholder: TITLE.ORDER_STATUS_SELECTION,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				this.downloadCSVOrderDetails(associate, confirm.value);
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	downloadCSVOrderDetails(associate, status) {
		const queryParams = "?status=" + status.toLowerCase();
		this.orderService.getAssociateOrdersCSVFile(associate._id, queryParams).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});
				var a = window.document.createElement("a");
				a.href = window.URL.createObjectURL(blob);
				a.download = `orders-${associate.uniqueIdentity}.csv`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}
}