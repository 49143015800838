import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ImageCropperModule } from 'ngx-img-cropper';

import {
  ProfileComponent,
  ChangePasswordComponent, BasicInfoComponent, EmergencyContactsComponent,
  UploadDocumentsComponent, GuarantorsComponent, IndependentCardManagementComponent
} from '../../index';

const COMPONETNS = [
  ProfileComponent,
  ChangePasswordComponent, BasicInfoComponent, EmergencyContactsComponent,
  UploadDocumentsComponent, GuarantorsComponent, IndependentCardManagementComponent
];

@NgModule({
  declarations: [COMPONETNS],
  imports: [
    ProfileRoutingModule, ImageCropperModule,
    SharedModule
  ]
})
export class ProfileModule { }
