import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { OrderRoutingModule, ROUTING_COMPONENTS } from './order-routing.module';

const MODULES = [
	SharedModule, CommonModule, OrderRoutingModule
];

@NgModule({
	declarations: [ROUTING_COMPONENTS],
	imports: [MODULES]
})
export class OrderModule { }
