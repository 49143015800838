import { Component, OnInit } from '@angular/core';
import { CylinderService, LoaderService, CommonService } from '../../../../__services';
import { ORDER_TYPE, ORDER_VALUES } from '../../../../__utilities/orders';

@Component({
	selector: 'app-current-price',
	templateUrl: './current-price.component.html',
	styleUrls: ['./current-price.component.css']
})
export class CurrentPriceComponent implements OnInit {
	cylinderCategories = [];
	orderTypes: object = ORDER_TYPE;
	orderValues: object = ORDER_VALUES;
	constructor(
		public cylinderService: CylinderService,
		public loader: LoaderService,
		public commonService: CommonService
	) { }

	ngOnInit(): void {
		this.loader.show();
		this.fetchCylinderCategory();
	}

	fetchCylinderCategory() {
		this.cylinderService.fetchCylinderCategory().subscribe(res => {
			this.cylinderCategories = res && res.success && res.response ? res.response : [];
			this.loader.hide();
		}, error => {
			this.loader.hide();
			this.cylinderCategories = [];
		});
	}

}
