import { Component, OnInit, TemplateRef, Input, ViewChild, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService } from '../../__services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MESSAGE } from '../../__utilities/messages';
declare var $: any;

@Component({
	selector: 'app-cart-order',
	templateUrl: './cart-order.component.html',
	styleUrls: ['./cart-order.component.css']
})
export class CartOrderComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('template') modalTemplate: TemplateRef<any>;
	cartOrderForm: UntypedFormGroup;
	modalRef: BsModalRef;
	isWalletPaymentMode: boolean = false;
	isWalletAmountUsed: boolean = false;
	creditPoint: number = 0;
	isCounter: boolean = false;
	otpBtnText: string = "Send OTP";
	isOtpBtnDisabled: boolean = false;
	interval: any = "";
	isLoading: boolean = false;
	isUnknownLocation: boolean = false;
	location: any = {
		type: 'Point',
		coordinates: []
	};
	options = {
		types: ['address'],
	};
	address: any = { houseNo: "", address: "" };
	isDisabled: boolean = false;
	isOTPReceived: boolean = false;
	subscription: any;

	@Input() walkinId: string = "";
	@Input() plantId: string = "";
	@Input() commonService: any = "";
	@Input() orderService: any = "";
	@Input() cartTotal: number = 0;
	@Input() storageService: any = "";
	@Input() cartKey: string = "";
	@Input() selectedWalkin: any = "";
	@Output() orderPlaced = new EventEmitter<any>();

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private fb: UntypedFormBuilder,
		public validationService: ValidationService,
		private modalService: BsModalService
	) { }

	ngOnInit(): void {
		this.initializeFormControls();
	}

	// Initialize Form Controls
	initializeFormControls() {
		this.cartOrderForm = this.fb.group({
			paymentMode: ['', Validators.compose([Validators.required])],
			walletAmount: ['', Validators.compose([])],
			otp: ['', Validators.compose([])],
			address: ['', Validators.compose([Validators.required])]
		});
	}

	// Open Modal
	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false });
		this.resetForm();
	}

	// Close Modal
	closeModal() {
		this.modalRef.hide();
	}

	// On Payment Mode Change
	onPaymentModeChange(event) {
		this.isOTPReceived = this.isWalletPaymentMode = false;
		this.isWalletPaymentMode = event.target.value == "walletTransfer";
		if (this.isWalletPaymentMode) {
			$("#isWalletAmount").prop("checked") ? false : $("#isWalletAmount").click();
		}
		else {
			this.manageWalletAmountValidator(false);
			this.isWalletAmountUsed = false;
		}
	}

	// Manage Wallet Amout Validations
	manageWalletAmountValidator(validate) {
		if (validate)
			this.cartOrderForm.controls.walletAmount.setValidators(Validators.required);
		else
			this.cartOrderForm.controls.walletAmount.clearValidators();
		this.cartOrderForm.controls.walletAmount.updateValueAndValidity();
	}

	// Validate Amount
	validateAmount(event) {
		const amount = event.target.value ? parseInt(event.target.value) : 0;
		if ((this.creditPoint && !amount) || amount > this.creditPoint || amount > this.cartTotal)
			this.cartOrderForm.controls.walletAmount.setValue("");
	}

	// Use Wallet Amount
	useWalletAmount(event) {
		this.isCounter = false;
		this.otpBtnText = "Send OTP";
		this.isOtpBtnDisabled = false;
		this.clearTimer();
		if (event.target.checked) {
			this.isLoading = true;
			this.orderService.fetchCreditPoint(this.walkinId).subscribe(res => {
				if (res && res.success && res.response && res.response.creditpoint) {
					this.creditPoint = res.response.creditpoint.totalPoint;
				}
				if (this.isWalletPaymentMode && !this.creditPoint)
					this.commonService.growlError(MESSAGE.INVALID_PAYMENT_MODE);
				this.isWalletAmountUsed = true;
				this.manageWalletAmountValidator(this.creditPoint ? true : false);
				this.isLoading = false;
			}, error => {
				this.manageWalletAmountValidator(false);
				this.isWalletAmountUsed = false;
				this.commonService.growlError(error.message);
				this.isLoading = false;
			});
		}
		else {
			this.manageWalletAmountValidator(false);
			this.cartOrderForm.controls.walletAmount.setValue("");
			this.manageOtpAmountValidator(false);
			this.cartOrderForm.controls.otp.setValue("");
			this.creditPoint = 0;
			this.isWalletAmountUsed = false;
		}
	}

	// Handle Address Event
	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.setAddress(this.address);
		}
		else {
			this.setAddress("");
			this.isUnknownLocation = true;
		}
	}

	// Set Address
	setAddress(address) {
		this.cartOrderForm.controls.address.setValue(address);
	}

	// Clear OTP Counter
	clearTimer() {
		this.interval && clearInterval(this.interval);
	}

	// Manage OTP Amount Validations
	manageOtpAmountValidator(validate) {
		if (validate)
			this.cartOrderForm.controls.otp.setValidators(Validators.required);
		else
			this.cartOrderForm.controls.otp.clearValidators();
		this.cartOrderForm.controls.otp.updateValueAndValidity();
	}

	// Send OTP
	sendOTP() {
		this.isOtpBtnDisabled = true;
		this.setCounter();
		this.orderService.sendOTP({ userId: this.walkinId }).subscribe(res => {
			this.isDisabled = false;
			this.isOTPReceived = true;
			this.isOtpBtnDisabled = false;
			this.manageOtpAmountValidator(true);
		}, error => {
			this.isOtpBtnDisabled = false;
			this.manageOtpAmountValidator(false);
			this.isOTPReceived = false;
			this.commonService.growlError(error.message);
		});
	}

	// Set 1-Minute Counter On OTP Send
	setCounter() {
		var counter = 60;
		var _this = this;
		this.interval = setInterval(() => {
			_this.isCounter = true;
			counter -= 1;
			_this.otpBtnText = '00:' + (counter < 10 ? '0' + counter : counter);
			if (counter == -1) {
				clearInterval(_this.interval);
				_this.isCounter = false;
				_this.otpBtnText = "RESEND OTP";
			}
		}, 1000)
	}

	checkEqualAmount(walletAmount) {
		const cartItems = this.getCartItems();
		let totalAmount = 0;
		cartItems.forEach(element => {
			totalAmount += element.charge
		});
		return totalAmount !== walletAmount ? MESSAGE.WALLET_AMOUNT_EQUAL_TO_TOTAL : "";
	}

	getCartItems() {
		let cartItems = this.storageService.getItem(this.cartKey);
		return cartItems[this.walkinId];
	}

	// Submit plant form
	onSubmit(orderData) {

		if ((this.selectedWalkin.isDeleted || this.selectedWalkin.isRejected) || (this.isWalletAmountUsed && this.creditPoint && !orderData.otp))
			return;

		if (this.isWalletAmountUsed && orderData.paymentMode == "walletTransfer") {
			const message = !this.creditPoint ? MESSAGE.INVALID_PAYMENT_MODE : this.checkEqualAmount(orderData.walletAmount);
			if (message) {
				this.commonService.growlError(message);
				return;
			}
		}

		this.isUnknownLocation = Object.keys(this.location.coordinates).length ? false : true;
		if (this.cartOrderForm.valid) {
			if (!this.isUnknownLocation) {
				let inputAddress = (this.cartOrderForm.controls.address.value).trim();
				this.isUnknownLocation = inputAddress != (this.address).trim();
			} else
				return;

			orderData = this.createPayload(orderData);
			this.isDisabled = true;
			this.orderService.createWalkinOrders(orderData).subscribe(res => {
				if (res && res.success && res.response) {
					this.orderPlaced.emit(res.response.data);
					this.closeModal();
				}
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.isUnknownLocation = !this.cartOrderForm.controls.address.value ? false : this.isUnknownLocation;
			this.validationService.validateAllFormFields(this.cartOrderForm);
		}
	}

	// Create Cart Order Payload
	createPayload(orderData) {
		orderData.userId = this.walkinId;
		orderData.plantId = this.plantId;
		orderData.address = { houseNo: "", address: this.address };
		orderData.location = this.location;
		if (this.isWalletAmountUsed && this.creditPoint) {
			orderData.isUseWallet = true;
			orderData.useWalletAmount = orderData.walletAmount;
		}
		else {
			orderData.isUseWallet = false;
			delete orderData.otp;
		}
		orderData.isConnection = true;
		orderData.items = this.getCartItems();
		return orderData;
	}

	// Reset Form
	resetForm() {
		this.cartOrderForm.reset();
		this.isUnknownLocation = false;
		this.cartOrderForm.controls.paymentMode.setValue("");
		this.resetWalletField();
		this.manageWalletAmountValidator(false);
		this.manageOtpAmountValidator(false);
	}

	// Reset Wallet Related Field
	resetWalletField() {
		this.isWalletAmountUsed = false;
		this.isWalletPaymentMode = false;
		this.creditPoint = 0;
		this.isOTPReceived = false;
		this.isDisabled = false;
		this.isOtpBtnDisabled = false;
		this.otpBtnText = "Send OTP";
		this.isCounter = false;
	}

	// Subscribe To Observer
	ngAfterViewInit() {
		this.subscribeToCartModal();
	}

	// Cart Modal Subscription
	subscribeToCartModal() {
		this.subscription = this.commonService.observeCartModal().subscribe(res => {
			if (res)
				this.openModal(this.modalTemplate);
		});
	}

	// Unsubscribe To Observer
	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}