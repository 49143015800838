import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Observable, Subject} from 'rxjs';
import { environment } from '../../environments/environment';
import { PERMISSIONS } from '../__utilities/permissions';
import { ROLES } from '../__utilities/roles';
import { ORDER_MODE } from '../__utilities/orders';
declare var $;

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	public hostUrl = environment.API_HOST;
	public profileSubject = new Subject<any>();
	public routeReuse = new Subject<any>();
	public notificationEmitter = new Subject<any>();
	public permissions: any = PERMISSIONS;
	public orderStatus: any = new Subject<any>();
	public orderStatusChange: any = new Subject<any>();
	public breadCrumb: any = new Subject<any>();
	public cartItems = new Subject<number>();
	public cartOrderModal = new Subject<Boolean>();
	public bankDetailsSubscription = new Subject<any>();
	constructor(
	) {
	}

	swal(isSuccess, message) {
		Swal.fire({
			icon: isSuccess ? 'success' : 'error',
			text: message,
			customClass: { container: 'custom-success' }
		})
	}

	// Growl Success
	growlSuccess(message) {
		$.growl.notice({ title: "Success", message: message });
	}

	// Growl Error
	growlError(message) {
		$.growl.error({ title: "Error", message: message });
	}

	// Send Route Changes
	sendRouteChanges(data) {
		this.breadCrumb.next(data);
	}

	getRouteChanges(): Observable<any> {
		return this.breadCrumb.asObservable();
	}

	// Bank Details Subscription
	sendBankDetails(data) {
		this.bankDetailsSubscription.next(data);
	}

	bankDetailSubscription(): Observable<any> {
		return this.bankDetailsSubscription.asObservable();
	}

	// Subscribe 
	sendProfileUpdates(data) {
		this.profileSubject.next(data);
	}

	// Get latest profile updates
	getProfileUpdates(): Observable<any> {
		return this.profileSubject.asObservable();
	}

	setCurrentOrderKey(key) {
		this.orderStatus.next(key);
	}

	getCurrentOrderKey(): Observable<any> {
		return this.orderStatus.asObservable();
	}

	// Set Cart Total
	setCartTotal(count) {
		this.cartItems.next(count);
	}

	// Fetch Cart Total
	getCartTotal(): Observable<number> {
		return this.cartItems.asObservable();
	}

	// Open Cart Modal
	openCartModal(data) {
		this.cartOrderModal.next(data);
	}

	// Listen to cart modal
	observeCartModal() {
		return this.cartOrderModal.asObservable();
	}

	// Default image url (Profile Image)
	defaultImageURL(event) {
		return event.target.src = 'assets/img/avatars/userteam.svg';
	}

	// Default image url (Profile Image)
	defaultTankURL(event) {
		return event.target.src = 'assets/img/avatars/userteam.svg';
	}

	// Default Regulator url
	defaultRegulatorImage(event) {
		return event.target.src = 'assets/img/avatars/regulator.jpg';
	}

	// Default id proof image
	defaultIDURL(event) {
		return event.target.src = 'assets/img/avatars/identity.png';
	}

	// Convert Date
	convertDate(date) {
		if (date) {
			var now: any = new Date(date);
			let month = now.getMonth() + 1;
			month = month.toString().length > 1 ? month : ('0' + month);
			date = now.getDate();
			date = date.toString().length > 1 ? date : ('0' + date);
			return now.getFullYear() + "-" + month + "-" + date;
		}
		return null;
	}

	convertDateTime(date) {
		if (date) {
			let myDate: any = new Date(date);
			myDate = myDate.toLocaleString();
			myDate = myDate.split(",");
			return myDate[0] + "<br>" + myDate[1];
		} else {
			return null;
		}
	}

	urltoFile(url, filename, mimeType) {
		return (fetch(url)
			.then(function (res) { return res.arrayBuffer(); })
			.then(function (buf) { return new File([buf], filename, { type: mimeType }); })
		);
	}

	// Scroll to top
	scrollTop() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}

	// Set input type file placeholder
	setFilePlaceholder(inputId, placeholder) {
		$('#' + inputId).parent().find('p').html(placeholder);
	}

	// Download File
	downloadFile(fileURL) {
		var xhr = new XMLHttpRequest();
		xhr.open("GET", fileURL, true);
		xhr.responseType = "blob";
		xhr.onload = function (e) {
			if (this.status === 200) {
				var reader = new FileReader();
				reader.onload = function (e) {
					var link: any = document.createElement('a') as HTMLElement;
					link.setAttribute('href', reader.result); //e.target.result not used , Gives error on build
					link.setAttribute('download', 'document');
					link.style.display = 'none';
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				};
				reader.readAsDataURL(this.response);
			};
		};
		xhr.send();
	}

	disableDate() {
		const currentDate = new Date();
		let month: any = currentDate.getMonth() + 1;
		month = month > 10 ? month : ('0' + month);
		const date = currentDate.getDate() > 10 ? currentDate.getDate() : ('0' + currentDate.getDate());
		return currentDate.getFullYear() + "-" + month + "-" + date;
	}

	sortObj(size, obj) {
		let value = 0;
		obj.forEach(element => {
			if (element.size == size)
				value = element.numberOfCylinder;
		});
		return value;
	}

	sortByProperty(property) {
		return function (a, b) {
			if (a[property] > b[property])
				return 1;
			else if (a[property] < b[property])
				return -1;
			return 0;
		}
	}

	isPermission(authUser, value) {
		const isCorporateUser = Object.values(ROLES).indexOf(authUser.name) > -1;
		return isCorporateUser ? true : ((authUser.permission && (typeof authUser.permission == "object") && authUser.permission.length) ? authUser.permission.indexOf(value) > -1 : false);
	}

	// Thumbnail URL
	thumbnailURL(imageURL) {
		if (imageURL)
			imageURL = imageURL.replace('/images/', '/thumbnail/');
		return this.hostUrl + imageURL;
	}

	// Default Licence Image 
	defaultLicenceImage(event, image, isPreview) {
		const defaultURL = "assets/img/avatars/identity.png";
		if (!image) return defaultURL;
		if (isPreview && image)
			image = image.replace('/images/', '/thumbnail/');
		var http = new XMLHttpRequest();
		http.open('HEAD', this.hostUrl + image, false);
		http.send();
		return event.target.src = http.status != 404 ? (this.hostUrl + image) : defaultURL;
	}

	calculateCost(item) {
		return (
			(item.totalCost ? item.totalCost : 0)
			+ (item.deliveryCharge ? item.deliveryCharge : 0)
			+ (item.transactionFees ? item.transactionFees : 0)
			+ (item.deliveryMode && item.orderMode == ORDER_MODE.online && item.travelCharge ? item.travelCharge : 0)
		).toFixed(1);
	}

	calculateSaleOrderCost(item) {
		return item.totalCost ? item.totalCost : 0;
	}

	capitalizeString(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	replaceString(replaceStr, fromString) {
		const regex = new RegExp(replaceStr, 'g');
		return fromString.replace(regex, ' ');
	}

	formatContactNumber(number) {
		return number.replace(/\D+/g, '');
	}

	base64ToFile(data, filename) {
		const arr = data.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		let u8arr = new Uint8Array(n);
	
		while(n--){
			u8arr[n] = bstr.charCodeAt(n);
		}
	
		return new File([u8arr], filename, { type: mime });
	  }
}