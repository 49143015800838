import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService, CommonService, LoaderService, TokenService, ValidationService, OrderService } from '../../../../__services';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { PHONE_CONFIG, DEFAULT_COUNTRY } from '../../../../__utilities/phone';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE, BTN_TEXT } from '../../../../__utilities/text';
import Swal from 'sweetalert2';
import { MESSAGE } from '../../../../__utilities/messages';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { ROLES } from '../../../../__utilities/roles';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-walkin',
	templateUrl: './walkin.component.html',
	styleUrls: ['./walkin.component.css']
})
export class WalkinComponent implements OnInit {

	paginate: any = { ...PAGINATION };
	isLoading: boolean = false;
	searchStr: string = '';
	walkins = [];
	heading: string = TITLE.WALKIN;
	walkinId: string = '';
	noRecord: string = '';
	isPermission: boolean = false;
	authUserPermission: any;
	isAdd: boolean = false;
	isEdit: boolean = false;
	walkinForm: UntypedFormGroup;
	editWalkinForm: UntypedFormGroup;
	isSubmitted: boolean = false;
	isDisabled: boolean = false;
	btnText: string = BTN_TEXT.ADD_WALKIN;
	permissions: any = PERMISSIONS;
	phoneConfig = PHONE_CONFIG;
	selectedIndex: number = 0;
	selectedUser: any = "";
	options = {
		types: ['address'],
	};
	address: string;
	location: any = {
		type: 'Point',
		coordinates: []
	};
	isUnknownLocation: boolean = false;
	isHubOrDistributor: boolean = false;
	isPrintOrderPermission: boolean = false;
	activatedRouteValues: any = "";
	constructor(
		public usersService: UsersService,
		public commonService: CommonService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService,
		public tokenService: TokenService,
		public orderService: OrderService,
		private fb: UntypedFormBuilder,
		public validationService: ValidationService,
	) {
		this.getRoutingParams();
		this.authUserPermission = this.tokenService.getPermission();
		this.isHubOrDistributor = this.authUserPermission.name == ROLES.PLANT_MANAGER || this.authUserPermission.name == ROLES.DISTRIBUTOR;
		this.isPrintOrderPermission = this.authUserPermission.name == ROLES.CORPORATE;
		this.initializeControls();
	}

	ngOnInit(): void {
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ walkinId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.walkinId = results.walkinId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				if (!this.walkinId)
					this.fetchWalkin();
			});
	}

	fetchWalkin() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.isLoading = true;
		this.usersService.getWalkin(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.walkins = res.response.data ? res.response.data : [];
			}
			else
				this.walkins = [];
			this.isLoading = false;
		}, error => {
			this.walkins = [];
			this.isLoading = false;
		});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fetchWalkin();
	}

	resetPagination() {
		this.paginate.currentPage = 1;
		this.paginate.totalItems = 0;
		this.walkins = [];
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.walkins = [];
		this.fetchWalkin();
	}

	addMore(isAdd) {
		this.isAdd = isAdd;
	}

	initializeControls() {
		this.walkinForm = this.fb.group({
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			contact: ['', Validators.compose([Validators.required])],
			address: ['', Validators.compose([Validators.required])],
		});
		this.editWalkinForm = this.fb.group({
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			contact: ['', Validators.compose([Validators.required])],
			address: ['', Validators.compose([Validators.required])],
		});
	}

	// Submit plant form
	onSubmit(userData) {
		this.isSubmitted = true;
		if (this.walkinForm.valid) {
			userData = this.createPayload(userData);
			this.isDisabled = true;
			this.usersService.addWalkinUser(userData).subscribe(res => {
				if (res && res.success) {
					this.walkins.unshift(res.User);
					this.paginate.totalItems += 1;
					this.resetForm();
				}
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.isSubmitted = false;
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.walkinForm);
		}
	}

	resetForm() {
		this.isSubmitted = false;
		this.walkinForm.reset();
		this.isEdit = false;
		this.isAdd = false;
		this.phoneConfig.CountryISO = DEFAULT_COUNTRY;
	}

	createPayload(userData) {
		userData.location = Object.keys(this.location.coordinates).length ? this.location : (this.selectedUser ? this.selectedUser.location : '');
		if (typeof userData.contact == "object") {
			userData.countryCode = userData.contact.countryCode;
			userData.dialCode = userData.contact.dialCode;
			userData.contact = this.commonService.formatContactNumber(userData.contact.number);
		}
		if (this.isEdit) {
			userData.address = this.address ? this.address : (this.selectedUser ? this.selectedUser.address : '');
		} else {
			userData.address = { address: this.address ? this.address : (this.selectedUser ? this.selectedUser.address : '') }
		}
		return userData;
	}

	// Delete User
	deleteUser(user, index) {
		Swal.fire({
			title: MESSAGE.DELETE_USER_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_USER_CONFIRMATION_TEXT + user.firstName + ' ' + user.lastName + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedUser(user._id, index);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Delete user
	deleteSelectedUser(walkinId, index) {
		this.usersService.deleteWalkinUsers(walkinId).subscribe(res => {
			if (res && res.success) {
				this.walkins.splice(index, 1);
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// Edit User
	editUser(user, index) {
		this.selectedIndex = index;
		this.selectedUser = user;
		let address = user.address && user.address.address ? user.address.address : "";
		this.editWalkinForm.controls['firstName'].setValue(user.firstName);
		this.editWalkinForm.controls['lastName'].setValue(user.lastName);
		this.phoneConfig.CountryISO = user.countryCode;
		this.editWalkinForm.controls['contact'].setValue(user.contact);
		this.editWalkinForm.controls["address"].setValue(address);
		if (user.location && user.location.coordinates && user.location.coordinates.length) {
			this.location.coordinates[0] = user.location.coordinates[0];
			this.location.coordinates[1] = user.location.coordinates[1];
		}
		this.isEdit = true;
	}

	// On walkin update
	updateUser(userData) {
		if (this.editWalkinForm.valid) {
			this.isDisabled = true;
			userData = this.createPayload(userData);
			this.usersService.updateWalkinUser(userData, this.selectedUser._id).subscribe(res => {
				if (res && res.success) {
					this.walkins[this.selectedIndex] = res.response;
					this.resetForm();
				}
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.editWalkinForm);
		}
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.isEdit ? this.editWalkinForm.controls.address.setValue(this.address) : this.walkinForm.controls.address.setValue(this.address);
		}
		else {
			this.isEdit ? this.editWalkinForm.controls.address.setValue('') : this.walkinForm.controls.address.setValue('');
			this.isUnknownLocation = true;
		}
	}

	viewWalkinUser(walkinUser) {
		this.router.navigate([this.activatedRouteValues.viewURL, walkinUser._id], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	downloadCSVOrderDetails(client) {
		this.orderService.getClientOrdersCSVFile(client._id).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});
			// if (window.navigator.msSaveOrOpenBlob) {
			// 	window.navigator.msSaveBlob(blob, `orders-${client.uniqueIdentity}.csv`);
			// } else {
				var a = window.document.createElement("a");
				a.href = window.URL.createObjectURL(blob);
				a.download = `orders-${client.uniqueIdentity}.csv`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			// }
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}
}
