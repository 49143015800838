import { Component, OnInit, Input } from '@angular/core';
import { PAYMENT_MODE, CLIENT_PAYMENT_MODE } from '../../__utilities/orders';
@Component({
	selector: 'app-multi-order-view',
	templateUrl: './multi-order-view.component.html',
	styleUrls: ['./multi-order-view.component.css']
})
export class MultiOrderViewComponent implements OnInit {

	@Input() orders: any = [];
	@Input() ordersText: object;
	@Input() commonService: any;
	remainder: number = 0;
	paymentMode: object;
	constructor() {
		this.paymentMode = Object.assign(PAYMENT_MODE, CLIENT_PAYMENT_MODE);
	}
	ngOnInit(): void {
		const count = this.orders.items.length;
		this.remainder = count ? count % 2 == 0 : count;
	}

}
