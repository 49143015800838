import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WarehouseComponent } from '../../index';
import { AuthGuard } from '../../../__guard/auth.guard';
import { ROLES } from '../../../__utilities/roles';
import { TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';

const routes: Routes = [
	{
		path: '',
		component: WarehouseComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.WAREHOUSE,
			viewURL:'/warehouses/warehouse-details/',
			breadcrumb: [
				{
					label: TITLE.WAREHOUSE,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_WAREHOUSE, ROLES.CORPORATE]
		}
	},
	{
		path: 'warehouse-details/:id',
		component: WarehouseComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.WAREHOUSE_DETAILS,
			breadcrumb: [
				{
					label: TITLE.WAREHOUSE,
					url: '/warehouses'
				},
				{
					label: TITLE.WAREHOUSE_DETAILS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_WAREHOUSE, ROLES.CORPORATE]
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class WarehouseRoutingModule { }

export const ROUTING_COMPONENTS = [
	WarehouseComponent
];