// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  API_HOST: 'https://smartgas-api.encoreskydev.com',
  VAPID_PUBLIC: "BLdF8foyihY35De1XAJDjb8qlva3Jc059POG82f3TBH6-DhnLuRu53hW--qdr-qoFeVCyb_jrexXkdCYPeFbp7o",
  AGM_KEY: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ',
  CAPTCHA_SITE_KEY: '6LckQzcqAAAAAJDq8uSgKQuO3NII_iv1Je295P9_',
  CARD_ENCRYPTION_KEY: '!@#$%$sg@estdev!'
};
