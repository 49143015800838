import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SettingService, CommonService, ValidationService, StorageService, CylinderService } from '../../../../__services';
import { ROLES } from '../../../../__utilities/roles';
import Swal from 'sweetalert2';
import { MESSAGE } from '../../../../__utilities/messages';

@Component({
  selector: 'app-cylinder-brand-category',
  templateUrl: './cylinder-brand-category.component.html',
  styleUrls: ['./cylinder-brand-category.component.scss']
})
export class CylinderBrandCategoryComponent implements OnInit {

  isBrandAdd: boolean = false;
  brandCategoryForm: UntypedFormGroup;
  isCorporate: boolean;
  authUser: any;
  roles: any = ROLES;
  brandNames = [];

  constructor(
    private fb: UntypedFormBuilder,
    public settingService: SettingService,
    public commonService: CommonService,
    public validationService: ValidationService,
    public storageService: StorageService,
    public cylinderService: CylinderService,

  ) {
    this.authUser = this.storageService.getItem('userObj');
    this.initializeControls();
    this.isCorporate = (this.authUser.roleId.name == ROLES.CORPORATE) ? true : false;
  }

  ngOnInit(): void {
    this.fetchBrands();
  }

  addBrand() {
    this.isBrandAdd = true;
  }

  // Reset form
  resetForm() {
    this.isBrandAdd = false;
    this.brandCategoryForm.reset();
  }

  initializeControls() {
    this.brandCategoryForm = this.fb.group({
      brandName: ['', Validators.compose([Validators.required])]
    });
  }

  // Submit form
  onSubmit(tankAttribute) {
    const payload = {
      brandName: tankAttribute.brandName
    }

    if (this.brandCategoryForm.valid) {
      this.settingService.addBrandCategory(payload).subscribe(res => {
        if (res && res.success) {
          this.isBrandAdd = false;
          this.fetchBrands();
        }
        this.resetForm();
        this.commonService.growlSuccess(res.message);
      }, error => {
        this.commonService.growlError(error.message);
      });
    }
    else {
      this.validationService.validateAllFormFields(this.brandCategoryForm);
    }
  }

  //Fetch Brand Names
  fetchBrands() {
    if(this.isCorporate){
      this.settingService.fetchBrandsCorporate().subscribe(res => {
        if (res && res.success && res.response) {
          this.brandNames = res.response.data;
        }
      }, error => {
        
      });
    }
    else{
      this.settingService.fetchBrandsIgr(this.authUser.independent._id).subscribe(res => {
        if (res && res.success && res.response) {
          this.brandNames = res.response.data;
        }
      }, error => {
        
      });
    }
  }

  //Delete Brands
  deleteBrands(id) {
    Swal.fire({
			title: MESSAGE.DELETE_BRAND_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_BRAND_CONFIRMATION_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.settingService.deleteBrands(id).subscribe(res => {
          if (res && res.success && res.response) {
            this.brandNames.forEach((element, index) => {
              if (element._id == id)
                this.brandNames.splice(index, 1);
            });
            this.fetchBrands();
            this.commonService.growlSuccess(res.message);
            
          }
        }, error => {
          this.commonService.growlError(error.message);
        });
			}
		});
  }

  enableBrandCorporate(val,id){
    const payload = {
      "isEnable" : val
    }    
    this.settingService.enableBrand(payload , id).subscribe(res => {
      if (res && res.success && res.response) {
        this.fetchBrands();
        this.commonService.growlSuccess(res.message);
      }
    }, error => {
      this.commonService.growlError(error.message);
    });
  }

  enableBrandIgr(val,id){
    console.log("id",id);
    
    const payload = {
      "retailerId" : this.authUser.independent._id,
      "isEnable" : val
    }    
    this.settingService.enableBrandIgr(payload ,id).subscribe(res => {
      if (res && res.success && res.response) {
        this.fetchBrands();
        this.commonService.growlSuccess(res.message);
      }
    }, error => {
      this.commonService.growlError(error.message);
    });
  }

}
