import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonService, OrderService, StorageService, WebSocketService, LoaderService } from '../../__services';
import { PAGINATION } from '../../__utilities/pagination';
import { MESSAGE } from '../../__utilities/messages';
import { Router, ActivatedRoute } from '@angular/router';
import { TITLE } from '../../__utilities/text';
import { ORDER_VALUES, ORDER_STATUS, ORDER_MODE, COMPONENT_KEYS } from '../../__utilities/orders';
import Swal from 'sweetalert2';
import { ROLES } from '../../__utilities/roles';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $;

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, AfterViewInit, OnDestroy {

	paginate: any = { ...PAGINATION };
	ordersText: any = ORDER_VALUES;
	orderStatus: any = { ...ORDER_STATUS };
	refillOrders: any = [];
	isLoading: boolean = false;
	searchStr: string = '';
	selectedRefillOrder: any = '';
	roles: any = ROLES;
	authUser: any;
	heading: string = TITLE.PICKUP_ORDERS;
	isFiterOption: boolean = false;
	filterOptionValue: boolean = false;
	reviewTo: string;
	selectedIndex: number;
	isChanges: boolean = false;
	isCorporate: boolean = false;
	isPlantOrDistributor: boolean = false;
	filterOptions: string = "";
	refillOrderId: string = "";
	currentOption: any = "";
	noRecord: string = "";
	isInvoice: boolean = false;
	paymentDetails: any = "";
	orderMode: string = "";
	isInvoiceSending: boolean = false;
	isInvoiceLoading: boolean = false;
	orderModes: any = ORDER_MODE;
	activatedRouteValue: any = "";
	searchOrderId: string = "";
	statusFilter: string = "";
	isOrders: boolean = false;
	isIGR: boolean = false;
	retailerId: string = "";
	prevPageId: number = 0;
	isDownloadInProgress: boolean = false;
	isEmailInProgress: boolean = false;
	subscription: Subscription;
	paymentInfo: any = "";
	isPaymentStatus: boolean = false;
	constructor(public commonService: CommonService,
		public storageService: StorageService,
		public activatedRoute: ActivatedRoute,
		public socketService: WebSocketService,
		public router: Router,
		public loader: LoaderService,
		public orderService: OrderService,
		public cd: ChangeDetectorRef) {
		this.authUser = this.storageService.getItem('userObj');
		this.isCorporate = this.authUser.roleId.name == ROLES.CORPORATE;
		this.isPlantOrDistributor = [ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER, ROLES.INDEPENDENT_RETAILER].indexOf(this.authUser.roleId.name) > -1;
		delete this.orderStatus['DECLINED'];
	}

	ngOnInit(): void {
		this.getRoutingParams();
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValue = this.activatedRoute.data['value'];
		this.isInvoice = this.activatedRouteValue.invoice;
		this.isOrders = this.activatedRouteValue.isOrders;
		this.isIGR = this.activatedRouteValue.isIGR;
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ id: results[0].id, query: results[1] })))
			.subscribe(results => {
				if (this.isOrders) {
					this.retailerId = results.id;
					this.prevPageId = results.query.page ? results.query.page : 1;
				}
				else {
					this.retailerId = results.query.id ? results.query.id : "";
					this.refillOrderId = results.id;
					this.paginate.currentPage = results.query.page ? results.query.page : 1;
					this.setBreadCrumb();
				}
				this.searchStr = results.query.search ? results.query.search : "";
				this.orderMode = results.query.orderMode ? results.query.orderMode : "";
				this.searchOrderId = results.query.orderId ? results.query.orderId : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.refillOrders = [];
				this.refillOrderId ? this.fetchRefillderById() : this.fetchRefillOrders();
			});
	}

	setBreadCrumb() {
		var breadCrumb = JSON.parse(JSON.stringify(this.activatedRoute.data['value'].breadcrumb));
		breadCrumb[1].url += this.retailerId;
		let _this = this;
		setTimeout(() => {
			_this.commonService.sendRouteChanges(breadCrumb);
		}, 0);
	}

	viewOrder(order) {
		this.isOrders = false;
		this.router.navigate([this.activatedRouteValue.viewURL, order._id], { queryParams: this.queryParamsObj() });
	}

	fetchRefundStatus(order) {
		if (order.isCancelledByMe && order.isAssociateRequest) return;
		this.isPaymentStatus = true;
		if (order.isCancelled || order.isCancelledByMe || order.unableToDelivered) {
			let queryParams = order.paymentReferenceId ?
				("?paymentReferenceId=" + order.paymentReferenceId) :
				"?walletTransactionId=" + order.walletTransactionId;
			this.orderService.fetchPaymentStatus(queryParams).subscribe(res => {
				if (res && res.success && res.response) {
					this.paymentInfo = res.response.paymentReference;
				}
			}, error => {
				this.paymentInfo = "";
			});
		}
	}

	goBack(isMainList) {
		const retailerId = this.retailerId;
		this.retailerId = "";
		if (isMainList)
			this.router.navigate([this.activatedRouteValue.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
		else
			this.router.navigate([this.activatedRouteValue.breadcrumb[1].url, retailerId], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.isOrders ? this.prevPageId : this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.orderMode) queryParams.orderMode = this.orderMode;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		if (this.retailerId) queryParams.id = this.retailerId;
		return queryParams;
	}

	// Fetch refill order details by order id
	fetchRefillderById() {
		this.isLoading = true;
		this.loader.show();
		this.orderService.fetchCartOrderById(this.refillOrderId).subscribe(res => {
			if (res && res.success && res.response.data) {
				this.selectedRefillOrder = res.response.data;
				this.paymentDetails = this.isInvoice ? {
					serverPayment: res.response.serverPayment,
					walletPayment: res.response.walletPayment
				} : "";
				this.fetchRefundStatus(res.response.data);
			}
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
		}, error => {
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
			this.selectedRefillOrder = "";
		});
	}

	fetchRefillOrders() {
		this.isLoading = true;
		let searchQuery = this.queryParams();
		this.orderService.fetchCartOrders(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.refillOrders = res.response.data ? res.response.data : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
			}
			else
				this.refillOrders = [];
			this.isLoading = false;
		}, () => {
			this.isLoading = false;
			this.refillOrders = [];
		});
	}

	// Search Params
	queryParams() {
		let searchStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage
			+ "&plantId=" + this.retailerId + "&orderMode=" + this.orderMode;
		if (this.filterOptions)
			searchStr += this.filterOptions;
		if (this.statusFilter)
			searchStr += "&filter=" + this.statusFilter;
		return searchStr;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fetchRefillOrders();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
		this.refillOrders = [];
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.refillOrders = [];
		this.fetchRefillOrders();
	}

	filterOrders(event, value) {
		this.isFiterOption = event.target.checked;
		this.filterOptionValue = value;
		let _this = this;
		setTimeout(() => {
			_this.resetPagination();
			_this.fetchRefillOrders();
		}, 500);
	}

	onReview(reviewTo) {
		this.reviewTo = reviewTo;
		this.isChanges = false;
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		if (this.refillOrderId) return;
		const key = this.isCorporate ? COMPONENT_KEYS.CLIENT_CART_ORDER : COMPONENT_KEYS.CLIENT_CART_PICKUP_ORDER;
		this.commonService.setCurrentOrderKey(key);
		this.subscription = this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { })
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.searchStr = "";
		this.refillOrders = [];
		this.filterOptions = "";
		this.resetPagination();
		this.fetchRefillOrders();
	}

	// Search By day , Month, Week And Year
	onFilter(obj) {
		this.resetPagination();
		this.filterOptions = "&startDate=" + obj.startDate + "&endDate=" + obj.endDate;
		this.refillOrders = [];
		this.fetchRefillOrders();
	}

	onOrderModeChange(orderMode) {
		this.orderMode = orderMode;
		this.searchByStatus();
	}

	onOrderStatusChange(orderStatus) {
		this.statusFilter = orderStatus;
		this.searchByStatus();
	}

	searchByStatus() {
		this.searchStr = "";
		this.resetPagination();
		this.refillOrders = [];
		this.fetchRefillOrders();
	}

	// Email Invoice
	emailInvoice() {
		this.isInvoiceSending = true;
		this.orderService.emailInvoice(this.refillOrderId).subscribe(res => {
			this.commonService.growlSuccess(res.message);
			this.isInvoiceSending = false;
		}, error => {
			this.isInvoiceSending = false;
			this.commonService.growlError(error.message);
		});
	}

	printPDF() {
		this.isInvoiceLoading = true;
		this.orderService.fetchInvoice(this.refillOrderId).subscribe(res => {
			const blob = new Blob([res], {
				type: 'application/pdf'
			});
			const url = window.URL.createObjectURL(blob);
			window.open(url);
			this.isInvoiceLoading = false;
		}, error => {
			this.isInvoiceLoading = false;
			this.commonService.growlError(MESSAGE.ERROR);
		});
	}

	emailReport(noOfRecord) {
		this.isEmailInProgress = true;
		let queryParams = this.exportReportQueryParams();
		queryParams += "&limit=" + noOfRecord;
		this.orderService.emailReport(queryParams).subscribe(res => {
			if (res && res.success)
				this.commonService.growlSuccess(res.message);
			else
				this.commonService.growlError(MESSAGE.ORDER_REPORT_EMAIL_FAILED);
			this.isEmailInProgress = false;
		}, error => {
			this.isEmailInProgress = false;
			this.commonService.growlError(error.message);
		});
	}

	downloadReport() {
		this.isDownloadInProgress = true;
		let queryParams = this.exportReportQueryParams();
		this.orderService.downloadReport(queryParams).subscribe(res => {
			this.isDownloadInProgress = false;
			this.downloadCSV(res, 'Orders');
		}, error => {
			this.isDownloadInProgress = false;
			this.commonService.growlError(error.message);
		});
	}

	downloadCSV(res, fileName) {
		const blob = new Blob([res], {
			type: 'text/csv'
		});

		var a = window.document.createElement("a");
		a.href = window.URL.createObjectURL(blob);
		a.download = `${fileName}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	exportReportQueryParams() {
		let searchStr = "?search=" + this.searchStr + "&plantId=" + this.retailerId + "&orderMode=" + this.orderMode;
		if (this.filterOptions)
			searchStr += this.filterOptions;
		if (this.statusFilter)
			searchStr += "&filter=" + this.statusFilter;
		return searchStr;
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}
