import { Component, OnInit } from '@angular/core';
import { CommonService, OrderService, LoaderService, WebSocketService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_VALUES } from '../../../../__utilities/orders';

@Component({
	selector: 'app-live-orders',
	templateUrl: './live-orders.component.html',
	styleUrls: ['./live-orders.component.css'],
})
export class LiveOrdersComponent implements OnInit {

	heading: string = TITLE.LIVE_ORDER;
	liveOrders: any = [];
	paginate: any = PAGINATION;
	tabId: string = '';
	origin: any = { lat: 0, lng: 0 };
	destination: any = { lat: 0, lng: 0 };
	selectedOrder: any = '';
	isLoading: boolean = false;
	isToggle: boolean = true;
	orderTypes: any = ORDER_VALUES;
	constructor(
		public commonService: CommonService,
		public orderService: OrderService,
		public loader: LoaderService,
		public webSocketService: WebSocketService
	) { this.resetPagination(); }

	ngOnInit(): void {
		this.fetchLiveOrders();
		this.subscribeToTracking();
	}
	// clickEvent(){
	// 	this.status = !this.status;       
	// }
	
	// Fetch Live Orders
	fetchLiveOrders() {
		this.loader.show();
		this.isLoading = true;
		this.orderService.fetchLiveOrders().subscribe(res => {
			if (res && res.success && res.response) {
				this.liveOrders = res.response.data ? res.response.data : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
			}
			else
				this.liveOrders = [];
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.isLoading = false;
			this.liveOrders = [];
		});
	}

	// Reset Pagination
	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	onTabClick(id) {
		this.isToggle = this.tabId == id ? !this.isToggle : true;
		this.tabId = id;
	}

	//Track Order 
	trackOrder(order, index) {
		this.selectedOrder = order;
		if (Object.keys(order.location).length && order.location.coordinates.length) {
			this.setOrigin(order.location.coordinates);
		}

		if(order.liveOrders && order.liveOrders[0] && order.liveOrders[0].plantId && order.liveOrders[0].plantId.location){
			this.destination = { lat: order.liveOrders[0].plantId.location.coordinates[1], lng: order.liveOrders[0].plantId.location.coordinates[0] }
		}
		// else if (order.liveOrders && order.liveOrders.requests && Object.keys(order.liveOrders.requests[index].location).length && order.liveOrders.requests[index].location.coordinates.length) {
		// 	this.destination = { lat: order.liveOrders.requests[index].location.coordinates[1], lng: order.liveOrders.requests[index].location.coordinates[0] }
		// }
	}

	setOrigin(coordinates) {
		this.origin.lat = coordinates[1];
		this.origin.lng = coordinates[0];
	}

	// Subscribe To Tracking
	subscribeToTracking() {
		this.webSocketService.onAssociateLocationChange().subscribe(res => {
			let isCoordinates = res && res.location && res.location.coordinates;
			if (isCoordinates && this.selectedOrder && res.userId == this.selectedOrder._id) {
				this.setOrigin(res.location.coordinates);
			}
		}, error => {
			const arr = [0, 0];
			this.setOrigin(arr);
		});
	}
}