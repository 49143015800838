import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage.service'
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TokenService {

	constructor(public storageService: StorageService, 
		public jwtHelperService: JwtHelperService,
		private router: Router) { }

	// Check if user authenticated
	public isAuthenticated(): boolean {

		try{
			const token = this.storageService.getItem('token');
			if (token)
				return !this.jwtHelperService.isTokenExpired(token);
			return false;
		}
		catch(error){
			this.storageService.clearAll();
			return false;
		}
	}

	// Get logged-in user details
	public getLoggedInUser() {
		const token = this.storageService.getItem('token');
		if (token && !this.jwtHelperService.isTokenExpired(token))
			return this.jwtHelperService.decodeToken(token);
		return false;
	}

	// Get Permission
	public getPermission() {
		return this.getLoggedInUser().roleId;
	}

	public validateToken(token) {
		if (token && !this.jwtHelperService.isTokenExpired(token))
			return this.jwtHelperService.decodeToken(token);
		return false;
	}
}
