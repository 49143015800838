import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { WarehouseService, CylinderService, LoaderService, CommonService, StorageService, TokenService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import Swal from 'sweetalert2';
import { MESSAGE } from '../../../../__utilities/messages';
import { TITLE, BTN_TEXT } from '../../../../__utilities/text';
import { ROLES } from '../../../../__utilities/roles';
import { COMPONENT_KEYS, ORDER_STATUS } from '../../../../__utilities/orders';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
	selector: 'app-refill-cylinder-order',
	templateUrl: './refill-cylinder-order.component.html',
	styleUrls: ['./refill-cylinder-order.component.css']
})
export class RefillCylinderOrderComponent implements OnInit, AfterViewInit, OnDestroy {
	warehouses: any = [];
	cylinderCategories: any = [];
	inputId = "cylinder";
	isDisabled: boolean = false;
	paginate: any = { ...PAGINATION };
	orderStatus: object = { ...ORDER_STATUS };
	searchStr: string = '';
	myRequestList: any = [];
	isLoading: boolean = false;
	isAdd: boolean = false;
	btnText: string = BTN_TEXT.ADD_REQUEST;
	selectedRequest: any = '';
	plantId: string = "";
	selectedIndex: number = 0;
	heading: string = TITLE.REFILL_ORDERS;
	searchOrderId: string = "";
	statusFilter: string = "";
	subscription:Subscription;
	constructor(
		public warehouseService: WarehouseService,
		public cylinderService: CylinderService,
		public commonService: CommonService,
		public storageService: StorageService,
		public tokenService: TokenService,
		public loader: LoaderService,
		public activatedRoute: ActivatedRoute
	) {
		this.subscription = new Subscription();
		let userObj = this.storageService.getItem('userObj');
		this.plantId = [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR].indexOf(userObj.roleId.name) > -1 ? userObj[userObj.roleId.name]._id : "";
	}

	ngOnInit(): void {
		this.fetchCylinderRefillRequest();
		this.fetchWarehouses();
		this.fetchRouteParameters();
		this.routeSubscription();
		delete this.orderStatus['CANCELLED'];
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	// Fetch route params
	fetchRouteParameters() {
		this.activatedRoute.queryParams.subscribe(params => {
			if (params && params.orderId)
				this.searchOrderId = params.orderId;
		});
	}

	// Fetch Warehouse
	fetchWarehouses() {
		let searchQuery = '';
		this.warehouseService.fetchWarehouse(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.warehouses = res.response.response ? res.response.response : [];
			}
			else
				this.warehouses = [];
		}, error => {
			this.warehouses = [];
		});
	}

	// Fetch Cylinder Refill Request
	fetchCylinderRefillRequest() {
		this.isLoading = true;
		let searchQuery = this.queryParams();
		this.cylinderService.fetchCylinderRefillRequest(this.plantId, searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response.categories.sort(this.commonService.sortByProperty('size'));
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.myRequestList = res.response.requests ? res.response.requests : [];
			}
			else {
				this.myRequestList = [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.myRequestList = [];
		});
	}

	queryParams() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.statusFilter)
			searchQuery += "&filter=" + this.statusFilter;
		return searchQuery;
	}

	onSubmit() {
		let warehouseId = $("#selectWarehouse").val();
		if (!warehouseId && !this.selectedRequest) {
			$("#selectWarehouse").focus();
			return;
		}

		let request = [];
		let noOfCylinder: number = 0;
		this.cylinderCategories.forEach((element, index) => {
			noOfCylinder = parseInt($('#' + this.inputId + index).val());
			if (noOfCylinder)
				request.push({ numberOfCylinder: noOfCylinder, size: element.size });
		});

		if (!request.length)
			return this.commonService.growlError(MESSAGE.ENTER_QUANTITY);

		let payload: any = { warehouseId: warehouseId, order: request, plantId: this.plantId, comment: $("#comment").val() };
		if (this.selectedRequest) {
			payload.id = this.selectedRequest._id;
			delete payload.warehouseId;
		}

		this.isDisabled = true;
		this.cylinderService.makeCylinderRefillRequest(payload).subscribe(res => {
			if (res && res.success) {
				if (!this.selectedRequest) {
					this.paginate.totalItems += 1;
					this.myRequestList.unshift(res.plant);
				}
				else {
					this.myRequestList[this.selectedIndex] = res.plant;
				}
				this.resetForm(false);
			}
			this.commonService.growlSuccess(res.message);
			this.isDisabled = false;
		}, error => {
			this.isDisabled = false;
			this.commonService.growlError(error.message);
		});
	}

	resetForm(isAdd = false) {
		this.isAdd = isAdd;
		this.isDisabled = false;
		this.selectedRequest = '';
	}

	addMore(event) {
		this.resetForm(true);
	}

	completeRequest(iscomplete, request, index) {
		if (request.isCompleted)
			return;

		this.cylinderService.completePlantRefillRequest(request._id, { complete: iscomplete }).subscribe(res => {
			if (res && res.success) {
				this.myRequestList[index].isCompleted = res.response.isCompleted;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			$("#checkboxCompleteRequest" + index).prop("checked", false);
			this.commonService.growlError(error.message);
		});
	}

	editRequest(request, index) {
		this.isAdd = true;
		this.selectedIndex = index;
		this.selectedRequest = request;
		let requestObjLength = Object.keys(request.order).length;
		let _this = this;
		if (requestObjLength) {
			var plantRequests: any = requestObjLength ? request.order.sort(this.commonService.sortByProperty('size')) : [];
			setTimeout(() => {
				let tempArr = _this.cylinderCategories.map(el => el.size);
				plantRequests.forEach((element, i) => {
					$('#' + _this.inputId + tempArr.indexOf(element.size)).val(element.numberOfCylinder);
				});
				$("#comment").val(request.comment);
			}, 0);
		}
	}

	deleteRequest(request, index) {
		Swal.fire({
			title: MESSAGE.DELETE_CYLINDER_REQUEST_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_CYLINDER_REQUEST_CONFIRMATION_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedRequest(request._id, index);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	deleteSelectedRequest(requestId, index) {
		this.cylinderService.deleteRefillRequest(requestId).subscribe(res => {
			if (res && res.success) {
				this.paginate.totalItems -= 1;
				this.commonService.growlSuccess(res.message);
				this.myRequestList.splice(index, 1);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetAndSearch();
	}

	onOrderStatusChange(status) {
		this.statusFilter = status;
		this.resetAndSearch();
	}

	resetAndSearch() {
		this.resetPagination();
		this.myRequestList = [];
		this.fetchCylinderRefillRequest();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.myRequestList = [];
		this.fetchCylinderRefillRequest();
	}

	routeSubscription() {
		this.subscription.add(this.commonService.routeReuse.subscribe(res => {
			res == TITLE.REFILL_ORDERS ? this.resetForm() : false;
		}));
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		this.commonService.setCurrentOrderKey(COMPONENT_KEYS.CYLINDER_REFILL_REQUEST);
		this.subscription.add(this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { }));
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.resetForm();
		this.searchStr = "";
		this.resetAndSearch();
	}
	
	ngOnDestroy(){
		if(this.subscription) this.subscription.unsubscribe();
	}
}