import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TITLE } from '../../__utilities/text';

@Component({
	selector: 'app-plant-manager-dashboard',
	templateUrl: './plant-manager-dashboard.component.html',
	styleUrls: ['./plant-manager-dashboard.component.css']
})
export class PlantManagerDashboardComponent implements OnInit {

	@Input() dashboardService;
	@Input() loggedInUser: any;
	@Input() cartOrders: any;
	@Input() userRoles: any;
	@Input() orderStatus: any;
	@Output() cartOrderEvent = new EventEmitter<string>();
	queryParams: string = '';
	title: any = TITLE;
	constructor() { }

	ngOnInit(): void {
		this.queryParams = "?plantId=" + (this.loggedInUser.roleId.name == this.userRoles.PLANT_MANAGER ? this.loggedInUser.plant._id : this.loggedInUser.distributor._id);
		this.fetchCartOrderCount();
	}

	// Fetch Cart Order
	fetchCartOrderCount() {
		this.cartOrderEvent.next(this.queryParams);
	}
}