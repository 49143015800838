import { Component, OnInit, OnDestroy, AfterViewInit, } from '@angular/core';
import { CommonService, OrderService, StorageService, WebSocketService, LoaderService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { MESSAGE } from '../../../../__utilities/messages';
import { Router, ActivatedRoute } from '@angular/router';
import { TITLE } from '../../../../__utilities/text';
import { ORDER_VALUES, ORDER_STATUS, ORDER_MODE, COMPONENT_KEYS } from '../../../../__utilities/orders';
import { NgxGalleryOptions, NgxGalleryAnimation } from 'ngx-gallery-9';
import Swal from 'sweetalert2';
import { ROLES } from '../../../../__utilities/roles';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $;

@Component({
	selector: 'app-clients-refill-order',
	templateUrl: './clients-refill-order.component.html',
	styleUrls: ['./clients-refill-order.component.css']
})
export class ClientsRefillOrderComponent implements OnInit, AfterViewInit, OnDestroy {

	paginate: any = { ...PAGINATION };
	ordersText: any = ORDER_VALUES;
	orderStatus: any = { ...ORDER_STATUS };
	refillOrders: any = [];
	isLoading: boolean = false;
	searchStr: string = '';
	selectedRefillOrder: any = '';
	roles: any = { ...ROLES };
	authUser: any;
	heading: string = TITLE.PICKUP_ORDERS;
	isFiterOption: boolean = false;
	filterOptionValue: boolean = false;
	reviewTo: string;
	reviewFrom: string;
	selectedIndex: number;
	isChanges: boolean = false;
	isCorporate: boolean = false;
	isPlantOrDistributor: boolean = false;
	isIgrDeliveryOrder: boolean = false;
	deliveryMode: boolean = false;
	filterOptions: string = "";
	refillOrderId: string = "";
	currentOption: any = "";
	noRecord: string = "";
	isInvoice: boolean = false;
	paymentDetails: any = "";
	orderMode: string = "";
	isInvoiceSending: boolean = false;
	isInvoiceLoading: boolean = false;
	orderModes: any = ORDER_MODE;
	activatedRouteValue: any = "";
	searchOrderId: string = "";
	statusFilter: string = "";
	subscription: Subscription;
	isDownloadInProgress: boolean = false;
	isCorporateUser: boolean = false;
	paymentInfo: any = "";
	isPaymentStatus: boolean = false;
	galleryOptions: NgxGalleryOptions[];
	galleryImages: any = [];
	constructor(public commonService: CommonService,
		public storageService: StorageService,
		public activatedRoute: ActivatedRoute,
		public socketService: WebSocketService,
		public router: Router,
		public loader: LoaderService,
		public orderService: OrderService) {
		this.authUser = this.storageService.getItem('userObj');
		this.isCorporate = this.authUser.roleId.name == ROLES.CORPORATE;
		this.isCorporateUser = (Object.values(ROLES)).indexOf(this.authUser.roleId.name) == -1;
		this.isPlantOrDistributor = [ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER, ROLES.INDEPENDENT_RETAILER].indexOf(this.authUser.roleId.name) > -1;
		delete this.orderStatus['DECLINED'];
	}

	ngOnInit(): void {
		this.getRoutingParams();
		this.galleryOptions = [
			{
				thumbnailsColumns: 4,
				imageAnimation: NgxGalleryAnimation.Slide,
				preview: false
			}
		];
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValue = this.activatedRoute.data['value'];
		this.isInvoice = this.activatedRouteValue.invoice;
		this.deliveryMode = this.activatedRouteValue.deliveryMode;
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ refillOrderId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.refillOrderId = results.refillOrderId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.orderMode = results.query.orderMode ? results.query.orderMode : "";
				this.searchOrderId = results.query.orderId ? results.query.orderId : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.refillOrders = [];
				this.refillOrderId ? this.fetchRefillderById() : this.fetchRefillOrders();
			});
	}

	viewOrder(order, isInvoice, selectedIndex) {
		this.router.navigate([isInvoice ? this.activatedRouteValue.invoiceURL : this.activatedRouteValue.viewURL, order._id], { queryParams: this.queryParamsObj() });
	}

	goBack() {
		this.router.navigate([this.activatedRouteValue.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.orderMode) queryParams.orderMode = this.orderMode;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		return queryParams;
	}

	// Fetch refill order details by order id
	fetchRefillderById() {
		this.isLoading = true;
		this.loader.show();
		this.orderService.fetchCartOrderById(this.refillOrderId).subscribe(res => {
			if (res && res.success && res.response.data) {
				this.selectedRefillOrder = res.response.data;

				this.paymentDetails = this.isInvoice ? {
					serverPayment: res.response.serverPayment,
					walletPayment: res.response.walletPayment
				} : "";
				if (res.response.data.paymentReferenceId) {
					this.fetchRefundStatus(res.response.data);
				}
			}
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
		}, error => {
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
			this.selectedRefillOrder = "";
		});
	}

	fetchRefundStatus(order) {
		if (order.isCancelledByMe) return;
		this.isPaymentStatus = true;
		if (order.isCancelled || order.isCancelledByMe || order.unableToDelivered) {
			let queryParams = order.paymentReferenceId ?
				("?paymentReferenceId=" + order.paymentReferenceId) :
				"?walletTransactionId=" + order.walletTransactionId;
			this.orderService.fetchPaymentStatus(queryParams).subscribe(res => {
				if (res && res.success && res.response) {
					this.paymentInfo = res.response.paymentReference;
				}
			}, error => {
				this.paymentInfo = "";
			});
		}
	}

	fetchRefillOrders() {
		this.isLoading = true;
		let searchQuery = this.queryParams();
		if (this.isPlantOrDistributor)
			searchQuery += "&deliveryMode=" + this.deliveryMode;
		this.orderService.fetchCartOrders(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.refillOrders = res.response.data ? res.response.data : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
			}
			else
				this.refillOrders = [];
			this.isLoading = false;
		}, () => {
			this.isLoading = false;
			this.refillOrders = [];
		});
	}

	// Search Params
	queryParams() {
		let plantId = this.isPlantOrDistributor ? this.authUser[this.authUser.roleId.name]._id : "";
		let searchStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage
			+ "&plantId=" + plantId + "&orderMode=" + this.orderMode;
		if (this.filterOptions)
			searchStr += this.filterOptions;
		if (this.statusFilter)
			searchStr += "&filter=" + this.statusFilter;
		if (this.authUser.roleId.name == ROLES.INDEPENDENT_RETAILER && this.activatedRouteValue) {
			searchStr += "&isIgrDeliveryOrder=" + this.activatedRouteValue.isIgrDelivery;
			this.isIgrDeliveryOrder = this.activatedRouteValue.isIgrDelivery;
		}
		return searchStr;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fetchRefillOrders();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
		this.refillOrders = [];
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.refillOrders = [];
		this.fetchRefillOrders();
	}

	completeOrder(order, index, position) {
		if (order.isCompleted)
			return;
		this.orderService.completeRefillOrder(order._id, { isComplete: true, isReturn: true }).subscribe(res => {
			if (res && res.success) {

				if (position == "outer") {
					this.refillOrders[index] = res.response.data;
				} else {
					this.selectedRefillOrder = res.response.data;
				}
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			$("#checkboxComplete" + index).prop('checked', false);
			this.commonService.growlError(error.message);
		});
	}

	completeDeliveryOrder(order, index, position) {
		this.orderService.completeDeliveryOrder(order._id).subscribe(res => {
			if (res && res.success) {
				if (position == "outer") {
					this.refillOrders[index] = res.response.data;
				} else {
					this.selectedRefillOrder = res.response.data;
				}
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			$("#checkboxComplete" + index).prop('checked', false);
			this.commonService.growlError(error.message);
		});
	}

	igrOutForDelivery(order, index, position) {
		if (order.pickedUp)
			return;
		this.orderService.igrOutForDelivery(order._id).subscribe(res => {
			if (res && res.success) {
				if (position == "outer") {
					this.refillOrders[index] = res.response.data;
				} else {
					this.selectedRefillOrder = res.response.data;
				}
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			$("#checkboxComplete" + index).prop('checked', false);
			this.commonService.growlError(error.message);
		});
	}

	igrCompleteDelivery(order, index, position) {
		if (order.pickedUp)
			return;
		this.orderService.igrCompleteDelivery(order._id).subscribe(res => {
			if (res && res.success) {
				if (position == "outer") {
					this.refillOrders[index] = res.response.data;
				} else {
					this.selectedRefillOrder = res.response.data;
				}
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			$("#checkboxComplete" + index).prop('checked', false);
			this.commonService.growlError(error.message);
		});
	}


	pickedUpOrder(order, index, position) {
		if (order.pickedUp)
			return;
		this.orderService.pickedUpOrder(order._id).subscribe(res => {
			if (res && res.success) {
				if (position == "outer") {
					this.refillOrders[index] = res.response.data;
				} else {
					this.selectedRefillOrder = res.response.data;
				}
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			$("#checkboxComplete" + index).prop('checked', false);
			this.commonService.growlError(error.message);
		});
	}

	cancelOrder(order, index, position) {
		if (order.isCancelledByMe && order.isReturnOldCylinder)
			return;
		this.orderService.cancelOrder(order._id).subscribe(res => {
			if (res && res.success) {
				if (position == "outer") {
					this.refillOrders[index] = res.response.data;
				} else {
					this.selectedRefillOrder = res.response.data;
				}

			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			$("#checkboxComplete" + index).prop('checked', false);
			this.commonService.growlError(error.message);
		});
	}

	approveOrder(order, index, key, position) {

		Swal.fire({
			title: key == "approve" ? (this.authUser.roleId.name != this.roles.INDEPENDENT_RETAILER ? MESSAGE.APPROVE_ORDER_TITLE : MESSAGE.CONFIRM_ORDER_TITLE) : MESSAGE.REJECT_ORDER_TITLE,
			text: MESSAGE.APPROVE_REJECT_ORDER + (this.authUser.roleId.name == this.roles.INDEPENDENT_RETAILER ? "confirm" : key) + " order ?",
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.isConfirmed) {
				const payload = { approve: key == "approve", reject: key == "reject" }
				this.orderService.approveRefillOrder(order._id, payload).subscribe(res => {
					if (res && res.success && res.response) {
						if (position == "outer") {
							this.refillOrders[index] = res.response.data;
						} else {
							this.selectedRefillOrder = res.response.data;
						}
					}
					this.commonService.growlSuccess(res.message);
				}, error => {
					if (key == "approve") {
						if (position == "outer") {
							$("#checkboxApprove" + index).prop('checked', false);
						} else {

						}
					}
					this.commonService.growlError(error.message);
				});
			}
			else {
				if (key == "approve") {
					if (position == "outer") {
						$("#checkboxApprove" + index).prop('checked', false);
					} else {

					}
				}
			}

		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	filterOrders(event, value) {
		this.isFiterOption = event.target.checked;
		this.filterOptionValue = value;
		let _this = this;
		setTimeout(() => {
			_this.resetPagination();
			_this.fetchRefillOrders();
		}, 500);
	}

	onReview(reviewTo, reviewFrom) {
		this.reviewTo = reviewTo;
		this.reviewFrom = reviewFrom
		this.isChanges = false;
	}

	// On post review
	onPostReview(review) {
		this.orderService.respondToReview(review.id, review.comment).subscribe(res => {
			if (res && res.success) {

				if (this.reviewTo == "client")
					this.selectedRefillOrder.clientReviewTip = res.response;

				if (this.reviewTo == "associate")
					this.selectedRefillOrder.associateReviewTip = res.response;

				if (this.reviewTo == "hub")
					this.selectedRefillOrder.plantReviewTip = res.response;

				this.commonService.growlSuccess(res.message);
				this.isChanges = true;
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		if (this.refillOrderId) return;
		const key = this.isCorporate ? COMPONENT_KEYS.CLIENT_CART_ORDER : COMPONENT_KEYS.CLIENT_CART_PICKUP_ORDER;
		this.commonService.setCurrentOrderKey(key);
		this.subscription = this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { })
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.searchStr = "";
		this.refillOrders = [];
		this.filterOptions = "";
		this.resetPagination();
		this.fetchRefillOrders();
	}

	// Search By day , Month, Week And Year
	onFilter(obj) {
		this.resetPagination();
		this.filterOptions = "&startDate=" + obj.startDate + "&endDate=" + obj.endDate;
		this.refillOrders = [];
		this.fetchRefillOrders();
	}

	onOrderModeChange(orderMode) {
		this.orderMode = orderMode;
		this.searchByStatus();
	}

	onOrderStatusChange(orderStatus) {
		this.statusFilter = orderStatus;
		this.searchByStatus();
	}

	searchByStatus() {
		this.searchStr = "";
		this.resetPagination();
		this.refillOrders = [];
		this.fetchRefillOrders();
	}

	// Email Invoice
	emailInvoice() {
		this.isInvoiceSending = true;
		this.orderService.emailInvoice(this.refillOrderId).subscribe(res => {
			this.commonService.growlSuccess(res.message);
			this.isInvoiceSending = false;
		}, error => {
			this.isInvoiceSending = false;
			this.commonService.growlError(error.message);
		});
	}

	printPDF() {
		this.isInvoiceLoading = true;
		this.orderService.fetchInvoice(this.refillOrderId).subscribe(res => {
			const blob = new Blob([res], {
				type: 'application/pdf'
			});
			const url = window.URL.createObjectURL(blob);
			window.open(url);
			this.isInvoiceLoading = false;
		}, error => {
			this.isInvoiceLoading = false;
			this.commonService.growlError(MESSAGE.ERROR);
		});
	}

	downloadReport() {
		this.isDownloadInProgress = true;
		let queryParams = this.exportReportQueryParams();
		this.orderService.downloadReport(queryParams).subscribe(res => {
			this.isDownloadInProgress = false;
			this.downloadCSV(res, 'Orders');
		}, error => {
			this.isDownloadInProgress = false;
			this.commonService.growlError(error.message);
		});
	}

	downloadCSV(res, fileName) {
		const blob = new Blob([res], {
			type: 'text/csv'
		});
		var a = window.document.createElement("a");
		a.href = window.URL.createObjectURL(blob);
		a.download = `${fileName}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

	}

	exportReportQueryParams() {
		const roleNames = Object.values(ROLES);
		const id = ROLES.CORPORATE != this.authUser.roleId.name && roleNames.indexOf(this.authUser.roleId.name) > -1 ? this.authUser[this.authUser.roleId.name]._id : "";
		let searchStr = "?search=" + this.searchStr + "&plantId=" + id + "&orderMode=" + this.orderMode;
		if (this.isPlantOrDistributor)
			searchStr += "&deliveryMode=" + this.deliveryMode;
		if (this.filterOptions)
			searchStr += this.filterOptions;
		if (this.statusFilter)
			searchStr += "&filter=" + this.statusFilter;
		return searchStr;
	}

	filterImages(key, requestId, images) {
		this.galleryImages[key] = [];
		this.galleryImages[key][requestId] = [];
		images.forEach((element, index) => {
			element = this.commonService.hostUrl + element;
			this.galleryImages[key][requestId][index] = { small: element, medium: element, big: element };
		});
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}