import { Component, HostListener, NgZone, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { navItems } from '../../_nav';
import {
	StorageService, TokenService, CommonService, ProfileService,
	PushNotificationService, WebSocketService, CylinderService,
	SettingService
} from '../../__services';
import { Router, ActivatedRoute } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { environment } from '../../../environments/environment';
import { ROLES } from '../../__utilities/roles';
import { MESSAGE } from '../../__utilities/messages';
import { NAVIGATION_URL, NAVIGATION_KEYS, COMPONENT_KEYS } from '../../__utilities/orders';
import Swal from 'sweetalert2';
import { Howl } from 'howler';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './default-layout.component.html'
})
@HostListener('scroll', ['$event'])
export class DefaultLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
	public sidebarMinimized = false;
	public date = (new Date()).getFullYear();
	public menuItems = [];
	public userDetails: any = '';
	private orderKey: string = '';
	imageURL: string;
	notifications: any = [];
	paginate: any = {
		itemsPerPage: 10,
		currentPage: 1,
		totalItems: 0
	};
	throttle = 300;
	scrollDistance = 1;
	scrollUpDistance = 2;
	totalItems: number = 0;
	counter: number = 0;
	timer: number = 10000;
	isOrderStatusChanged: boolean = false;
	breadcrumbs: any = [];
	cartTotal: number = 0;
	isOnline: boolean = false;
	userObj: any = "";
	isDistributor: boolean = false;
	isIndependentRetailer: boolean = false;
	subscription: Subscription;
	settings: any = "";
	arr1 = [NAVIGATION_KEYS.new_client_refill_purchase_order, NAVIGATION_KEYS.associate_request];
	arr2 = [NAVIGATION_KEYS.hub_refill_cylinder_request, NAVIGATION_KEYS.hub_new_cylinder_request, NAVIGATION_KEYS.warehouse_recycle_cylinder_request];
	constructor(private storageService: StorageService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private tokenService: TokenService,
		public commonService: CommonService,
		private profileService: ProfileService,
		private swPush: SwPush,
		private ngZone: NgZone,
		private cylinderService: CylinderService,
		private pushService: PushNotificationService,
		private webSocketService: WebSocketService,
		private settingService: SettingService) {
		this.subscription = new Subscription();
		this.userDetails = this.tokenService.getLoggedInUser();
		this.getProfile();
		this.webPushSubscription();
		this.createMenuItems();
		this.getNotifications();
		window['angularComponentReference'] = {
			component: this, zone: this.ngZone,
			loadAngularFunction: () =>
				this.ngFunction(event),
		};
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
		this.subscribeRouteChanges();
		this.userObj = this.storageService.getItem('userObj');
		this.isIndependentRetailer = this.userObj.roleId.name == ROLES.INDEPENDENT_RETAILER;
		this.isDistributor = [ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER, ROLES.INDEPENDENT_RETAILER].indexOf(this.userObj.roleId.name) > -1;
		if (this.isIndependentRetailer) {
			this.fetchCurrentPrice();
		}
	}

	subscribeRouteChanges() {
		const subscription = this.commonService.getRouteChanges().subscribe(res => {
			this.breadcrumbs = res ? res : [];
		}, error => { });
		this.subscription.add(subscription);
	}


	ngOnInit() {

		let _this = this;
		setTimeout(() => {
			console.log("socket fetch");

			_this.webSocketService.setupSocketConnection();
			setTimeout(() => {
				console.log("socket fetch2");
				_this.orderStatusSocket();
				_this.fetchUpdatedSetting();
			}, 2000);
		}, 1000);
		this.getCurrentOrderKey();
		this.getProfileUpdates();
		//this.createMenuItems();
	}

	fetchCurrentPrice() {
		this.cylinderService.fetchCurrentPrice().subscribe(res => {
			if (res && res.success && res.response) {
				this.settings = res.response.setting;
				// this.lpgPrice = res.response.lpg && res.response.lpg.length ? res.response.lpg[0].LPGPricePerKg : 0;
			}
		}, error => {
			// this.lpgPrice = 0;
			this.settings = "";
		});
	}

	orderStatusSocket() {
		this.webSocketService.onOrderStatusChange().subscribe(res => {
			console.log("orderStatusSocket", res);
			if (res) {
				this.isOrderStatusChanged = this.orderKey && res.keys.indexOf(this.orderKey) > -1 ? true : false;
				if (res.message) {
					this.playAudio();
					this.initSwal(res);
				}

				if (res.keys.indexOf(COMPONENT_KEYS.REATILER_ONLINE_OFFLINE) > -1)
					this.isOnline = res.isOnline;
			}
		}, error => {
			console.log(error, "socket error");
			this.isOrderStatusChanged = false;
		});
	}

	fetchUpdatedSetting() {
		this.webSocketService.fetchUpdatedSetting().subscribe(res => {
			if (res && res.keys.indexOf(COMPONENT_KEYS.SETTING_PRICE_UPDATE) > -1)
				this.settings = res;
		}, error => {
		});
	}

	ngAfterViewInit() {
		const subscription = this.commonService.getCartTotal().subscribe(res => {
			this.cartTotal = res;
		});
		this.subscription.add(subscription);
	}

	initSwal(res) {
		Swal.fire({
			html: res.message + '<br><a href = "javascript:void(0);" id = "viewOrder" class = "view_order">View Order</a>',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal_content' },
			showCancelButton: false,
			cancelButtonText: 'Reject',
			showConfirmButton: false,
			confirmButtonText: "Approve",
			showCloseButton: true,
			closeButtonHtml: '<i class = "fa fa-times" style = "font-size:25px;"></i>'

		}).then((confirm: any) => {
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
				return;
			}
		});
		// Navigate to view detail page
		let _this = this;
		document.getElementById("viewOrder").addEventListener("click", function () {
			const url = (res.keys.indexOf(COMPONENT_KEYS.CLIENT_CART_ORDER) > -1
				? '/orders/cart-orders' :
				res.keys.indexOf(COMPONENT_KEYS.CLIENT_CART_PICKUP_ORDER) > -1)
				? '/orders/pickup' : (res.isIgrDeliveryOrder ? '/orders/gas-supplier/delivery' : '/orders/delivery');
			_this.router.navigate([url]);
			_this.updateOrdersList();
			(<HTMLInputElement>document.querySelector("button.swal2-close")).click();
		});
	}

	getCurrentOrderKey() {
		const subscription = this.commonService.getCurrentOrderKey().subscribe(res => {
			this.orderKey = res;
		}, error => { });
		this.subscription.add(subscription);
	}

	ngFunction(event) {
		const title = event.target.innerText.trim();
		this.commonService.routeReuse.next(title);
	}

	// Get Profile Updates
	getProfileUpdates() {
		const subscription = this.commonService.profileSubject.subscribe(res => {
			if (res && res.image) {
				this.imageURL = this.commonService.hostUrl + "/" + res.image;
			}
		});
		this.subscription.add(subscription);
	}

	// Get Notifications
	getNotifications() {
		const userRole = this.userDetails && this.userDetails.roleId && this.userDetails.roleId.name;
		// + "&isRead=false"
		let queryParams = "?page=" + this.paginate.currentPage + "&limit=" + (this.paginate.itemsPerPage) + "&isRead=false";
		this.pushService.fetchNotifications(queryParams).subscribe(res => {
			if (res && res.success && res.response && res.response.data) {
				this.notifications = [];
				this.totalItems = res.response.pagination.totalDocs;
				if (res.response.data && this.totalItems) {
					res.response.data.forEach(element => {
						element.queryParams = {};
						if (element.orderId)
							element.queryParams.orderId = element.orderId;

						element.url = NAVIGATION_URL[element.eventName];
						if (this.arr1.indexOf(element.eventName) > -1)
							element.url = userRole == ROLES.CORPORATE ? NAVIGATION_URL[element.eventName][0] : NAVIGATION_URL[element.eventName][1];

						if (this.arr2.indexOf(element.eventName) > -1)
							element.url = userRole == ROLES.PLANT_MANAGER ? NAVIGATION_URL[element.eventName][0] : NAVIGATION_URL[element.eventName][1];

						if (element.eventName == NAVIGATION_KEYS.user_chat) {
							element.queryParams.senderId = element.senderId._id;
							element.queryParams.key = element.senderId.roleId.name;
						}

						// if (element.eventName == NAVIGATION_KEYS.new_client_refill_purchase_pickup_order || element.eventName == NAVIGATION_KEYS.new_client_refill_purchase_delivery_order) {
						// 	element.url = NAVIGATION_URL[element.eventName] + "/" + element.orderId;
						// }
						if (element.eventName == NAVIGATION_KEYS.new_client_refill_purchase_pickup_order || element.eventName == NAVIGATION_KEYS.new_client_refill_purchase_delivery_order) {
							element.url = element.eventName == NAVIGATION_KEYS.new_client_refill_purchase_delivery_order ? (element.isIgrDeliveryOrder ? NAVIGATION_URL[NAVIGATION_KEYS.new_client_refill_purchase_delivery_order_gas_supplier] + "/" + element.orderId : NAVIGATION_URL[element.eventName] + "/" + element.orderId) : NAVIGATION_URL[element.eventName] + "/" + element.orderId;
						}

						if (element.eventName == NAVIGATION_KEYS.independent_new_cylinder_request) {
							element.url = userRole == ROLES.INDEPENDENT_RETAILER ? NAVIGATION_URL[element.eventName][0]
								: userRole == ROLES.WAREHOUSE_MANAGER ? NAVIGATION_URL[element.eventName][1] : NAVIGATION_URL[element.eventName][2];
						}

						if (element.eventName == NAVIGATION_KEYS.new_igr_registartion)
							element.queryParams.id = element.plantId;

						if ([NAVIGATION_KEYS.new_igr_registartion, NAVIGATION_KEYS.new_fleet_registartion, NAVIGATION_KEYS.new_client_registartion, NAVIGATION_KEYS.new_associate_registartion].indexOf(element.eventName) > -1)
							element.queryParams.userId = element.userId;

						this.notifications.push(element);
					});
				}
			}
		}, error => {
		});
	}

	readNotification(notification, index) {
		console.log("notification", notification);


		let notificationId = notification && notification._id;
		let isNotificationClicked = true;
		if (!notification._id && this.notifications && this.notifications.length) {
			notificationId = this.notifications[this.notifications.length - 1]._id;
			isNotificationClicked = false;
		}
		this.readNotificationById(notificationId, index, isNotificationClicked);
		isNotificationClicked && this.router.navigate([notification.url], { queryParams: notification.queryParams });
	}

	readNotificationById(notificationId, index, isNotificationClicked) {
		isNotificationClicked ? this.removeCurrentIndex(index) : this.removeCount();
		this.pushService.readNotification({ id: notificationId }).subscribe(res => {
		}, error => {
		});
	}

	removeCurrentIndex(index) {
		this.notifications.splice(index, 1);
		this.totalItems = this.totalItems ? this.totalItems - 1 : 0;
	}

	removeCount() {
		// this.totalItems = 0;
	}

	isPopUpOpen() {
		if (!this.totalItems) return;
		let _this = this;
		var interval = setInterval(() => {
			_this.counter += 1;
			if (_this.counter == 10) {
				clearInterval(interval);
				_this.counter = 0;
				_this.removeCount();
				_this.readNotification("", "");
			}
		}, 1000);
	}

	onScrollDown() {
		this.timer += 10000;
		this.paginate.currentPage += 1;
		this.getNotifications();
		let _this = this;
		setTimeout(() => {
			_this.readNotification("", "");
		}, _this.timer);
	}

	webPushSubscription() {
		if (this.swPush.isEnabled) {
			this.swPush
				.requestSubscription({
					serverPublicKey: environment.VAPID_PUBLIC,
				})
				.then(subscription => {
					this.pushService.sendSubscriptionToTheServer(subscription).subscribe(res => {
					}, err => {
						console.log("err");
					});
				})
				.catch(console.error);
		}

		this.swPush.notificationClicks.subscribe(
			({ action, notification }) => {
			});

		this.swPush.messages.subscribe((message: any) => {
			this.commonService.notificationEmitter.next(true);
			this.totalItems += 1;

			const eventName = message.notification.data.content.eventName;
			let url = NAVIGATION_URL[eventName];
			let queryParams: any = {};

			if (message.notification.data.content.orderId)
				queryParams.orderId = message.notification.data.content.orderId;

			const userRole = this.userDetails && this.userDetails.roleId && this.userDetails.roleId.name;
			if (this.arr1.indexOf(eventName) > -1)
				url = userRole == ROLES.CORPORATE ? NAVIGATION_URL[eventName][0] : NAVIGATION_URL[eventName][1];

			if (eventName == NAVIGATION_KEYS.user_chat) {
				queryParams.senderId = message.notification.data.content.sender._id;
				queryParams.key = message.notification.data.content.sender.roleId.name;
			}

			if (this.arr2.indexOf(eventName) > -1)
				url = userRole == ROLES.PLANT_MANAGER ? NAVIGATION_URL[eventName][0] : NAVIGATION_URL[eventName][1];

			if (eventName == NAVIGATION_KEYS.independent_new_cylinder_request) {
				url = userRole == ROLES.INDEPENDENT_RETAILER ? NAVIGATION_URL[eventName][0]
					: userRole == ROLES.WAREHOUSE_MANAGER ? NAVIGATION_URL[eventName][1] : NAVIGATION_URL[eventName][2];
			}
			let notificationPayload = {
				_id: message.notification.data._id,
				title: message.notification.title,
				body: message.notification.body,
				createdAt: message.notification.data.dateOfArrival,
				url: url,
				queryParams: queryParams
			};
			this.notifications.length ? this.notifications.unshift(notificationPayload) : this.notifications.push(notificationPayload);
			this.totalItems += 1;
		});
	}

	getProfile() {
		this.profileService.fetchProfile().subscribe(res => {
			if (res && res.success && res.user) {
				if (res.user.roleId && res.user.roleId.permission) {
					this.userDetails = { ...this.userDetails, roleId: { ...this.userDetails.roleId || [], permission: [...this.userDetails.roleId.permission || [], ...res.user.roleId.permission] } }
				}
				this.imageURL = this.commonService.hostUrl + "/" + res.user.image;
				const userRole = this.userDetails && this.userDetails.roleId && this.userDetails.roleId.name;
				if (userRole == ROLES.INDEPENDENT_RETAILER)
					this.commonService.sendBankDetails(res.user);
				if (this.isDistributor)
					this.isOnline = res.user.isOnline;
				this.createMenuItems();
			}

		}, error => {
		});
	}

	setUserStatus(isOnline) {
		if (!this.isDistributor) return;
		this.profileService.changeUserStatus(this.userObj[this.userDetails.roleId.name]._id, { isOnline: isOnline }).subscribe(res => {
			if (res && res.success && res.response) {
				this.isOnline = res.response.isOnline;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(MESSAGE.ERROR);
			this.isOnline = this.isOnline;
		});
	}

	createMenuItems = function () {
		this.menuItems = [];
		const allMenus: any = navItems.filter(menuItem => menuItem);
		const userPermissions = (typeof this.userDetails.roleId.permission == "object") ? this.userDetails.roleId.permission : [];
		let tempArr = [];
		let isAccessAllowed = false;
		allMenus.map((element, index) => {
			isAccessAllowed = false;
			tempArr = [];
			if (typeof element.permission[0] == "object" && element.permission[0].length && (this.userDetails.roleId.name != ROLES.CORPORATE)) {
				element.permission[0].map(ele => {
					if (!isAccessAllowed) {
						isAccessAllowed = userPermissions.indexOf(ele) > -1;
					}
				});
				if ((!isAccessAllowed && this.userDetails.roleId.name != "independent") && element.permission.indexOf(this.userDetails.roleId.name) > -1) {
					isAccessAllowed = true;
				}


			}
			else {
				isAccessAllowed = (element.permission.length == 0 || element.permission.indexOf(this.userDetails.roleId.name) > -1)
					|| (element.permission[0] && userPermissions.indexOf(element.permission[0]) > -1) || element.isAccessForAll ? true : false;
			}

			if (isAccessAllowed) {
				if (element.children && element.children.length) {
					element.children.map((childs, index) => {
						if (childs.permission.length && ((childs.permission[0] && (userPermissions.indexOf(childs.permission[0]) > -1)) || childs.permission.indexOf(this.userDetails.roleId.name) > -1)) {
							tempArr.push(childs);
						}
					});
					element = { ...element, children: tempArr };
					this.menuItems.push(element);
				}
				else
					this.menuItems.push(element);
			}
		});
	}

	toggleMinimize(e) {
		this.sidebarMinimized = e;
	}

	logout() {
		this.pushService.removeSubscriptionId().subscribe(res => {
		}, error => { });
		this.storageService.clearAll();
	}

	updateOrdersList() {
		if (this.orderKey && this.isOrderStatusChanged) {
			this.commonService.orderStatusChange.next(true);
			this.isOrderStatusChanged = false;
		}
	}

	// Play audio on counter change
	playAudio = function () {
		var sound = new Howl({
			src: ['/assets/audio/notification.mp3'],
			mute: false,
			autoplay: true,
			volume: 1,
			onend: function () {
			}
		});
		sound.play();
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}