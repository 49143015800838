import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class SettingService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Update Content (Terms & Conditions, Privacy Policy, Consent Clause)
	updateContent(content, page): Observable<any> {
		// Page PP - Privacy Policy, TC - Terms & Conditions
		if (page == 'PP')
			return this.http.post(BASE_URL.PRIVACY_POLICY, content, this.headerService.httpHeaders());
		if (page == 'TC')
			return this.http.post(BASE_URL.TERMS_CONDITIONS, content, this.headerService.httpHeaders());
		if (page == 'CC')
			return this.http.post(BASE_URL.CONSENT_CLAUSE, content, this.headerService.httpHeaders());
	}

	// Fetch Content (Terms & conditions, Privacy Policy, Consent Clause) 
	fetchContent(page, isPublicURL): Observable<any> {
		// Page PP - Privacy Policy, TC - Terms & Conditions
		if (page == 'PP')
			return this.http.get(isPublicURL ? BASE_URL.VIEW_PRIVACY_POLICY : BASE_URL.PRIVACY_POLICY, this.headerService.httpHeaders());
		if (page == 'TC')
			return this.http.get(isPublicURL ? BASE_URL.VIEW_TERMS_CONDITIONS : BASE_URL.TERMS_CONDITIONS, this.headerService.httpHeaders());
		if (page == 'CC')
			return this.http.get(isPublicURL ? BASE_URL.VIEW_CONSENT_CLAUSE : BASE_URL.CONSENT_CLAUSE, this.headerService.httpHeaders());
	}

	// Settings
	fetchPermissionsList(): Observable<any> {
		return this.http.get(BASE_URL.PERMISSIONS_LIST, this.headerService.httpHeaders());
	}

	// Role Add/Update
	manageRole(payload, isEdit): Observable<any> {
		if (isEdit)
			return this.http.put(BASE_URL.ROLE, payload, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.ROLE, payload, this.headerService.httpHeaders());
	}

	// Delete Role
	deleteRole(roleId): Observable<any> {
		return this.http.delete(BASE_URL.ROLE + "/" + roleId, this.headerService.httpHeaders());
	}

	// Delete Role
	fetchRoles(): Observable<any> {
		return this.http.get(BASE_URL.ROLE, this.headerService.httpHeaders());
	}

	// Fetch Settings
	fetchSettings(): Observable<any> {
		return this.http.get(BASE_URL.SETTING_URL, this.headerService.httpHeaders());
	}

	// Update Settins
	updateSettings(id, payload): Observable<any> {
		return this.http.put(BASE_URL.SETTING_URL + "/" + id, payload, this.headerService.httpHeaders());
	}

	// Fetch Corporate Brand Names
	fetchBrandsCorporate(): Observable<any>{
		return this.http.get(BASE_URL.FETCH_BRAND_NAMES, this.headerService.httpHeaders());
	}

	// Fetch IGR Brand Names
	fetchBrandsIgr(id): Observable<any>{
		return this.http.get(BASE_URL.FETCH_RETAILER_BRAND_NAMES + "/" + id, this.headerService.httpHeaders());
	}

	// Manage Brand Names
	addBrandCategory(brandName): Observable<any> {
		return this.http.post(BASE_URL.FETCH_BRAND_NAMES, brandName, this.headerService.httpHeaders());
	}

	// Delete Brand Names
	deleteBrands(id): Observable<any> {
		return this.http.delete(BASE_URL.FETCH_BRAND_NAMES +'/'+ id, this.headerService.httpHeaders());
	}

	// Enable Corporate Brand Names
	enableBrand(payload,id): Observable<any> {
		return this.http.patch(BASE_URL.ENABLE_BRAND_NAMES +'/'+ id, payload, this.headerService.httpHeaders());
	}

	// Enable IGR Brand Names
	enableBrandIgr(payload,id): Observable<any> {
		return this.http.patch(BASE_URL.ENABLE_IGR_BRAND_NAMES +'/'+ id, payload, this.headerService.httpHeaders());
	}
}