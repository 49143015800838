import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonService, TokenService, OrderService } from '../../__services';
import { PERMISSIONS } from '../../__utilities/permissions';
import { ROLES } from '../../__utilities/roles';
import { TITLE } from '../../__utilities/text';
import { MESSAGE } from '../../__utilities/messages';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-corporate-dashboard',
	templateUrl: './corporate-dashboard.component.html',
	styleUrls: ['./corporate-dashboard.component.css']
})
export class CorporateDashboardComponent implements OnInit {

	@Input() dashboardService;
	@Input() cartOrders: any;
	@Input() orderStatus: any;
	@Output() cartOrderEvent = new EventEmitter<string>();
	permissions: any = PERMISSIONS;
	isCorporateUser: boolean = false;
	authUser: any;
	title: any = TITLE;
	isFilter: any;
	reportType = {
		INDEPENDENT_SALES: 1,
		HUB_DISTRIBUTOR_SALES: 2,
		ASSOCIATE_SALES: 3,
		FLEET_OWNER_SALES: 4
	};
	currentReportType: number = 0;
	constructor(public commonService: CommonService, public tokenService: TokenService, public orderService: OrderService) {
		this.authUser = this.tokenService.getLoggedInUser();
		this.isCorporateUser = Object.values(ROLES).indexOf(this.authUser.roleId.name) > -1;
		this.isDownloadSectionVisible();
	}

	ngOnInit(): void {
		this.fetchCartOrderCount();
	}

	isDownloadSectionVisible() {
		if (this.isCorporateUser) return true;
		return (
			this.commonService.isPermission(this.authUser.roleId, PERMISSIONS.DOWNLOAD_DRIVER_SALES_REPORT) ||
			this.commonService.isPermission(this.authUser.roleId, PERMISSIONS.DOWNLOAD_FLEET_OWNER_SALES_REPORT) ||
			this.commonService.isPermission(this.authUser.roleId, PERMISSIONS.DOWNLOAD_INDEPENDENT_GAS_RETAILER_SALES_REPORT) ||
			this.commonService.isPermission(this.authUser.roleId, PERMISSIONS.DOWNLOAD_HUB_DISTRIBUTOR_SALES_REPORT)
		)
	}

	// Is permission
	isPermission(value) {
		if (this.isCorporateUser)
			return true;
		return this.commonService.isPermission(this.authUser.roleId, value);
	}

	// Fetch Cart Order Count
	fetchCartOrderCount() {
		this.cartOrderEvent.next('');
	}

	// Download Sales Report
	downloadSalesReport(reportType, orderMode) {
		const filter = this.isFilter ? this.isFilter + `&deliveryMode=${orderMode}` : `?deliveryMode=${orderMode}`;
		const csvName = {
			[this.reportType.ASSOCIATE_SALES]: 'associate-sales-report.csv',
			[this.reportType.HUB_DISTRIBUTOR_SALES]: 'hub-distributor-sales-report.csv',
			[this.reportType.INDEPENDENT_SALES]: 'independent-sales-report.csv',
			[this.reportType.FLEET_OWNER_SALES]: 'fleet-owner-sales-report.csv'
		};
		const message = {
			[this.reportType.ASSOCIATE_SALES]: MESSAGE.NO_ORDER_FOUND,
			[this.reportType.HUB_DISTRIBUTOR_SALES]: MESSAGE.NO_HUB_DISTRIBUTOR_AVAILABLE,
			[this.reportType.INDEPENDENT_SALES]: MESSAGE.NO_INDEPENDENT_GAS_RETAILER_AVAILABLE,
			[this.reportType.FLEET_OWNER_SALES]: MESSAGE.NO_FLEET_OWNER_AVAILABLE
		};
		this.currentReportType = reportType;
		this.orderService.downloadSalesReport(reportType, filter).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});

			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = csvName[reportType];
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			this.currentReportType = 0;
		}, error => {
			this.currentReportType = 0;
			this.commonService.growlError(message[reportType]);
		});
	}
	openOrderPopup() {

		Swal.fire({
			title: TITLE.ORDER_TYPE_SELECTION,
			html: `<div>
			<label style="float:left;" for="swal-input1">Sort By</label>
			<select style="margin-top:0px;" id="swal-input1" class="swal2-input">
				<option value="latest_first" selected>Latest First</option>
				<option value="latest_last">Oldest First</option>
			</select>
			<label style="float:left;" for="swal-input2">Limit</label>
			<select style="margin-top:0px;" id="swal-input2" class="swal2-input">
				<option selected>500</option>
				<option>1000</option>
				<option>10000</option>
			</select>
			<label style="float:left;" for="swal-input3">Role Type</label>
			<select style="margin-top:0px;" id="swal-input3" class="swal2-input">
				<option value="client" selected>Client</option>
				<option value="associate">Driver</option>
				<option value="fleet">Fleet</option>
				<option value="hub">Smart Gas Hub</option>
				<option value="igr">Gas Suppliers</option>
			</select>
		</div>`,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				const v1 = (document.getElementById('swal-input1') as HTMLSelectElement)
				const v2 = (document.getElementById('swal-input2') as HTMLInputElement)
				const v3 = (document.getElementById('swal-input3') as HTMLInputElement)
				this.downloadUserListReport(v1, v2, v3)
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	openUnapprovedOrderPopup() {

		Swal.fire({
			title: TITLE.ORDER_TYPE_SELECTION,
			html: `<div>
			<label style="float:left;" for="swal-input1">Sort By</label>
			<select style="margin-top:0px;" id="swal-input1" class="swal2-input">
				<option value="latest_first" selected>Latest First</option>
				<option value="latest_last">Oldest First</option>
			</select>
			<label style="float:left;" for="swal-input2">Limit</label>
			<select style="margin-top:0px;" id="swal-input2" class="swal2-input">
				<option selected>500</option>
				<option>1000</option>
				<option>10000</option>
			</select>
			<label style="float:left;" for="swal-input3">Role Type</label>
			<select style="margin-top:0px;" id="swal-input3" class="swal2-input">
				<option value="associate">Driver</option>
				<option value="fleet">Fleet</option>
				<option value="independent">Gas Suppliers</option>
			</select>
		</div>`,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				const v1 = (document.getElementById('swal-input1') as HTMLSelectElement)
				const v2 = (document.getElementById('swal-input2') as HTMLInputElement)
				const v3 = (document.getElementById('swal-input3') as HTMLInputElement)
				this.downloadUnapprovedUserListReport(v1, v2, v3)
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Download Sales Report
	downloadUserListReport(v1, v2, v3) {
		const limit = v2.options[v2.selectedIndex].value;
		const sortBy = v1.options[v1.selectedIndex].value;
		const roleType = v3.options[v3.selectedIndex].value;

		const otherFilter = `limit=${limit ? limit : ""}&sortBy=${sortBy ? sortBy : ""}&roleType=${roleType ? roleType : ""}`

		const filter = this.isFilter ? this.isFilter + `&${otherFilter}` : `?${otherFilter}`;
		this.orderService.downloadUserListReport(filter).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});

			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = "userlist.csv";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			this.currentReportType = 0;
		}, error => {
			this.currentReportType = 0;
			if (error) {
				this.commonService.growlError("User List Not Found");
			} else {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Download Unapproved Sales Report
	downloadUnapprovedUserListReport(v1, v2, v3) {
		const limit = v2.options[v2.selectedIndex].value;
		const sortBy = v1.options[v1.selectedIndex].value;
		const roleType = v3.options[v3.selectedIndex].value;

		const otherFilter = `limit=${limit ? limit : ""}&sortBy=${sortBy ? sortBy : ""}&roleType=${roleType ? roleType : ""}`

		const filter = this.isFilter ? this.isFilter + `&${otherFilter}` : `?${otherFilter}`;
		this.orderService.downloadUnapprovedUserListReport(filter).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});

			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = "userlist.csv";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			this.currentReportType = 0;
		}, error => {
			this.currentReportType = 0;
			if (error) {
				this.commonService.growlError("User List Not Found");
			} else {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	onFilter(obj) {
		this.isFilter = "?startDate=" + obj.startDate + "&endDate=" + obj.endDate;
	}
}
