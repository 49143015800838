import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IndependentGasRetailerService, CommonService } from '../../../__services';
import { PAGINATION } from '../../../__utilities/pagination';
import { TITLE } from '../../../__utilities/text';

@Component({
	selector: 'app-independent-gas-retailer-payment',
	templateUrl: './independent-gas-retailer-payment.component.html',
	styleUrls: ['./independent-gas-retailer-payment.component.css']
})
export class IndependentGasRetailerPaymentComponent implements OnInit, OnDestroy {
	searchStr: string = "";
	isLoading: boolean = false;
	paginate: any = { ...PAGINATION };
	retailerPayment: Array<any> = [];
	selectedRow: any = "";
	status: object = {
		NEW: "Processing",
		SUCCESSFUL: "Transferred",
		FAILED: "Failed"
	}
	orderStatus: object = {
		all: "All",
		processing: "Processing",
		failed: "Failed",
		transferred: "Transferred"
	};
	paymentStatus: string = "";
	routeReuseSubscription:Subscription;
	constructor(
		public retailerService: IndependentGasRetailerService,
		public commonService: CommonService
	) { }

	ngOnInit(): void {
		this.fetchGasRetailerPayment();
		this.routeSubscription();
	}

	fetchGasRetailerPayment() {
		this.isLoading = true;
		this.retailerService.fetchRetailerPayment(this.queryParams()).subscribe(response => {
			if (response && response.success && response.response) {
				this.paginate.totalItems = response.response.pagination.totalDocs;
				this.retailerPayment = response.response.data;
			}
			this.isLoading = false;
		}, error => {
			this.retailerPayment = [];
			this.isLoading = false;
		});
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.INDEPENDENT_PAYMENT_HISTORY ? (this.selectedRow = "") : false;
		});
	}

	viewDetail(payment) {
		this.selectedRow = payment;
	}

	queryParams() {
		let searchStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.paymentStatus)
			searchStr += "&filter=" + this.paymentStatus;
		return searchStr;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fetchGasRetailerPayment();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
		this.retailerPayment = [];
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.retailerPayment = [];
		this.fetchGasRetailerPayment();
	}

	onPaymentStatusChange(status) {
		this.paymentStatus = status.toLowerCase();
		this.resetPagination();
		this.fetchGasRetailerPayment();
	}

	goBack() {
		this.selectedRow = "";
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}
