import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    constructor(private http: HttpClient, private headerService: HeaderService) { }

    // Login Functionality
    login(credentials): Observable<any> {
        return this.http.post(BASE_URL.LOGIN, credentials, this.headerService.httpHeaders());
    }

    // Signup Gas Retailer
    signUpGasRetailer(registrationData): Observable<any> {
        return this.http.post(BASE_URL.INDEPENDENT_GAS_RETAILER, registrationData, this.headerService.imageHttpHeaders());
    }

    // Forgot Password
    forgotPassword(email): Observable<any> {
        return this.http.post(BASE_URL.FORGOT_PASSWORD, email, this.headerService.httpHeaders());
    }

    // Verify Token
    verifyToken(email): Observable<any> {
        return this.http.post(BASE_URL.VERIFICATION_TOKEN, email, this.headerService.httpHeaders());
    }

    // Forgot Password
    resetPassword(data): Observable<any> {
        return this.http.post(BASE_URL.RESET_PASSWORD, data, this.headerService.httpHeaders());
    }

    // Captcha Verification
    captchaVerification(payload): Observable<any> {
        return this.http.post(BASE_URL.CAPTCHA_VERIFICATION, payload, this.headerService.httpHeaders());
    }

}