import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService, ProfileService, CommonService } from '../../../../__services';

@Component({
	selector: 'app-emergency-contacts',
	templateUrl: './emergency-contacts.component.html',
	styleUrls: ['./emergency-contacts.component.css']
})
export class EmergencyContactsComponent implements OnInit {

	@Input() phoneConfig;
	@Input() defaultCountry;
	@Input() message;

	emergencyContactForm: UntypedFormGroup;
	isSubmitted: boolean = false;
	emergencyContact: any;
	isDisabled: boolean = false;
	isUnknownLocation: boolean = false;
	address: string = "";
	options = {
		types: ['address'],
		//componentRestrictions: { country: 'ng' }
	};
	constructor(
		public validationService: ValidationService,
		public profileService: ProfileService,
		public commonService: CommonService,
		private fb: UntypedFormBuilder,
		private cd: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this.initializeControls();
		this.fetchEmergencyContact();
	}

	initializeControls() {
		this.emergencyContactForm = this.fb.group({
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			contact: ['', Validators.compose([Validators.required])],
			address: ['', Validators.compose([Validators.required])],
		});
	}

	onSubmit(emergencyContactData) {
		this.isSubmitted = true;
		if (this.emergencyContactForm.valid) {
			this.isDisabled = true;
			emergencyContactData = this.createPayload(emergencyContactData);
			this.profileService.manageEmergencyContact(emergencyContactData).subscribe(res => {
				if (res && res.success) {
					this.commonService.growlSuccess(res.message);
					this.resetForm();
				}
				else
					this.commonService.growlError(res.message);
			}, error => {
				this.resetForm();
				this.commonService.growlError(this.message.ERROR);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.emergencyContactForm);
		}
	}

	createPayload(payload) {
		payload.dialCode = payload.contact.dialCode ? payload.contact.dialCode : this.emergencyContact.dialCode;
		payload.countryCode = payload.contact.countryCode ? payload.contact.countryCode : this.emergencyContact.countryCode;
		payload.contact = payload.contact.number ? this.commonService.formatContactNumber(payload.contact.number) : this.emergencyContact.contact;
		payload.address = this.address;
		if (this.emergencyContact)
			payload.id = this.emergencyContact._id;
		return payload;
	}

	// Fetch Emergency Contact
	fetchEmergencyContact() {
		this.profileService.fetchEmergencyContact().subscribe(res => {
			if (res && res.success && res.contact && res.contact.length) {
				this.emergencyContact = res.contact[0];
				this.setValues(res.contact[0]);
			}
		}, error => {
		});
	}

	// Selected Contact
	setValues(contact) {
		this.isSubmitted = false;
		this.emergencyContactForm.controls.firstName.setValue(contact.firstName);
		this.emergencyContactForm.controls.lastName.setValue(contact.lastName);
		this.emergencyContactForm.controls.address.setValue(contact.address);
		this.address = contact.address;
		this.phoneConfig.CountryISO = contact.countryCode;
		this.emergencyContactForm.controls.contact.setValue(contact.contact);
		if (!this.cd['destroyed']) this.cd.detectChanges();
	}

	// Reset Form
	resetForm() {
		this.isDisabled = false;
		this.isSubmitted = false;
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			this.address = address.formatted_address;
			this.emergencyContactForm.controls.address.setValue(this.address);
		}
		else {
			this.emergencyContactForm.controls.address.setValue('');
			this.isUnknownLocation = true;
		}
	}
}
