import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ORDER_TYPE, ORDER_VALUES, PAYMENT_MODE, CLIENT_PAYMENT_MODE } from '../../__utilities/orders';
import { CommonService } from '../../__services/common.service';
import { Router } from '@angular/router';
declare function generatePDF(content): any;

@Component({
	selector: 'app-order-payment',
	templateUrl: './order-payment.component.html',
	styleUrls: ['./order-payment.component.css']
})
export class OrderPaymentComponent implements OnInit {

	@Input() orderDetails: any = "";
	@Input() paymentDetails: any = "";
	@Input() isInvoiceLoading: boolean = false;
	@Input() isInvoiceSending: boolean = false;
	@Input() activatedRouteValue: any = "";
	@Input() retailerId: string = "";
	@Input() queryParam: object = {};
	@Output() emailInvoice = new EventEmitter<any>();
	@Output() printPdf = new EventEmitter<any>();
	orderDescription: any = ORDER_VALUES;
	ordersType: any = ORDER_TYPE;
	paymentMode: object = {};
	constructor(
		public commonService: CommonService,
		public router: Router
	) {
		this.paymentMode = Object.assign(PAYMENT_MODE, CLIENT_PAYMENT_MODE);
	}

	ngOnInit(): void {
	}

	goBack() {
		if (this.retailerId)
			this.router.navigate([this.activatedRouteValue.breadcrumb[0].url, this.retailerId], { queryParams: this.queryParam });
		else
			this.router.navigate([this.activatedRouteValue.breadcrumb[0].url], { queryParams: this.queryParam });

	}

	// Print PDF
	printPDF() {
		this.printPdf.next();
	}

	// Send Invoice Email
	sendInvoiceEmail() {
		this.emailInvoice.next();
	}
}