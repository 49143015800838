import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { SettingService, ValidationService, CommonService, TokenService } from '../../../../__services';
import { PERMISSIONS } from '../../../../__utilities/permissions';

@Component({
	selector: 'app-common-setting',
	templateUrl: './common-setting.component.html',
	styleUrls: ['./common-setting.component.css']
})
export class CommonSettingComponent implements OnInit {

	commonSettingForm: UntypedFormGroup;
	isDisabled: boolean = false;
	settings: any;
	isPermission: boolean = false;
	constructor(
		public validationService: ValidationService,
		private fb: UntypedFormBuilder,
		public commonService: CommonService,
		public settingService: SettingService,
		public tokenService: TokenService) { }


	ngOnInit() {
		const permission = this.tokenService.getPermission();
		this.isPermission = this.commonService.isPermission(permission, PERMISSIONS.UPDATE_COMMON_SETTING);
		this.initializeControl();
		this.fetchSetting();
	}

	// Initialize Controls
	initializeControl() {
		this.commonSettingForm = this.fb.group({
			referralPercentage: ['', Validators.compose([Validators.required])],
			// maxCylinderOrderByHub: ['', Validators.compose([Validators.required])],
			maxCylindersAcceptByDriver: ['', Validators.compose([Validators.required])],
			// cutOffTime: ['', Validators.compose([Validators.required])],
			maxTimeForOrderCancellation: ['', Validators.compose([Validators.required])],
			orderAutoCancelledTime: ['', Validators.compose([Validators.required])],
			driverAcceptOrderBeforeTime: ['', Validators.compose([Validators.required])],
			driverRequestOrderBeforeTime: ['', Validators.compose([Validators.required])],
			nearestDriverMaxDistance: ['', Validators.compose([Validators.required])],
			payoutToDrivers: ['', Validators.compose([Validators.required])],
			transactionHandlingFees: ['', Validators.compose([Validators.required])],
			// commissionToSmartGas: ['', Validators.compose([Validators.required])],
			gasRetailerCommission: ['', Validators.compose([Validators.required])],
			nearestHubMaxDistance: ['', Validators.compose([Validators.required])],
			primeDeliveryCharge: ['', Validators.compose([Validators.required])],
			deliveryChargePerKm: ['', Validators.compose([Validators.required])],
			travelChargePerMinute: ['', Validators.compose([Validators.required])],
			orderCancellationPenalty: ['', Validators.compose([Validators.required])],
			newCylinderCommission: ['', Validators.compose([Validators.required])],
			// exchangeOrderEnabled: ['', Validators.compose([])],
			newPurchaseOrderEnabled: ['', Validators.compose([])],
			refillOrderEnabled: ['', Validators.compose([])],
			locationRestrictToNigeria: ['', Validators.compose([])]
		});
	}

	// Fetch Setting
	fetchSetting() {
		this.settingService.fetchSettings().subscribe(res => {
			if (res && res.success && res.setting) {
				this.settings = res.setting;
				this.setValues(res.setting);
			}
		}, error => {

		});
	}

	setValues(values) {
		let controls = this.commonSettingForm.controls;
		controls.referralPercentage.setValue(values.referralAmount);
		// controls.maxCylinderOrderByHub.setValue(values.maxCylinderOrderPlant);
		controls.maxCylindersAcceptByDriver.setValue(values.maxOrderRequestAcceptAssociate);
		// controls.cutOffTime.setValue(this.convertToTime(values.cutOffTime));
		controls.maxTimeForOrderCancellation.setValue(values.orderCancelMaxTime);
		controls.orderAutoCancelledTime.setValue(values.NotScheduleOrderCancelMaxTime);
		controls.driverAcceptOrderBeforeTime.setValue(this.convertToTime(values.associateAcceptOrderBeforeTime));
		controls.driverRequestOrderBeforeTime.setValue(this.convertToTime(values.associateRequestOrderBeforeTime));
		controls.nearestDriverMaxDistance.setValue(values.nearestAssociateMaxDistance);
		controls.payoutToDrivers.setValue(values.payoutToAssociates);
		controls.transactionHandlingFees.setValue(values.transactionHandlingFees);
		// controls.commissionToSmartGas.setValue(values.commissionToSmartGas);
		controls.gasRetailerCommission.setValue(values.gasRetailerCommission);
		controls.nearestHubMaxDistance.setValue(values.nearestPlantMaxDistance);
		controls.primeDeliveryCharge.setValue(values.primeDeliveryFees);
		controls.deliveryChargePerKm.setValue(values.deliveryChargePerKm);
		controls.travelChargePerMinute.setValue(values.travelChargePerMinute);
		controls.orderCancellationPenalty.setValue(values.orderCancellationPenalty);
		controls.newCylinderCommission.setValue(values.newCylinderCommission);
	}

	onCheckboxChange(event, control) {
		this.settings[control] = event.target.checked;
	}

	// Update Setting
	onSubmit(setting) {
		if (this.commonSettingForm.valid) {
			this.isDisabled = true;
			// setting.maxCylinderOrderPlant = setting.maxCylinderOrderByHub;
			setting.nearestPlantMaxDistance = setting.nearestHubMaxDistance;
			setting.maxOrderRequestAcceptAssociate = setting.maxCylindersAcceptByDriver;
			setting.orderCancelMaxTime = setting.maxTimeForOrderCancellation;
			setting.NotScheduleOrderCancelMaxTime = setting.orderAutoCancelledTime;
			setting.referralAmount = setting.referralPercentage;
			setting.primeDeliveryFees = setting.primeDeliveryCharge;
			setting.nearestAssociateMaxDistance = setting.nearestDriverMaxDistance;
			setting.payoutToAssociates = setting.payoutToDrivers;
			// setting.cutOffTime = this.convertToEpoch(setting.cutOffTime);
			setting.associateRequestOrderBeforeTime = this.convertToEpoch(setting.driverRequestOrderBeforeTime);
			setting.associateAcceptOrderBeforeTime = this.convertToEpoch(setting.driverAcceptOrderBeforeTime);
			// setting.exchangeOrderEnabled = this.settings.exchangeOrderEnabled;
			setting.newPurchaseOrderEnabled = this.settings.newPurchaseOrderEnabled;
			setting.refillOrderEnabled = this.settings.refillOrderEnabled;
			setting.locationRestrictToNigeria = this.settings.locationRestrictToNigeria;
			// delete setting.maxCylinderOrderByHub;
			delete setting.nearestHubMaxDistance;
			delete setting.maxTimeForOrderCancellation;
			delete setting.referralPercentage;
			delete setting.orderAutoCancelledTime;
			delete setting.maxCylindersAcceptByDriver;
			delete setting.primeDeliveryCharge;
			delete setting.nearestDriverMaxDistance;
			delete setting.payoutToDrivers;
			delete setting.driverAcceptOrderBeforeTime;
			delete setting.driverAcceptOrderBeforeTime;
			this.settingService.updateSettings(this.settings._id, setting).subscribe(res => {
				if (res.success) {
					this.settings = res.setting;
					this.setValues(res.setting);
					this.commonService.growlSuccess(res.message);
				}
				this.isDisabled = false;
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		} else {
			this.validationService.validateAllFormFields(this.commonSettingForm);
		}
	}
	convertToEpoch(time) {
		var d = new Date()
		var newDate = new Date(d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate() + " " + time).getTime();
		return newDate;
	}

	convertToTime(date) {
		var time = new Date(date).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit', hour12: false });
		return time;
	}
}