import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '../__services/';
import { CommonService } from '../__services/common.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private tokenService: TokenService,
        private commonService: CommonService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isAuthenticated = this.tokenService.isAuthenticated();
        if (isAuthenticated) {
            this.router.events.subscribe((event) => {
                if (!this.router.navigated && (this.router.url == state.url)) {
                    const _this = this;
                    setTimeout(() => {
                        _this.commonService.sendRouteChanges(route.data.breadcrumb);
                    }, 100);
                }
                else {
                    this.commonService.sendRouteChanges(route.data.breadcrumb);
                }
            });
            let auth: any = this.tokenService.getLoggedInUser();
            let isPermissionLength = route.data.permission && route.data.permission.length;
            if (!isPermissionLength) return true;
            let isRoleMatch = isPermissionLength && route.data.permission.indexOf(auth.roleId.name) > -1;
            let isPermissionMatch = (isPermissionLength && !isRoleMatch && (typeof auth.roleId.permission == "object"))
                ? auth.roleId.permission.indexOf(route.data.permission[0]) > -1 : false;
            return (isRoleMatch || isPermissionMatch) ? true : this.router.navigate(['/dashboard']);
        }
        else {
            this.router.navigate(["/login"]);
            return false;
        }

    }
}