import { Component, OnInit, Input } from '@angular/core';
import { TITLE } from '../../__utilities/text';

@Component({
  selector: 'app-cylinder-quantity',
  templateUrl: './cylinder-quantity.component.html',
  styleUrls: ['./cylinder-quantity.component.css']
})
export class CylinderQuantityComponent implements OnInit {

  @Input() cylinder: any;
  @Input() commonService: any;
  @Input() cylinderCategories: any;
  @Input() isIndependentGasRetailer: boolean = false;
  title: any = TITLE;
  constructor() { }

  ngOnInit(): void {
  }

}
