import { Component, OnInit, ViewChild } from '@angular/core';
import { ChatService, WebSocketService, TokenService, CommonService } from '../../__services';
import { PAGINATION } from '../../__utilities/pagination';
import { ROLES, ROLES_APP_USER } from '../../__utilities/roles';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-chat-layout',
	templateUrl: './chat-layout.component.html',
	styleUrls: ['./chat-layout.component.css']
})
export class ChatLayoutComponent implements OnInit {
	@ViewChild(InfiniteScrollDirective) infiniteScroll: InfiniteScrollDirective;
	users: any;
	paginate: any = { ...PAGINATION };
	throttle = 150;
	scrollUpDistance = 3;
	direction = '';
	selectedUser: any = {};
	selectedUserId: string = "";
	chatHistory = [];
	authUser: any;
	isLoading: boolean = false;
	activeGroup: string = '';
	roles: any = ROLES;
	isOnScroll: boolean = false;
	searchText: String = '';
	selectedIndex: number = 0;
	status: boolean = false;
	unhide: any = "d-block trans";
	hide: any = "d-none trans";
	senderId: string = "";
	titles = {
		CORPORATE: 'Corporate',
		PLANT: 'Hub Name',
		WAREHOUSE: 'Warehouse Name',
		ASSOCIATES: 'Drivers',
		CLIENTS: 'Clients',
		DISTRIBUTORS: 'Distributors',
		INDEPENDENT_GAS_RETAILER: 'Company Name'
	};
	usersKey = {
		CORPORATE: 'employeeUser',
		PLANT: 'plantUser',
		WAREHOUSE: 'warehouseUser',
		ASSOCIATES: 'associateUser',
		DISTRIBUTORS: 'distributorUser',
		INDEPENDENT: 'independentUser'
	};
	constructor(
		public chatService: ChatService,
		public webSocketService: WebSocketService,
		public tokenService: TokenService,
		public commonService: CommonService,
		public activatedRoute: ActivatedRoute
	) {
		this.authUser = this.tokenService.getLoggedInUser();
		this.fetchQueryParams();
	}

	ngOnInit(): void {
	}

	fetchQueryParams() {
		this.activatedRoute.queryParams.subscribe(res => {
			if (res && res.senderId && res.key) {
				this.activeGroup = res.key;
				this.senderId = res.senderId;
			}
			this.fetchUsers();
		});
	}

	fetchUsers() {
		this.isLoading = true;
		this.chatService.fetchChatUsers().subscribe(res => {
			if (res && res.success && res.response) {
				this.users = res.response;
				const activeGroup = this.activeGroup.toUpperCase();
				var user = this.users[this.usersKey[activeGroup]] ? this.users[this.usersKey[activeGroup]] : [];
				var selectedTitle: string = "";
				switch (this.activeGroup) {
					case ROLES.CORPORATE:
						selectedTitle = this.titles.CORPORATE;
						break;
					case ROLES.PLANT_MANAGER:
						selectedTitle = this.titles.PLANT;
						break;
					case ROLES.WAREHOUSE_MANAGER:
						selectedTitle = this.titles.WAREHOUSE;
						break;
					case ROLES.DISTRIBUTOR:
						selectedTitle = this.titles.DISTRIBUTORS;
						break;
					case ROLES.INDEPENDENT_RETAILER:
						selectedTitle = this.titles.INDEPENDENT_GAS_RETAILER;
						break;
					case ROLES_APP_USER.ASSOCIATE:
						selectedTitle = this.titles.ASSOCIATES;
						break;
					default:
						selectedTitle = "";
				}

				if (user && user.length) {
					let _this = this;
					let userId = "";
					const userRoleArr = [this.titles.CORPORATE, this.titles.ASSOCIATES, this.titles.CLIENTS];
					user.every(function (element, index) {
						userId = userRoleArr.indexOf(selectedTitle) > -1 ? element._id : element.user._id;
						if (userId == _this.senderId) {
							_this.selectUser(element, selectedTitle, index);
							return false;
						}
						return true;
					});
				}
			}
			else
				this.users = '';
			this.isLoading = false;
			const _this = this;
			setTimeout(() => {
				_this.subscribeMessage();
			}, 2000);
		}, error => {
			this.isLoading = false;
			this.users = '';
		});
	}

	subscribeMessage() {
		this.webSocketService.onMessage().subscribe(res => {
			if (this.selectedUserId == res.senderId._id) {
				this.chatHistory.push(res);
				this.setLastMessage(res);
				this.resetChatInput();
			}
		});
	}

	onEnter(message) {
		this.postChat(message);
	}

	// Show Group
	showGroup(group) {
		this.activeGroup = this.activeGroup == group ? "" : group;
	}
	// Select User
	selectUser(user, selectedTitle, index) {
		if (window.matchMedia('(max-width: 800px)').matches) {
			$('html, body').animate({
				scrollTop: $("#chatInput").offset().top
			}, 2000);
		}

		if (this.status === false)
			this.status = true;
		this.selectedUser = {};
		this.selectedIndex = index;
		let isTrue = (selectedTitle == this.titles.CORPORATE ||
			selectedTitle == this.titles.ASSOCIATES ||
			selectedTitle == this.titles.CLIENTS);
		let userId = isTrue ? user._id : user.user._id;
		if (isTrue)
			this.selectedUser.user = user;
		else
			this.selectedUser = user;
		this.selectedUser.selectedTitle = selectedTitle;
		if (this.selectedUserId && this.selectedUserId == userId) return;
		this.selectedUserId = userId;
		this.chatHistory = [];
		if (!user.message || !Object.keys(user.message).length) return;
		this.paginate.currentPage = 1;
		this.isOnScroll = false;
		this.infiniteScroll.ngOnDestroy();
		this.infiniteScroll.setup();
		this.fetchChatHistory(this.selectedUserId);
	}

	// Post Message
	postChat(message) {
		message = message.trim();
		if (message && Object.keys(this.selectedUser).length) {
			this.isLoading = true;
			let payload = { id: this.selectedUserId, message: message };
			this.chatService.postChat(payload).subscribe(res => {
				if (res && res.success && res.response) {
					this.chatHistory.push(res.response);
					this.setLastMessage(res.response);
					this.resetChatInput();
				}
				this.isLoading = false;
			}, error => {
				this.isLoading = false;
			});
		}
	}

	setLastMessage(response) {
		this.users[this.usersKey[this.activeGroup.toUpperCase()]][this.selectedIndex].message = response;
	}

	resetChatInput() {
		setTimeout(() => {
			(<HTMLInputElement>document.getElementById('chatInput')).value = '';
			var objDiv = document.getElementById("chatDiv");
			objDiv.scrollTop = objDiv.scrollHeight;
		}, 0);
	}

	// Fetch Chat History
	fetchChatHistory(receiverId) {
		this.isLoading = true;
		let searchQuery = "?&page=" + this.paginate.currentPage + "&limit=" + (this.paginate.itemsPerPage) + "&search=";
		this.chatService.fetchChatHistory(receiverId, searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				if (res.response.messages && res.response.messages.length) {
					let arr = res.response.messages.reverse();
					arr.forEach(element => {
						this.chatHistory.unshift(element);
					});
					if (!this.isOnScroll)
						this.resetChatInput();
				}
				this.paginate.totalItems = res.response.pagination.totalDocs;
			} else {
				this.chatHistory = [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.chatHistory = [];
		});
	}

	onScrollUp() {
		this.paginate.currentPage += 1;
		this.isOnScroll = true;
		this.fetchChatHistory(this.selectedUserId);
	}

	searchUser(searchStr) {
		this.searchText = searchStr;
	}
}