import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http'


@Injectable({
	providedIn: 'root'
})
export class HeaderService {

	headers: any;

	constructor() {
		this.headers = new HttpHeaders;

	}

	public httpHeaders() {
		let headers = new HttpHeaders();
		headers = headers.set('Access-Control-Allow-Origin', '*');
		headers = headers.set('Content-type', 'application/json');
		headers = headers.set('Accept', 'application/json');
		let option = { headers: headers };
		return option;
	}

	public imageHttpHeaders() {
		let headers = new HttpHeaders();
		headers.append('Cache-Control', 'no-cache');
		headers.append('Pragma', 'no-cache');
		headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
		headers.append('Accept', 'application/json');
		let option = { headers: headers };
		return option;
	}

}
