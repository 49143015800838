export const MESSAGE = {
    DELETE_ROLE_CONFIRMATION_TITLE: 'Delete Role',
    DELETE_ROLE_CONFIRMATION_TEXT: 'Do you want to delete role ',
    ERROR: 'Something went wrong',
    NO_RECORD_FOUND: 'No record found',
    NO_COMMENT: 'No comment yet',
    NO_ORDER_FOUND: 'No order found',
    DELETE_EMERGENCY_CONTACT_CONFIRMATION_TITLE: 'Delete Contact',
    DELETE_EMERGENCY_CONTACT_CONFIRMATION_TEXT: 'Do you want to delete contact ',
    DELETE_PLANT_CONFIRMATION_TITLE: 'Delete Hub',
    DELETE_PLANT_CONFIRMATION_TEXT: 'Do you want to delete Hub ',
    INVALID_DOC_FORMAT: 'Invalid format (Accept only jpg, png, doc & pdf)',
    INVALID_IMG_FORMAT: 'Invalid format (Accept only jpg, png)',
    DELETE_CATEGORY_CONFIRMATION_TITLE: 'Delete Category',
    DELETE_BRAND_CONFIRMATION_TITLE: 'Delete Brand',
    DELETE_CATEGORY_CONFIRMATION_TEXT: 'Do you want to delete selected category ?',
    DELETE_BRAND_CONFIRMATION_TEXT: 'Do you want to delete selected Brand ?',
    DELETE_WAREHOUSE_CONFIRMATION_TITLE: 'Delete Warehouse',
    DELETE_WAREHOUSE_CONFIRMATION_TEXT: 'Do you want to delete warehouse ',
    DELETE_CYLINDER_CONFIRMATION_TITLE: 'Delete Cylinder',
    DELETE_CYLINDER_CONFIRMATION_TEXT: 'Do you want to delete cylinder size ',
    DELETE_GUARANTOR_CONFIRMATION_TITLE: 'Delete Guarantor',
    DELETE_GUARANTOR_CONFIRMATION_TEXT: 'Do you want to delete guarantor ',
    DELETE_CYLINDER_REQUEST_CONFIRMATION_TITLE: 'Delete Request',
    DELETE_CYLINDER_REQUEST_CONFIRMATION_TEXT: 'Do you want to delete selected request ?',
    CANCEL_ORDER_CONFIRMATION_TITLE: 'Cancel Order',
    CANCEL_ORDER_CONFIRMATION_TEXT: 'Do you want to cancel selected order ?',
    DELETE_RECYCLE_CONFIRMATION_TITLE: 'Delete Recycle Station',
    DELETE_RECYCLE_CONFIRMATION_TEXT: 'Do you want to delete selected station ?',
    REJECT_ASSOCIATE_CONFIRMATION_TITLE: 'Block Driver',
    REJECT_ASSOCIATE_CONFIRMATION_TEXT: 'Do you want to block driver ',
    REJECT_INDIVIDUAL_GAS_RETAILER_CONFIRMATION_TITLE: 'Block Retailer',
    REJECT_INDIVIDUAL_GAS_RETAILER_CONFIRMATION_TEXT: 'Do you want to block retailer ',
    DELETE_USER_CONFIRMATION_TITLE: 'Delete User',
    DELETE_USER_CONFIRMATION_TEXT: 'Do you want to delete user ',
    DELETE_CYLINDER_RECYCLE_REQUEST_CONFIRMATION_TITLE: 'Delete Request',
    DELETE_CYLINDER_RECYCLE_REQUEST_CONFIRMATION_TEXT: 'Do you want to delete selected request',
    REJECT_ASSOCIATE_REQUEST_CONFIRMATION_TITILE: 'Reject Request',
    REJECT_ASSOCIATE_REQUEST_CONFIRMATION_TEXT: 'Do you want to reject selected request ?',
    ACCEPT_DELETE_ACCOUNT_REQUEST_TITLE: 'Accept Request',
    ACCEPT_DELETE_ACCOUNT_REQUEST_TEXT: 'Do you want to accept selected account request ?',
    REJECT_DELETE_ACCOUNT_REQUEST_TITLE: 'Reject Request',
    REJECT_DELETE_ACCOUNT_REQUEST_TEXT: 'Do you want to reject selected account request ?',
    DELETE_CARD_TITLE: 'Delete Card',
    DELETE_CARD_TEXT: 'Do you want to delete selected card ?',
    INVALID_INPUT: 'Please provide valid input',
    ORDER_PIN_REQUIRED: 'Order pin required',
    INVALID_ORDER_PIN: 'Invalid order pin',
    INVALID_PERMISSION: 'Please select atleast one permission.',
    INVALID_LINK: "Invalid Link",
    DELETE_REGULATOR_CONFIRMATION_TITLE: 'Delete Regulator',
    DELETE_REGULATOR_CONFIRMATION_TEXT: 'Do you want to delete selected regulator ?',
    DELETE_DISTRIBUTOR_CONFIRMATION_TITLE: 'Delete Distributor',
    DELETE_DISTRIBUTOR_CONFIRMATION_TEXT: 'Do you want to delete distributor ',
    APPROVE_ORDER_TITLE: 'Approve Order',
    CONFIRM_ORDER_TITLE: 'Confirm Order',
    REJECT_ORDER_TITLE: 'Reject Order',
    APPROVE_REJECT_ORDER: 'Do you want to ',
    ADD_TO_CART_CONFIRMATION_TITLE: 'Add to cart',
    ADD_TO_CART_CONFIRMATION_TEXT: 'Do you want to add this order to cart ?',
    INSUFFICIENT_WALLET_AMOUNT: 'Insufficient wallet amount.',
    WALLET_AMOUNT_EQUAL_TO_TOTAL: 'Wallet amount must be equal to total charge.',
    INVALID_PAYMENT_MODE: 'Insufficient wallet amount, please choose other payment mode.',
    NO_HUB_DISTRIBUTOR_AVAILABLE: 'No hub available.',
    NO_INDEPENDENT_GAS_RETAILER_AVAILABLE: 'No Gas Supplier available',
    NO_FLEET_OWNER_AVAILABLE: 'No fleet owner available',
    ENTER_QUANTITY: 'Please enter atleast one quantity.',
    INVALID_ACCOUNT_DETAILS: 'Sorry, that account number is invalid, please check and try again',
    GAS_RETAILER_SIGNUP_SUCCESS: 'Application Received! <br> An email will be sent to you once we make a decision. Thank you!',
    ORDER_REPORT_EMAIL_FAILED: 'Failed to email order report',
    SELECT_RECORD: 'Select no. of record',
    REPORT_DOWNLOAD_FAILED: 'Failed to download report'
}

export const FILE_PLACEHOLDER = {
    UPLOAD_RESUME: 'Choose a resume',
    UPLOAD_PROFILE: 'Choose a profile',
    UPLOAD_GOVT_ID: 'Upload Govt Issued Photo ID',
    UPLOAD_CATEGORY_IMAGE: 'Choose Category Image',
    UPLOAD_UTILITY_BILL: 'Upload Recent Utility Bill',
    REGULATOR_IMAGE: 'Upload Regulator Image',
    UPLOAD_CONTRACT: 'Upload Signed Contract',
    UPLOAD_CYLINDER_TOP_IMAGE: 'Upload Cylinder Top Image',
    UPLOAD_CYLINDER_SIDE1_IMAGE: 'Upload Cylinder Side 1 Image',
    UPLOAD_CYLINDER_SIDE2_IMAGE: 'Upload Cylinder Side 2 Image',
    SELECT_FROM_GALLERY: 'Select From Gallery',
    LIVE_PICTURE: 'Capture Live Image'
}