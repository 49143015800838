import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlantsComponent } from '../../index';
import { OrdersComponent } from '../../../containers';
import { AuthGuard } from '../../../__guard/auth.guard';
import { ROLES } from '../../../__utilities/roles';
import { TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';

const routes: Routes = [
	{
		path: '',
		component: PlantsComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PLANT,
			viewURL: '/hubs/hub-details/',
			viewOrdersURL:'/hubs/orders/',
			breadcrumb: [
				{
					label: TITLE.PLANT,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_HUB, ROLES.CORPORATE]
		}
	},
	{
		path: 'hub-details/:id',
		component: PlantsComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PLANT_DETAILS,
			breadcrumb: [
				{
					label: TITLE.PLANT,
					url: '/hubs'
				},
				{
					label: TITLE.PLANT_DETAILS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_HUB, ROLES.CORPORATE]
		}
	},
	{
		path: 'orders/:id',
		component: OrdersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PLANT_DETAILS,
			isOrders:true,
			viewURL:'/hubs/orders/order-details',
			breadcrumb: [
				{
					label: TITLE.PLANT,
					url: '/hubs'
				},
				{
					label: TITLE.ORDERS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_HUB, ROLES.CORPORATE]
		}
	},
	{
		path: 'orders/order-details/:id',
		component: OrdersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PLANT_DETAILS,
			isOrders:false,
			breadcrumb: [
				{
					label: TITLE.PLANT,
					url: '/hubs'
				},
				{
					label: TITLE.ORDERS,
					url: '/hubs/orders/'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_HUB, ROLES.CORPORATE]
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PlantsRoutingModule { }

export const ROUTING_COMPONENTS = [
	PlantsComponent
];
