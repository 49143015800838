import { environment } from '../../environments/environment';
export const END_BASE_URL = `${environment.API_HOST}/`;

export const BASE_URL = {
    TERMS_CONDITIONS: END_BASE_URL + 'admin/term-condition',
    CONSENT_CLAUSE: END_BASE_URL + 'admin/consent-clause',
    PRIVACY_POLICY: END_BASE_URL + 'admin/privacypolicy',
    VIEW_PRIVACY_POLICY: END_BASE_URL + 'utility/privacy-policy',
    VIEW_TERMS_CONDITIONS: END_BASE_URL + 'utility/term-condition',
    VIEW_CONSENT_CLAUSE: END_BASE_URL + 'utility/consent-clause',
    PERMISSIONS_LIST: END_BASE_URL + 'admin/role/permission',
    ROLE: END_BASE_URL + 'admin/role',
    LOGIN: END_BASE_URL + 'admin/login',
    PROFILE: END_BASE_URL + 'admin/user/profile',
    UPLOAD_AVATAR: END_BASE_URL + 'admin/user/profile-image',
    GUARANTOR: END_BASE_URL + 'api/user/guarantor',
    EMERGENCY_CONTACT: END_BASE_URL + 'api/user/emergency-contact',
    UPLOAD_DOCUMENTS: END_BASE_URL + 'admin/user/profile-id',
    PLANTS: END_BASE_URL + "admin/plant",
    CHANGE_PASSWORD: END_BASE_URL + 'admin/user/change-password',
    FORGOT_PASSWORD: END_BASE_URL + 'admin/forget-password',
    VERIFICATION_TOKEN: END_BASE_URL + 'utility/token',
    RESET_PASSWORD: END_BASE_URL + 'admin/reset-password',
    CYLINDER_CATEGORY: END_BASE_URL + 'admin/cylinder/category',
    INDEPENDENT_CYLINDER_CATEGORY: END_BASE_URL + 'admin/cylinder/retailer/category',
    WAREHOUSE: END_BASE_URL + 'admin/warehouse',
    CYLINDER: END_BASE_URL + 'admin/cylinder',
    MAKE_CYLINDER_REQUEST: END_BASE_URL + 'admin/plant/request',
    PLANT_CYLINDER_REQUEST_LIST: END_BASE_URL + 'admin/plant/',
    WAREHOUSE_REQUEST_LIST: END_BASE_URL + 'admin/warehouse',
    PUSH_NOTIFICATION_URL: END_BASE_URL + 'admin/subscription',
    CLIENTS: END_BASE_URL + 'admin/user/client',
    CLIENT_BY_ID: END_BASE_URL + 'admin/user/detail',
    ASSOCIATES: END_BASE_URL + 'admin/user/associate',
    WALKIN: END_BASE_URL + 'admin/walkin-user',
    PLANT_REQUEST_APPROVE: END_BASE_URL + 'admin/warehouse/request',
    ORDER_PURCHASE: END_BASE_URL + 'admin/order/purchase',
    CANCEL_ORDER_PURCHASE: END_BASE_URL + 'api/order/purchase',
    ORDER_REFILL: END_BASE_URL + 'admin/order/refill',
    PLANT_ORDER: END_BASE_URL + 'admin/plant/',
    MANAGE_PLANT_REFILL_REQUEST: END_BASE_URL + 'admin/warehouse/refill',
    PLANT_REFILL_REQUEST: END_BASE_URL + 'admin/plant/refill',
    FETCH_NOTIFICATIONS: END_BASE_URL + 'admin/notification',
    READ_NOTIFICATION: END_BASE_URL + 'admin/notification/read',
    ASSOCIATE_REQUEST: END_BASE_URL + 'admin/plant/associate/request',
    ASSOCIATE_REQUEST_CART: END_BASE_URL + 'admin/cart/order/associate/request',
    CHAT_URL: END_BASE_URL + 'utility/chat',
    CHAT_USERS: END_BASE_URL + 'utility/users',
    CORPORATE_PLANT_CYLINDER_REQUEST: END_BASE_URL + 'admin/corporate/plant/cylinder/request',
    CORPORATE_PLANT_REFILL_REQUEST: END_BASE_URL + 'admin/corporate/plant/cylinder/refill',
    CORPORATE_ASSOCIATE_CYLINDER_REQUEST: END_BASE_URL + 'admin/corporate/associate/cylinder/request',
    SETTING_URL: END_BASE_URL + 'admin/setting',
    RETURN_OLD_CYLINDER_REFILL: END_BASE_URL + 'admin/plant/associate/request/refill/',
    DASHBOARD_REFILL_COUNT: END_BASE_URL + 'admin/order/count/refill',
    DASHBOARD_PURCHASE_COUNT: END_BASE_URL + 'admin/order/count/purchase',
    DASHBOARD_ASSOCIATE_REQUEST_COUNT: END_BASE_URL + 'admin/order/count/associate/request',
    DASHBOARD_PLANTS_REFILL_REQUEST_COUNT: END_BASE_URL + 'admin/warehouse/count/refill/request',
    DASHBOARD_PLANTS_CYLINDER_REQUEST_COUNT: END_BASE_URL + 'admin/warehouse/count/cylinder/request',
    LIVE_ORDERS: END_BASE_URL + 'admin/user/associate/live/order',
    FLEET_OWNER: END_BASE_URL + 'admin/user/fleet',
    FLEET_ASSOCIATE: END_BASE_URL + 'admin/user/fleet/associate',
    CLIENT_ACCOUNT_DELETE_REQUEST: END_BASE_URL + 'admin/user/account/delete/client',
    ASSOCIATE_ACCOUNT_DELETE_REQUEST: END_BASE_URL + 'admin/user/account/delete/associate',
    MANAGE_ACCOUNT_DELETE_REQUEST: END_BASE_URL + 'admin/user/account/delete/',
    QUESTIONS: END_BASE_URL + 'admin/question',
    CORPORATE_USER_REGISTRATION: END_BASE_URL + 'admin/user/registration',
    CORPORATE_USER_FETCH: END_BASE_URL + 'admin/user/management',
    RESPOND_TO_REVIEW: END_BASE_URL + 'admin/cartreview/respond',
    PAYMENT_CHECKOUT_URL: END_BASE_URL + 'admin/payment',
    REGULATOR_URL: END_BASE_URL + 'admin/cylinder/regulator',
    REVIEW_URL: END_BASE_URL + 'admin/review/',
    DISTRIBUTOR: END_BASE_URL + 'admin/distributor',
    FETCH_USER_DETAIL: END_BASE_URL + 'api/user/profile',
    FETCH_UNAPPROVED_USER_DETAIL: END_BASE_URL + 'admin/user/declined/user',
    USER_COMPANY: END_BASE_URL + 'admin/user/company',
    CAPTCHA_VERIFICATION: END_BASE_URL + 'admin/captcha-verification',
    WALKIN_REFILL_ORDER: END_BASE_URL + 'admin/walkin-user/order/refill',
    WALKIN_PURCHASE_ORDER: END_BASE_URL + 'admin/walkin-user/order/purchase',
    FETCH_CREDIT_POINT: END_BASE_URL + 'api/creditpoint',
    SEND_OTP: END_BASE_URL + 'admin/walkin-user/order/otp/send',
    ADMIN_CART_ORDER: END_BASE_URL + 'admin/cart/order', // Includes both refill and purchase
    ADMIN_CART_ORDER_PICKUP: END_BASE_URL + 'admin/cart/order',
    ADMIN_CART_ORDER_PICKUP_DROPOFF: END_BASE_URL + 'admin/cart/order/dropoff',
    ADMIN_CART_ORDER_DELIVERY: END_BASE_URL + 'admin/cart/order/delivery',
    CART_ORDER_DASHBOARD_COUNT: END_BASE_URL + 'admin/cart/order/dashboard/count',
    CORPORATE_PLANT_CYLINDER_REQUEST_CSV: END_BASE_URL + 'admin/corporate/hub/newcylinder/request/report',
    CORPORATE_PLANT_REFILL_CYLINDER_REQUEST_CSV: END_BASE_URL + 'admin/corporate/hub/refillcylinder/request/report',
    CORPORATE_PLANT_SALES_REPORT: END_BASE_URL + 'admin/plant/distributor/sales/report',
    INDEPENDENT_GAS_RETAILER: END_BASE_URL + 'admin/independent',
    MANAGE_INDEPENDENT_GAS_RETAILER_STOCK: END_BASE_URL + 'admin/independent/cylinder/add',
    INDEPENDENT_GAS_RETAILER_SALES_REPORT: END_BASE_URL + 'admin/independent/sales/report',
    INDEPENDENT_CYLINDER_REQUEST: END_BASE_URL + 'admin/independent/cylinder/request',
    ASSOCIATE_SALES_REPORT: END_BASE_URL + 'admin/user/associate/sales/report',
    FLEET_SALES_REPORT: END_BASE_URL + 'admin/user/fleet/sales/report',
    FETCH_DELIVERY_CHARGES: END_BASE_URL + 'admin/independent/cylinder/request/delivery/charge',
    FETCH_TRANSACTION_CHARGES: END_BASE_URL + 'api/payment/transaction/fees',
    FETCH_PAYMENT_DETAILS: END_BASE_URL + 'api/payment',
    INDEPENDENT_FETCH_CYLINDER_REQUEST: END_BASE_URL + 'admin/independent/cylinder',
    WAREHOUSE_APPROVE_REJECT_INDEPENDENT_CYLINDER_REQUEST: END_BASE_URL + 'admin/warehouse/cylinder/request',
    CORPORATE_INDEPENDENT_CYLINDER_REQUEST: END_BASE_URL + 'admin/corporate/independent/cylinder/request',
    CORPORATE_INDEPENDENT_CYLINDER_ORDER_REPORT: END_BASE_URL + 'admin/corporate/independent/cylinder/request/order/report',
    INDEPENDENT_CYLINDER_REQUEST_COUNT: END_BASE_URL + 'admin/warehouse/count/independent/cylinder/request',
    BANK_LIST: END_BASE_URL + 'payment/bank/list',
    UPDATE_BANK_INFO: END_BASE_URL + 'payment/bank',
    VALIDATE_ACCOUNT: END_BASE_URL + 'payment/validate/account',
    MANAGE_CARD_DETAILS: END_BASE_URL + 'payment/card',
    LPG_PRICE: END_BASE_URL + 'admin/cylinder/lpg/price',
    INDEPENDENT_LPG_PRICE: END_BASE_URL + 'admin/cylinder/retailer/lpg/price',
    GAS_RETAILER_PAYMENT: END_BASE_URL + 'payment/retailer/payment',
    CLIENTS_ORDER_PAYMENT: END_BASE_URL + 'payment/client/payment',
    EMAIL_ORDER_REPORT: END_BASE_URL + 'admin/cart/client/order/report/email',
    DOWNLOAD_ORDER_REPORT: END_BASE_URL + 'admin/cart/client/order/report/download',
    FETCH_COMMON_DETAILS: END_BASE_URL + 'admin/setting/common/details',
    DOWNLOAD_USER_LIST_REPORT: END_BASE_URL + 'admin/user/list/report',
    DOWNLOAD_UNAPPROVED_USER_LIST_REPORT: END_BASE_URL + 'admin/user/declined/list/report',
    FETCH_BRAND_NAMES: END_BASE_URL + 'admin/brand/admin',
    FETCH_RETAILER_BRAND_NAMES: END_BASE_URL + 'admin/brand/retailer',
    ENABLE_BRAND_NAMES: END_BASE_URL + 'admin/brand/admin/enable',
    ENABLE_IGR_BRAND_NAMES: END_BASE_URL + 'admin/brand/retailer/enable',
    SET_IGR_DELIVERY_CHARGE: END_BASE_URL + 'admin/independent/delivery/charge',
};