import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { WarehouseRoutingModule, ROUTING_COMPONENTS } from './warehouse-routing.module';

@NgModule({
  declarations: [ROUTING_COMPONENTS],
  imports: [
    CommonModule,
    WarehouseRoutingModule,
    SharedModule
  ]
})
export class WarehouseModule { }
