import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { SettingRoutingModule, ROUTING_COMPONENTS } from './setting-routing.module';
import { CKEditorModule } from 'ng2-ckeditor';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ImageCropperModule } from 'ngx-img-cropper';

const MODULES = [
  SettingRoutingModule, SharedModule,
  CKEditorModule,
  Ng2SearchPipeModule, ImageCropperModule
];

@NgModule({
  declarations: [ROUTING_COMPONENTS],
  imports: [
    MODULES
  ]
})
export class SettingModule { }
