import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService, WarehouseService, CommonService, TokenService, LoaderService } from '../../../__services';
import { PAGINATION } from '../../../__utilities/pagination';
import { PHONE_CONFIG, DEFAULT_COUNTRY } from '../../../__utilities/phone';
import { MESSAGE } from '../../../__utilities/messages';
import { BTN_TEXT, TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
	selector: 'app-warehouse',
	templateUrl: './warehouse.component.html',
	styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit, OnDestroy {

	isAdd: boolean = false;
	paginate: any = { ...PAGINATION };
	warehouseForm: UntypedFormGroup;
	isSubmitted: boolean = false;
	warehouses: any = [];
	phoneConfig = PHONE_CONFIG;
	messages: any = MESSAGE;
	location: any = {
		type: 'Point',
		coordinates: []
	};
	address: String = '';
	isDisabled: boolean = false;
	isLoading: boolean = false;
	selectedWarehouse: any;
	searchStr: string = '';
	isUnknownLocation: boolean = false;
	btnText = BTN_TEXT.ADD_WAREHOUSE;
	heading: string = TITLE.WAREHOUSE;
	cylinderCategories: any = [];
	inputId: string = "cylinder";
	isFilledCylinder: boolean = false;
	selectedIndex: number = 0;
	permissions: any = {};
	warehouseId: string = "";
	documents: any = "";
	activatedRouteValues: any = "";
	routeReuseSubscription:Subscription;
	options = {
		types: ['address'],
		componentRestrictions: { country: 'ng' }
	};
	constructor(
		private fb: UntypedFormBuilder,
		public validationService: ValidationService,
		public warehouseService: WarehouseService,
		public commonService: CommonService,
		public tokenService: TokenService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService
	) {
	}

	ngOnInit(): void {
		this.getRoutingParams();
		this.initializeControls();
	}

	// Set Permission
	setPermission() {
		let authUserPermission = this.tokenService.getPermission();
		if (!this.warehouseId)
			this.permissions = {
				isAddUpdateWarehouse: this.commonService.isPermission(authUserPermission, PERMISSIONS.ADD_UPDATE_WAREHOUSE),
				isDeleteWarehouse: this.commonService.isPermission(authUserPermission, PERMISSIONS.DELETE_WAREHOUSE),
			}
		else
			this.permissions.isviewCylinderStock = this.commonService.isPermission(authUserPermission, PERMISSIONS.VIEW_WAREHOUSE_CYLINDER_STOCK);
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ warehouseId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.warehouseId = results.warehouseId;
				this.routeSubscription();
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.warehouses = [];
				this.warehouseId ? this.fetchWarehouseById(this.warehouseId) : this.fetchWarehouse();
				this.setPermission();
			});
	}

	// Fetch Plant By id
	fetchWarehouseById(warehouseId) {
		this.loader.show();
		this.isLoading = true;
		this.warehouseService.fetchWarehouseById(warehouseId).subscribe(res => {
			if (res.success && res.response && res.response.data) {
				this.selectedWarehouse = res.response.data;
				this.cylinderCategories = res.response.categories ? res.response.categories.sort(this.commonService.sortByProperty('size')) : [];
				this.documents = this.selectedWarehouse.user.hasOwnProperty("govtId") && Object.keys(this.selectedWarehouse.user.govtId).length
					? this.selectedWarehouse.user.govtId : "";
				if (this.documents) {
					this.documents.resume = this.selectedWarehouse.user.resume;
					this.documents.signedContract = this.selectedWarehouse.user.signedContract;
				}
			}
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.isLoading = false;
		});
	}

	initializeControls() {
		this.warehouseForm = this.fb.group({
			name: ['', Validators.compose([Validators.required])],
			address: ['', Validators.compose([Validators.required])],
			contact: ['', Validators.compose([Validators.required])],
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			email: ['', Validators.compose([Validators.required, Validators.email])]
		});
	}


	addMore(isAdd) {
		this.isAdd = isAdd;
		this.resetForm();
	}

	onSubmit(warehouseData) {
		this.isSubmitted = true;
		if (this.warehouseForm.valid) {
			warehouseData = this.createPayload(warehouseData);
			this.isUnknownLocation = Object.keys(this.location.coordinates).length ? false : true;
			if (!this.isUnknownLocation) {
				let inputAddress = (this.warehouseForm.controls.address.value).trim();
				this.isUnknownLocation = inputAddress != (this.address).trim();
			}
			if (this.isUnknownLocation) return;

			this.isDisabled = true;
			this.warehouseService.manageWarehouse(warehouseData).subscribe(res => {
				if (res && res.success) {
					if (warehouseData.id)
						this.warehouses[this.selectedIndex] = res.response;
					else {
						this.paginate.totalItems += 1;
						this.warehouses.unshift(res.response);
					}
					this.isAdd = false;
					this.resetForm();
				}
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			if (this.warehouseForm.controls.address.value)
				this.isUnknownLocation = Object.keys(this.location.coordinates).length ? false : true;
			else
				this.isUnknownLocation = false;
			this.validationService.validateAllFormFields(this.warehouseForm);
		}
	}

	resetForm() {
		this.selectedWarehouse = '';
		this.isSubmitted = false;
		this.warehouseForm.reset();
		this.address = '';
		this.isUnknownLocation = false;
		this.location.coordinates = [];
		this.phoneConfig.CountryISO = DEFAULT_COUNTRY;
		this.isFilledCylinder = false;
	}

	createPayload(warehouseData) {
		warehouseData.location = Object.keys(this.location).length ? this.location : (this.selectedWarehouse ? this.selectedWarehouse.location : '');
		warehouseData.address = this.address ? this.address : (this.selectedWarehouse ? this.selectedWarehouse.address : '');
		if (this.selectedWarehouse)
			warehouseData.id = this.selectedWarehouse._id;

		if (typeof warehouseData.contact == 'object') {
			warehouseData.countryCode = warehouseData.contact.countryCode;
			warehouseData.dialCode = warehouseData.contact.dialCode;
			warehouseData.contact = this.commonService.formatContactNumber(warehouseData.contact.number);
		}
		return warehouseData;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.warehouses = [];
		this.fetchWarehouse();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.warehouses = [];
		this.fetchWarehouse();
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.warehouseForm.controls.address.setValue(this.address);
		}
		else {
			this.warehouseForm.controls.address.setValue('');
		}
	}

	// Fetch Warehouse
	fetchWarehouse() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.isLoading = true;
		this.warehouseService.fetchWarehouse(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response.categories ? (res.response.categories).sort(this.commonService.sortByProperty('size')) : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.warehouses = res.response.response ? res.response.response : [];
			}
			else
				this.warehouses = [];
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.warehouses = [];
		});
	}

	editWarehouse(warehouse, index) {
		this.isAdd = true;
		this.selectedWarehouse = warehouse;
		this.selectedIndex = index;
		if (this.selectedWarehouse.location.coordinates && this.selectedWarehouse.location.coordinates.length) {
			this.location.coordinates[0] = this.selectedWarehouse.location.coordinates[0];
			this.location.coordinates[1] = this.selectedWarehouse.location.coordinates[1];
		}
		let controls = this.warehouseForm.controls;
		this.address = this.selectedWarehouse.address;
		controls.name.setValue(warehouse.name);
		controls.address.setValue(warehouse.address);
		controls.firstName.setValue(warehouse.user.firstName);
		controls.lastName.setValue(warehouse.user.lastName);
		controls.email.setValue(warehouse.user.email);
		this.phoneConfig.CountryISO = warehouse.user.countryCode;
		controls.contact.setValue(warehouse.user.contact);
	}

	// Delete Warehouse
	deleteWarehouse(warehouse, index) {
		Swal.fire({
			title: MESSAGE.DELETE_WAREHOUSE_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_WAREHOUSE_CONFIRMATION_TEXT + warehouse.name + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedWarehouse(warehouse._id, index);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Delete selected warehouse
	deleteSelectedWarehouse(warehouseId, index) {
		this.warehouseService.deleteWarehouse(warehouseId).subscribe(res => {
			if (res && res.success) {
				this.paginate.totalItems -= 1;
				this.warehouses.splice(index, 1);
				this.commonService.growlSuccess(res.message);
			}
			else {
				this.commonService.growlError(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.WAREHOUSE ? (this.isAdd = false, this.resetForm()) : false;
		});
	}

	// On add filled cylinders
	onAddFilledCylinder(warehouse, index) {
		this.selectedWarehouse = warehouse;
		this.isFilledCylinder = true;
		this.isAdd = true;
		this.selectedIndex = index;
	}

	// Back to list in case of view details
	backToList() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	viewWarehouse(warehouseId) {
		this.router.navigate([this.activatedRouteValues.viewURL, warehouseId], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	onSubmitFilledCylinder() {
		let request = [];
		let noOfCylinder = 0;
		this.cylinderCategories.forEach((element, index) => {
			noOfCylinder = parseInt($('#' + this.inputId + index).val());
			if (noOfCylinder)
				request.push({ 'numberOfCylinder': noOfCylinder, 'size': element.size });
		});
		if (!request.length)
			return this.commonService.growlError(MESSAGE.ENTER_QUANTITY);
		this.postFilledCylinderRequest({ filledCylinder: request });
	}

	// Post filled cylinder request
	postFilledCylinderRequest(payload) {
		this.isDisabled = true;
		this.warehouseService.addFilledCylinder(this.selectedWarehouse._id, payload).subscribe(res => {
			if (res && res.success && res.response) {
				this.isAdd = false;
				this.warehouses[this.selectedIndex].filledCylinder = res.response;
				this.resetForm();
			}
			this.isDisabled = false;
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.isDisabled = false;
			this.commonService.growlError(error.message);
		});
	}

	openOrderPopup(warehouse) {
		this.selectedWarehouse = warehouse;
		const inputOptions = {
			1: TITLE.NEW_CYLINDER_REQUEST,
			2: TITLE.REFILL_CYLINDER_REQUEST,
			3: TITLE.INDEPENDENT_CYLINDER_REQUEST
		};
		Swal.fire({
			title: TITLE.ORDER_TYPE_SELECTION,
			input: 'select',
			inputOptions: inputOptions,
			inputPlaceholder: TITLE.ORDER_TYPE_SELECTION,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				this.requestCSV(confirm.value);
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	requestCSV(value) {
		const fileNameArr = [
			'orders-newCylinderRequest-',
			'orders-refillCylinderRequest-',
			'orders-independentCylinderRequest-'
		];
		const filter = `?warehouseId=${this.selectedWarehouse._id}`
		this.warehouseService.downloadCSV(value, filter).subscribe(res => {
			this.downloadCSV(res, fileNameArr[value - 1] + this.selectedWarehouse.name)
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}

	downloadCSV(res, fileName) {
		const blob = new Blob([res], {
			type: 'text/csv'
		});
		// if (window.navigator.msSaveOrOpenBlob) {
		// 	window.navigator.msSaveBlob(blob, `${fileName}.csv`);
		// } else {
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = `${fileName}.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		// }
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}