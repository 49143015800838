import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../__guard/auth.guard';
import {
	ClientsRefillOrderComponent, PlantsRefillOrderComponent,
	RefillCylinderOrderComponent, LiveOrdersComponent
} from '../../index';
import { ROLES } from '../../../__utilities/roles';
import { TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';

const routes: Routes = [

	{
		path: 'pickup',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PICKUP_ORDERS,
			viewURL: '/orders/pickup/order-details/',
			invoiceURL: '/orders/pickup/invoice/',
			deliveryMode: false,
			breadcrumb: [
				{
					label: TITLE.PICKUP_ORDERS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'pickup/order-details/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PICKUP_ORDERS,
			breadcrumb: [
				{
					label: TITLE.PICKUP_ORDERS,
					url: '/orders/pickup'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'pickup/invoice/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENT_REFILL_ORDERS,
			invoice: true,
			breadcrumb: [
				{
					label: TITLE.PICKUP_ORDERS,
					url: '/orders/pickup'
				},
				{
					label: TITLE.INVOICE,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'cart-orders',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_ORDER,
			viewURL: '/orders/cart-orders/order-details/',
			invoiceURL: '/orders/cart-order/invoice/',
			deliveryMode: false,
			breadcrumb: [
				{
					label: TITLE.CLIENTS_ORDER,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.CORPORATE]
		}
	},
	{
		path: 'cart-orders/order-details/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_ORDER,
			breadcrumb: [
				{
					label: TITLE.CLIENTS_ORDER,
					url: '/orders/cart-orders'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.CORPORATE]
		}
	},
	{
		path: 'cart-order/invoice/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_ORDER,
			invoice: true,
			breadcrumb: [
				{
					label: TITLE.CLIENTS_ORDER,
					url: '/orders/cart-orders'
				},
				{
					label: TITLE.INVOICE,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.CORPORATE]
		}
	},
	{
		path: 'delivery',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS,
			viewURL: '/orders/delivery/order-details/',
			invoiceURL: '/orders/delivery/invoice/',
			deliveryMode: true,
			isIgrDelivery: false,
			breadcrumb: [
				{
					label: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'gas-supplier/delivery',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.FULFILLED_BY_ME,
			viewURL: '/orders/gas-supplier/delivery/order-details/',
			invoiceURL: '/orders/gas-supplier/delivery/invoice/',
			deliveryMode: true,
			isIgrDelivery: true,
			breadcrumb: [
				{
					label: TITLE.FULFILLED_BY_ME,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'delivery/order-details/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS,
			deliveryMode: true,
			breadcrumb: [
				{
					label: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS,
					url: '/orders/delivery'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'gas-supplier/delivery/order-details/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.FULFILLED_BY_ME,
			deliveryMode: true,
			breadcrumb: [
				{
					label: TITLE.FULFILLED_BY_ME,
					url: '/orders/gas-supplier/delivery'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'delivery/invoice/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS,
			invoice: true,
			deliveryMode: true,
			breadcrumb: [
				{
					label: TITLE.CLIENTS_HOME_DRIVER_DELIVERY_ORDERS,
					url: '/orders/delivery'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'gas-supplier/delivery/invoice/:id',
		component: ClientsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.FULFILLED_BY_ME,
			invoice: true,
			deliveryMode: true,
			breadcrumb: [
				{
					label: TITLE.FULFILLED_BY_ME,
					url: '/orders/gas-supplier/delivery'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENTS_ORDER, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'hubs-refill-order',
		component: PlantsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PLANTS_REFILL_ORDER,
			viewURL: '/orders/hubs-refill-order/order-details/',
			breadcrumb: [
				{
					label: TITLE.PLANTS_REFILL_ORDER,
					url: ''
				}
			],
			permission: ['', ROLES.WAREHOUSE_MANAGER]
		}
	},
	{
		path: 'hubs-refill-order/order-details/:id',
		component: PlantsRefillOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PLANTS_REFILL_ORDER,
			breadcrumb: [
				{
					label: TITLE.PLANTS_REFILL_ORDER,
					url: '/orders/hubs-refill-order'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: ['', ROLES.WAREHOUSE_MANAGER]
		}
	},
	{
		path: 'refill-order',
		canActivate: [AuthGuard],
		component: RefillCylinderOrderComponent,
		data: {
			title: TITLE.REFILL_ORDERS,
			breadcrumb: [
				{
					label: TITLE.REFILL_ORDERS,
					url: ''
				}
			],
			permission: ['', ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR]
		}
	},
	{
		path: 'live-orders',
		canActivate: [AuthGuard],
		component: LiveOrdersComponent,
		data: {
			title: TITLE.LIVE_ORDER,
			breadcrumb: [
				{
					label: TITLE.LIVE_ORDER,
					url: ''
				}
			],
			permission: [PERMISSIONS.DRIVER_ORDER_DELIVERY_TRACK, ROLES.CORPORATE]
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class OrderRoutingModule { }

export const ROUTING_COMPONENTS = [
	ClientsRefillOrderComponent, PlantsRefillOrderComponent, RefillCylinderOrderComponent, LiveOrdersComponent
];
