import { Component, OnInit, OnDestroy } from '@angular/core';
import { PAGINATION } from '../../../__utilities/pagination';
import { OrderService, CommonService } from '../../../__services';
import { TITLE } from '../../../__utilities/text';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-client-order-payment',
	templateUrl: './client-order-payment.component.html',
	styleUrls: ['./client-order-payment.component.css']
})
export class ClientOrderPaymentComponent implements OnInit, OnDestroy {
	searchStr: string = "";
	isLoading: boolean = false;
	paginate: any = { ...PAGINATION };
	orderPayment: Array<any> = [];
	selectedRow: any = "";
	orderStatus: object = {
		all: "All",
		hold: "Hold",
		refund: "Refund",
		captured: "Captured",
		partially_voided: "Partial Refund"
	};
	status: object = {
		partially_voided: "Partial Refund",
		hold: "Hold",
		voided: "Refund",
		captured: "Captured"
	};
	paymentStatus: string = "";
	statusOptionValue: String = "";
	routeReuseSubscription: Subscription;
	constructor(
		public orderService: OrderService,
		public commonService: CommonService
	) { }

	ngOnInit(): void {
		this.fetchClientsOrderPayment();
		this.routeSubscription();
	}

	fetchClientsOrderPayment() {
		this.isLoading = true;
		this.orderService.fetchClientsOrderPayment(this.queryParams()).subscribe(response => {
			if (response && response.success && response.response) {
				this.paginate.totalItems = response.response.pagination.totalDocs;
				this.orderPayment = response.response.data;
			}
			this.isLoading = false;
		}, error => {
			this.orderPayment = [];
			this.isLoading = false;
		});
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.CLIENT_ORDER_PAYMENT ? (this.selectedRow = "") : false;
		});
	}

	viewDetail(payment) {
		this.selectedRow = payment;
	}

	queryParams() {
		let searchStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.paymentStatus)
			searchStr += "&filter=" + this.paymentStatus;
		return searchStr;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.fetchClientsOrderPayment();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
		this.orderPayment = [];
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.orderPayment = [];
		this.fetchClientsOrderPayment();
	}

	onPaymentStatusChange(status) {
		this.statusOptionValue = status;
		this.paymentStatus = status == this.orderStatus['partially_voided'] ? 'partially_voided' : status.toLowerCase();
		this.resetPagination();
		this.fetchClientsOrderPayment();
	}

	goBack() {
		this.selectedRow = "";
	}

	ngOnDestroy() {
		this.routeReuseSubscription.unsubscribe();
	}

	convertAmount(amount) {
		return parseFloat(amount).toFixed(2);
	}

}
