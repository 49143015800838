import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageService, TokenService, AuthenticationService, ValidationService } from '../../../__services';
import { environment } from '../../../../environments/environment';
import { ROLES } from '../../../__utilities/roles';
declare var $: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
	@ViewChild('email') email: ElementRef;
	@ViewChild('password') password: ElementRef;
	error: any = { isError: false, message: null };
	isForgotPassword = false;
	loginForm: UntypedFormGroup;
	forgotPasswordForm: UntypedFormGroup;
	isDisabled: boolean = false;
	isRememberMe: boolean = false;
	isCaptchaVerified: boolean = !environment.production;
	isCaptchaProceed: boolean = false;
	captchaSiteKey: string = environment.CAPTCHA_SITE_KEY;

	constructor(private fb: UntypedFormBuilder,
		private authenticationService: AuthenticationService,
		private router: Router,
		private storageService: StorageService,
		public validationService: ValidationService,
		private tokenService: TokenService) {
		this.isAuthenticated();
	}
	ngOnInit() {
		this.initializeFormControls();
		this.rememberMeCheck();
	}

	async resolved(captchaResponse: string) {
		if (captchaResponse)
			await this.validateCaptcha(captchaResponse);
		else {
			this.isCaptchaVerified = true;
		}
	}

	validateCaptcha(captchaResponse) {
		this.authenticationService.captchaVerification({ recaptcha: captchaResponse }).subscribe(res => {
			this.isCaptchaProceed = true;
			this.isCaptchaVerified = res && res.success;
		}, error => {
			this.isCaptchaProceed = true;
			this.isCaptchaVerified = true;
		});
	}

	// Remember Check
	rememberMeCheck() {
		var rem_token = this.storageService.getItem('rem_token');
		if (rem_token) {
			let credentials = atob(rem_token).split("|rem_token|");
			this.loginForm.controls.email.setValue(credentials[0]);
			this.loginForm.controls.password.setValue(credentials[1]);
			this.isRememberMe = true;
		}
	}
	rememberMe(event) {
		this.isRememberMe = event.target.checked;
	}

	// Initialize form controls
	initializeFormControls() {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(6)]],
			recaptchaVerification: ['', environment.production ? [Validators.required] : ""],
		});

		this.forgotPasswordForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]]
		});
	}

	get login() { return this.loginForm.controls; }
	get forgot() { return this.forgotPasswordForm.controls; }

	onSubmit(credentials, captchaRef) {
		if (this.loginForm.valid && this.isCaptchaVerified) {
			this.isDisabled = true;
			this.authenticationService.login(credentials).subscribe(res => {
				if (res && res.success) {
					this.storageService.setItem('token', res.token);
					delete res.user.password;
					this.storageService.setItem('userObj', res.user);
					if (this.isRememberMe) {
						const value = btoa(credentials.email + "|rem_token|" + credentials.password);
						this.storageService.setItem('rem_token', value);
					}
					else {
						this.storageService.removeItem('rem_token');
					}
					const isProfileCompleted = res.user.roleId.name != ROLES.CORPORATE ? res.user.isProfileCompleted : true;
					this.router.navigate([isProfileCompleted ? '/dashboard' : '/profile']);
				}
				else {
					this.resetCaptcha(captchaRef);
				}
				this.displayError(false, '');
			}, error => {
				this.resetCaptcha(captchaRef);
				this.loginForm.controls.password.setValue('');
				this.displayError(true, error.message);
			});
		}
		else {
			const controls = this.loginForm.controls;
			if (controls.password.invalid)
				this.password.nativeElement.focus();
			if (controls.email.invalid)
				this.email.nativeElement.focus();
			this.validationService.validateAllFormFields(this.loginForm);
		}
	}

	resetCaptcha(captchaRef) {
		this.isCaptchaProceed = false;
		this.isCaptchaVerified = !environment.production;
		captchaRef.reset();
	}

	displayError(isError, errorMessage) {
		this.isDisabled = false;
		this.error.isError = isError;
		this.error.message = errorMessage;
	}

	forgotPassword(email) {
		if (this.forgotPasswordForm.valid) {
			this.isDisabled = true;
			this.authenticationService.forgotPassword(email).subscribe(res => {
				this.displayError(false, res.message);
				this.forgotPasswordForm.reset();
			}, error => {
				this.displayError(true, error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.forgotPasswordForm);
		}
	}

	isAuthenticated() {
		let isAuthenticated = this.tokenService.isAuthenticated();
		isAuthenticated ? this.router.navigate(['/dashboard']) : null;
	}
}