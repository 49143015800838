import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
	ValidationService, CommonService, SettingService, LoaderService,
	ImageCropperService, CylinderService, TokenService
} from '../../../../__services';
import { MESSAGE, FILE_PLACEHOLDER } from '../../../../__utilities/messages';
import { TITLE } from '../../../../__utilities/text';
import Swal from 'sweetalert2';
import { ImageCropperComponent } from 'ngx-img-cropper';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { BTN_TEXT } from '../../../../__utilities/text';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
	selector: 'app-regulator-information',
	templateUrl: './regulator-information.component.html',
	styleUrls: ['./regulator-information.component.css']
})
export class RegulatorInformationComponent implements OnInit, OnDestroy {
	regulatorForm: UntypedFormGroup;
	regulators: any = [];
	selectedRegulator: any;
	isDisabled: boolean = false;
	isAdd: boolean = false;
	filePlaceholder = FILE_PLACEHOLDER;
	isFileUpload: boolean = false;
	extArr = ['image/jpeg', 'image/png'];
	fileObj: any;
	imageCropData: any = {};
	regulatorImage: any;
	cropperSettings: any;
	isSubmitted: boolean = false;
	permissions: any = {};
	btnText = BTN_TEXT;
	isLoading: boolean = false;
	base64Url: string = "";
	routeReuseSubscription:Subscription;
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;
	constructor(
		public validationService: ValidationService,
		public commonService: CommonService,
		public settingService: SettingService,
		public loader: LoaderService,
		public imageCropperService: ImageCropperService,
		public cylinderService: CylinderService,
		public tokenService: TokenService,
		private fb: UntypedFormBuilder,
	) {
		this.initializeControls();
		this.fetchRegulator();

	}

	ngOnInit(): void {
		this.loader.show();
		this.cropperSettings = this.imageCropperService.cropperSetting();
		this.cropperSettings.rounded = false;
		this.cropperSettings.keepAspect = false;
		this.setPermissions();
		this.routeSubscription();
	}

	// Set Permissions 
	setPermissions() {
		const authUserPermission = this.tokenService.getPermission();
		// this.permissions = {
		// 	isAddUpdateRegulator: this.commonService.isPermission(authUserPermission, PERMISSIONS.ADD_UPDATE_REGULATOR_INFORMATION),
		// 	isDeleteRegulator: this.commonService.isPermission(authUserPermission, PERMISSIONS.DELETE_REGULATOR_INFORMATION)
		// };
	}

	initializeControls() {
		this.regulatorForm = this.fb.group({
			type: ['', Validators.compose([Validators.required])],
			charge: ['', Validators.compose([Validators.required])],
			discount: ['', Validators.compose([Validators.required])],
			image: ['', Validators.compose([Validators.required])],
			isRefill: ['', Validators.compose([])],
			isPurchase: ['', Validators.compose([])]
		});
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.CYLINDER_CATEGORIES ? this.resetForm() : false;
		});
	}

	// Reset form
	resetForm() {
		this.regulatorForm.reset();
		this.selectedRegulator = '';
		this.isDisabled = false;
		this.isFileUpload = false;
		this.isAdd = false;
		this.isSubmitted = false;
		this.base64Url = "";
		this.regulatorForm.controls.image.setValidators([Validators.required]);
		this.regulatorForm.controls.image.updateValueAndValidity();
	}

	// Fetch Regulator Informations
	fetchRegulator() {
		this.isLoading = true;
		this.cylinderService.fetchRegulator().subscribe(res => {
			if (res && res.success && res.response) {
				this.regulators = res.response;
			} else {
				this.regulators = [];
			}
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.regulators = [];
			this.isLoading = false;
		});
	}


	fileChangeListener(event, key, placeholder) {
		//var image: any = new Image();
		var file: File = event.target.files[0];
		var that = this;
		if (file) {
			if (this.extArr.indexOf(file.type) == -1) {
				this.isFileUpload = false;
				setTimeout(() => {
					that.commonService.setFilePlaceholder('regulatorImage', placeholder);
				}, 0);
				this.commonService.growlError(MESSAGE.INVALID_IMG_FORMAT);
				return;
			}
			this.isFileUpload = true;
			this.fileObj = file;
			this.commonService.setFilePlaceholder('regulatorImage', file.name);
			this.regulatorForm.controls.image.setValue(file.name);
			this.regulatorImage = file;
			var myReader: FileReader = new FileReader();
			myReader.onloadend = function (loadEvent: any) {
				// image.src = loadEvent.target.result;
				//setTimeout(() => { that.cropper.setImage(image); }, 0);
				that.base64Url = loadEvent.target.result;
			};
			myReader.readAsDataURL(file);
		}
	}
	
	getImageData(key) {
		var _this = this;
		this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
			_this.isFileUpload = false;
			_this.regulatorImage = file;
			_this.commonService.setFilePlaceholder('regulatorImage', file.name);
			_this.regulatorForm.controls.image.setValue(_this.fileObj.name);
		}, error => {
			_this.commonService.growlError(MESSAGE.ERROR);
			_this.isFileUpload = false;
			_this.imageCropData.image = null;
		});
	}

	addNew() {
		this.resetForm();
		this.isAdd = true;
	}


	// Submit form
	onSubmit(regulator) {
		this.isSubmitted = true;
		if (this.regulatorForm.valid) {
			// this.isFileUpload = false;
			this.isDisabled = true;
			regulator = this.createPayload(regulator);
			this.cylinderService.manageRegulator(regulator).subscribe(res => {
				if (res && res.success) {
					if (regulator.get('id')) {
						this.regulators.forEach((element, index) => {
							if (element._id == regulator.get('id')) {
								this.regulators[index] = res.response;
							}
						});
					}
					else {
						this.regulators.push(res.response);
					}
				}
				this.resetForm();
				this.commonService.growlSuccess(res.message);
			}, error => {
				this.isDisabled = false;
				this.isSubmitted = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.regulatorForm);
		}
	}

	// Create Payload 
	createPayload(regulator) {
		let formData = new FormData();
		formData.append('type', regulator.type);
		formData.append('charge', regulator.charge);
		formData.append('discount', regulator.discount);
		formData.append('isPurchase', $("#isPurchase").is(":checked"));
		formData.append('isRefill', $("#isRefill").is(":checked"));
		if (this.regulatorImage) formData.append('image', this.regulatorImage);
		if (this.selectedRegulator) formData.append('id', this.selectedRegulator._id);
		return formData;
	}

	// On Edit
	onEdit(regulator) {
		this.isAdd = true;
		this.selectedRegulator = regulator;
		let controls = this.regulatorForm.controls;
		controls.type.setValue(regulator.type);
		controls.charge.setValue(regulator.charge);
		controls.discount.setValue(regulator.discount);
		this.commonService.scrollTop();
		this.regulatorForm.controls.image.clearValidators();
		this.regulatorForm.controls.image.updateValueAndValidity();
	}

	// On Delete
	onDelete(regulator, index) {
		this.resetForm();
		Swal.fire({
			title: MESSAGE.DELETE_REGULATOR_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_REGULATOR_CONFIRMATION_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedRegulator(regulator._id, index);
			}
		});
	}

	// Delete selected regulator
	deleteSelectedRegulator(id, index) {
		this.cylinderService.deleteRegulator(id).subscribe(res => {
			if (res && res.success) {
				this.regulators.splice(index, 1);
			}
			this.commonService.growlSuccess(res.message);
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	ngOnDestroy(){
		this.routeReuseSubscription.unsubscribe();
	}
}
