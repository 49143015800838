import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService, CommonService, LoaderService, TokenService, OrderService, StorageService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import Swal from 'sweetalert2';
import { MESSAGE } from '../../../../__utilities/messages';
import { PERMISSIONS } from '../../../../__utilities/permissions';
import { ORDER_VALUES, ORDER_STATUS } from '../../../../__utilities/orders';
import { ROLES } from '../../../../__utilities/roles';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $;

@Component({
	selector: 'app-associate',
	templateUrl: './associate.component.html',
	styleUrls: ['./associate.component.css']
})
export class AssociateComponent implements OnInit, OnDestroy {

	paginate: any = { ...PAGINATION };
	orderStatus: any = { ...ORDER_STATUS };
	isLoading: boolean = false;
	searchStr: string = '';
	associates = [];
	selectedAssociate: any = "";
	heading: string = TITLE.ASSOCIATE;
	title: any = TITLE;
	associateId: string = '';
	noRecord: string = MESSAGE.NO_RECORD_FOUND;
	isPermission: boolean = false;
	isOrderDetails: boolean = false;
	orders: any = [];
	orderDetails: any = "";
	ordersText: any = ORDER_VALUES;
	isPrintOrderPermission: boolean = false;
	activatedRouteValues: any = "";
	isFleetAssociate: boolean = false;
	statusFilter: string = "";
	inputOptions: any = { ...ORDER_STATUS };
	searchUserId: string = "";
	constructor(
		public usersService: UsersService,
		public commonService: CommonService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService,
		public tokenService: TokenService,
		public orderService: OrderService,
		public storageService: StorageService
	) {
		const authUserPermission = this.tokenService.getPermission();
		this.isPermission = this.commonService.isPermission(authUserPermission, PERMISSIONS.DRIVER_APPROVE_REJECT_ACCOUNT);
		this.isPrintOrderPermission = authUserPermission.name == ROLES.CORPORATE;
		delete this.orderStatus['DECLINED'];
	}

	ngOnInit(): void {
		this.getRoutingParams();
		this.filterInputOptions();
	}

	// Fetch Associate By id
	fetchAssociateById(associateId) {
		this.loader.show();
		this.isLoading = true;
		this.usersService.fetchAssociateById(associateId).subscribe(res => {
			if (res.success && res.response)
				this.selectedAssociate = res.response;
			this.setBreadCrumb();
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.isLoading = false;
		});
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		this.isOrderDetails = this.activatedRouteValues.isOrderDetails;
		this.isFleetAssociate = this.activatedRouteValues.isFleetAssociate;
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ associateId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.associateId = results.associateId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.searchUserId = results.query.userId ? results.query.userId : "";
				this.associates = [];
				this.associateId ? this.fetchAssociateById(this.associateId) : this.fetchAssociates();
				if (this.isOrderDetails)
					this.getAssociateOrderDetails();
			});
	}


	setBreadCrumb() {
		var breadcrumb = [...this.activatedRoute.data['value'].breadcrumb, ...[{
			label: this.selectedAssociate.firstName + " " + this.selectedAssociate.lastName,
			url: '',
			icon: 'fa fa-user'
		}]];
		let _this = this;
		setTimeout(() => {
			_this.commonService.sendRouteChanges(breadcrumb);
		}, 0);
	}

	fetchAssociates() {
		let searchQuery = this.getSearchString();;
		this.isLoading = true;
		this.usersService.getAssociates(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.associates = res.response.associates ? res.response.associates : [];
			}
			else
				this.associates = [];
			this.isLoading = false;
		}, error => {
			this.associates = [];
			this.isLoading = false;
		});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.isOrderDetails ? this.getAssociateOrderDetails() : this.fetchAssociates();
	}

	resetPagination() {
		this.paginate.currentPage = 1;
		this.paginate.totalItems = 0;
		this.associates = [];
		this.orders = [];
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.associates = [];
		this.orders = [];
		this.isOrderDetails ? this.getAssociateOrderDetails() : this.fetchAssociates();
	}

	onOrderStatusChange(status) {
		this.statusFilter = status;
		this.resetPagination();
		this.getAssociateOrderDetails();
	}

	viewAssociateDetails(associate) {
		this.selectedAssociate = associate;
	}

	viewOrder(associate, url) {
		this.router.navigate([url, associate._id], { queryParams: this.isFleetAssociate ? '' : this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	onClick(event, associate, index, isApprove) {
		if (!isApprove)
			Swal.fire({
				title: MESSAGE.REJECT_ASSOCIATE_CONFIRMATION_TITLE,
				text: MESSAGE.REJECT_ASSOCIATE_CONFIRMATION_TEXT + associate.firstName + ' ' + associate.lastName + '?',
				allowEscapeKey: false,
				allowOutsideClick: false,
				reverseButtons: true,
				allowEnterKey: false,
				customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
				showCancelButton: true,
				cancelButtonText: 'Cancel',
				showConfirmButton: true,
				confirmButtonText: "Confirm",
			}).then((confirm) => {
				if (confirm.value) {
					this.manageAssociate(associate, index, isApprove);
				}
			}).catch(err => {
				if (err) {
					this.commonService.growlError(MESSAGE.ERROR);
				}
			});
		else {
			this.manageAssociate(associate, index, isApprove);
		}
	}

	manageAssociate(associate, index, isApprove) {
		const payload = {
			approve: isApprove,
			reject: !isApprove
		};
		this.usersService.manageAssociate(associate._id, payload).subscribe(res => {
			if (res && res.success) {
				this.associates[index] = res.user;
				if (!isApprove && !associate.isVerified && !associate.isRejected && !isApprove) {
					this.associates.splice(index, 1);
					this.paginate.totalItems -= 1;
				}
				else
					this.associates[index] = res.user;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	getSearchString() {
		let queryStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.statusFilter) queryStr += "&filter=" + this.statusFilter;
		return queryStr;
	}

	// Fetch Client Order Details By Client Id
	getAssociateOrderDetails() {
		this.isLoading = true;
		let searchQuery = this.getSearchString();
		searchQuery += "&deliveredBy=" + this.associateId;
		this.orderService.fetchOrdersById(searchQuery).subscribe(res => {
			if (res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.orders = res.response.data;
			}
			else
				this.orders = [];
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.orders = [];
		});
	}

	viewOrderDetails(order) {
		this.orderDetails = order;
	}

	goBack() {
		this.orderDetails = "";
	}

	backToMainList() {
		let url = this.activatedRouteValues.breadcrumb[0].url;
		if (this.isFleetAssociate) url += this.storageService.getItem('fleetId');
		this.router.navigate([url], { queryParams: this.queryParamsObj() });
	}

	filterInputOptions() {
		delete this.inputOptions['PENDING'];
		delete this.inputOptions['DECLINED'];
		for (var key in this.inputOptions) {
			this.inputOptions[key] = this.inputOptions[key].charAt(0).toUpperCase() + this.inputOptions[key].substring(1)
		}
	}

	openDownloadBox(associate) {
		Swal.fire({
			title: TITLE.ORDER_STATUS_SELECTION,
			input: 'select',
			inputOptions: this.inputOptions,
			inputPlaceholder: TITLE.ORDER_STATUS_SELECTION,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				this.downloadCSVOrderDetails(associate, confirm.value);
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	downloadCSVOrderDetails(associate, status) {
		const queryParams = "?status=" + status.toLowerCase();
		this.orderService.getAssociateOrdersCSVFile(associate._id, queryParams).subscribe(res => {
			const blob = new Blob([res], {
				type: 'text/csv'
			});
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob);
			a.download = `orders-${associate.uniqueIdentity}.csv`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}

	ngOnDestroy() {
		this.storageService.removeItem('fleetId');
	}
}