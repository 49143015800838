export const ROLES = {
    CORPORATE: 'corporate',
    PLANT_MANAGER: 'plant',
    WAREHOUSE_MANAGER: 'warehouse',
    DISTRIBUTOR: 'distributor',
    INDEPENDENT_RETAILER: 'independent'
};

export const ROLES_APP_USER = {
    CLIENT: 'client',
    ASSOCIATE: 'associate',
    FLEET: 'fleet'
};
