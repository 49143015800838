import { Component, OnInit,OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CylinderService, CommonService, StorageService, TokenService, LoaderService, IndependentGasRetailerService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { MESSAGE } from '../../../../__utilities/messages';
import { ORDER_STATUS, COMPONENT_KEYS } from '../../../../__utilities/orders';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
	selector: 'app-approve-independent-cylinder-request',
	templateUrl: './approve-independent-cylinder-request.component.html',
	styleUrls: ['./approve-independent-cylinder-request.component.css']
})
export class ApproveIndependentCylinderRequestComponent implements OnInit, AfterViewInit, OnDestroy {
	paginate: any = { ...PAGINATION };
	orderStatus: any = { ...ORDER_STATUS };
	isDisabled: boolean = false;
	isLoading: boolean = false;
	requestList: any = [];
	searchStr: string = "";
	cylinderCategories: any = [];
	selectedRequest: any;
	warehouseId: String = "";
	heading: string = TITLE.CYLINDER_REQUEST;
	requestId: string = "";
	noRecord: string = "";
	activatedRouteValues: any = "";
	statusFilter: string = "";
	subscription:Subscription;
	constructor(
		public gasRetailerService: IndependentGasRetailerService,
		public cylinderService: CylinderService,
		public commonService: CommonService,
		public tokenService: TokenService,
		public storageService: StorageService,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public loader: LoaderService
	) {
		let userObj = this.storageService.getItem('userObj');
		this.warehouseId = userObj && userObj.warehouse && userObj.warehouse._id;
	}

	ngOnInit(): void {
		this.getRoutingParams();
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ requestId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.requestId = results.requestId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.requestList = [];
				this.requestId ? this.fetchCylinderRequestById() : (this.isLoading = true, this.fetchRequestList());
			});
	}

	// Fetch Cylinder Request By Id.
	fetchCylinderRequestById() {
		this.isLoading = true;
		this.loader.show();
		this.cylinderService.fetchIndependentCylinderRequestById(this.requestId).subscribe(res => {
			if (res && res.success && res.response)
				this.selectedRequest = res.response;
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
		}, error => {
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.isLoading = false;
			this.loader.hide();
			this.selectedRequest = "";
		});
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.requestList = [];
		this.fetchRequestList();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	fetchRequestList() {
		this.isLoading = true;
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.statusFilter) searchQuery += "&filter=" + this.statusFilter;
		this.gasRetailerService.independentCylinerRequestList(this.warehouseId, searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response.categories ? (res.response.categories).sort(this.commonService.sortByProperty('size')) : [];
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.requestList = res.response.requests ? res.response.requests : [];
			}
			else {
				this.requestList = [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.requestList = [];
		});
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetAndSearch();
	}

	resetAndSearch() {
		this.resetPagination();
		this.requestList = [];
		this.fetchRequestList();
	}

	onOrderStatusChange(orderStatus) {
		this.statusFilter = orderStatus;
		this.resetAndSearch();
	}

	viewRequest(request) {
		this.router.navigate([this.activatedRouteValues.viewURL, request._id], { queryParams: this.queryParamsObj() });
	}

	goBack() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		return queryParams;
	}


	approveRequest(boolean, request, index) {
		if (!boolean)
			Swal.fire({
				title: MESSAGE.REJECT_ASSOCIATE_REQUEST_CONFIRMATION_TITILE,
				text: MESSAGE.REJECT_ASSOCIATE_REQUEST_CONFIRMATION_TEXT,
				allowEscapeKey: false,
				allowOutsideClick: false,
				reverseButtons: true,
				allowEnterKey: false,
				showCancelButton: true,
				cancelButtonText: 'Cancel',
				showConfirmButton: true,
				confirmButtonText: "Confirm",
			}).then((confirm) => {
				if (confirm.value) {
					this.deleteSelectedRequest(boolean, request, index);
				}
			}).catch(err => {
				if (err) {
					this.commonService.growlError(MESSAGE.ERROR);
				}
			});
		else
			this.deleteSelectedRequest(boolean, request, index);
	}

	deleteSelectedRequest(boolean, request, index) {
		this.gasRetailerService.approveIndependentCylinderRequest(request._id, { approve: boolean, reject: !boolean }).subscribe(res => {
			if (res && res.success) {
				this.requestList[index] = res.response
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	dispatchRequest(request, index) {
		this.gasRetailerService.dispatchIndependentCylinderRequest(request._id, { dispatch: true }).subscribe(res => {
			if (res && res.success) {
				this.requestList[index] = res.response;
				this.commonService.growlSuccess(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		if (this.requestId) return;
		this.commonService.setCurrentOrderKey(COMPONENT_KEYS.INDEPENDENT_NEW_CYLINDER_REQUEST);
		this.subscription = this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { })
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.resetPagination();
		this.searchStr = "";
		this.requestList = [];
		this.fetchRequestList();
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	ngOnDestroy() {
		if(this.subscription) this.subscription.unsubscribe();
	}
}
