import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ValidationService, ProfileService, CommonService } from '../../../../__services';
import { ImageCropperComponent } from 'ngx-img-cropper';

@Component({
	selector: 'app-upload-documents',
	templateUrl: './upload-documents.component.html',
	styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit, OnDestroy {


	@Input() myProfile: any;
	@Input() cropperSettings: any;
	@Input() message;
	@Input() filePlaceholder;
	@Input() isContractUser: boolean = false;
	@Input() isIndependentRetailer: boolean = false;
	isFileUpload: boolean = false;
	frontImage: any = '';
	backImage: any = '';
	contract: any = '';
	fileObj: any;
	imgExt = ['image/jpeg', 'image/png'];
	docExt = ['application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'image/jpeg', 'image/png', 'application/pdf'];
	fileExtArr = [''];
	documentForm: UntypedFormGroup;
	utilityBill: any;
	imageKey: string = '';
	isDisabled: boolean = false;
	isSubmitted: boolean = false;
	isUploading: boolean = false;
	// Image cropper properties
	name: string;
	imageCropData: any;
	ctx: any;
	@ViewChild('cropper', undefined) cropper: ImageCropperComponent;

	constructor(
		public validationService: ValidationService,
		public profileService: ProfileService,
		public commonService: CommonService,
		private fb: UntypedFormBuilder,
	) { }

	ngOnInit(): void {
		this.initializeControls();
		this.imageCropData = {}; // Used for cropper
		this.cropperSettings.rounded = false;
		this.cropperSettings.keepAspect = false;
		if (this.isIndependentRetailer) this.myProfile = this.myProfile.user;
	}

	initializeControls() {
		this.documentForm = this.fb.group({
			documentExpiryDate: ['', Validators.compose([Validators.required])],
			contractExpiryDate: ['', Validators.compose(this.isContractUser ? [Validators.required] : [])],
			frontImage: ['', Validators.compose([Validators.required])],
			backImage: ['', Validators.compose([Validators.required])],
			utility_bill: ['', Validators.compose(this.isIndependentRetailer ? [Validators.required] : [])],
			contract: ['', Validators.compose(this.isContractUser ? [Validators.required] : [])]
		});
	}

	// Doctype = utility_bill, photoId
	fileChangeListener(event, docType, placeHolder) {

		var image: any = new Image();
		var file: File = event.target.files[0];

		if (this.docExt.indexOf(file.type) == -1 && (docType == 'utility_bill' || docType == 'contract')) {
			this.displayError(event, placeHolder, this.message.INVALID_DOC_FORMAT);
			return;
		}

		if (this.imgExt.indexOf(file.type) == -1 && docType != 'utility_bill' && docType != 'contract') {
			this.displayError(event, placeHolder, this.message.INVALID_IMG_FORMAT);
			return;
		}

		if (docType != 'utility_bill' && docType != 'contract')
			this.isFileUpload = true;

		this.fileObj = file;
		var myReader: FileReader = new FileReader();
		var that = this;
		myReader.onloadend = function (loadEvent: any) {
			image.src = loadEvent.target.result;
			if (docType != 'utility_bill' && docType != 'contract') {
				that.imageKey = docType;
				setTimeout(() => {
					that.cropper.setImage(image);
				}, 0);

			} else {
				that.commonService.setFilePlaceholder(event.target.id, file.name);
				if (docType == 'contract') {
					that.contract = that.fileObj;
					that.documentForm.controls.contract.setValue(file.name);
				}
				else {
					that.utilityBill = that.fileObj;
					that.documentForm.controls.utility_bill.setValue(file.name);
				}
			}
		};
		myReader.readAsDataURL(file);
	}


	displayError(event, placeHolder, message) {
		this.isFileUpload = false;
		let _this = this;
		setTimeout(() => {
			_this.commonService.setFilePlaceholder(event.target.id, placeHolder);
		}, 0);
		this.commonService.growlError(message);
	}

	onSubmit(document) {
		this.isSubmitted = true;
		if (this.documentForm.valid) {
			this.isDisabled = true;
			let formData = new FormData();
			formData.append('frontImage', this.frontImage);
			formData.append('backImage', this.backImage);
			if (this.utilityBill) formData.append('resume', this.utilityBill);
			formData.append('expireDate', document.documentExpiryDate);
			if (this.isContractUser) {
				formData.append('contract', this.contract);
				formData.append('contractExpireDate', document.contractExpiryDate);
			}
			this.profileService.uploadDocuments(formData).subscribe(res => {
				if (res && res.success) {
					this.updateCardItems(res);
					this.commonService.growlSuccess(res.message);
					this.resetForm();
				}
				else {
					this.isDisabled = false;
					this.commonService.growlError(res.message);
				}
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(this.message.ERROR);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.documentForm);
		}
	}

	updateCardItems(res) {
		this.myProfile.resume = res.image.resume;
		this.myProfile.govtId = res.image.govtId;
		this.myProfile.signedContract = res.image.signedContract;
	}

	// Get Image data
	getImageData() {
		var _this = this;
		this.commonService.urltoFile(this.imageCropData.image, this.fileObj.name, this.fileObj.type).then(function (file) {
			_this.isFileUpload = false;
			_this.commonService.setFilePlaceholder(_this.imageKey, file.name);
			_this.documentForm.controls[_this.imageKey].setValue(file.name);
			_this.imageCropData.image = null;
			if (_this.imageKey == 'frontImage')
				_this.frontImage = file;
			else
				_this.backImage = file;
		}, error => {
			_this.commonService.growlError(_this.message.ERROR);
			_this.resetForm();
		});
	}

	// Reset Form
	resetForm() {
		this.isFileUpload = false;
		this.imageCropData.image = null;
		this.documentForm.reset();
		this.documentForm.controls.frontImage.setValue('');
		this.documentForm.controls.backImage.setValue('');
		this.documentForm.controls.utility_bill.setValue('');
		this.frontImage = '';
		this.backImage = '';
		this.utilityBill = '';
		this.contract = '';
		this.isSubmitted = false;
		this.isDisabled = false;
		this.commonService.setFilePlaceholder('frontImage', this.filePlaceholder.UPLOAD_GOVT_ID);
		this.commonService.setFilePlaceholder('backImage', this.filePlaceholder.UPLOAD_GOVT_ID);
		this.commonService.setFilePlaceholder('fileResume', this.filePlaceholder.UPLOAD_UTILITY_BILL);
		this.commonService.setFilePlaceholder('contract', this.filePlaceholder.UPLOAD_CONTRACT);
	}

	ngOnDestroy() {
		this.cropperSettings.rounded = true;
		this.cropperSettings.keepAspect = true;
	}
}