import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-emergency-details',
	templateUrl: './emergency-details.component.html',
	styleUrls: ['./emergency-details.component.css']
})
export class EmergencyDetailsComponent implements OnInit {
	@Input() emergencyContact;
	@Input() isAssociate:boolean = false;
	constructor() { }

	ngOnInit(): void {
	}

}