import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../__guard/auth.guard';
import { TITLE } from '../../../__utilities/text';
import { ROLES } from '../../../__utilities/roles';
import {
	ClientComponent, AssociateComponent, FleetOwnerComponent,
	ClientDeleteRequestComponent, UsersComponent, WalkinComponent,
	WalkInsOrderComponent
} from '../../index';
import { PERMISSIONS } from '../../../__utilities/permissions';
import { ClientDetailsComponent } from '../../../containers';

const routes: Routes = [
	{
		path: 'clients',
		component: ClientComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS,
			viewURL: '/users/clients/order-details/',
			viewProfileURL: '/users/clients/clients-details/',
			breadcrumb: [
				{
					label: TITLE.CLIENTS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENT, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR]
		}
	},
	{
		path: 'clients/order-details/:id',
		component: WalkInsOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS,
			isClientWalkin: true,
			breadcrumb: [
				{
					label: TITLE.CLIENTS,
					url: '/users/clients'
				},
				{
					label: TITLE.CLIENT_ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_CLIENT, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR]
		}
	},
	{
		path: 'clients/clients-details/:id',
		component: ClientDetailsComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENTS_DETAILS,
			breadcrumb: [
				{
					label: TITLE.CLIENTS,
					url: '/users/clients'
				},
			],
			permission: [PERMISSIONS.VIEW_CLIENT, ROLES.CORPORATE, ROLES.DISTRIBUTOR, ROLES.PLANT_MANAGER]
		}
	},
	{
		path: 'corporate-users',
		component: UsersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CORPORATE_USERS,
			breadcrumb: [
				{
					label: TITLE.CORPORATE_USERS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_USERS, ROLES.CORPORATE]
		}
	},
	{
		path: 'fleet-owner',
		component: FleetOwnerComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.FLEET_OWNER,
			viewURL: '/users/fleet-owner/details/',
			breadcrumb: [
				{
					label: TITLE.FLEET_OWNER,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_FLEET_OWNER, ROLES.CORPORATE]
		}
	},
	{
		path: 'fleet-owner/details/:id',
		component: FleetOwnerComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.FLEET_OWNER,
			viewURL: '/users/fleet-owner/drivers/order-details/',
			breadcrumb: [
				{
					label: TITLE.FLEET_OWNER,
					url: '/users/fleet-owner'
				},
				{
					label: TITLE.FLEET_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_FLEET_OWNER, ROLES.CORPORATE]
		}
	},
	{
		path: 'fleet-owner/drivers/order-details/:id',
		component: AssociateComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE_DETAILS,
			isOrderDetails: true,
			isFleetAssociate: true,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE,
					url: '/users/fleet-owner/details/'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_DRIVER, ROLES.CORPORATE]
		}
	},
	{
		path: 'drivers',
		component: AssociateComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE,
			viewURL: '/users/drivers/order-details/',
			viewProfileURL: '/users/drivers/driver-details/',
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_DRIVER, ROLES.CORPORATE]
		}
	},
	{
		path: 'drivers/order-details/:id',
		component: AssociateComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE,
			isOrderDetails: true,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE,
					url: '/users/drivers'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_DRIVER, ROLES.CORPORATE]
		}
	},
	{
		path: 'drivers/driver-details/:id',
		component: AssociateComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE_DETAILS,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE,
					url: '/users/drivers'
				},
				{
					label: TITLE.ASSOCIATE_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_DRIVER, ROLES.CORPORATE]
		}
	},
	{
		path: 'client-account-delete-request',
		component: ClientDeleteRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CLIENT_ACCOUNT_DELETE_REQUEST,
			breadcrumb: [
				{
					label: TITLE.CLIENT_ACCOUNT_DELETE_REQUEST,
					url: ''
				}
			],
			isClientRequest: true,
			permission: [PERMISSIONS.VIEW_CLIENT_ACCOUNT_DELETE_REQUEST, ROLES.CORPORATE]
		}
	},
	{
		path: 'driver-account-delete-request',
		component: ClientDeleteRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ASSOCIATE,
			breadcrumb: [
				{
					label: TITLE.ASSOCIATE_ACCOUNT_DELETE_REQUEST,
					url: ''
				}
			],
			isClientRequest: false,
			permission: [PERMISSIONS.VIEW_DRIVER_ACCOUNT_DELETE_REQUEST, ROLES.CORPORATE]
		}
	},
	{
		path: 'walk-ins',
		component: WalkinComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.WALKIN,
			viewURL: '/users/walk-ins/order-details/',
			breadcrumb: [
				{
					label: TITLE.WALKIN,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_WALKIN, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR]
		}
	},
	{
		path: 'walk-ins/order-details/:id',
		component: WalkInsOrderComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.WALKIN,
			isClientWalkin: false,
			breadcrumb: [
				{
					label: TITLE.WALKIN,
					url: '/users/walk-ins'
				},
				{
					label: TITLE.WALKIN_ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_WALKIN, ROLES.CORPORATE, ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR]
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UserRoutingModule { }

export const ROUTING_COMPONENTS = [
	ClientComponent, AssociateComponent, UsersComponent,
	FleetOwnerComponent, ClientDeleteRequestComponent, WalkinComponent,
	WalkInsOrderComponent
];