import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../__services'


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private storageService: StorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.storageService.getItem("token");
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ` + token
                }
            });
        }
        return next.handle(request).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    this.storageService.clearAll();
                }
            }
            const errorMessage = { message: err.error.message, success: err.error.success };
            return throwError(errorMessage);
        }))
    }
}
