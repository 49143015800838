import { Component, OnInit, Input } from '@angular/core';
import { MESSAGE } from '../../__utilities/messages';

@Component({
	selector: 'app-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

	@Input() documents: any;
	@Input() commonService: any;
	@Input() isIGR:boolean = false;
	messages:any = MESSAGE;
	constructor() { }

	ngOnInit(): void {
		
	}

}
