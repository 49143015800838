export const PERMISSIONS = {
	VIEW_HUB: 1,
	ADD_UPDATE_HUB: 2,
	DELETE_HUB: 3,
	VIEW_HUB_NEW_CYLINDER_REQUEST: 4,
	VIEW_HUB_REFILL_CYLINDER_REQUEST: 5,
	VIEW_HUB_CYLINDER_STOCK: 6,
	VIEW_WAREHOUSE: 7,
	ADD_UPDATE_WAREHOUSE: 8,
	DELETE_WAREHOUSE: 9,
	VIEW_WAREHOUSE_CYLINDER_STOCK: 10,
	VIEW_USERS: 11,
	ADD_USERS: 12,
	DELETE_USERS: 13,
	VIEW_CLIENTS_ORDER: 14,
	//VIEW_CLIENT_PURCHASE_CYLINDER_ORDER: 15,
	VIEW_DRIVER_CYLINDER_REQUEST: 16,
	DRIVER_ORDER_DELIVERY_TRACK: 17,
	VIEW_TERMS_CONDITION: 18,
	UPDATE_TERMS_CONDITION: 19,
	VIEW_ROLE_PERMISSION: 20,
	ADD_UPDATE_ROLE_PERMISSION: 21,
	VIEW_PRIVACY_POLICY: 22,
	UPDATE_PRIVACY_POLICY: 23,
	VIEW_COMMON_SETTING: 24,
	UPDATE_COMMON_SETTING: 25,
	VIEW_PRODUCT_PRICE: 26,
	ADD_UPDATE_PRODUCT_PRICE: 27,
	DELETE_PRODUCT_PRICE: 28,
	RESPONDS_TO_REVIEW: 29,
	VIEW_CLIENT: 30,
	CLIENT_APPROVE_REJECT_ACCOUNT: 31,
	VIEW_CLIENT_ACCOUNT_DELETE_REQUEST: 32,
	APPROVE_REJECT_CLIENT_ACCOUNT_DELETE_REQUEST: 33,
	VIEW_FLEET_OWNER: 34,
	APPROVE_REJECT_FLEET_OWNER_ACCOUNT: 35,
	VIEW_DRIVER: 36,
	DRIVER_APPROVE_REJECT_ACCOUNT: 37,
	VIEW_DRIVER_ACCOUNT_DELETE_REQUEST: 38,
	APPROVE_REJECT_DRIVER_ACCOUNT_DELETE_REQUEST: 39,
	// VIEW_REGULATOR_INFORMATION: 40,
	// ADD_UPDATE_REGULATOR_INFORMATION: 41,
	// DELETE_REGULATOR_INFORMATION: 42,
	VIEW_DISTRIBUTORS: 43,
	ADD_UPDATE_DISTRIBUTOR: 44,
	DELETE_DISTRIBUTOR: 45,
	VIEW_DISTRIBUTOR_CYLINDER_STOCK: 46,
	UPDATE_CORPORATE_USER_COMPANY_NAME: 47,
	VIEW_CONSENT_CLAUSE: 48,
	UPDATE_CONSENT_CLAUSE: 49,
	VIEW_WALKIN: 50,
	VIEW_INDEPENDENT_GAS_RETAILER: 51,
	APPROVE_REJECT_INDEPENDENT_RETAILER: 52,
	INDEPENDENT_ORDER_SALE_PERMISSION: 53,
	VIEW_INDEPENDENT_GAS_RETAILER_CYLINDER_STOCK: 54,
	HELP_DESK: 55,
	VIEW_INDEPENDENT_GAS_RETAILER_NEW_CYLINDER_REQUEST: 56,
	//VIEW_INDEPENDENT_GAS_RETAILER_PAYMENT: 57,
	VIEW_CLIENTS_ORDER_PAYMENT: 58,
	DOWNLOAD_DRIVER_SALES_REPORT: 59,
	DOWNLOAD_FLEET_OWNER_SALES_REPORT: 60,
	DOWNLOAD_INDEPENDENT_GAS_RETAILER_SALES_REPORT: 61,
	DOWNLOAD_HUB_DISTRIBUTOR_SALES_REPORT: 62,
	USER_REFILL_EXCHANGE: 63,
	USER_REFILL_MY_OWN: 64,
	USER_NEW_PURCHASE: 65
};