import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
	IndependentGasRetailerComponent, IndependentNewCylinderRequestComponent,
	ApproveIndependentCylinderRequestComponent, IndependentGasRetailerPaymentComponent
} from '../../';
import { AuthGuard } from '../../../__guard/auth.guard';
import { ROLES } from '../../../__utilities/roles';
import { TITLE } from '../../../__utilities/text';
import { PERMISSIONS } from '../../../__utilities/permissions';
import { OrdersComponent } from '../../../containers';

const routes: Routes = [
	{
		path: '',
		component: IndependentGasRetailerComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.INDEPENDENT_GAS_RETAILER,
			viewURL: '/independent-gas-retailers/retailer-details',
			viewOrdersURL:'//independent-gas-retailers/orders/',
			breadcrumb: [
				{
					label: TITLE.INDEPENDENT_GAS_RETAILER,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER, ROLES.CORPORATE]
		}
	},
	{
		path: 'orders/:id',
		component: OrdersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.INDEPENDENT_GAS_RETAILER,
			isOrders:true,
			isIGR:true,
			viewURL:'/independent-gas-retailers/orders/order-details',
			breadcrumb: [
				{
					label: TITLE.INDEPENDENT_GAS_RETAILER,
					url: '/independent-gas-retailers'
				},
				{
					label: TITLE.ORDERS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER, ROLES.CORPORATE]
		}
	},
	{
		path: 'orders/order-details/:id',
		component: OrdersComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.INDEPENDENT_GAS_RETAILER,
			isOrders:false,
			breadcrumb: [
				{
					label: TITLE.INDEPENDENT_GAS_RETAILER,
					url: '/hindependent-gas-retailers'
				},
				{
					label: TITLE.ORDERS,
					url: '/independent-gas-retailers/orders/'
				},
				{
					label: TITLE.ORDER_DETAILS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER, ROLES.CORPORATE]
		}
	},
	{
		path: 'retailer-details/:id',
		component: IndependentGasRetailerComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.INDEPENDENT_GAS_RETAILER,
			breadcrumb: [
				{
					label: TITLE.INDEPENDENT_GAS_RETAILER,
					url: '/independent-gas-retailers'
				},
				{
					label: TITLE.INDEPENDENT_GAS_RETAILER_DETAILS,
					url: ''
				},
			],
			permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER, ROLES.CORPORATE]
		}
	},
	{
		path: 'cylinder-request',
		component: IndependentNewCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.EMPTY_CYLINDER_REQUEST,
			viewURL: '/independent-gas-retailers/cylinder-request/request-details/',
			breadcrumb: [
				{
					label: TITLE.EMPTY_CYLINDER_REQUEST,
					url: ''
				},
			],
			permission: [ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'cylinder-request/request-details/:id',
		component: IndependentNewCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.EMPTY_CYLINDER_REQUEST,
			breadcrumb: [
				{
					label: TITLE.EMPTY_CYLINDER_REQUEST,
					url: '/independent-gas-retailers/cylinder-request'
				},
				{
					label: TITLE.REQUEST_DETAILS,
					url: ''
				},
			],
			permission: [ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'cylinder-requests',
		component: ApproveIndependentCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.INDEPENDENT_CYLINDER_REQUEST,
			viewURL: '/independent-gas-retailers/cylinder-requests/request-details/',
			breadcrumb: [
				{
					label: TITLE.INDEPENDENT_CYLINDER_REQUEST,
					url: ''
				},
			],
			permission: [ROLES.WAREHOUSE_MANAGER]
		}
	},
	// {
	// 	path: 'payment',
	// 	component: IndependentGasRetailerPaymentComponent,
	// 	canActivate: [AuthGuard],
	// 	data: {
	// 		title: TITLE.INDEPENDENT_PAYMENT_HISTORY,
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.INDEPENDENT_PAYMENT_HISTORY,
	// 				url: ''
	// 			},
	// 		],
	// 		permission: [PERMISSIONS.VIEW_INDEPENDENT_GAS_RETAILER_PAYMENT,ROLES.CORPORATE]
	// 	}
	// },
	{
		path: 'cylinder-requests/request-details/:id',
		component: ApproveIndependentCylinderRequestComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.INDEPENDENT_CYLINDER_REQUEST,
			breadcrumb: [
				{
					label: TITLE.INDEPENDENT_CYLINDER_REQUEST,
					url: '/independent-gas-retailers/cylinder-requests'
				},
				{
					label: TITLE.REQUEST_DETAILS,
					url: ''
				},
			],
			permission: [ROLES.WAREHOUSE_MANAGER]
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class GasRetailerRoutingModule { }

export const ROUTING_COMPONENTS = [
	IndependentGasRetailerComponent, IndependentNewCylinderRequestComponent,
	ApproveIndependentCylinderRequestComponent, IndependentGasRetailerPaymentComponent
];
