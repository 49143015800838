import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CylinderService, LoaderService, CommonService, StorageService } from '../../../../__services';
import { PAGINATION } from '../../../../__utilities/pagination';
import { TITLE } from '../../../../__utilities/text';
import { MESSAGE } from '../../../../__utilities/messages';
import { COMPONENT_KEYS, ORDER_STATUS } from '../../../../__utilities/orders';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $: any;

@Component({
	selector: 'app-plant-refill-request',
	templateUrl: './plant-refill-request.component.html',
	styleUrls: ['./plant-refill-request.component.css']
})
export class PlantRefillRequestComponent implements OnInit, AfterViewInit, OnDestroy {

	paginate: any = { ...PAGINATION };
	orderStatus: object = { ...ORDER_STATUS };
	searchStr: string = "";
	requests = [];
	isLoading: boolean = false;
	cylinderCategories = [];
	selectedRequest: any;
	heading: string = TITLE.PLANT_REFILL_REQUEST;
	filterOptions: string = "";
	refillRequestId: string = "";
	noRecord: string = "";
	activatedRouteValues: any = "";
	statusFilter: string = "";
	subscription: Subscription;
	constructor(
		public cylinderService: CylinderService,
		public commonService: CommonService,
		public storageService: StorageService,
		public loader: LoaderService,
		public router: Router,
		public activatedRoute: ActivatedRoute
	) {
	}

	ngOnInit(): void {
		this.getRoutingParams();
		delete this.orderStatus["CANCELLED"];
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ refillRequestId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.refillRequestId = results.refillRequestId;
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.statusFilter = results.query.status ? results.query.status : "";
				this.requests = [];
				this.refillRequestId ? this.fetchPlantRefillRequestById() : this.fetchPlantsRefillRequest();
			});
	}

	// Fetch plant refill request by id
	fetchPlantRefillRequestById() {
		this.loader.show();
		this.isLoading = true;
		this.cylinderService.fetchPlantRefillRequestById(this.refillRequestId).subscribe(res => {
			if (res.success && res.response && res.response.data) {
				this.selectedRequest = res.response.data;
			}
			else
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
			this.loader.hide();
			this.isLoading = false;
		}, error => {
			this.loader.hide();
			this.isLoading = false;
			this.noRecord = MESSAGE.NO_RECORD_FOUND;
		});
	}

	resetPagination() {
		this.paginate.currentPage = 1;
		this.paginate.totalItems = 0;
	}

	viewRequest(request) {
		this.router.navigate([this.activatedRouteValues.viewURL, request._id], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		if (this.statusFilter) queryParams.status = this.statusFilter;
		return queryParams;
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.requests = [];
		this.fetchPlantsRefillRequest();
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetAndSearch();
	}

	resetAndSearch() {
		this.resetPagination();
		this.requests = [];
		this.fetchPlantsRefillRequest();
	}

	onOrderStatusChange(status) {
		this.statusFilter = status;
		this.resetAndSearch();
	}

	// Go Back
	goBack() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	// Fetch Plant Cylinder Request
	fetchPlantsRefillRequest() {
		this.isLoading = true;
		let searchQuery = this.queryParams();
		this.cylinderService.fetchPlantsRefillRequest(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.cylinderCategories = res.response.categories;
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.requests = res.response.data && res.response.data.length ? res.response.data : [];
			}
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
		});
	}
	// Search Params
	queryParams() {
		let searchStr = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		if (this.filterOptions) searchStr += this.filterOptions;
		if (this.statusFilter) searchStr += "&filter=" + this.statusFilter;
		return searchStr;
	}

	// Search By start and end date
	onFilter(obj) {
		this.resetPagination();
		this.filterOptions = "&startDate=" + obj.startDate + "&endDate=" + obj.endDate;
		this.requests = [];
		this.fetchPlantsRefillRequest();
	}

	// Update list on status change by mobile application.
	orderStatusSubscription() {
		if (this.refillRequestId) return;
		this.commonService.setCurrentOrderKey(COMPONENT_KEYS.CYLINDER_REFILL_REQUEST);
		this.subscription = this.commonService.orderStatusChange.subscribe(res => {
			if (res) {
				this.fetchUpdatedList();
			}
		}, error => { })
	}

	// fetch Updated list
	fetchUpdatedList() {
		this.searchStr = "";
		this.requests = [];
		this.resetAndSearch();
	}

	ngAfterViewInit() {
		this.orderStatusSubscription();
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe();
	}
}
