import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersService, ValidationService, PlantService, CommonService, TokenService, LoaderService } from '../../../__services';
import { PAGINATION } from '../../../__utilities/pagination';
import { PHONE_CONFIG, DEFAULT_COUNTRY } from '../../../__utilities/phone';
import { MESSAGE } from '../../../__utilities/messages';
import { PERMISSIONS } from '../../../__utilities/permissions';
import { BTN_TEXT, TITLE } from '../../../__utilities/text';
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ORDER_TYPE, ORDER_VALUES } from '../../../__utilities/orders';


@Component({
	selector: 'app-plants',
	templateUrl: './plants.component.html',
	styleUrls: ['./plants.component.css']
})

export class PlantsComponent implements OnInit, OnDestroy {
	paginate: any = { ...PAGINATION };
	plantForm: UntypedFormGroup;
	phoneConfig = PHONE_CONFIG;
	messages: any = MESSAGE;
	plants = [];
	options = {
		types: ['address'],
		componentRestrictions: { country: 'ng' }
	};
	isSubmitted: boolean = false;
	address: string;
	location: any = {
		type: 'Point',
		coordinates: []
	};
	selectedPlant: any;
	isAdd: boolean = false;
	isView: boolean = false;
	isDisabled: boolean = false;
	isLoading: boolean = false;
	searchStr: string = '';
	btnText: string = BTN_TEXT.ADD_PLANT;
	heading: string = TITLE.PLANT;
	isUnknownLocation: boolean = false;
	selectedIndex: number = 0;
	plantId: string = "";
	cylinderCategories: any = [];

	isAddUpdatePermission: boolean = false;
	isDeletePermission: boolean = false;
	permissions: any = {};
	documents: any;
	activatedRouteValues: any = "";
	toolTipMessage: string = "";
	routeReuseSubscription: Subscription;
	constructor(
		public usersService: UsersService,
		private fb: UntypedFormBuilder,
		public validationService: ValidationService,
		public plantService: PlantService,
		public commonService: CommonService,
		public tokenService: TokenService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public loader: LoaderService
	) {
	}

	ngOnInit(): void {
		this.getRoutingParams();
		this.initializeControls();
	}

	// Set Permission
	setPermission() {
		let authUserPermission = this.tokenService.getPermission();
		if (!this.plantId)
			this.permissions = {
				isAddUpdateHub: this.commonService.isPermission(authUserPermission, PERMISSIONS.ADD_UPDATE_HUB),
				isDeleteHub: this.commonService.isPermission(authUserPermission, PERMISSIONS.DELETE_HUB),
			}
		else
			this.permissions.isviewCylinderStock = this.commonService.isPermission(authUserPermission, PERMISSIONS.VIEW_HUB_CYLINDER_STOCK);
	}

	// Get Routing Params
	getRoutingParams() {
		this.activatedRouteValues = this.activatedRoute.data['value'];
		combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
			.pipe(map(results => ({ plantId: results[0].id, query: results[1] })))
			.subscribe(results => {
				this.plantId = results.plantId;
				this.routeSubscription();
				this.paginate.currentPage = results.query.page ? results.query.page : 1;
				this.searchStr = results.query.search ? results.query.search : "";
				this.plants = [];
				this.plantId ? this.fetchPlantById(this.plantId) : (this.fetchPlant());
				this.setPermission();
			});
	}

	// Fetch Plant By id
	fetchPlantById(plantId) {
		this.loader.show();
		this.isLoading = true;
		this.plantService.fetchPlantById(plantId).subscribe(res => {
			if (res.success && res.response && res.response.data) {
				this.selectedPlant = res.response.data;
				this.documents = this.selectedPlant.user.hasOwnProperty("govtId") && Object.keys(this.selectedPlant.user.govtId).length
					? this.selectedPlant.user.govtId : "";
				if (this.documents) {
					this.documents.resume = this.selectedPlant.user.resume;
					this.documents.signedContract = this.selectedPlant.user.signedContract;
				}
				this.cylinderCategories = res.response.categories ? res.response.categories.sort(this.commonService.sortByProperty('size')) : [];
			}
			this.stopLoading();
		}, error => {
			this.stopLoading();
		});
	}

	stopLoading() {
		this.loader.hide();
		this.isLoading = false;
	}

	initializeControls() {
		this.plantForm = this.fb.group({
			name: ['', Validators.compose([Validators.required])],
			firstName: ['', Validators.compose([Validators.required])],
			lastName: ['', Validators.compose([Validators.required])],
			email: ['', Validators.compose([Validators.required, Validators.email])],
			contact: ['', Validators.compose([Validators.required])],
			address: ['', Validators.compose([Validators.required])],
			landMarkAddress: ['', Validators.compose([])],
		});
	}

	// Submit plant form
	onSubmit(plantData) {
		console.log("plantData", plantData);

		this.isSubmitted = true;
		if (this.plantForm.valid) {
			plantData = this.createPayload(plantData);
			this.isUnknownLocation = Object.keys(this.location.coordinates).length ? false : true;
			if (!this.isUnknownLocation) {
				let inputAddress = (this.plantForm.controls.address.value).trim();
				this.isUnknownLocation = inputAddress != (this.address).trim();
			}
			if (this.isUnknownLocation) return;

			this.isDisabled = true;
			this.plantService.managePlant(plantData).subscribe(res => {
				if (res && res.success) {
					if (plantData.id)
						this.plants[this.selectedIndex] = res.plant;
					else {
						this.paginate.totalItems += 1;
						this.plants.unshift(res.plant);
					}
					this.resetForm();
				}
				this.commonService.growlSuccess(res.message);
				this.isDisabled = false;
			}, error => {
				this.isSubmitted = false;
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			if (this.plantForm.controls.address.value)
				this.isUnknownLocation = Object.keys(this.location.coordinates).length ? false : true;
			else
				this.isUnknownLocation = false;
			this.validationService.validateAllFormFields(this.plantForm);
		}
	}

	fetchPlant() {
		let searchQuery = "?search=" + this.searchStr + "&page=" + this.paginate.currentPage + "&limit=" + PAGINATION.itemsPerPage;
		this.isLoading = true;
		this.plantService.fetchPlants(searchQuery).subscribe(res => {
			if (res && res.success && res.response) {
				this.paginate.totalItems = res.response.pagination.totalDocs;
				this.plants = res.response.plants ? res.response.plants : [];
				console.log("this.plants", this.plants);

			}
			else
				this.plants = [];
			this.isLoading = false;
		}, error => {
			this.isLoading = false;
			this.plants = [];
		});
	}

	createPayload(plantData) {
		plantData.location = Object.keys(this.location.coordinates).length ? this.location : (this.selectedPlant ? this.selectedPlant.location : '');
		plantData.address = this.address ? this.address : (this.selectedPlant ? this.selectedPlant.address : '');
		if (this.selectedPlant)
			plantData.id = this.selectedPlant._id;

		if (typeof plantData.contact == "object") {
			plantData.countryCode = plantData.contact.countryCode;
			plantData.dialCode = plantData.contact.dialCode;
			plantData.contact = this.commonService.formatContactNumber(plantData.contact.number);
			plantData.landMarkAddress = plantData.landMarkAddress;
		}
		return plantData;
	}

	addMore(isAdd) {
		this.isAdd = isAdd;
	}

	onSearch(searchStr) {
		this.searchStr = searchStr;
		this.resetPagination();
		this.plants = [];
		this.fetchPlant();
	}

	onPaginate(pageNo) {
		this.paginate.currentPage = pageNo;
		this.plants = [];
		this.fetchPlant();
	}

	resetPagination() {
		this.paginate.totalItems = 0;
		this.paginate.currentPage = 1;
	}

	resetForm() {
		this.isSubmitted = false;
		this.plantForm.reset();
		this.isAdd = false;
		this.isView = false;
		this.phoneConfig.CountryISO = DEFAULT_COUNTRY;
		this.selectedPlant = null;
		this.isUnknownLocation = false;
		this.location.coordinates = [];
		this.address = '';
	}

	public handleAddressChange(address) {
		if (typeof address == "object") {
			this.isUnknownLocation = false;
			let location = address.geometry.location;
			this.location.coordinates[0] = location.lng();
			this.location.coordinates[1] = location.lat();
			this.address = address.formatted_address;
			this.plantForm.controls.address.setValue(this.address);
		}
		else {
			this.plantForm.controls.address.setValue('');
			this.isUnknownLocation = true;
		}
	}

	editPlant(plant, isView, index) {
		this.isAdd = true;
		this.isView = isView;
		this.selectedPlant = plant;
		this.selectedIndex = index;
		if (this.selectedPlant.location.coordinates && this.selectedPlant.location.coordinates.length) {
			this.location.coordinates[0] = this.selectedPlant.location.coordinates[0];
			this.location.coordinates[1] = this.selectedPlant.location.coordinates[1];
		}
		let controls = this.plantForm.controls;
		this.address = this.selectedPlant.address;
		controls.name.setValue(plant.name);
		controls.address.setValue(plant.address);
		controls.landMarkAddress.setValue(plant.landMarkAddress);
		controls.firstName.setValue(plant.user.firstName);
		controls.lastName.setValue(plant.user.lastName);
		controls.email.setValue(plant.user.email);
		this.phoneConfig.CountryISO = plant.user.countryCode;
		controls.contact.setValue(plant.user.contact);
	}

	// Delete Plant Confirmation
	deletePlant(plant, index) {
		Swal.fire({
			title: MESSAGE.DELETE_PLANT_CONFIRMATION_TITLE,
			text: MESSAGE.DELETE_PLANT_CONFIRMATION_TEXT + plant.name + '?',
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			customClass: { confirmButton: 'swal-confirm-btn', content: 'swal-contents' },
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.deleteSelectedPlant(plant._id, index);
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	// Delete Plant
	deleteSelectedPlant(plantId, index) {
		this.plantService.deletePlant(plantId).subscribe(res => {
			if (res && res.success) {
				this.paginate.totalItems -= 1;
				this.plants.splice(index, 1);
				this.commonService.growlSuccess(res.message);
			}
			else {
				this.commonService.growlError(res.message);
			}
		}, error => {
			this.commonService.growlError(error.message);
		});
	}

	routeSubscription() {
		this.routeReuseSubscription = this.commonService.routeReuse.subscribe(res => {
			res == TITLE.PLANT ? this.resetForm() : false;
		});
	}

	// Back to list from details page
	backToList() {
		this.router.navigate([this.activatedRouteValues.breadcrumb[0].url], { queryParams: this.queryParamsObj() });
	}

	viewPlant(plantId) {
		this.router.navigate([this.activatedRouteValues.viewURL, plantId], { queryParams: this.queryParamsObj() });
	}

	viewOrders(plantId) {
		this.searchStr = "";
		this.router.navigate([this.activatedRouteValues.viewOrdersURL, plantId], { queryParams: this.queryParamsObj() });
	}

	queryParamsObj() {
		const queryParams: any = { page: this.paginate.currentPage };
		if (this.searchStr) queryParams.search = this.searchStr;
		return queryParams;
	}

	openOrderPopup(plant) {
		this.selectedPlant = plant;
		const inputOptions = {
			'NewCylinderRequest': TITLE.NEW_CYLINDER_REQUEST,
			'RefillCylinderRequest': TITLE.REFILL_CYLINDER_REQUEST,
		};
		Swal.fire({
			title: TITLE.ORDER_TYPE_SELECTION,
			input: 'select',
			inputOptions: inputOptions,
			inputPlaceholder: TITLE.ORDER_TYPE_SELECTION,
			showCancelButton: true,
			confirmButtonText: "Download",
			customClass: { input: 'my-drop form-control' }
		}).then((confirm) => {
			if (confirm.isConfirmed && confirm.value) {
				confirm.value == 'NewCylinderRequest' ? this.newCylinderRequestCSV() : this.refillCylinderRequestCSV();
			} else {
				confirm.isConfirmed ? Swal.fire(TITLE.SELECT_ANY_ONE_TYPE) : "";
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	downloadCSV(res, fileName) {
		const blob = new Blob([res], {
			type: 'text/csv'
		});
		var a = window.document.createElement("a");
		a.href = window.URL.createObjectURL(blob);
		a.download = `${fileName}.csv`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	newCylinderRequestCSV() {
		const filter = `?plantId=${this.selectedPlant._id}`
		this.plantService.newCylinderRequestCSV(filter).subscribe(res => {
			this.downloadCSV(res, `orders-newCylinderRequest-${this.selectedPlant.name}`)
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}

	refillCylinderRequestCSV() {
		const filter = `?plantId=${this.selectedPlant._id}`
		this.plantService.refillCylinderRequestCSV(filter).subscribe(res => {
			this.downloadCSV(res, `orders-refillCylinderRequest-${this.selectedPlant.name}`)
		}, error => {
			this.commonService.growlError(MESSAGE.NO_ORDER_FOUND);
		});
	}

	setRetailerOrderSalePermission(plants, payload, index) {
		this.usersService.setRetailerOrderSalePermission(plants._id, payload).subscribe(response => {
			this.commonService.growlSuccess(response.message);
			this.plants[index] = response.response.data;
		}, error => {
			this.commonService.growlSuccess(error.message);
		});
	}

	openOrderPermissionPopup(plant, index) {
		const purchaseCheckedText = plant.isNewPurchase ? 'checked' : '';
		const refillMyOwnCheckedText = plant.isRefillMyOwn ? 'checked' : '';
		Swal.fire({
			title: 'Hub : ' + plant.name,
			html:
				'<p style = "text-align:left; padding-top:10px;"><label style="position:absolute; margin-left: -1.3rem;" class="checkbox"><input type="checkbox" name="checkAll" id="swalRefillOwn" ' + refillMyOwnCheckedText + ' /><span class="default"></span></label>&nbsp;&nbsp;' + ORDER_VALUES[ORDER_TYPE.REFILL_MY_OWN] + '</p>' +
				'<p style = "text-align:left; padding-top:10px;"><label style="position:absolute; margin-left: -1.3rem;" class="checkbox"><input type="checkbox" name="checkAll" id="swalPurchase" ' + purchaseCheckedText + ' /><span class="default"></span></label>&nbsp;&nbsp;' + ORDER_VALUES[ORDER_TYPE.PURCHASE] + '</p>',
			focusConfirm: false,
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			confirmButtonText: "Save",
			preConfirm: () => {
				const payload = {
					isNewPurchase: (<HTMLInputElement>document.getElementById('swalPurchase')).checked,
					isRefillMyOwn: (<HTMLInputElement>document.getElementById('swalRefillOwn')).checked
				};
				this.setRetailerOrderSalePermission(plant, payload, index);
			}
		});
	}
	showHideTip(isShow) {
		this.toolTipMessage = isShow ? "Sale Permission" : "";
	}

	ngOnDestroy() {
		this.routeReuseSubscription.unsubscribe();
	}
}