import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService, ValidationService, PaymentService, CommonService } from '../../../__services';
import { TITLE } from '../../../__utilities/text';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { MESSAGE } from '../../../__utilities/messages';
import * as crypto from '../../../__utilities/encryption';
import Swal from 'sweetalert2';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $: any;

@Component({
	selector: 'app-independent-card-management',
	templateUrl: './independent-card-management.component.html',
	styleUrls: ['./independent-card-management.component.css']
})
export class IndependentCardManagementComponent implements OnInit {
	heading: string = TITLE.CARD_DETAILS;
	searchStr: string = "";
	isAdd: boolean = false;
	isDisabled: boolean = false;
	cards = [];
	cardForm: UntypedFormGroup;
	bankInfoForm: UntypedFormGroup;
	ccnum: any = "";
	cvv: any = "";
	mm: any = "";
	yy: any = "";
	isLoading: boolean = false;
	noRecord: string = "";
	selectedBank: any = "";
	isValidAccountDetails: boolean = false;
	isValidatingAccountDetails: boolean = false;
	bankList: any[] = [];
	bankDetails: any = "";
	formGroupValidateArr = {
		1: 'personalInfoFormGroup',
		2: 'bankInfoFormGroup',
		3: 'otherInfoFromGroup'
	};
	registrationData: any = "";
	@Input() myProfile: any;
	constructor(
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public loader: LoaderService,
		public validationService: ValidationService,
		private fb: UntypedFormBuilder,
		public paymentService: PaymentService,
		public commonService: CommonService
	) {
	}

	ngOnInit(): void {
		this.initializeControls();
		this.fetchDetails();
	}

	initializeControls() {
		this.cardForm = this.fb.group({
			cardHolderName: ['', Validators.compose([Validators.required])],
			cardNumber: ['', Validators.compose([Validators.required, Validators.minLength(16), Validators.maxLength(16)])],
			expiryMonth: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(12)])],
			expiryYear: ['', Validators.compose([Validators.required, Validators.min(Number(new Date().getFullYear().toString().substr(-2))
			)])],
			cvv: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(3)])],
		});

		this.bankInfoForm = this.fb.group({
			bankName: ['', Validators.compose([Validators.required])],
			accountNumber: ['', Validators.compose([Validators.required])],
			accountHolderName: ['', Validators.compose([Validators.required])],
		});

		this.bankInfoForm.disable();
	}

	// Fetch Details
	fetchDetails() {
		this.displayLoader(true);
		combineLatest([this.paymentService.fetchBankList(), this.paymentService.fetchCardDetails()])
			.pipe(map(results => ({ bankList: results[0], cardDetails: results[1] })))
			.subscribe(results => {
				this.showBankDetails(results.bankList);
				this.showCardDetails(results.cardDetails);
				this.displayLoader(false);
			}, error => {
				this.noRecord = MESSAGE.NO_RECORD_FOUND;
				this.cards = [];
				this.bankList = [];
				this.displayLoader(false);
			});
	}

	displayLoader(isLoading = false) {
		this.isLoading = isLoading;
		isLoading ? this.loader.show() : this.loader.hide();
	}

	showBankDetails(response) {
		this.bankList = response && response.success && response.response && response.response.data ? response.response.data : [];
		const bankDetails = {
			bankName: this.myProfile.user.bankDetail.bankCode,
			accountNumber: this.myProfile.user.bankDetail.accountNumber,
			accountHolderName: this.myProfile.user.bankDetail.accountName
		};
		this.bankInfoForm.setValue(bankDetails);
	}

	showCardDetails(response) {
		this.cards = response && response.success && response.response.data ? response.response.data : [];
		this.noRecord = this.cards.length ? "" : MESSAGE.NO_RECORD_FOUND;
	}

	addNew() {
		this.isAdd = true;
	}

	resetForm() {
		this.isAdd = false;
		this.cardForm.reset();
	}

	onDelete(card, i) {
		Swal.fire({
			title: MESSAGE.DELETE_CARD_TITLE,
			text: MESSAGE.DELETE_CARD_TEXT,
			allowEscapeKey: false,
			allowOutsideClick: false,
			reverseButtons: true,
			allowEnterKey: false,
			showCancelButton: true,
			cancelButtonText: 'Cancel',
			showConfirmButton: true,
			confirmButtonText: "Confirm",
		}).then((confirm) => {
			if (confirm.value) {
				this.paymentService.deleteCardDetails(card.cardToken).subscribe(res => {
					if (res && res.success) {
						this.commonService.growlSuccess(res.message);
						this.cards.splice(i, 1);
						this.noRecord = this.cards.length ? "" : MESSAGE.NO_RECORD_FOUND;
					}
				}, error => {
					this.commonService.growlSuccess(error.message);
				});
			}
		}).catch(err => {
			if (err) {
				this.commonService.growlError(MESSAGE.ERROR);
			}
		});
	}

	onBankChange(event) {
		this.selectedBank = event.target.options[event.target.selectedIndex].innerText;
	}

	onSubmit(cardDetails) {
		if (this.cardForm.valid) {
			this.isDisabled = true;
			cardDetails = {
				...cardDetails, cardNumber: crypto.aesEncrypt(cardDetails.cardNumber),
				cvv: crypto.aesEncrypt(cardDetails.cvv),
				cardHolder: cardDetails.cardHolderName
			};
			this.paymentService.addCard(cardDetails).subscribe(res => {
				if (res && res.success) {
					this.cards.unshift(res.response.data);
					this.noRecord = "";
					this.resetForm();
				}
				this.isDisabled = false;
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.cardForm);
		}
	}

	submitBankForm() {
		if (this.bankInfoForm.valid) {
			this.isDisabled = true;
			const bankDetails = this.bankInfoForm.value;
			bankDetails.accountName = bankDetails.accountHolderName;
			bankDetails.bankCode = bankDetails.bankName;
			bankDetails.bankName = this.selectedBank ? this.selectedBank : this.myProfile.user.bankDetail.bankName;
			delete bankDetails.accountHolderName;
			this.paymentService.updateBankDetails(bankDetails).subscribe(response => {
				if (response && response.success) {
					this.commonService.growlSuccess(response.message);
					this.bankInfoForm.disable();
				}
				this.isDisabled = false;
			}, error => {
				this.isDisabled = false;
				this.commonService.growlError(error.message);
			});
		}
		else {
			this.validationService.validateAllFormFields(this.bankInfoForm);
		}
	}

	onEdit() {
		this.bankInfoForm.enable();
	}
}
