import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ValidationService, ImageCropperService, ProfileService, TokenService, StorageService } from '../../../../__services';
import { CropperSettings } from 'ngx-img-cropper';
import { PHONE_CONFIG, DEFAULT_COUNTRY } from '../../../../__utilities/phone';
import { MESSAGE, FILE_PLACEHOLDER } from '../../../../__utilities/messages';
import { ROLES } from '../../../../__utilities/roles';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	cropperSettings: CropperSettings;
	data: any = {};
	phoneConfig = PHONE_CONFIG;
	message = MESSAGE;
	filePlaceholder = FILE_PLACEHOLDER;
	defaultCountry = DEFAULT_COUNTRY;
	activeTab: string;
	myProfile: any;
	isVisible: boolean = false;
	isContractUser: boolean = false;
	isIndependentRetailer: boolean = false;
	userDetails: any = "";
	constructor(
		public validationService: ValidationService,
		public imageCropperService: ImageCropperService,
		private fb: UntypedFormBuilder,
		public profileService: ProfileService,
		public tokenService: TokenService,
		public storageService: StorageService
	) {
		this.cropperSettings = this.imageCropperService.cropperSetting();
		this.userDetails = this.storageService.getItem('userObj');
		const roleName = this.userDetails.roleId.name;
		this.isVisible = [ROLES.PLANT_MANAGER, ROLES.DISTRIBUTOR, ROLES.WAREHOUSE_MANAGER, ROLES.INDEPENDENT_RETAILER].indexOf(roleName) > -1;
		this.isContractUser = roleName == ROLES.PLANT_MANAGER || roleName == ROLES.DISTRIBUTOR || roleName == ROLES.WAREHOUSE_MANAGER;
		this.isIndependentRetailer = roleName == ROLES.INDEPENDENT_RETAILER;
	}

	ngOnInit(): void {
		this.isIndependentRetailer ? this.fetchIndependentProfile() : this.fetchProfile();
	}

	tabChange(event) {
		this.cropperSettings.rounded = true;
		this.activeTab = event.id;
	}
	// Fetch Profile
	fetchProfile() {
		this.profileService.fetchProfile().subscribe(res => {
			if (res && res.success && res.user) {
				this.myProfile = res.user;
			}
		}, error => {
			this.myProfile = {};
		});
	}

	// Fetch Independent Profile
	fetchIndependentProfile() {
		this.profileService.fetchIndependentProfile(this.userDetails.independent._id).subscribe(res => {
			if (res && res.success && res.response) {
				this.myProfile = res.response.data ? res.response.data : {};
			}
		}, error => {
			this.myProfile = {};
		});
	}
}
