import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

	faqType: string = "";
	faqTypeArray = ["client", "associate", "fleet", 'igr'];
	constructor(
		public activatedRoute: ActivatedRoute
	) {
		this.activatedRoute.queryParams.subscribe(params => {
			if (this.faqTypeArray.indexOf(params['faqType']) > -1)
				this.faqType = params['faqType'];
		});
	}

	ngOnInit(): void {
	}

}
