export const BTN_TEXT = {
    ADD_PLANT: 'Add Hub',
    ADD_ROLE: 'Add Role',
    ADD_MORE: 'Add More',
    ADD_WAREHOUSE: 'Add Warehouse',
    ADD_CYLINDER: 'Add Cylinder',
    ADD_RECYCLE: 'Add Station',
    ADD_USERS: 'Add Users',
    ADD_REGULATOR: 'Add Regulator',
    ADD_DISTRIBUTOR: 'Add Distributor',
    ADD_WALKIN: 'Add Client',
    ADD_REQUEST: 'Add Request',
    CREATE_ORDER: 'Create Order',
    ADD_STOCK: 'Add Stock'
}

export const TABLE_TEXT = {
    NO_DATA: 'No data available'
}

export const TITLE = {
    PLANT: 'Smart Gas Hubs',
    PLANT_DETAILS: 'Hub Details',
    PLANT_ORDERS: 'Hub Orders',
    ORDERS: 'Orders',
    WAREHOUSE: 'Warehouses',
    WAREHOUSE_DETAILS: 'Warehouses Details',
    CLIENTS: 'Online Clients',
    CLIENTS_MENU: 'Clients',
    WALKIN: 'Walk-ins',
    WALKIN_ORDER_DETAILS: 'Walk-ins Order Details',
    CLIENT_ORDER_DETAILS: 'Client Order Details',
    CLIENTS_HISTORY: 'Client History',
    ASSOCIATE: 'Drivers',
    ASSOCIATE_DETAILS: 'Driver Details',
    CLIENTS_DETAILS: 'Client Details',
    CYLINDERS: 'Cylinders',
    TERMS_CONDITIONS: 'Terms & Conditions',
    PRIVACY_POLICY: 'Privacy Policy',
    CONSENT_CLAUSE: 'Consent Clause For Application/Web Portal Use',
    CONSENT_CLAUSE_TITLE: "Consent Clause",
    ROLES_PERMISSIONS: 'Roles and Permission',
    CYLINDER_CATEGORIES: 'Cylinder Pricing',
    CYLINDER_BRAND_CATEGORIES: 'Cylinder Brand Category',
    DASHBOARD: 'Dashboard',
    PROFILE: 'Profile',
    CHANGE_PASSWORD: 'Change Password',
    CLIENT_ORDER_PAYMENT: "Client's Order Payment",
    CYLINDER_REQUEST: 'New Cylinder + Gas Request',
    PLANT_REQUEST: 'Hub Request',
    CYLINDER_REGISTRATION: 'Cylinder Registration',
    RESET_PASSWORD: 'Reset Password',
    PURCHASE_ORDERS: 'New Cylinder + Gas',
    ORDER_DETAILS: 'Order Details',
    INVOICE: 'Invoice',
    REFILL_ORDERS: 'Refill Request',
    CLIENT_REFILL_ORDERS: "Refills",
    PICKUP_ORDERS: "Pickup Orders",
    CLIENT_REFILL_PICKUP_ORDERS: "Client's Refill Pickup Orders",
    REFILL_PICKUP_ORDERS: "Refill Pickup Orders",
    CLIENTS_HOME_DELIVERY_ORDERS: "Home Delivery Orders",
    CLIENTS_HOME_DRIVER_DELIVERY_ORDERS: "Home Delivery Orders (Smart Gas Driver)",
    FULFILLED_BY_SMARTGAS: "Fulfilled By Smart Gas",
    FULFILLED_BY_ME: "Fulfilled By Me",
    HOME_DELIVERY_ORDERS: "Home Delivery Orders",
    PLANTS_ORDER: "Hub's Order",
    PLANTS_REFILL_ORDER: "Hub's Refill Order",
    HOME: 'Home',
    LOGIN: 'Login',
    USERS: 'Users',
    UNAPPROVED_USERS: 'Unapproved Users',
    SETTINGS: 'Settings',
    MANAGE_PRODUCT_PRICE: 'Manage Product Price',
    ADD_YOUR_CYLINDER_STOCK: 'Add Your Cylinder Stock',
    PAGE_404: 'Page 404',
    PAGE_500: 'Page 500',
    ASSOCIATE_REQUEST: 'Driver Request',
    NOTIFICATIONS: 'Notifications',
    REQUESTS: 'Hub Inventory Requests',
    PLANT_CYLINDER_REQUEST: 'New Cylinder + Gas Request',
    PLANT_REFILL_REQUEST: "Refills",
    REQUEST_DETAILS: "Request Details",
    COMMON_SETTINGS: 'Common Settings',
    LIVE_ORDER: 'Live Orders',
    FLEET_OWNER: 'Fleet Owners',
    FLEET_DETAILS: 'Details',
    CLIENT_ACCOUNT_DELETE_REQUEST: 'Client Account Delete Request',
    ASSOCIATE_ACCOUNT_DELETE_REQUEST: 'Driver Account Delete Request',
    HELP_DESK: 'Help Desk',
    CORPORATE_USERS: 'Corporate Users',
    REGULATOR_INFO: 'Regulator Pricing',
    DISTRIBUTORS: 'Distributors',
    DISTRIBUTOR_DETAILS: 'Distributor Details',
    CHAT: 'Chat',
    ORDERS_HISTORY: "Client's Order History",
    PAYMENT_HISTORY: "Client's Payment History",
    REFILL_GAS: 'Refill Gas',
    ORDER_PAYMENT: 'Order Payment',
    CYLINDER_STOCK: 'Cylinder Stock',
    SET_LPG_PRICE: 'Set Gas Price',
    SET_DEL_CHRG: 'Set Delivery Charges',
    FILLED_CYLINDERS: 'Filled Cylinders',
    EMPTY_CYLINDERS: 'Empty Cylinders',
    NEW_CYLINDERS: 'New Cylinders',
    CLIENTS_ORDER: "Client's Order",
    NEW_CYLINDER_REQUEST: 'New Cylinder Request',
    INDEPENDENT_CYLINDER_REQUEST: 'Independent Retailer - Cylinder Request',
    INDEPENDENT_GAS_RETAILER: 'Gas Suppliers',
    INDEPENDENT_GAS_RETAILER_DETAILS: 'Details',
    CURRENT_SELLING_PRICE: 'Current Selling Price',
    REFILL_EXCHANGE_OWN_CYLINDER: 'Gas',
    QUANTITY: 'Quantity',
    REFILL_CYLINDER_REQUEST: 'Refill Cylinder Request',
    ORDER_TYPE_SELECTION: 'Select a order type',
    ORDER_STATUS_SELECTION: 'Select order status',
    SELECT_ANY_ONE_TYPE: 'You need to select any one.',
    CARD_DETAILS: 'Card Details',
    EMPTY_CYLINDER_REQUEST: 'New Cylinder Request',
    INDEPENDENT_PAYMENT_HISTORY: 'Gas Suppliers Payment'
};