import { Injectable } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';

@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {

  constructor() { }

  cropperSetting = function () {
    let cropperSettings: any = new CropperSettings();
    cropperSettings.width = 200;
    cropperSettings.height = 200;

    cropperSettings.croppedWidth = 200;
    cropperSettings.croppedHeight = 200;

    cropperSettings.canvasWidth = 400;
    cropperSettings.canvasHeight = 300;

    cropperSettings.minWidth = 10;
    cropperSettings.minHeight = 10;

    cropperSettings.rounded = true;
    cropperSettings.keepAspect = true;

    cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    cropperSettings.cropperDrawSettings.strokeWidth = 2;

    cropperSettings.noFileInput = true;
    return cropperSettings;
  }
}
