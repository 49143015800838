import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { PlantsRoutingModule, ROUTING_COMPONENTS } from './plants-routing.module';

@NgModule({
  declarations: [ROUTING_COMPONENTS],
  imports: [
    SharedModule,
    PlantsRoutingModule
  ]
})
export class PlantsModule { }
