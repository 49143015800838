import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_URL } from '../__utilities/api';
import { HeaderService } from '../__services/header.service';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {

	constructor(private http: HttpClient, private headerService: HeaderService) { }

	// Update Profile
	updateProfile(profileData): Observable<any> {
		return this.http.put(BASE_URL.PROFILE, profileData, this.headerService.imageHttpHeaders());
	}

	// Update Independent Profile
	updateIndependentProfile(profileData): Observable<any> {
		return this.http.put(BASE_URL.INDEPENDENT_GAS_RETAILER, profileData, this.headerService.imageHttpHeaders());
	}

	// Upload Avatar
	uploadAvatar(avatar): Observable<any> {
		return this.http.put(BASE_URL.UPLOAD_AVATAR, avatar, this.headerService.imageHttpHeaders());
	}

	// Add/Update Emergency Contact
	manageEmergencyContact(contact): Observable<any> {
		if (contact.id)
			return this.http.put(BASE_URL.EMERGENCY_CONTACT, contact, this.headerService.httpHeaders());
		return this.http.post(BASE_URL.EMERGENCY_CONTACT, contact, this.headerService.httpHeaders());
	}

	// Get Emergency Contact
	fetchEmergencyContact(): Observable<any> {
		return this.http.get(BASE_URL.EMERGENCY_CONTACT, this.headerService.httpHeaders());

	}

	// Manager Guarantor
	manageGuarantor(guarantor): Observable<any> {
		if (guarantor.get('id'))
			return this.http.put(BASE_URL.GUARANTOR, guarantor, this.headerService.imageHttpHeaders());
		return this.http.post(BASE_URL.GUARANTOR, guarantor, this.headerService.imageHttpHeaders());
	}

	// Fetch Profile
	fetchProfile(): Observable<any> {
		return this.http.get(BASE_URL.PROFILE, this.headerService.httpHeaders());
	}

	// Fetch Profile
	fetchIndependentProfile(id): Observable<any> {
		return this.http.get(BASE_URL.INDEPENDENT_GAS_RETAILER + "/" + id, this.headerService.httpHeaders());
	}

	// Fetch Guarantors
	fetchGuarantors(): Observable<any> {
		return this.http.get(BASE_URL.GUARANTOR, this.headerService.httpHeaders());
	}

	// Delete Guarantors
	deleteGuarantor(id): Observable<any> {
		return this.http.delete(BASE_URL.GUARANTOR + "/" + id, this.headerService.httpHeaders());
	}

	// Delete Contact
	deleteEmergencyContact(id): Observable<any> {
		return this.http.delete(BASE_URL.EMERGENCY_CONTACT + "/" + id, this.headerService.httpHeaders());
	}

	// Upload documents
	uploadDocuments(documents): Observable<any> {
		return this.http.put(BASE_URL.UPLOAD_DOCUMENTS, documents, this.headerService.imageHttpHeaders());
	}

	// Change Password
	changePassword(credentials): Observable<any> {
		return this.http.put(BASE_URL.CHANGE_PASSWORD, credentials, this.headerService.httpHeaders());
	}

	// Change user status (Online/Offline)
	changeUserStatus(id, payload): Observable<any> {
		return this.http.patch(BASE_URL.PLANTS + "/" + id + "/status", payload, this.headerService.httpHeaders());
	}
}
