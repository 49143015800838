import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PERMISSIONS } from '../../../__utilities/permissions';
import { AuthGuard } from '../../../__guard/auth.guard';
import { TITLE } from '../../../__utilities/text';
import { ROLES } from '../../../__utilities/roles';
import {
	CylinderCategorySettingComponent, RolesPermissionSettingComponent,
	CommonSettingComponent, IndependentProductSettingComponent,
	RegulatorInformationComponent, ContentManagementComponent, CylinderBrandCategoryComponent
} from '../../';

const routes: Routes = [
	{
		path: 'roles-permissions',
		component: RolesPermissionSettingComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.ROLES_PERMISSIONS,
			breadcrumb: [
				{
					label: TITLE.ROLES_PERMISSIONS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_ROLE_PERMISSION, ROLES.CORPORATE]
		}
	},
	{
		path: 'terms-conditions',
		component: ContentManagementComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.TERMS_CONDITIONS,
			breadcrumb: [
				{
					label: TITLE.TERMS_CONDITIONS,
					url: ''
				}
			],
			page: 'TC',
			permission_code: PERMISSIONS.UPDATE_TERMS_CONDITION,
			permission: [PERMISSIONS.VIEW_TERMS_CONDITION, ROLES.CORPORATE]
		}
	},
	{
		path: 'privacy-policy',
		component: ContentManagementComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PRIVACY_POLICY,
			breadcrumb: [
				{
					label: TITLE.PRIVACY_POLICY,
					url: ''
				}
			],
			page: 'PP',
			permission_code: PERMISSIONS.UPDATE_PRIVACY_POLICY,
			permission: [PERMISSIONS.VIEW_PRIVACY_POLICY, ROLES.CORPORATE]
		}
	},
	{
		path: 'consent-clause',
		component: ContentManagementComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.PRIVACY_POLICY,
			breadcrumb: [
				{
					label: TITLE.CONSENT_CLAUSE,
					url: ''
				}
			],
			page: 'CC',
			permission_code: PERMISSIONS.UPDATE_CONSENT_CLAUSE,
			permission: [PERMISSIONS.VIEW_CONSENT_CLAUSE, ROLES.CORPORATE]
		}
	},
	{
		path: 'cylinder-category-pricing',
		component: CylinderCategorySettingComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CYLINDER_CATEGORIES,
			breadcrumb: [
				{
					label: TITLE.CYLINDER_CATEGORIES,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_PRODUCT_PRICE, ROLES.CORPORATE]
		}
	},
	{
		path: 'cylinder-brand-category',
		component: CylinderBrandCategoryComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CYLINDER_BRAND_CATEGORIES,
			breadcrumb: [
				{
					label: TITLE.CYLINDER_BRAND_CATEGORIES,
					url: ''
				}
			],
			permission: [ROLES.CORPORATE, ROLES.INDEPENDENT_RETAILER]
		}
	},
	{
		path: 'common-setting',
		component: CommonSettingComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.COMMON_SETTINGS,
			breadcrumb: [
				{
					label: TITLE.COMMON_SETTINGS,
					url: ''
				}
			],
			permission: [PERMISSIONS.VIEW_COMMON_SETTING, ROLES.CORPORATE]
		}
	},
	{
		path: 'category-pricing',
		component: IndependentProductSettingComponent,
		canActivate: [AuthGuard],
		data: {
			title: TITLE.CYLINDER_STOCK,
			breadcrumb: [
				{
					label: TITLE.CYLINDER_STOCK,
					url: ''
				}
			],
			permission: [ROLES.INDEPENDENT_RETAILER]
		}
	}
	// { 
	// 	path: 'regulator-info', 
	// 	component: RegulatorInformationComponent, 
	// 	canActivate: [AuthGuard], 
	// 	data: { 
	// 		title: TITLE.REGULATOR_INFO, 
	// 		breadcrumb: [
	// 			{
	// 				label: TITLE.REGULATOR_INFO,
	// 				url: ''
	// 			}
	// 		],
	// 		permission: [PERMISSIONS.VIEW_REGULATOR_INFORMATION, ROLES.CORPORATE] 
	// 	} 
	// },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SettingRoutingModule { }

export const ROUTING_COMPONENTS = [
	CylinderCategorySettingComponent, RolesPermissionSettingComponent, RegulatorInformationComponent,
	CommonSettingComponent, ContentManagementComponent, IndependentProductSettingComponent, CylinderBrandCategoryComponent
];
